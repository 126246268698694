import React from "react";
import {
  //Button,
  Col,
  Typography,
  // Select,
  Avatar,
  Skeleton,
} from "antd";

import warning from "../../../assets/icons/warning.svg";
import { ReactComponent as SettlementIcon } from "../../../assets/icons/Settlement.svg";
import { ReactComponent as DisputeIcon } from "../../../assets/icons/Dispute.svg";
import { ReactComponent as AssetsIcon } from "../../../assets/icons/Assets.svg";
// import { useNavigate } from "react-router-dom";

const { Text } = Typography;
const SellerSettlementHeader = (props) => {
  const { data, isFetchingVendorData } = props;
  const isNegative = data?.amount_pending < 0;
  // const navigate = useNavigate();
  // const handleChange = (value) => {
  // };

  return (
    <>
      <Col className="settleHeaderWrapper">
        <Text className="settleStatement">MOB settlements</Text>

        {isFetchingVendorData ? (
          <>
            <Col style={{ display: "flex", gap: "20px" }}>
              <Skeleton active paragraph={{ rows: 4 }} />
              <Skeleton active paragraph={{ rows: 4 }} />
              <Skeleton active paragraph={{ rows: 4 }} />
            </Col>
          </>
        ) : (
          <>
            {/* <Row style={{flexFlow:"unset",gap:"16px"}}> */}
            <Col className="end-container-seller-view">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={8}
                className={`tag-main-container ${
                  isNegative && "negative-tag-text-container"
                }`}
                style={{
                  backgroundColor: isNegative
                    ? "#ffebe6"
                    : "rgba(255, 255, 255, 0.2)",
                  border: "none",
                }}
              >
                <Col
                  className={`icon-main-container ${
                    isNegative && "negative-icon-main-container"
                  }`}
                >
                  <SettlementIcon />
                </Col>
                <Col className={`tag-text-container`}>
                  <Text
                    className={`tag-text-heading`}
                    style={{ color: isNegative ? "#0a243f" : "#0a243f" }}
                  >
                    Settlement amount pending
                  </Text>
                  <Text
                    className="tag-text-number"
                    style={{ color: isNegative ? "#bf2600" : "#0a243f" }}
                  >
                    ₹ {(data?.amount_pending || 0).toFixed(2)}
                  </Text>
                  {isNegative ? (
                    <Text
                      className="negative-text"
                      style={{ color: "#0a243f" }}
                    >
                      This negative amount is because of returns and exchange
                      after the settlement was done
                    </Text>
                  ) : (
                    <Text
                      className="negative-text"
                      style={{ color: "#0a243f" }}
                    >
                      This is the amount pending to be paid.
                    </Text>
                  )}
                  {/* <Text
                        className="footer-container-text"
                        style={{ color: "#fff" }}
                      >
                        Revenue: ₹ {(data?.revenue || 0).toFixed(2)}
                      </Text>
                      <Text
                        className="footer-container-text"
                        style={{ color: "#fff" }}
                      >
                        Tax: ₹ {(data?.tax || 0).toFixed(2)}
                      </Text> */}
                </Col>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={8}
                className="dispute-container"
                style={{
                  backgroundColor: "#fcf2eb",
                  border: "none",
                }}
              >
                <Col className="icon-background-4">
                  <DisputeIcon />
                </Col>
                <Col className="tag-text-container">
                  <Text
                    className="tag-text-heading"
                    style={{ color: "#0a243f" }}
                  >
                    To be resolved
                  </Text>
                  <Text
                    className="tag-text-number"
                    style={{ color: "#0a243f" }}
                  >
                    ₹ {(data?.to_be_resolved || 0).toFixed(2)}
                  </Text>
                  <Text
                    className="footer-container-text"
                    style={{ color: "#0a243f" }}
                  >
                    This is the amount for orders to be resolved. It will be
                    released immediately thereafter.
                  </Text>
                </Col>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={8}
                className="assets-container"
                style={{
                  backgroundColor: "#ebf7ef",
                  border: "none",
                }}
              >
                <Col className="icon-background-5">
                  <AssetsIcon />
                </Col>
                <Col className="tag-text-container">
                  <Text
                    className="tag-text-heading"
                    style={{ color: "#0a243f" }}
                  >
                    Payment settled
                  </Text>
                  <Text
                    className="tag-text-number"
                    style={{ color: "#0a243f" }}
                  >
                    ₹ {(data?.payment_settled || 0).toFixed(2)}
                  </Text>
                  <Text
                    className="footer-container-text"
                    style={{ color: "#0a243f" }}
                  >
                    This is the amount that has been paid to you by MOB for
                    orders
                  </Text>
                </Col>
              </Col>
            </Col>
            {/* </Row> */}
            {data?.amount_pending < 0 && (
              <Col className="extra-payment">
                <Avatar
                  style={{ marginRight: "15px" }}
                  shape="square"
                  src={warning}
                />{" "}
                You have recieved and extra payment of ₹{" "}
                {Math.abs(data?.amount_pending || 0).toFixed(2)} .It will be
                adjusted in the next settlement
              </Col>
            )}
          </>
        )}
      </Col>
    </>
  );
};

export default SellerSettlementHeader;
