import { React } from "react";
import { TimePicker, Form, Col } from "antd";
import dayjs from "dayjs";

const SellerStoreTiming = (prop) => {
  const {
    ShopTimingForm,
    handleEditedTimingsData,
    ShopOpeningTime,
    ShopClosingTime,
  } = prop;
    

  return (
    <div>
      <Col className="password-change-container">
        <Form
          form={ShopTimingForm}
          name="ShopTimingForm"
          autoComplete="true"
          style={
            {
              // maxWidth: 500,
            }
          }
          layout="vertical"
          onFinish={handleEditedTimingsData}
        >
          <Col style={{ display: "flex", justifyContent: "space-around" }}>
            <Form.Item
              label={
                <span>
                  Open time
                  {/* <span className="text-danger"> *</span> */}
                </span>
              }
              name="shop_opening_time"
              rules={[
                {
                  required: true,
                  message: "Please select shop opening time",
                },
              ]}
            >
              <TimePicker
                use12Hours={true}
                className="time-picker-control"
                defaultValue={ShopOpeningTime === '' ? '' : dayjs(ShopOpeningTime, 'hh:mm a')}
              
                format="hh:mm A"
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Close time
                  {/* <span className="text-danger"> *</span> */}
                </span>
              }
              name="shop_closing_time"
              rules={[
                {
                  required: true,
                  message: "Please select shop closing time",
                },
              ]}
            >
              <TimePicker
                use12Hours={true}
                className="time-picker-control"
                defaultValue={ShopClosingTime === '' ? '' : dayjs(ShopClosingTime, 'hh:mm a')}
                format="hh:mm A"
              />
            </Form.Item>
          </Col>
        </Form>
      </Col>
    </div>
  );
};

export default SellerStoreTiming;
