import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./../baseQuery";

export const SellerOrdersApi = createApi({
  reducerPath: "SellerOrdersAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    SellerOrder: builder.query({
      query: (params) => {
        return {
          url: `api/seller/order/get_suborders/`,
          params,
        };
      },
    }),  
    SellerPickupOrder: builder.query({
      query: (params) => {
        return {
          url: `api/seller/order/get_ready_for_pickup/`,
          params,
        };
      },
    }), 
    SellerGetSubOrderDetails: builder.query({
      query: (id) => {
        return {
          url: `api/seller/order/${id}/get_suborder_details/`,        
        };
      },
    }),   
    UpdateOrderStatus: builder.mutation({
      query: ({id, params}) => ({
        url: `api/seller/order/${id}/update_suborder_details/`,
        method: "PATCH",
        body: params,
      }),
    }),   

    UpdateVehcileRequestStatus: builder.mutation({
      query: ({id, params}) => ({
        url: `api/seller/order/assign_suborder_vehicle/`,
        method: "POST",
        body: params,
      }),
    }), 
   
  }),
});

export const { useLazySellerOrderQuery,useLazySellerPickupOrderQuery ,useLazySellerGetSubOrderDetailsQuery,useUpdateOrderStatusMutation,useUpdateVehcileRequestStatusMutation} = SellerOrdersApi;

export default SellerOrdersApi;
