import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useLazyGetCustomersSubOrdersDetailsQuery} from "../../../apis/customer";
import {Avatar, Button, Col, Row, Skeleton, Typography} from "antd";
import "./CustomerSubOrderDetails.css";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {ReactComponent as TableShopIcon} from "../../../assets/icons/TableShopicon.svg";
import {ReactComponent as InfoIcon} from "../../../assets/icons/InfoIcon.svg";
import {ReactComponent as FileInvoiceIcon} from "../../../assets/icons/invoiceButtonIcon.svg";
import {dateFormat} from "../../../commonFuntions/CommonUtilFunctions";
import OrderAddress from "../../ManageOrders/OrderDetails/OrderAddress/OrderAddress";
import BillingAddress from "../../../components/Modal/EditAddressModal/AddAddress/BillingAddress";
import DropdownComponent from "../../ManageOrders/OrderDetails/OrderItems/DropdownComponent";
import CustomSteps from "../../../components/CustomSteps/CustomSteps";
import CustomImageContainer from "../../../components/CustomImageContainer/CustomImageContainer";
import ViewAllImageModal from "../../../components/Modal/SubOrderModals/ViewAllImageModal/ViewAllImageModal";
import {useDispatch, useSelector} from "react-redux";
import {subOrderViewImagesDataSelector} from "../../../redux/slices/manageOrders/selector";
import {getSubOrderViewImagesData} from "../../../redux/slices/manageOrders/action";
const initialFormData = {
  nameCompany: "",
  mobileNo: "",
  gst: "",
  addressI: "",
  addressII: "",
  pincode: "",
  city: "",
  state: "",
  email: "",
  address_tag: "",
  id: "",
};
const CustomerSubOrderDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const viewImageList = useSelector(subOrderViewImagesDataSelector);
  const navigate = useNavigate();
  const {Text} = Typography;
  const [billingModal, setBillingModal] = useState(false);
  const [viewAllModalOpen, setViewAllModalOpen] = useState(false);
  const [billingFormData, setBillingFormData] = useState(initialFormData);
  const [
    ,
    // isModalOpen
    setIsModalOpen,
  ] = useState(false);
  const handleViewAllModalCancel = () => {
    setViewAllModalOpen(false);
    dispatch(getSubOrderViewImagesData([]));
  };
  const handleViewAllModalOpen = (data) => {
    console.log("🚀 ~ handleViewAllModalOpen ~ data:", data);
    dispatch(getSubOrderViewImagesData(data));
    setViewAllModalOpen(true);
  };

  const [
    getCustomerSubOrderApi,
    {data: customerSubOrder, isFetching: isFetchingEditData},
  ] = useLazyGetCustomersSubOrdersDetailsQuery();
  console.log("🚀 ~ CustomerOrders ~ params:", params, customerSubOrder);
  const showModal = () => {
    setIsModalOpen(true);
  };
  useEffect(() => {
    getCustomerSubOrderApi(params?.order_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleBackNavigate = () => {
    navigate(-1);
  };
  const handleNavigateToRFQDetails = (id) => {
    navigate(`/admin/rfq/quotes/${id}`);
  };
  const handleAddGst = () => {
    setBillingModal(true);
    setBillingFormData({
      nameCompany: customerSubOrder?.data?.billing_address?.name || "",
      mobileNo: customerSubOrder?.data?.billing_address?.phone_number || "",
      gst: customerSubOrder?.data?.billing_address?.gst_number || "",
      addressI: customerSubOrder?.data?.billing_address?.address_line_1 || "",
      addressII: customerSubOrder?.data?.billing_address?.address_line_2 || "",
      pincode: customerSubOrder?.data?.billing_address?.pincode || "",
      city: customerSubOrder?.data?.billing_address?.city || "",
      state: customerSubOrder?.data?.billing_address?.state || "",
      email: customerSubOrder?.data?.billing_address?.email || "",
      address_tag: customerSubOrder?.data?.billing_address?.address_tag || "",
      id: customerSubOrder?.data?.billing_address?.id || "",
    });
  };
  return (
    <>
      <Row className="customer-sub-order-container">
        <Col className="sub-order-main-container">
          <Col className="sub-order-main-container-left">
            <Button
              className="sub-order-back-button"
              icon={<ArrowLeftOutlined />}
              onClick={handleBackNavigate}>
              BACK TO ORDERS1
            </Button>
            {isFetchingEditData ? (
              <>
                <Skeleton active style={{width: "50%"}} />
              </>
            ) : (
              <>
                <Col className="sub-order-heading">
                  <Col className="order-del" style={{marginTop: "20px"}}>
                    <Text className="header-text">Order Details</Text>
                    <Text className="header-textI">
                      Order ID:{" "}
                      <Link
                        className="order-id-text"
                        to={`/admin/orders/order-details/${customerSubOrder?.data?.id}`}
                        // onClick={() => handleDetailNav(record)}
                        target="_blank">
                        {customerSubOrder?.data?.order_id || "N/A"}
                      </Link>
                    </Text>
                    <Text className="header-textI">
                      Placed on:{" "}
                      {customerSubOrder?.data?.created_at
                        ? dateFormat(customerSubOrder?.data?.created_at)
                        : "N/A"}
                    </Text>
                    {(customerSubOrder?.data?.is_direct_order || true) && (
                      <>
                        {customerSubOrder?.data?.rfq_details?.rfq_id && (
                          <Text className="header-textI">
                            RFQ No:{" "}
                            <Text
                              style={{color: "#2973f0", cursor: "pointer"}}
                              onClick={() =>
                                handleNavigateToRFQDetails(
                                  customerSubOrder?.data?.rfq_details?.rfq_id
                                )
                              }>
                              {customerSubOrder?.data?.rfq_details?.rfq_id}
                            </Text>
                          </Text>
                        )}
                      </>
                    )}
                  </Col>
                  <Col>
                    {/* <Button
                      icon={<ContactUsIcon />}
                      className="contact-us-button"
                    >
                      Contact us
                    </Button> */}
                  </Col>
                </Col>
              </>
            )}
            {isFetchingEditData ? (
              <>
                <Skeleton.Button active block />
              </>
            ) : (
              <>
                {(customerSubOrder?.pending_amount || true) && (
                  <Col className="sub-order-note">
                    <Text className="sub-order-note-text-1">NOTE:</Text>{" "}
                    <Text className="sub-order-note-text-2">
                      In multiple shipment scenarios, all your products will
                      reach you as expected, however, the products might not be
                      assigned accurately against the vehicles.
                    </Text>
                  </Col>
                )}
              </>
            )}

            <Row className="sub-orders-card-container">
              {customerSubOrder?.data?.suborders?.map((value, index) => {
                return (
                  <Row key={index} className="sub-orders-card-container-parent">
                    <Row className="sub-orders-card-container-parent-header">
                      <Row className="myAccOrderDetailsTableImageParent">
                        <Row className="myAccOrderDetailsTableImageContentParent">
                          <Avatar
                            // src={BackyardIcon}
                            icon={<TableShopIcon />}
                            height={32}
                            width={32}
                            alt="error"
                          />
                        </Row>

                        <Row className="myAccOrderDetailsTableTextContentParent">
                          <Text className="myAccOrderDetailsTableTextTitleParent">
                            {value?.products[0]?.vendor}
                          </Text>
                          <Text className="myAccOrderDetailsTableTextValueParent">
                            Sub ID :{value?.suborder_id}
                          </Text>
                        </Row>
                      </Row>
                      <Row className="sub-order-button-container">
                        {/* <Col className="sub-order-button-container-dropdown"> */}
                        <DropdownComponent
                          title={"files attached"}
                          data={value?.suborder_files}
                          // _onClick={handleAddFile}
                          subOrderData={value}
                          // deleteSuborderFileApi={deleteSuborderFileApi}
                        />
                        {/* </Col> */}
                        {/* {!!value?.suborder_files?.length > 0 && (
                          <InvoiceDropdown
                            title={"files attached"}
                            data={value?.suborder_files}
                          />
                        )} */}
                        {value?.invoices?.id && (
                          <Button
                            className="sub-order-view-all-item-button"
                            icon={<FileInvoiceIcon />}
                            // onClick={() => handleOpenModal(value?.products)}
                          >
                            Invoice
                          </Button>
                        )}
                        {/* {value?.invoices && ( */}
                        {/* <Button
                          className="sub-order-view-all-item-button"
                          icon={<CrossCircleIcon />}
                          // onClick={() => handleOpenModal(value?.products)}
                        >
                          Cancel
                        </Button> */}
                        {/* )} */}
                      </Row>
                    </Row>

                    <Row className="myAccProductCategoryItemsParent">
                      <Row className="myAccProductCategoryItemsHeadingResponsiveParent">
                        <Row className="myAccProductCategoryItemsHeadingParent">
                          <Text className="myAccOrderDetailsTableTextTitleParent">
                            Shipment {1} :
                            <Text className="myAccOrderDetailsTableTextShipment">
                              {value.status === "Waiting"
                                ? " Order created"
                                : ` ${value.status}`}
                            </Text>
                          </Text>

                          <Button
                            className="sub-order-view-all-item-button"
                            onClick={() =>
                              handleViewAllModalOpen(value?.products)
                            }>
                            View all items
                          </Button>
                        </Row>
                        <CustomImageContainer
                          imageList={value?.products}
                          displayImage={2}
                          handleClick={handleViewAllModalOpen}
                          paramData={value?.products}
                        />
                      </Row>
                      <CustomSteps
                        trackingList={value?.tracking}
                        order_placed={customerSubOrder?.data?.created_at}
                      />
                    </Row>
                  </Row>
                );
              })}
              <ViewAllImageModal
                handleCancel={handleViewAllModalCancel}
                isModalOpen={viewAllModalOpen}
                imageList={viewImageList}
              />
            </Row>
          </Col>
          <Col className="sub-order-main-container-right">
            <Col className="sub-order-right-inside-container">
              {isFetchingEditData ? (
                <>
                  <Skeleton avatar active paragraph={{rows: 1}} />
                </>
              ) : (
                <OrderAddress
                  title={"Delivery address"}
                  addressType={"Delivery"}
                  addressID={customerSubOrder?.data?.delivery_addreess?.id}
                  addressDetails={customerSubOrder?.data?.delivery_address}
                  // addressDetails={editOrderData?.address?.delivery_addreess}
                  deliveryAddress={customerSubOrder?.data?.delivery_address}
                  //   func={editAddressModal}
                  //   orderId={orderId}
                  order={true}
                  editCheck={true}
                />
              )}
              {isFetchingEditData ? (
                <>
                  <Skeleton active paragraph={{rows: 1}} />
                </>
              ) : (
                <OrderAddress
                  title={"Billing address"}
                  addressType={"Billing"}
                  addressID={customerSubOrder?.data?.billing_address?.id}
                  addressDetails={customerSubOrder?.data?.billing_address}
                  deliveryAddress={customerSubOrder?.data?.billing_address}
                  // addressDetails={editOrderData?.address?.billing_address}
                  //   func={editBillingModal}
                  //   orderId={orderId}
                  order={true}
                  editCheck={true}
                />
              )}
              {isFetchingEditData ? (
                <>
                  <Skeleton active paragraph={{rows: 1}} />
                </>
              ) : (
                <>
                  {!customerSubOrder?.data?.billing_address?.gst_number && (
                    <Col className="add-gst">
                      <Text>Forgot to add GST?</Text>
                      <Text className="gst-btn" onClick={() => handleAddGst()}>
                        Request to add
                      </Text>
                    </Col>
                  )}
                </>
              )}
              <BillingAddress
                initialFormData={initialFormData}
                billingFormData={billingFormData}
                setBillingFormData={setBillingFormData}
                billingModal={billingModal}
                setBillingModal={setBillingModal}
                setIsModalOpen={setIsModalOpen}
                editData={customerSubOrder?.data?.billing_address}
                addressListModal={showModal}
              />
              {isFetchingEditData ? (
                <>
                  <Skeleton active paragraph={{rows: 1}} />
                </>
              ) : (
                <>
                  <Col className="sub-order-summary-container">
                    <Col className="sub-order-summary-text-heading">
                      Order summary
                    </Col>
                    <Col className="bill-wrapper">
                      <Col className="amount-calc">
                        <Text>Subtotal</Text>
                        <Text>{`₹ ${Number(
                          customerSubOrder?.data?.sub_total
                        ).toFixed(2)}`}</Text>
                      </Col>
                      <Col className="amount-calc">
                        <Text className="align-text">
                          Shipping <InfoIcon />
                        </Text>
                        <Text>{`₹ ${Number(
                          customerSubOrder?.data?.shipping_fee || 0
                        ).toFixed(2)}`}</Text>
                      </Col>
                      <Col className="amount-calc">
                        <Text className="align-text">
                          Tax
                          <InfoIcon />
                        </Text>
                        <Text>{`₹ ${Number(
                          customerSubOrder?.data?.sgst * 2
                        ).toFixed(2)}`}</Text>
                      </Col>
                      <Col className="amount-calc">
                        <Text>Discounts</Text>
                        <Text style={{color: "#01a685"}}>{` - ₹ ${Number(
                          Math.abs(customerSubOrder?.data?.discount || 0)
                        ).toFixed(2)}`}</Text>
                      </Col>
                    </Col>
                    <Col className="sub-order-total-amount">
                      <Col className="amount-calc">
                        <Text style={{fontSize: "18px"}}>Total</Text>
                        <Text style={{fontSize: "18px"}}>{`₹ ${Number(
                          customerSubOrder?.data?.total
                        ).toFixed(2)}`}</Text>
                      </Col>
                    </Col>
                    <Col className="sub-order-pending-amount">
                      <Text style={{fontSize: "18px", fontWeight: 500}}>
                        Payment method
                      </Text>
                      <Text
                        style={{
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#0a243f",
                        }}>
                        Visa card ending 9987
                      </Text>
                    </Col>
                  </Col>
                </>
              )}
            </Col>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default CustomerSubOrderDetails;
