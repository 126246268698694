import React from "react";
import "./CancelCreateQuoteModal.css";
import { Button, Col, Modal, Typography } from "antd";
import { ReactComponent as FileErrorIcon } from "../../../../assets/seller/icons/fileerror.svg";
const CancelCreateOrderModal = (props) => {
  const { Text } = Typography;
  const {
    open,
    handleOk,
    handleSave = () => {},
    // data = ""
  } = props;

  return (
    <>
      <Modal
        className="Cancel-Create-QuoteModal"
        // title={"You have unsaved changes"}
        onCancel={handleOk}
        open={open}
        footer={[
          <Col className="cancel-footer-container">
            <Button onClick={handleOk} className="cancel-button">
              CANCEL
            </Button>
            <Button
              onClick={() => handleSave()}
              className="cancel-delete-button"
            >
              Leave without saving
            </Button>
          </Col>,
        ]}
      >
        <Col className="container-modal text-center ">
        <FileErrorIcon className="mt-4"/>
          <Text className="inner-text">
          You have unsaved changes
          </Text>
            <Text className="outer-text"> Are you sure you want to leave this screen and discard your changes?
          </Text>
        </Col>
      </Modal>
    </>
  );
};

export default CancelCreateOrderModal;
