import React, { useState } from "react";
import {
  Button,  
  Col,
  Modal,
  Typography, 
} from "antd";
import "./ConfirmOrderModal.css";
import { ReactComponent as RemoveStockIcon } from "../../../../assets/seller/icons/removestockicon.svg";
const { Title } = Typography;

const ConfirmStockModal = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    editQuoteData,
    handleConfirmOrder,    
    loading,
    orderButtonText,
    isCreditOrder,    
    // eslint-disable-next-line 
    dueAmount
  } = props;
      

  const [checkboxState, setCheckboxState] = useState({
    email: false,
    phone_number: false,
  });

  // const handleCheckboxChange = (e, type) => {
  //   const targetValue = e.target.checked;
  //   setCheckboxState({ ...checkboxState, [type]: targetValue });
  // };

  const handleSave = () => {
    handleConfirmOrder(checkboxState, editQuoteData?.id);
    // setIsModalOpen(false);
    // setCheckboxState({ email: false, phone_number: false });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setCheckboxState({ email: false, phone_number: false });
  };

  return (
    <>
      <Modal
        onCancel={handleCancel}
        title={"Are you sure?"}
        // open={true}
        open={isModalOpen}
        style={{ color: "#0a243f", padding: "0px" }}
        footer={[
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              // borderTop: "1px solid #dedede",
              borderRadius: "0px 0px 8px 8px",
              padding: "20px 0px",
              // boxShadow: "0 -6px 10px 0 rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
              width: "100%",
            }}
          >
            <Button
              onClick={handleCancel}
              style={{
                height: "48px",
                fontWeight: 500,
                fontSize: "14px",
                maxWidth: "120px",
                width: "100%",
              }}
            >
              CANCEL
            </Button>
            <Button
              loading={loading}
              disabled={loading}
              onClick={handleSave}
              style={{
                height: "48px",
                maxWidth: "280px",
                width: "100%",
                fontWeight: 500,
                fontSize: "14px",
                color: "white",
                backgroundColor: isCreditOrder ? "#535dd8" : "#015fe5",   
                border: "none",
              }}
            >
              {orderButtonText}
            </Button>
          </Col>,
        ]}
      >
        <Col
          style={{
            display: "flex",
            height: isCreditOrder ? "550px":"250px",
            flexDirection: "column",
            justifyContent: "center",
            padding: "20px",
            overflowY: "scroll",
            marginBottom:"20px",
            textAlign:"center"
          }}
        >
         
            <>
            <Col className="mb-2">
            <RemoveStockIcon/>
            </Col>
            <Col className="mt-4">
            <Title
              level={4}
              style={{
                color: "#0a243f",
                // margin: 0,
                marginBottom: "30px",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
             Are you sure you want to remove these
             items from stock? 
            </Title>
            <Title
              level={4}
              style={{
                color: "#0a243f",
                // margin: 0,
                margin: "20px",
                fontWeight: 400,
                fontSize: "14px",
              }}
            >
             Are you sure you want to remove these
             items from stock? <br /> 
             These items will be reduced from your inventory. If you are not maintaing inventory count it may appear in negative
            </Title>
            </Col>
          </>
          
         
        </Col>
      </Modal>
    </>
  );
};

export default React.memo(ConfirmStockModal);
