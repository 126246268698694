import React, { useEffect } from "react";
import { Tabs } from "antd";
import "./SellerSettlement.css";
import SettlementHeader from "./SellerSettlementHeader";
import RecentSettlement from "./SellerRecentSettlement";
import MobOrders from "./SellerMobOrders";
import { useNavigate, useParams } from "react-router-dom";
import {
  useLazyGetMobOrdersQuery,
  useLazyGetSearchSettlementVendorsQuery,
  useLazyGetSettlementDataByIDQuery,
} from "../../../apis/Seller/SellerSettlementAPI";

import {
  getSettlementDataByID,
  getSettlementSearchData,
} from "../../../redux/slices/settlement/action";
import {
  settlementDataByIDSelector,
  settlementSearchDataSelector,
} from "../../../redux/slices/settlement/selector";
import { useDispatch, useSelector } from "react-redux";

const SellerSettlements = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectorData = useSelector(settlementDataByIDSelector);
  const searchDropdownData = useSelector(settlementSearchDataSelector);
  const [orderTableApi, { data: orderData, isFetching: isLoading }] =
    useLazyGetMobOrdersQuery();
    
  const [
    getSettlementByIDAPI,
    { data: settlementDataByID, isFetching: isFetchingVendorData },
  ] = useLazyGetSettlementDataByIDQuery();

  const [getSettlementSearchApi, { data: settlementSearchData }] =
    useLazyGetSearchSettlementVendorsQuery();

  
  useEffect(() => {
    getSettlementByIDAPI({ bmp_id: param?.bmp_id });
    // getSettlementSearchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param?.bmp_id]);
  useEffect(() => {
    if (settlementDataByID?.status) {
      dispatch(getSettlementDataByID(settlementDataByID?.data));
    }
    if (settlementSearchData?.status) {
      dispatch(getSettlementSearchData(settlementSearchData?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settlementDataByID, settlementSearchData]);

  const onChange = (key) => {   
    if (key === "settlement") {
      getSettlementByIDAPI({ bmp_id: param?.bmp_id });
    } else {
      orderTableApi({ bmp_id: param?.bmp_id });
    }
  };
  const handleHitApi = (item) => {
    getSettlementByIDAPI({ bmp_id: item?.bmp_id });
  };


  const items = [
    {
      key: "settlement",
      label: "Recent settlements",
      children: (
        <RecentSettlement
          data={selectorData?.settlements}
          vendor_id={selectorData?.vendor_info?.vendor_id}
          getSettlementByIDAPI={getSettlementByIDAPI}
          bmp_id={param?.bmp_id}
          seller_name={selectorData?.vendor_info?.vendor_name}
          handleRefetch={handleHitApi}
        />
      ),
    },
    {
      key: "orders",
      label: "MOB orders",
      children: (
        <MobOrders
          bmp_id={param?.bmp_id}
          orderTableApi={orderTableApi}
          orderData={orderData}
          isLoading={isLoading}
         
        />
      ),
    },
  ];
  const handleBackNavigate = () => {
    navigate(`/admin/pending-settlement`);
  };
  return (
    <>
      <SettlementHeader
        data={selectorData?.vendor_info}
        handleBackNavigate={handleBackNavigate}
        searchDropdownData={searchDropdownData}
        getSettlementSearchApi={getSettlementSearchApi}
        handleHitApi={handleHitApi}
        seller_name={selectorData?.vendor_info?.vendor_name}       
        isFetchingVendorData={isFetchingVendorData}
      />
      <Tabs
        className="settlementWrapper"
        size="large"
        defaultActiveKey="1"
        items={items}        
        onChange={onChange}
      />
    </>
  );
};

export default SellerSettlements;
