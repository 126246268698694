import React, { useEffect, useState } from "react";
import "./SellerSalesSummary.css";
import {
  //  Avatar, Dropdown, Menu,
  Col,
  Tag,
  Typography,
} from "antd";
import {
  LoadingOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput.js";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect.js";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker.js";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination.js";
import {
  useLazyGetDownloadSellerSalesReportsQuery,
  useLazyGetSellerSalesReportsQuery,
} from "../../../apis/Seller/SellerReportsAPI.js";
import TableSkeleton from "../../../components/skeleton/TableSkeleton.js";
// import { DashOutlined } from "@ant-design/icons";
// import { ReactComponent as DefaultIcon } from "../../../assets/icons/default.svg";

import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import {  
  statusColorMapping,
} from "../../../commonFuntions/CommonUtilFunctions.js";
const { Text } = Typography;
const options = [
  {
    key: 1,
    value: "Export",
    label: "Export",
  },
];

const dateOptions = [
  {
    key: 1,
    value: "Today",
    label: "Today",
  },
  {
    key: 2,
    value: "Yesterday",
    label: "Yesterday",
  },
  {
    key: 3,
    value: "last_7_day",
    label: "Last 7 day",
  },
  {
    key: 4,
    value: "last_30_day",
    label: "Last 30 day",
  },
  {
    key: 5,
    value: "last_60_day",
    label: "Last 60 day",
  },
  {
    key: 6,
    value: "Custom",
    label: "Custom",
  },
];

const SellermobCreditSales = () => {
  const [dateFilter, setDateFilter] = useState();
  const [searchedValue, setSearchedValue] = useState({});
  const [dateSearch, setDateSearch] = useState({
    start_date: "",
    end_date: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [sorterState, setSorterState] = useState({
    order_id: "",
    sub_order_id: "",
    created_at: "",
  });
  const [getSellerSalesReportApi, { data: sellerSalesReportData, isFetching }] =
    useLazyGetSellerSalesReportsQuery();
  const [
    getDownloadSellerSalesReportApi,
    { isFetching: DownloadReportFetching },
  ] = useLazyGetDownloadSellerSalesReportsQuery();
  useEffect(() => {
    getSellerSalesReportApi({mobCredit:true});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (value) => {
    let formattedDate = null;

    switch (value) {
      case "Today":
        formattedDate = dayjs().format("YYYY-MM-DD");
        break;
      case "Yesterday":
        formattedDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        break;
      case "last_7_day":
        formattedDate = dayjs().subtract(6, "day").format("YYYY-MM-DD");
        break;
      case "last_30_day":
        formattedDate = dayjs().subtract(29, "day").format("YYYY-MM-DD");
        break;
      case "last_60_day":
        formattedDate = dayjs().subtract(59, "day").format("YYYY-MM-DD");
        break;
      case "Custom":
        formattedDate = "Custom";
        break;
      default:
        break;
    }
   
    const currentDate = dayjs().format("YYYY-MM-DD");
    if (value !== "Custom" && value !== null) {
      if (value === "clear_date") {
        getSellerSalesReportApi({
          ...(searchedValue?.search && { search: searchedValue?.search }),
          ...(sorterState.order_id && { sort_by: sorterState.order_id }),
          ...(sorterState.sub_order_id && {
            sort_by: sorterState.sub_order_id,
            mobCredit:true,
          }),
          ...(sorterState.created_at && { sort_by: sorterState.created_at }),
          page: 1,
        });
        setDateSearch({
          start_date: "",
          end_date: "",
        });
      } else {
        getSellerSalesReportApi({
          start_date: formattedDate,
          end_date: currentDate,
          mobCredit:true,
          ...(searchedValue?.search && { search: searchedValue?.search }),
          ...(sorterState.order_id && { sort_by: sorterState.order_id }),
          ...(sorterState.sub_order_id && {
            sort_by: sorterState.sub_order_id,
          }),
          ...(sorterState.created_at && { sort_by: sorterState.created_at }),
          page: 1,
        });
        setDateSearch({
          start_date: formattedDate,
          end_date: currentDate,
        });
      }
      setCurrentPage(1);
    }
    setDateFilter(value);
  };
  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates && dates.length === 2) {
      const startDateFormatted = dates[0].format("YYYY-MM-DD");
      const endDateFormatted = dates[1].format("YYYY-MM-DD");
      getSellerSalesReportApi({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
        ...(sorterState.order_id && { sort_by: sorterState.order_id }),
        ...(sorterState.sub_order_id && { sort_by: sorterState.sub_order_id }),
        ...(sorterState.created_at && { sort_by: sorterState.created_at }),
        page: 1,
        mobCredit:true,
      });
      setDateSearch({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
      });
    }
  };
  const sorterChange = (item) => {
    let key = item;
    setSorterState({
      // ...sorterState,
      [key.replace("-", "")]: item,
    });
    getSellerSalesReportApi({
      sort_by: item,
      ...(searchedValue?.search && { search: searchedValue?.search }),
      ...(dateSearch?.start_date && { start_date: dateSearch?.start_date }),
      ...(dateSearch?.end_date && { end_date: dateSearch?.end_date }),
      mobCredit:true,
      page: 1,
    });
  };
  const handleExportApi = async () => {    
    let response = await getDownloadSellerSalesReportApi({
      ...(searchedValue.search && { search: searchedValue.search }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      ...(sorterState.order_id && { sort_by: sorterState.order_id }),
      ...(sorterState.sub_order_id && { sort_by: sorterState.sub_order_id }),
      ...(sorterState.created_at && { sort_by: sorterState.created_at }),
      mobCredit:true,
      // download: true,
    });
    if (response?.status === "fulfilled") {
      // handlePdfDownload(response?.data?.data?.url);
      saveAs(response?.data?.data?.url);
    }
  };

  const columns = [
    // {
    //   // title: "Order Id",
    //   fixed: "left",
    //   title: (
    //     <>
    //       <Col className="title_sorter">
    //         <Text className="sorter_heading">Order ID</Text>
    //         <Text className="sorter_container_icon">
    //           <CaretUpOutlined
    //             style={{
    //               color: sorterState?.order_id === "order_id" ? "" : "grey",
    //             }}
    //             onClick={() => sorterChange("order_id")}
    //             className="sorter_icon"
    //           />
    //           <CaretDownOutlined
    //             style={{
    //               color: sorterState?.order_id === "-order_id" ? "" : "grey",
    //             }}
    //             onClick={() => sorterChange("-order_id")}
    //             className="sorter_icon"
    //           />
    //         </Text>
    //       </Col>
    //     </>
    //   ),
    //   dataIndex: "order_id",
    //   key: "order_id",
    //   width: "200px",
    //   render: (params, record) => {
    //     return (
    //       <Link
    //         // onClick={() => handleDetailNav(record)}
    //         to={`/admin/orders/order-details/${record?.order?.id}`}
    //         target="_blank"
    //         className="dashboard-table-orderid"
    //       >
    //         {params}
    //       </Link>
    //     );
    //   },
    // },
    {
      
      fixed: typeof window !== 'undefined' && window.innerWidth > 768 ? "left" : "",
      // title: "Sub Order Number",
      title: (
        <>
          <Col className="title_sorter">
            <Text className="sorter_heading">Sub Order ID</Text>
            <Text className="sorter_container_icon">
              <CaretUpOutlined
                style={{
                  color:
                    sorterState?.sub_order_id === "sub_order_id" ? "" : "grey",
                }}
                onClick={() => sorterChange("sub_order_id")}
                className="sorter_icon"
              />
              <CaretDownOutlined
                style={{
                  color:
                    sorterState?.sub_order_id === "-sub_order_id" ? "" : "grey",
                }}
                onClick={() => sorterChange("-sub_order_id")}
                className="sorter_icon"
              />
            </Text>
          </Col>
        </>
      ),
      dataIndex: "suborder_id",
      key: "sub_order_no",
      width: "300px",
      render: (params, record) => {
        return (
          <Link
            // onClick={() => handleDetailNav(record)}
            to={`/seller/orders/SellerViewDetails/${record?.suborder_id}`}
            target="_blank"
            className="dashboard-table-orderid"
          >
            {params}
          </Link>
        );
      },
    },
    //////////////////////////
    {
      // title: "Order Date",
      title: (
        <>
          <Col className="title_sorter">
            <Text className="sorter_heading">Order Date</Text>
            <Text className="sorter_container_icon">
              <CaretUpOutlined
                style={{
                  color: sorterState?.created_at === "created_at" ? "" : "grey",
                }}
                onClick={() => sorterChange("created_at")}
                className="sorter_icon"
              />
              <CaretDownOutlined
                style={{
                  color:
                    sorterState?.created_at === "-created_at" ? "" : "grey",
                }}
                onClick={() => sorterChange("-created_at")}
                className="sorter_icon"
              />
            </Text>
          </Col>
        </>
      ),
      dataIndex: "created_at",
      key: "order_date",
      width: "250px",
    },
    // {
    //   title: "BMP",
    //   dataIndex: "bmp_id",
    //   key: "bmp_id",
    //   width: "150px",
    //   render: (params, record) => {
    //     return (
    //       <>
    //         <Link
    //           to={`/admin/sellers/seller-view/${params}`}
    //           // onClick={() => handleNavigateSellerView(params)}
    //           target="_blank"
    //           style={{ color: "#2973f0", cursor: "pointer" }}
    //           className="dashboard-table-orderid"
    //         >
    //           {params}
    //         </Link>
    //       </>
    //     );
    //   },
    // },
    

    ////////////////////
    {
      title: "Status",
      dataIndex: "order_status",
      key: "status",
      width: "150px",
      render: (params, record) => {
        return (
          <>
            {params && (
              <Tag
                style={{ padding: "8px" }}
                className={`tag-font-style ${statusColorMapping[params]}`}
              >
                {params}
              </Tag>
            )}
          </>
        );
      },
    },

    
    
    // {
    //   title: "mobCREDIT Order",
    //   dataIndex: "mob_credit_order",
    //   key: "mob_credit_order",
    //   width: "180px",
    //   align:"center",
    //   render: (params, record) => {
    //     return <Col>{params? "Yes":"No"}</Col>;
    //   },
    // },
    // {
    //   title: "Customer Address",
    //   dataIndex: "customer_address",
    //   key: "customer_address",
    //   width: "250px",
    //   render: (params, record) => {
    //     return <Col>{params ? params : "N/A"}</Col>;
    //   },
    // },
    // {
    //   title: "Customer City",
    //   dataIndex: "customer_city",
    //   key: "customer_city",
    //   width: "200px",
    //   render: (params, record) => {
    //     return <Col>{params ? params : "N/A"}</Col>;
    //   },
    // },
    // {
    //   title: "Customer Pincode",
    //   dataIndex: "customer_pincode",
    //   key: "customer_pincode",
    //   width: "200px",
    //   render: (params, record) => {
    //     return <Col>{params ? params : "N/A"}</Col>;
    //   },
    // },

    // {
    //   title: "Seller Address",
    //   dataIndex: "seller_address",
    //   key: "seller_address",
    //   width: "300px",
    //   render: (params, record) => {
    //     return (
    //       <Col
    //         // onClick={() => handleUserDetailsNavigation(record)}
    //         style={{ fontWeight: 500 }}
    //       >
    //         {params ? params : "N/A"}
    //       </Col>
    //     );
    //   },
    // },
    // {
    //   title: "Seller City",
    //   dataIndex: "seller_city",
    //   key: "seller_city",
    //   width: "200px",
    //   render: (params, record) => {
    //     return (
    //       <Col
    //         // onClick={() => handleUserDetailsNavigation(record)}
    //         style={{ fontWeight: 500 }}
    //       >
    //         {params ? params : "N/A"}
    //       </Col>
    //     );
    //   },
    // },
    // {
    //   title: "Seller Pincode",
    //   dataIndex: "seller_pincode",
    //   key: "seller_pincode",
    //   width: "200px",
    //   render: (params, record) => {
    //     return (
    //       <Col
    //         // onClick={() => handleUserDetailsNavigation(record)}
    //         style={{ fontWeight: 500 }}
    //       >
    //         {params ? params : "N/A"}
    //       </Col>
    //     );
    //   },
    // },
    {
      title: "Quantity",
      dataIndex: "order_quantity",
      key: "order_quantity",
      width: "200px",
      align:"center",
      render: (params, record) => {
        return (
          <Col
            // onClick={() => handleUserDetailsNavigation(record)}
            style={{ fontWeight: 400 }}
          >
            {params ? params : "N/A"}
          </Col>
        );
      },
    },
    {
      title: "Total Price",
      dataIndex: "order_amount",
      key: "order_amount",
      width: "150px",
      render: (params, record) => {
        return <Col>{`₹ ${parseFloat(params || 0).toFixed(2)}`}</Col>;
      },
    },

    {
      title: "Net Amount",
      dataIndex: "net_amount",
      key: "net_amount",
      width: "200px",
      render: (params, record) => {
        return <Col>{`₹ ${parseFloat(params || 0).toFixed(2)}`}</Col>;
      },
    },
    {
      title: "IGST",
      dataIndex: "igst",
      key: "igst",
      width: "150px",
      render: (params, record) => {
        return <Col>{`₹ ${parseFloat(params || 0).toFixed(2)}`}</Col>;
      },
    },
    {
      title: "SGST",
      dataIndex: "sgst",
      key: "sgst",
      width: "150px",
      render: (params, record) => {
        return <Col>{`₹ ${parseFloat(params || 0).toFixed(2)}`}</Col>;
      },
    },
    {
      title: "CGST",
      dataIndex: "cgst",
      key: "cgst",
      width: "150px",
      render: (params, record) => {
        return <Col>{`₹ ${parseFloat(params || 0).toFixed(2)}`}</Col>;
      },
    },
    {
      title: "Discount Amount",
      dataIndex: "discount_amount",
      key: "discount_amount",
      width: "150px",
      render: (params, record) => {
        return <Col>{`₹ ${parseFloat(params || 0).toFixed(2)}`}</Col>;
      },
    },

    // {
    //   title: "Business mobile",
    //   dataIndex: "phone_number",
    //   key: "phone_number",
    //   width: "200px",
    //   render: (params, record) => {
    //     return <Col>{params ? params : "N/A"}</Col>;
    //   },
    // },
    // {
    //   title: "MOB Margin",
    //   dataIndex: "mob_margin",
    //   key: "mob_margin",
    //   width: "150px",
    //   render: (params, record) => {
    //     return <Col>{`₹ ${parseFloat(params || 0).toFixed(2)}`}</Col>;
    //   },
    // },

    // {
    //   title: "Coupon Applied",
    //   dataIndex: "coupon_applied",
    //   key: "coupon_applied",
    //   width: "150px",
    //   render: (params, record) => {
    //     return <Col>{params || "N/A"}</Col>;
    //   },
    // },
    // {
    //   title: "Coupon code",
    //   dataIndex: "coupon_code",
    //   key: "coupon_code",
    //   width: "150px",
    //   render: (params, record) => {
    //     return <Col>{params || "N/A"}</Col>;
    //   },
    // },
    // {
    //   title: "Coupon Amount",
    //   dataIndex: "coupon_amount",
    //   key: "coupon_amount",
    //   width: "150px",
    //   render: (params, record) => {
    //     return <Col>{`₹ ${parseFloat(params).toFixed(2)}`}</Col>;
    //   },
    // },
    // {
    //   title: "Delivery Status",
    //   dataIndex: "payment_status",
    //   key: "payment_status",
    //   width: "200px",
    // },
    // {
    //   title: "Delivery Received",
    //   dataIndex: "payment_received",
    //   key: "payment_received",
    //   width: "200px",
    // },

    // {
    //   title: "Invoice No",
    //   dataIndex: "invoice_no",
    //   key: "invoice_no",
    //   width: "200px",
    // },
    // {
    //   title: "Invoice Date",
    //   dataIndex: "invoice_date",
    //   key: "invoice_date",
    //   width: "200px",
    //   render: (params, record) => {
    //     return <Text>{dateFormat(params)}</Text>;
    //   },
    // },
    // {
    //   title: "Gateway Fee",
    //   dataIndex: "gateway_fee",
    //   key: "gateway_fee",
    //   width: "200px",
    // },
    {
      title: "Delivery Note",
      dataIndex: "delivery_notes",
      key: "delivery_notes",
      width: "200px",
    },
  ];

  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      mobCredit:true,
      ...(searchedValue && { search: searchedValue?.search }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      // ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
      // ...(sorterState.created_at && { sort_by: sorterState.created_at }),
    };
    setCurrentPage(currPage);
    await getSellerSalesReportApi(params);
  };

  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };

  return (
    <Col className="SalesReport-wrapper">
      <Text className="heading">mobCREDIT Sales</Text>
      <Col className="d-flex-colum" style={{ display: "flex", gap: "1rem"}}>
        <SearchInput
          size={"large"}
          placeholder={"Search by customer name, mobile no, email"}
           width={"50%"}
          getLibraryApi={getSellerSalesReportApi}
          handleSearchedValue={handleSearchedValue}
          isLoading={isFetching}
        />
        <Col className="action-sub-container">
          <CustomSelect
            placeholder={"Date"}
            width={"150px"}
            options={dateOptions}
            handleChange={handleChange}
            value={dateFilter}
          />
          {dateFilter === "Custom" && (
            <DateRangePicker
              onChange={handleChangeRangePicker}
              format={"DD-MMM-YYYY"}
            />
          )}

<CustomSelect
          placeholder={"Export"}
          width={"150px"}
          options={options}
          handleChange={handleExportApi}
          value={DownloadReportFetching ? <LoadingOutlined /> : "EXPORT"}
          disabled={DownloadReportFetching ? true : false}
          loading={DownloadReportFetching}
        />
        </Col>
       
      </Col>
      <CustomTableWithPagination
        className="order-list-table table-fixed"
        //   tableExpandable={defaultExpandable}
        // tableDataSource={tableDataSource || []}
        tableDataSource={sellerSalesReportData?.data?.results}
        tableColumns={columns}
        locale={{
          emptyText: isFetching ? <TableSkeleton length={10} /> : "",
        }}
        isLoading={sellerSalesReportData?.data?.results?.length && isFetching}
        scroll={{ x: "100%" }}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        itemsPerPage={20}
        totalEntries={
          !isFetching && sellerSalesReportData?.data?.pagination?.total_entries
        }
      />
    </Col>
  );
};

export default SellermobCreditSales;
