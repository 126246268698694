import React from "react";
import "./CustomerNote.css";
import { Col, Typography } from "antd";
const { Text } = Typography;
const CustomerNote = (props) => {
  const { editSubOrderData } = props;
  return (
    <Col className="customerNote-wrapper">
      <Text className="translation-textI">
        {editSubOrderData?.quote_delivery_notes ||
          editSubOrderData?.notes || editSubOrderData?.customer_note|| 
          "No value"}
      </Text>
      <Col className="customerNoteText-wrapper">
        {/* <Text className="translation-text">See translation</Text> */}
      </Col>
    </Col>
  );
};

export default CustomerNote;
