import React, { useState,useEffect } from "react";
import { Layout, Affix } from "antd";
// import Sidebar from "../components/SellerComponents/SellerSidebar/Sidebar";

import "../SellerResponsive.css"
import { Helmet } from "react-helmet";
import NavbarBlank from "../components/SellerComponents/SellerHeader/NavbarBlank";
const {  Header: AntHeader } = Layout;

export const SellerBlankLayout = (props) => {
  // const [collapsed, setCollapsed] = useState(false);
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const [collapsed, setCollapsed] = useState(() => {
    const storedCollapsed = localStorage.getItem("collapsed");
    return storedCollapsed!== null? JSON.parse(storedCollapsed) : isMobile;
  });
  useEffect(() => {
    localStorage.setItem("collapsed", JSON.stringify(collapsed));
  }, [collapsed]);
   return (
   
    <Layout className={`layout-dashboard layout-profile ${collapsed ? 'menu-collapsed' : ''}`}>
     
       {/* Header */}
       <Helmet>
         <title>MOB - Seller Portal</title>
       </Helmet>
       
       <Affix>
          <AntHeader className="">
          <NavbarBlank collapsed={collapsed} setCollapsed={setCollapsed} />
          </AntHeader>
        </Affix>

      <Layout>        
       
        {/* <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} /> */}
      {/* </Sider> */}
        <section style={{ overflow: 'initial',marginLeft:"0px !important"}} className="content-container"> {props.children}</section>
      </Layout>
    </Layout>
  );
};
export default SellerBlankLayout;
