import React, { useState, useEffect  } from "react";
import "./GstCell.css";
import {
  priceAfterTax,
  totalPrice,
} from "../../../../../commonFuntions/CommonUtilFunctions";
// eslint-disable-next-line 
import { Col, Dropdown, Input, Menu, Typography } from "antd";
// import { ReactComponent as CheckIcon } from "../../../../../assets/icons/checkIcon.svg";
// import { ReactComponent as SelectDownIcon } from "../../../../../../../assets/icons/selectDownIcon.svg";
// import { ReactComponent as PurpleDownIcon } from "../../../../../../../assets/icons/purpleDownIcon.svg";

import {
  AfterTaxText,
  // eslint-disable-next-line 
  BMPPrice,
  GSTContainer,
  GstInput,
  // MOBButton,
  // MOBHeading,
  // MOBSubHeading,
  // MenuItem,
  // eslint-disable-next-line 
  MobDropDownAddedText,
  // eslint-disable-next-line 
  MobDropDownContainer,
  // eslint-disable-next-line 
  RowDropDownContainer,
} from "../../Styled";
// import Toggle from "../../../../../components/Discount/Toggle";

// const { Text } = Typography;
const GstCell = ({
  dataSource,
  tableIndex,
  params,
  record,
  setDataSource,
  editCondition,
  hasSavedRef
}) => {

  const [gst, setGst] = useState("");
  const [discount, setDiscount] = useState("");
  const [discountError, setDiscountError] = useState(false);
  const [quantity, setQuantity] = useState("");


 


  const handleGst = (e) => {
    const value = e.target.value;
    // const value = e.target.value.split(" ");
    const updatedValue = value;
    // const updatedValue = value[0] || "";
    if (updatedValue === "") {
      setGst("");
    } else if (updatedValue === "0") {
      setGst(0);
    } else {
      setGst(parseInt(updatedValue) || "");
    }
  };

  const handleDiscount = (e) => {
    const value = e.target.value.split(" ");
    const updatedValue = value[1] || "";
    const decimalPattern = /^(?:\d+|\d+\.\d{0,2})$/;
    if (decimalPattern.test(updatedValue)) {
      if (parseFloat(updatedValue || 0) > parseFloat(record?.after_tax || 0)) {
        setDiscountError(true);
      } else {
        setDiscount(updatedValue || "");
        setDiscountError(false);
      }
    }
    if (updatedValue === "") {
      setDiscount("");
      setDiscountError(false);
    }
  };
  const handleQuantity = (e) => {
    const value = e.target.value;
    const decimalPattern = /^(?:\d+|\d+\.\d{0,2})$/;
    if (decimalPattern.test(value)) {
      setQuantity(value || "");
    }
    if (value === "") {
      setQuantity("");
    }
  };

  

  useEffect(() => {
   
    setGst(record?.gst);
    setDiscount(record?.discount);
    setQuantity(record?.quantity);
    // console.log("🚀 ~ useEffect ~ hasSavedRef.current:", hasSavedRef.current)
    // console.log("🚀 ~ useEffect ~ record?.quantity:", record?.quantity)
    if (!hasSavedRef.current && record?.quantity>0) {
   
    
      handleSaveCatalogue(record?.discount,record?.quantity,record?.gst);
      hasSavedRef.current = true;
    }
    // if (hasSavedEditRef.current && editCondition) {
   
    
    //   handleSaveCatalogue(record?.discount,record?.quantity,record?.gst);
    //   hasSavedEditRef.current = false;
    // }
    // else{
    //   hasSavedEditRef.current = false;
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record,record?.quantity,hasSavedRef.current]);

  
  
  const handleSaveCatalogue = (tempDiscount,tempQuantity,tempgst) => {  
    const afterTax = priceAfterTax({
      gst: tempgst,
      before_tax: record?.before_tax,
    });
    const total = totalPrice({
      gst: tempgst,
      before_tax: record?.before_tax,
      discount: tempDiscount,
      quantity: tempQuantity,
    });
    // const bmp = parseFloat(afterTax || 0) * parseFloat(quantity || 0);
    if (parseFloat(tempDiscount || 0) > parseFloat(record?.after_tax || 0)) {
      setDiscountError(true);
    } else {
      setDiscountError(false);
    }
    const bmp =
      record?.amount_in === "amount"
        ? parseFloat(afterTax || 0) * parseFloat(tempQuantity || 0) -
          parseFloat(record?.amount || 0) * parseFloat(tempQuantity || 0)
        : (parseFloat(afterTax || 0) * parseFloat(tempQuantity || 0)) /
          (1 + parseFloat(record?.amount || 0) / 100);
    // const bmp =
    //   record.amount_in === "amount"
    //     ? parseFloat(total) - parseFloat(record.amount || 0)
    //     : parseFloat(total) / (1 + parseFloat(record.amount || 0) / 100);

    setDataSource((prevData) => {
      return prevData?.map((table, index) => {
        return tableIndex === index
          ? table?.map((item) => {
              if (item?.key === record?.key) {
                return {
                  ...item,
                  gst: tempgst,
                  after_tax: afterTax,
                  discount: tempDiscount,
                  quantity: tempQuantity,
                  total: total,
                  bmp_price: bmp,
                  // amount: 0,
                };
              }
              return item;
            })
          : table;
      });
    });
  }; 

  const handleSave = () => {    
    const afterTax = priceAfterTax({
      gst: gst,
      before_tax: record?.before_tax,
    });
    const total = totalPrice({
      gst: gst,
      before_tax: record?.before_tax,
      discount: discount,
      quantity: quantity,
    });
      console.log("🚀 ~ handleSave ~ quantity:", quantity)
      console.log("🚀 ~ handleSave ~ discount:", discount)
    
    // const bmp = parseFloat(afterTax || 0) * parseFloat(quantity || 0);
    if (parseFloat(discount || 0) > parseFloat(record?.after_tax || 0)) {
      setDiscountError(true);
    } else {
      setDiscountError(false);
    }
    const bmp =
      record?.amount_in === "amount"
        ? parseFloat(afterTax || 0) * parseFloat(quantity || 0) -
          parseFloat(record?.amount || 0) * parseFloat(quantity || 0)
        : (parseFloat(afterTax || 0) * parseFloat(quantity || 0)) /
          (1 + parseFloat(record?.amount || 0) / 100);
    // const bmp =
    //   record.amount_in === "amount"
    //     ? parseFloat(total) - parseFloat(record.amount || 0)
    //     : parseFloat(total) / (1 + parseFloat(record.amount || 0) / 100);

    setDataSource((prevData) => {
      return prevData?.map((table, index) => {
        return tableIndex === index
          ? table?.map((item) => {
              if (item?.key === record?.key) {
                return {
                  ...item,
                  gst: gst,
                  after_tax: afterTax,
                  discount: discount,
                  quantity: quantity,
                  total: total,
                  bmp_price: bmp,
                  // amount: 0,
                };
              }
              return item;
            })
          : table;
      });
    });
  }; 
  useEffect(() => {   
    setQuantity(record?.quantity);  

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record.quantity]);
   
  return (
    <>
      <GSTContainer>
        <GstInput
          className="custum_input"
          value={`${gst}`}
          // value={`${gst}%`}
          onChange={handleGst}
          onPressEnter={handleSave}
          onBlur={handleSave}
          suffix={"%"}
        />
        <AfterTaxText level={5}>
          ₹ {priceAfterTax(record).toFixed(2)}
        </AfterTaxText>
        <GstInput
          className={`${
            discountError ? "discount_error_input" : "custum_input"
          }`}
          value={`₹ ${discount}`}
          onChange={(e) => handleDiscount(e)}
          onPressEnter={handleSave}
          onBlur={handleSave}
        />
        <GstInput
          className="custum_input"
          value={quantity}
          onChange={handleQuantity}
          onPressEnter={handleSave}
          onBlur={handleSave}
        />
      </GSTContainer>
      
    </>
  );
};
export default GstCell;
