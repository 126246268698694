import React, { useState, useEffect } from "react";
import { Steps, Affix, Button, Row, Col, Form } from "antd";
import GettingStarted from "./GettingStarted";
import Activation from "./Activation";
import Commercials from "./Commercials";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  // useLocation,
  useNavigate,
  // eslint-disable-next-line
  useParams,
} from "react-router-dom";

import {
  // eslint-disable-next-line
  update_razorpay_order_id,
  // eslint-disable-next-line
  update_razorpay_payment_id,
  // eslint-disable-next-line
  update_razorpay_signature,
} from "../../../redux/slices/PaymentState/action";
import {
  // eslint-disable-next-line
  selectRazorpayPaymentId,
  // eslint-disable-next-line
  selectRazorpayOrderId,
  // eslint-disable-next-line
  selectRazorpaySignature,
} from "../../../redux/slices/PaymentState/selector";
import {
  // eslint-disable-next-line 
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import {
  useUploadDocumentsMutation,
  usePurchasePlanMutation,
  useLazyGetRazorPayPaymentQuery,
} from "../../../apis/Seller/SellerCommercialsAPI";

const SellerMobCreditOnboarding = () => {
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checkedStarted, setcheckedStarted] = useState(false);
  const [checkedCommercials, setcheckedCommercials] = useState(false);
  const [errorStarted, seterrorStarted] = useState(false);
  const [errorCommercials, seterrorCommercials] = useState(false);
  const handleStartedCheckboxChange = (e) => {
    setcheckedStarted(e.target.checked);
  };
  const handleCommercialsCheckboxChange = (e) => {
    setcheckedCommercials(e.target.checked);
  };
  const [businessGstinCertificate, setbusinessGstinCertificate] = useState([]);
  const [businessPanCard, setbusinessPanCard] = useState([]);
  const [aadharCard, setaadharCard] = useState([]);
  const [directorPanCard, setdirectorPanCard] = useState([]);
  const [defaultPlanId, setDefaultPlanId] = useState(null);
  // eslint-disable-next-line
  const [uploadDocuments, { loading, error, data }] =
    useUploadDocumentsMutation();
  // eslint-disable-next-line
  const [CreatePayment, { loading: IsPaymentLoading, data: paymentdata }] =
    usePurchasePlanMutation();
  const [form] = Form.useForm();
  const handleBack = () => {
    navigate("/seller/sellerdashboard");
  };
  // const razorpay_payment_id = useSelector(selectRazorpayPaymentId);
  // const razorpay_order_id = useSelector(selectRazorpayOrderId);
  // const razorpay_signature = useSelector(selectRazorpaySignature);
  // eslint-disable-next-line 
  const razorpay_payment_id = useSelector(
    (state) => state.PaymentState?.razorpay_payment_id
  );
  // eslint-disable-next-line 
  const razorpay_order_id = useSelector(
    (state) => state.PaymentState?.razorpay_order_id
  );
  // eslint-disable-next-line 
  const razorpay_signature = useSelector(
    (state) => state.PaymentState?.razorpay_signature
  );

  const [
    // eslint-disable-next-line
    getRazorpayPayment,
    {
      data: getRazorPayPaymentData,
      // eslint-disable-next-line
      isFetching: isFetchingRazorPayPayment,
      // eslint-disable-next-line
      isSuccess: successfullyPaymentDone,
    },
  ] = useLazyGetRazorPayPaymentQuery();
  console.log(
    "🚀 ~ SellerMobCreditOnboarding ~ getRazorPayPaymentData:",
    getRazorPayPaymentData
  );
  useEffect(() => {
    if (getRazorPayPaymentData?.status === true) {
      // eslint-disable-next-line
      const queryParameter = {
        order_id: getRazorPayPaymentData?.data?.invoice_number,
        pdf_url: getRazorPayPaymentData?.data?.pdf_url,
        expiry:getRazorPayPaymentData?.data?.expiry,
        // merchantPaymentRefId: razorpay_order_id,
        // paymentId: razorpay_payment_id,
        // transactionId: razorpay_signature,
      };

      navigate({
        pathname: "/Seller/Paymentresponse",
        search: new URLSearchParams(queryParameter).toString(),
        replace: true,
      });
    } else {
      // showErrorToast("Error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRazorPayPaymentData]);
  const onFinish = async (values) => {
    const data = {
      businessGstinCertificate: businessGstinCertificate,
      businessPanCard: businessPanCard,
      aadharCard: aadharCard,
      directorPanCard: directorPanCard,
    };
    const paymentdata = {
      plan_id: values?.planId||defaultPlanId,
    };

    try {
      const response = await uploadDocuments({ data });
      // console.log("🚀 ~ onFinish ~ response:", response);
      if(response?.data?.status)
      {
      const paymentResponse = await CreatePayment(paymentdata);
      // console.log("🚀 ~ onFinish ~ paymentResponse:", paymentResponse);
      if (paymentResponse?.data?.status) {
        showSuccessToast(paymentResponse?.data?.data?.message);
        console.log("pay id", paymentResponse?.data?.data?.payment_id);
        const options = {
          // key: "rzp_test_fjQ8CCi7188hME",
          // amount: '1', // Amount in the smallest currency unit (e.g., paise for INR)
          currency: "INR",
          name: "Mad Over Buildings",
          description: "subscription Transaction",
          // image: "/MOB-logo-for-razorpay-black.png",
          image: "https://madoverbuildings.com/MOB-logo-for-razorpay-black.png",
          order_id: paymentResponse?.data?.data?.payment_id, // Use the provided order ID
          handler: function (response) {
            // alert('Payment successful! Payment ID: ' + response.razorpay_payment_id);
            // console.log("🚀 ~ onFinish ~ response:", response);
            // Handle successful payment response here (e.g., send the payment ID to your backend)

            const razorpay_payment_id = response.razorpay_payment_id;
            const razorpay_order_id = response.razorpay_order_id;
            const razorpay_signature = response.razorpay_signature;
            if (
              razorpay_payment_id &&
              razorpay_order_id &&
              razorpay_signature
            ) {
              dispatch(update_razorpay_order_id(razorpay_order_id));
              dispatch(update_razorpay_payment_id(razorpay_payment_id));
              dispatch(update_razorpay_signature(razorpay_signature));
              getRazorpayPayment({
                params: {
                  payment_id: razorpay_payment_id,
                  order_id: razorpay_order_id,
                  razorpay_signature: razorpay_signature,
                  // payment_Gateway: "RAZORPAY",
                },
              });
            }
          },
          // prefill: {
          //   name: 'John Doe',
          //   email: 'john.doe@example.com',
          //   contact: '9999999999',
          // },
          notes: {
            address: "Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } else {
        // showErrorToast(response?.data?.data?.message);
      }
    }
    else{
      showErrorToast(response?.data?.data?.message);
    }

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const steps = [
    {
      title: "Getting started",
      content: (
        <GettingStarted
          checkedStarted={checkedStarted}
          handleStartedCheckboxChange={handleStartedCheckboxChange}
          errorStarted={errorStarted}
        />
      ),
    },
    {
      title: "Commercials & terms",
      content: (
        <Commercials
          checkedCommercials={checkedCommercials}
          handleCommercialsCheckboxChange={handleCommercialsCheckboxChange}
          errorCommercials={errorCommercials}
        />
      ),
    },
    {
      title: "Activation",
      content: (
        <Activation
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          businessGstinCertificate={businessGstinCertificate}
          businessPanCard={businessPanCard}
          aadharCard={aadharCard}
          directorPanCard={directorPanCard}
          setbusinessGstinCertificate={setbusinessGstinCertificate}
          setbusinessPanCard={setbusinessPanCard}
          setaadharCard={setaadharCard}
          setdirectorPanCard={setdirectorPanCard}
          defaultPlanId={defaultPlanId}
          setDefaultPlanId={setDefaultPlanId}
        />
      ),
    },
  ];

  const [current, setCurrent] = useState(0);
  const next = () => {    
    if (current === 0) {
      if (!checkedStarted) {
        seterrorStarted(true);
        return;
      }
    }
    if (current === 1) {
      if (!checkedCommercials) {
        seterrorCommercials(true);
        return;
      }
    }
    setCurrent(current + 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const prev = () => {
    setCurrent(current - 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const handleMakePayment = (values) => {
    form.submit();
  };
  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <>
      <Row>
        <Col
          className="mt-4"
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 12, offset: 0 }}
          md={{ span: 8, offset: 4 }}
          lg={{ span: 8, offset: 4 }}
          xl={{ span: 12, offset: 6 }}
        >
          <Steps current={current} direction="horizontal" labelPlacement="vertical" responsive={false} items={items} />
        </Col>
      </Row>
      <div className="mt-4" style={{}}>{steps[current].content}</div>

      <Affix offsetBottom={0}>
        <Col
          className="mt-4"
         
        >
          <Row
            style={{
              marginTop: 24,
              backgroundColor: "white",
              boxShadow: "0 -6px 10px 0 rgba(0, 0, 0, 0.1)",
              padding: 16,
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {current > 0 && (
                <Button
                  style={{ margin: "0 8px" }}
                  className="btn-common"
                  onClick={() => prev()}
                >
                  Previous
                </Button>
              )}
              {current === 0 && (
                <>
                {/* Mobile */}
              <Button
                icon={<ArrowLeftOutlined />}
                onClick={handleBack}
                className="btn-common d-block d-sm-block d-lg-none d-xl-none"
              >
               BACK
              </Button>
              {/* Web */}
              <Button
              icon={<ArrowLeftOutlined />}
              onClick={handleBack}
              className="btn-common d-none d-md-block d-lg-block d-xl-block"
            >
              BACK TO DASHBOARD
            </Button>
            </>
              )}
            </Col>
            <Col className="text-right" xs={12} sm={12} md={12} lg={12} xl={12}>
              {current === 0 && (
                <Button
                  className="btn-common"
                  type="primary"
                  onClick={() => next()}
                >
                  NEXT - COMMERCIALS
                </Button>
              )}
              {current === 1 && (
                <Button
                  type="primary"
                  className="btn-common"
                  onClick={() => next()}
                >
                  NEXT - ACTIVATION
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button
                  type="primary"
                  className="btn-common custome-ml"
                  onClick={() => handleMakePayment()}
                >
                  PAY FOR IMMEDIATE ACTIVATION
                </Button>
              )}
              {/* {current < steps.length - 1 && (
      <Button type="primary" onClick={() => next()}>
        NEXT - COMMERCIALS
      </Button>
    )} */}
              {/* {current === steps.length - 1 && (
      <Button type="primary" onClick={() => message.success("Processing complete!")} >
        Done
      </Button>
    )} */}
            </Col>
          </Row>
        </Col>
      </Affix>
    </>
  );
};

export default SellerMobCreditOnboarding;
