import { React, useEffect } from "react";
import { Col, Typography,Row ,Button, Divider,Tag} from "antd";
import "./SellerNotification.css";
import { useLazyGetNotificationQuery } from "../../../apis/Seller/sellerDashboard";

import { ReactComponent as EmptyNotificationIcon } from "../../../assets/seller/icons/no-notifications.svg";
import { dateFormat } from "../../../commonFuntions/CommonUtilFunctions";
// import { useNavigate } from "react-router-dom";
import CardSkeleton from "../../skeleton/SellerCardSkeleton";
const Text = Typography;

function SellerNotification(prop) {
  const {setNotificationCount}=prop;
  // const navigate = useNavigate();
  // eslint-disable-next-line
  const [SellerNotificationApi, { data: NotificationData, isFetching }] =
    useLazyGetNotificationQuery();
  useEffect(() => {
    SellerNotificationApi();
  }, [SellerNotificationApi]);
  // console.log("🚀 ~ SellerNotification ~ NotificationData:", NotificationData);
  // const handleViewDetals = (id, status) => {
  //   navigate(`/Seller/orders/SellerViewDetails/${id}`, {
  //     state: { id, status },
  //   }, {
  //     target: '_blank',
  //   });
  // };
  const handleViewDetals = (id, status) => {
    const url = `/Seller/orders/SellerViewDetails/${id}`;
    window.open(url, '_blank');
  };
  const handlerandom = () => {};
  useEffect(() => {
    setNotificationCount(NotificationData?.data?.length || 0);
  }, [setNotificationCount,NotificationData?.data]);
  return (
    <>
      {isFetching ? (
        <CardSkeleton length={4} />
      ) : (
        <>
          {!NotificationData?.data || NotificationData?.data?.length===0 ? (
            <>
            <Row gutter={[16,16]} className="mt-4" style={{display: "flex",justifyContent: "center",width:"100%" }} >
            <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <EmptyNotificationIcon />
             </Col>
            <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
              <Text className="no-notification">No new notifications!</Text>
            </Col>
            </Row>
            </>
          ) : (
            NotificationData?.data.map((item, index) => (
              <>
              <Row className="notification-order-list" key={index}>
                <Col className="d-flex" style={{justifyContent:"space-between"}} span={24}>  
                <Col>  
                <span>
                      <Tag
                        className={`status-tag-style-notification ${
                          item?.order?.status === "Waiting"
                            ? "status-waiting"
                            : item?.order?.status === "Ready for Pickup"
                            ? "status-ready"
                            : item?.order?.status === "Rejected by seller"
                            ? "status-rejected"
                            : "status-default"
                        }`}
                      >
                        {item?.order?.status}
                      </Tag>
                    </span>          
                
                </Col> 
                <Col>            
                <span className="text-bold">{dateFormat(item?.created_at)}</span>
                </Col> 
                </Col>
                <Col span={24} className="mt-2">               
                Seller Order ID:  <span className="text-bold"> {item?.order?.order_id}</span> 
                </Col>
                <Col span={24} style={{justifyContent:"space-between"}} className="d-flex">    
                <Col >           
                   Items:  <span className="text-bold">{item?.order?.items}</span> <br/>
                   Total:  <span className="text-bold"> ₹ {item?.order?.total}</span> 
                   </Col>
                   <Col>
                  
                      {(item?.order?.status === "Ready for Pickup" ||
                        item?.order?.status === "Out for delivery" ||
                        item?.order?.status === "Delivered" ||   item?.order?.status === "Rejected by seller" ||
                        item?.order?.status === "Cancelled") && (
                        <Button
                          style={{ marginRight: "6px" }}
                          className="btnI accept-btn-style btn-common-width"
                          onClick={() =>
                            handleViewDetals(item?.order?.order_id, item?.order.status)
                          }
                        >
                          VIEW DETAILS
                        </Button>
                      )}
                      {/* {item?.status === "Ready for Pickup" && (
                        <>
                          <Button
                            className="btn-theme accept-btn-style btnI"
                            onClick={handleConfirmationModal}
                          >
                            ORDER SHIPPED
                          </Button>
                        </>
                      )} */}
                      {item?.order?.status === "Waiting" && (
                        <Button
                          type="primary"
                          className="btn-theme accept-btn-style btnI btn-common-width"
                          onClick={() => {
                            item?.order?.status === "Waiting"
                              ? handleViewDetals(
                                  item?.order?.order_id,
                                  item?.order?.status
                                )
                              : handlerandom();
                          }}
                        >
                          VIEW TO ACCEPT
                        </Button>
                      )}
                  
                   </Col>
                </Col>
              </Row>
              <Divider className="notification-divider"/>
              </>
            ))
          )}
        </>
      )}
    </>
  );
}

export default SellerNotification;
