import { createApi } from "@reduxjs/toolkit/query/react";
import { loginBaseQuery } from "./../baseQuery";
export const sellerAuthenticationAPI = createApi({
  reducerPath: "sellerAuthenticationAPI",
  baseQuery: loginBaseQuery,
  endpoints: (builder) => ({
    sellerLogin: builder.mutation({
      query: (formData) => ({
        url: "/api/seller/seller_login/",
        method: "POST",
        body: formData,
      }),
    }),

    SellerGetOtp: builder.mutation({
      query: (formOtp) => ({
        url: "/api/seller/send_otp/",
        method: "POST",
        body: formOtp,
      }),
    }),

    SellerVerifyOtp: builder.mutation({
      query: (formOtpVerify) => ({
        url: "/api/seller/check_otp/",
        method: "POST",
        body: formOtpVerify,
      }),
    }),

    SellerRegistrationOtp: builder.mutation({
      query: (formOtpVerify) => ({
        url: "api/seller/send_otp_to_vendor/",
        method: "POST",
        body: formOtpVerify,
      }),
    }),
    SellerFinalRegistration: builder.mutation({
      query: (formRegistration) => ({
        url: "/api/seller/create_vendor/",
        method: "POST",
        body: formRegistration,
      }),
    }),
    
  }),
});

export const {
  useSellerLoginMutation,
  useSellerGetOtpMutation,
  useSellerVerifyOtpMutation,
  useSellerRegistrationOtpMutation,
  useSellerFinalRegistrationMutation,
  
} = sellerAuthenticationAPI;

export default sellerAuthenticationAPI;
