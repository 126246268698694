import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./../baseQuery";

export const SellerdashboardAPI = createApi({
  reducerPath: "SellerdashboardAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getDashboard: builder.query({
      query: (params) => {
        return {
          url: `/api/seller/dashboard/`,
          params,
        };
      },
    }),
   
    getMainSearch: builder.query({
      query: (params) => {
        return {
          url: `/api/seller/dashboard/seller_search/`,
          params,
        };
      },
    }),
    sellerRaiseRequest: builder.mutation({
      query: (data) => ({
        url: "/api/seller/dashboard/raise_request/",
        method: "POST",
        body: data,
      }),
    }),
    getNotification: builder.query({
      query: (params) => {
        return {
          url: `/api/seller/dashboard/get_notifications/`,
          params,
        };
      },
    }),
  }),
});

export const { useLazyGetDashboardQuery, useLazyGetMainSearchQuery,useSellerRaiseRequestMutation,useLazyGetNotificationQuery } =
SellerdashboardAPI;

export default SellerdashboardAPI;
