import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQueryWithReauth} from "./baseQuery";

export const createQuoteAPI = createApi({
  reducerPath: "createQuoteAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getRFQUserById: builder.query({
      query: (id) => {
        return {
          url: `api/orders/rfq/${id}/get_rfq_user/`,
        };
      },
    }),
    getAllVendors: builder.query({
      query: (params) => {
        return {
          url: "accounts/mob_vendor/",
          params,
        };
      },
    }),
    getCatalogueByVendor: builder.query({
      query: ({id, searchValue}) => {
        return {
          url: `api/product/vendor-product-search/?bmp_id=${id}&search=${
            searchValue || ""
          }`,
        };
      },
    }),
    getCatalogueByVendorDrawer: builder.query({
      query: ({params, id,page}) => {
        return {
          url: `api/product/vendor-product-search/?bmp_id=${id}&page=${page}`,
          params,
        };
      },
    }),
    getSellerCatalogue: builder.query({
      query: ({id, searchValue}) => {
        return {
          url: `/api/seller/catalogue/product_search?search=${
            searchValue || ""
          }`,
        };
      },
    }),
    getSellerCatalogueDrawer: builder.query({
      query: ({params,page}) => {
        return {
          url: `/api/seller/catalogue/product_search/`,
          params,
        };
      },
    }),
    getCatalogueByVendorModal: builder.query({
      query: ({id, slug}) => {
        return {
          url: `api/product/vendor-product-search/${slug}/get_product_details/?bmp_id=${id}`,
        };
      },
    }),
    createRFQQuote: builder.mutation({
      query: (newVendorData) => ({
        url: "api/orders/rfq/create_quote/",
        method: "POST",
        body: newVendorData,
      }),
    }),
    updateRFQQuoteDetails: builder.mutation({
      query: (newVendorData) => ({
        url: "api/orders/rfq/update_quote_details/",
        method: "PATCH",
        body: newVendorData,
      }),
    }),
    getRFQQuoteDetail: builder.query({
      query: (id) => ({
        url: `api/orders/rfq/${id}/get_quote/`,
      }),
    }),
    updateRFQQuote: builder.mutation({
      query: (updatedData) => ({
        url: "api/orders/rfq/update_quote/",
        method: "PATCH",
        body: updatedData,
      }),
    }),
    duplicateRFQQuote: builder.mutation({
      query: (duplicateData) => ({
        url: "api/orders/rfq/duplicate_quote/",
        method: "POST",
        body: duplicateData,
      }),
    }),
    deleteRFQQuote: builder.mutation({
      query: (deleteId) => ({
        url: `api/orders/rfq/delete_quote/`,
        method: "DELETE",
        body: deleteId,
      }),
    }),
    addNewImage: builder.mutation({
      query: (imageFile) => {
        const formData = new FormData();
        formData.append("image", imageFile);
        return {
          url: "api/utility/image_upload/",
          method: "POST",
          body: formData,
        };
      },
    }),
    addNewAddress: builder.mutation({
      query: (addNewAddress) => ({
        url: "accounts/mob_user/create_address/",
        method: "POST",
        body: addNewAddress,
      }),
    }),
    updateAddress: builder.mutation({
      query: (updateAddress) => ({
        url: "accounts/mob_user/update_address/",
        method: "PATCH",
        body: updateAddress,
      }),
    }),
    deleteAddress: builder.mutation({
      query: (addressId) => ({
        url: `accounts/mob_user/delete_address/`,
        method: "DELETE",
        body: addressId,
      }),
    }),
    deleteQuoteItem: builder.mutation({
      query: (params) => ({
        url: "api/orders/rfq/delete_quote_item/",
        method: "DELETE",
        body: params,
      }),
    }),
    deleteQuoteTable: builder.mutation({
      query: (params) => ({
        url: "api/orders/rfq/delete_quote_vendor/",
        method: "DELETE",
        body: params,
      }),
    }),
  }),
});

export const {
  useGetRFQUserByIdQuery,
  useLazyGetRFQUserByIdQuery,
  // useLazyGetAllVendorsQuery,
  useLazyGetRFQQuoteDetailQuery,
  useGetAllVendorsQuery,
  useLazyGetAllVendorsQuery,
  useLazyGetCatalogueByVendorQuery,
  useCreateRFQQuoteMutation,
  useUpdateRFQQuoteDetailsMutation,
  useAddNewAddressMutation,
  useDeleteAddressMutation,
  useUpdateAddressMutation,
  useAddNewImageMutation,
  useUpdateRFQQuoteMutation,
  useDuplicateRFQQuoteMutation,
  useDeleteRFQQuoteMutation,
  useDeleteQuoteItemMutation,
  useDeleteQuoteTableMutation,
  useLazyGetCatalogueByVendorModalQuery,
  useLazyGetCatalogueByVendorDrawerQuery,
  useLazyGetSellerCatalogueDrawerQuery,
  useLazyGetSellerCatalogueQuery
} = createQuoteAPI;

export default createQuoteAPI;
