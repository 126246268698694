import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../commonUtils/commonUtils.js";

const baseQuery = fetchBaseQuery({
  baseUrl: apiBaseUrl + "/",
  prepareHeaders: (headers, { getState }) => {
    const accessToken = getState().loginAuthReducer?.authToken;
    const bmpValue = localStorage.getItem("bmp_id"); 
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    } else {
      const isAuthenticated = localStorage.getItem("accessToken");
      if (isAuthenticated) {
        headers.set("Authorization", `Bearer ${isAuthenticated}`);
      }
    }
    headers.set("Bmp", `${bmpValue}`);
    return headers;
  },

  // timeout: 15000,
});
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.clear();
  }
  return result;
};
const loginBaseQuery = fetchBaseQuery({
  baseUrl: apiBaseUrl + "/",
  // timeout: 15000,
});

export { baseQuery, loginBaseQuery, baseQueryWithReauth };
