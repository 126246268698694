import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./../baseQuery";
export const SellerRegistrationAPI = createApi({
  reducerPath: "SellerRegistrationAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({    
    SellerNewRegistration: builder.mutation({
      query: (updateData) => ({
        url: "/api/seller/dashboard/add_new_store/",
        method: "POST",
        body: updateData,
      }),
    }),
  }),
});

export const {
  useSellerNewRegistrationMutation
} = SellerRegistrationAPI;

export default SellerRegistrationAPI;
