import {
  // Button,
  Col,
  Table,
  Typography,
  Row,
} from "antd";
import { React, useState, useEffect } from "react";
import "./SellerDeliveryTable.css";
import { ReactComponent as InfoIcon } from "../../../../../../assets/icons/InfoIcon.svg";
import { amountToWords } from "../../../../../../commonFuntions/CommonUtilFunctions";

const { Text } = Typography;

const SellerDeliveryTable = ({
  columns,
  data,
  deliveryStatus,
  editSubOrderData,
  paymentStatus,
  setPaymentStatus,
  totalAmountDue,
  totalAmountPaid
}) => {
  const totalPriceAfterTax = Number(editSubOrderData?.total || 0);

  let discountAmount = 0;
  if (editSubOrderData?.discount_in === "percentage") {
    discountAmount = (totalPriceAfterTax * editSubOrderData?.discount) / 100;
  } else {
    discountAmount = Number(editSubOrderData?.discount || 0);
  }
  const totalProductPriceBeforeTax = (editSubOrderData?.items || []).reduce(
    (acc, item) => acc + item.price_before_tax * item.quantity,
    0
  );
  const totalProductPriceAfterTax = (editSubOrderData?.items || []).reduce(
    (acc, item) => acc + item.price_after_tax * item.quantity,
    0
  );
  const Tax = (
    Number(totalProductPriceAfterTax || 0) -
    Number(totalProductPriceBeforeTax || 0)
  ).toFixed(2);

  // const Tax=0;
  const finalTotal = totalPriceAfterTax;
  const scroll = "100%";
  if (totalAmountDue > 0) {
    setPaymentStatus("Payment pending");
  } else {
    setPaymentStatus("Paid");
  }
  const [paginationWidth, setPaginationWidth] = useState(0);

  const handleResize = (_, newWidth) => {
    setPaginationWidth(newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Table
        className={`deliveryBy-table custom-tablehead order-list-table  ${
          deliveryStatus === "Rejected by seller" ? "rejected-table" : ""
        }`}
        columns={columns}
        pagination={false}
        scroll={{
          ...scroll,
          x: paginationWidth || "100%",
        }}
        footer={() => (
          <>
            <Row>
              <Col xs={24} sm={12} md={8} lg={6} xl={12}></Col>
              <Col xs={24} sm={12} md={8} lg={6} xl={12}>
                <Col
                  className={`deliveryBy-calc ${
                    deliveryStatus === "Rejected by seller"
                      ? "rejected-footer"
                      : ""
                  }`}
                >
                  <Col className="deliveryBy-align-amount">
                    <Text>Subtotal (with Tax)</Text>
                    <Text>
                      ₹ {Number(editSubOrderData?.sub_total || 0).toFixed(2)}
                    </Text>
                  </Col>
                  <Col className="deliveryBy-align-amount">
                    <Text>
                      Discount
                      {(editSubOrderData?.discount > 0 && editSubOrderData?.discount_in !=="amount")  && 
                     `(${editSubOrderData?.discount}%)`}
                    </Text>
                    <Text>₹ {Number(discountAmount || 0).toFixed(2)}</Text>
                  </Col>
                  <Col className="deliveryBy-align-amount">
                    <Text className="tax-info">
                      {" "}
                      Tax
                      <InfoIcon />
                    </Text>
                    <Text>
                      ₹{" "}
                      {/* {Number((editSubOrderData?.cgst || 0) + (editSubOrderData?.sgst || 0)).toFixed(2)} */}
                      {/* {(editSubOrderData?.tax || 0).toFixed(2)} */}
                      {Tax}
                    </Text>
                  </Col>
                  <Col className="deliveryBy-align-amount">
                    <Text>Delivery fee</Text>
                    <Text>
                      ₹ {Number(editSubOrderData?.delivery_fee || 0).toFixed(2)}
                    </Text>
                  </Col>
                </Col>
                <Col className="deliveryBy-align-total">
                  <Text className="deliveryBy-text">Total</Text>
                  <Text className="deliveryBy-text">
                    ₹ {Number(finalTotal || 0).toFixed(2)}
                  </Text>
                </Col>
                <Col className={`deliveryBy-calc`}>
                  <Col className="deliveryBy-align-amount">
                    <Text>Paid to date</Text>
                    <Text>₹ {Number(totalAmountPaid || 0).toFixed(2)}</Text>
                  </Col>
                  {totalAmountDue > 0 ? (
                    <Col className="deliveryBy-align-amount ">
                      <Text className="text-danger">Amount due</Text>
                      <Text className="text-notpaid text-danger">
                        ₹ {Number(totalAmountDue || 0).toFixed(2)}
                      </Text>
                    </Col>
                  ) : totalAmountDue < 0 ? (
                    <Col className="deliveryBy-align-amount text-success">
                      <Text className="text-success">Paid excess</Text>
                      <Text className="text-paid text-success">
                        ₹ {Number(totalAmountDue || 0).toFixed(2)}
                      </Text>
                    </Col>
                  ) : (
                    <Col className="deliveryBy-align-amount ">
                      <Text>Amount due</Text>
                      <Text className="text-refund">
                        ₹ {Number(totalAmountDue || 0).toFixed(2)}
                      </Text>
                    </Col>
                  )}
                </Col>
                <Col className={`deliveryBy-calc`}>
                  <Col className="deliveryBy-align-amount">
                    <Text>AMOUNT IN WORDS</Text>
                  </Col>
                  <Col className="deliveryBy-align-amount ">
                    <Text>
                      <span className="text-upper">
                        {amountToWords(Number(finalTotal || 0).toFixed(2))}{" "}
                      </span>{" "}
                      only
                    </Text>
                  </Col>
                </Col>
              </Col>
            </Row>
          </>
        )}
        dataSource={data?.map((itemData) => ({
          ...itemData,
          //           button:(
          //  deliveryStatus==="Delivered" && <Col className="btn-containerI">

          // <Button className="styled-btn">Return</Button>
          // <Button className="styled-btn">Exchange</Button>
          // </Col>

          //           )
        }))}
      />
    </>
  );
};

export default SellerDeliveryTable;
