import React from "react";
import {
  //Button,
  Col,
  Typography,
  Row,  
  Skeleton,
} from "antd";
import "./SellerPayables.css"

import { ReactComponent as DownArrowIcon } from "../../../assets/seller/icons/downarrow_green.svg";
import { ReactComponent as UpArrowIcon } from "../../../assets/seller/icons/uparrow_red.svg";
import { ReactComponent as PartiesIcon } from "../../../assets/seller/icons/parties.svg";
// import { useNavigate } from "react-router-dom";

const { Text } = Typography;
const SellerpayableHeader = (props) => {
  const { data, isFetching } = props;


  // const navigate = useNavigate();
  // const handleChange = (value) => {
  // };

  return (
    <>
      <Row className="payableWrapper pl-0">
        <Text className="settleStatement">Payables/ recievables</Text>

        {isFetching ? (
          <>
            <Col style={{ display: "flex", gap: "20px" }}>
              <Skeleton active paragraph={{ rows: 4 }} />
              <Skeleton active paragraph={{ rows: 4 }} />
              <Skeleton active paragraph={{ rows: 4 }} />
            </Col>
          </>
        ) : (
          <>
            {/* <Row style={{flexFlow:"unset",gap:"16px"}}> */}
            <Col className="end-container-seller-view">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={8}
                className={"tag-main-container"}
                style={{                  
                  border: "none",
                }}
              >
                <Col
                  className={`icon-settle-container`}
                >
                  <DownArrowIcon />
                </Col>
                <Col className={`tag-text-container`}>
                  <Text
                    className={`tag-text-heading`}                   
                  >
                   To receive
                  </Text>
                  <Text
                    className="tag-text-number"                  
                  >
                    ₹ {(data?.to_give || 0).toFixed(2)}
                  </Text>
                  
                </Col>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={8}
                className="tag-main-container"
                style={{                
                  border: "none",
                }}
              >
                <Col className="icon-settle-container">
                  <UpArrowIcon />
                </Col>
                <Col className="tag-text-container">
                  <Text
                    className="tag-text-heading"
                    style={{ color: "#0a243f" }}
                  >
                    To give
                  </Text>
                  <Text
                    className="tag-text-number"
                    style={{ color: "#0a243f" }}
                  >
                    ₹ {(data?.to_recieve || 0).toFixed(2)}
                  </Text>
                  
                </Col>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={8}
                className="tag-main-container"
                style={{                
                  border: "none",
                }}
              >
                <Col className="icon-settle-container">
                  <PartiesIcon />
                </Col>
                <Col className="tag-text-container">
                  <Text
                    className="tag-text-heading"
                    style={{ color: "#0a243f" }}
                  >
                  Total Customers
                  </Text>
                  <Text
                    className="tag-text-number"
                    style={{ color: "#0a243f" }}
                  >
                    {data?.total_parties || 0}
                  </Text>
                 
                </Col>
              </Col>
            </Col>
            
          </>
        )}
      </Row>
    </>
  );
};

export default SellerpayableHeader;
