import React, { useEffect } from "react";
import {
  Col,
  Typography,
  Row,
  Card,
  Upload,
  Form,
  Radio,
  Carousel,
  Input,
} from "antd";
import "./SellerMobCreditOnboarding.css";
import { ReactComponent as UploadFileIcon } from "../../../assets/seller/icons/uploadfile.svg";
import { useLazyGetAllPlanDetailsQuery } from "../../../apis/Seller/SellerCommercialsAPI";
import { useLazyGetVendorDetailsQuery } from "../../../apis/Seller/SellerAccountsAPI";
import  HidecorLogo  from "../../../assets/seller/icons/hidecor.webp";
import YaahviLogo  from "../../../assets/seller/icons/yahhvi.webp";
import sandharbhLogo  from "../../../assets/seller/icons/sandharbh.webp";
import MysankalpLogo  from "../../../assets/seller/icons/mysankalp.webp";
import MysankalpAuthorLogo  from "../../../assets/seller/icons/mysankalp_author.webp";
import HidecoreAuthorLogo  from "../../../assets/seller/icons/hidecor_author.webp";
import yashviauthorLogo  from "../../../assets/seller/icons/yashviauthor.webp";
import SandarbauthorLogo  from "../../../assets/seller/icons/sandarb_author.webp";

const { Text } = Typography;

const Activation = (props) => {
  const {
    form,
    onFinish,
    onFinishFailed,
    setbusinessGstinCertificate,
    setbusinessPanCard,
    setaadharCard,
    setdirectorPanCard,
    // businessGstinCertificate,
    // businessPanCard,
    // aadharCard,
    // directorPanCard
    defaultPlanId,
    setDefaultPlanId
  } = props;

  const [getALLPlan, { data: allPlanData, isFetching: isFetchingPlan }] =
    useLazyGetAllPlanDetailsQuery();

    // eslint-disable-next-line 
  const [SellerDetails, { data: SellerData, isFetching: isLoading }] =   
    useLazyGetVendorDetailsQuery();
    
  useEffect(() => {
    SellerDetails();
  }, [SellerDetails]);
  useEffect(() => {
    getALLPlan();
  }, [getALLPlan]);

  
  useEffect(() => {
    if (allPlanData && allPlanData.data.length > 0) {
      setDefaultPlanId(allPlanData.data[0].id); // Set default to the first plan's ID
    }
  }, [allPlanData,setDefaultPlanId]);
  
  const handleBusinessGSTFileChange = async (info) => {  
    if (info.fileList.length > 0) {     
      const newFileList = [...info.fileList];
      setbusinessGstinCertificate(newFileList);
      form.setFieldsValue({ businessGstinCertificate: info.fileList });
    }
    else{
      form.setFieldsValue({ businessGstinCertificate: [] });
    }
  };
  const handlebusinessPanCardChange = async (info) => {
    if (info.fileList.length > 0) {     
    const newFileList = [...info.fileList];
    setbusinessPanCard(newFileList);
    form.setFieldsValue({ businessPanCard: info.fileList });
    }
    else{
      form.setFieldsValue({ setbusinessPanCard: [] });
    }
  };
  const handleaadharCardChange = async (info) => {
    if (info.fileList.length > 0) {     
    const newFileList = [...info.fileList];
    setaadharCard(newFileList);
    form.setFieldsValue({ aadharCard: info.fileList });
    }
    else{
      form.setFieldsValue({ setaadharCard: [] });
    }
  };
  const handledirectorPanCardChange = async (info) => {
    if (info.fileList.length > 0) {     
    const newFileList = [...info.fileList];
    setdirectorPanCard(newFileList);
    form.setFieldsValue({ directorPanCard: info.fileList });
    }
    else{
      form.setFieldsValue({ setdirectorPanCard: [] });
    }
  };
  const testimonialsData = [
    {
      quote: "mobCREDIT has been a game-changer for our material procurement process. It streamlines cash flow, eliminates delays, and ensures seamless project execution. It’s an essential tool for any architect focused on efficiency.",
      author: "Anantha VR",
      position: "Hidecor",
      Logo: HidecorLogo,
      logoclass:"HidecorLogo",
      authorimage:HidecoreAuthorLogo,
      firmtype:"Interior Design firm",
    },
    {
      quote: "mobCREDIT has simplified the process, eliminating the hassle of constantly requesting credit from suppliers. Now, we focus on what matters most—building! Highly recommend it!",
      author: "Sankalp Solanki",
      position: "My Sankalp",
      Logo: MysankalpLogo,
      logoclass:"MysankalpLogo",
        authorimage:MysankalpAuthorLogo,
        firmtype:"Home & Office Construction",
    },
    {
      quote: "Initially, we didn’t think we needed it, but mobCREDIT has greatly simplified payments and boosted supplier trust. With broader adoption by suppliers, it will ease procurement.",
      author: "Amith Shetty",
      position: "Yaahvi Homes",
      Logo: YaahviLogo,
      logoclass:"YaahviLogo",
        authorimage:yashviauthorLogo,
        firmtype:"Construction & Interior firm",
    },
    {
      quote: "Each transaction is securely authorised via OTP, giving us control and peace of mind. Ability to use this with independent suppliers is excellent. No more sudden follow ups from the suppliers for repayments.",
      author: "Sairaam Rai",
      position: "Sandarbh Design Studio",
      Logo: sandharbhLogo,
      logoclass:"sandharbhLogo",
        authorimage:SandarbauthorLogo,
        firmtype:"Home & Office Construction",
    },
  ];
  return (
    <>
      <Form
        form={form}
        name="basic"
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Col
          className="mt-4"
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
        >
          <Row gutter={16} type="flex" className="mt-4">
            <Col xs={24} sm={12} md={8} lg={8} xl={24}>
              <Text className="txt-getstarted">
                One last step, get activated in next 90 sec
              </Text>
            </Col>
          </Row>

          <Row gutter={16} type="flex" className="mt-4">
            <Col span={24}>
              <Typography.Title level={4}>
                Please verify the below details
              </Typography.Title>
              <Text>Please call mob for editing these fields</Text>
            </Col>
          </Row>
          <Row gutter={16} type="flex" className="mt-6">
            <Col  xs={24} sm={24} md={24} lg={24} xl={12}>
              <Form
                name="form"              
                layout="vertical"
                autoComplete="off"
                initialValues={{
                businessGstinCertificate: [],
                businessPanCard: [],
                aadharCard: [],
                directorPanCard: []
              }}
              >
                <Form.Item
                  label={
                    <span>
                      Business name <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                >
                  <Form.Item
                    label="BusinessName"
                    name="BusinessName"
                    noStyle
                    
                  >
                    <Row>
                      <Col span={24}>
                        <Input
                          className="custom-input"
                           disabled="true"
                          style={{
                            height: "40px",
                          }}
                          value={SellerData?.data?.vendor_name}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
                <Form.Item
                className="margin-top-50"
                  label={
                    <span>
                     GSTIN <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                >
                  <Form.Item
                    label="GSTIN"
                    name="GSTIN"
                    noStyle
                    
                  >
                    <Row>
                      <Col span={24}>
                        <Input
                          className="custom-input"
                           disabled="true"
                          style={{
                            height: "40px",
                          }}
                          value={SellerData?.data?.gst_number}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  className="margin-top-50"
                  label={
                    <span>
                      Business mobile (for OTP) <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                >
                  <Form.Item
                    label="MobileNo"
                    name="MobileNo"
                    noStyle
                  
                  >
                    <Row>
                      <Col span={24}>
                        <Input
                          className="custom-input"
                          disabled="true"
                          style={{
                            height: "40px",
                          }}
                          value={SellerData?.data?.phone_number}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
              </Form>
            </Col>
            <Col  xs={24} sm={24} md={24} lg={24} xl={12}>
              <Card>
                <Text style={{ display: "flex", gap: "5px" }}>
                  {SellerData?.data?.vendor_name}{" "}
                </Text>
                {SellerData?.data?.gst_number && (
                  <Col style={{ margin: "12px 0",padding:"0px" }}>
                    <Text className="gst_text">
                      GST NO: {SellerData?.data?.gst_number}
                    </Text>
                  </Col>
                )}
                <Text>
                  {SellerData?.data?.address?.address} ,{" "}
                  {SellerData?.data?.address?.address_line_2 && (
                    <>{SellerData?.data?.address?.address_line_2} , </>
                  )}
                  <br />
                  {SellerData?.data?.address?.state},{SellerData?.data?.address?.city}
                  <br />
                  {SellerData?.data?.address?.pincode}
                </Text>
                <br />
                <Text>+91 {SellerData?.data?.address?.phone_number}</Text>
              </Card>
            </Col>
          </Row>
          <Row gutter={16} type="flex" className="mt-4">
            <Col span={24}>
              <Typography.Title level={4}>
                Please upload these documents of your business
              </Typography.Title>
              <Row gutter={16} className="mt-4">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="businessGstinCertificate"
                  rules={[
                    {
                      required: true,
                      message: "Please select business GSTIN certificate",
                    },
                  ]}
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e.fileList}
                  >
                    <Card className="card-content">
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexWrap:"wrap"
                        }}
                      >
                        <Typography.Title level={5} className="d-flex" style={{gap:"10px"}}>
                          <UploadFileIcon />
                          Business GSTIN certificate{" "}
                          <span className="text-danger">*</span>
                        </Typography.Title>
                        <Upload
                        className="upload-control"
                          showUploadList={true}
                           listType="text"
                        action="" 
                          maxCount={1}                          
                          onChange={(info) => handleBusinessGSTFileChange(info)}
                          onRemove={() => {
                            form.setFieldsValue({ businessGstinCertificate: [] });
                          }}
                        >
                          Upload
                        </Upload>
                      </Col>
                    </Card>
                  </Form.Item>
                  <Form.Item name="businessPanCard"
                  rules={[
                    {
                      required: true,
                      message: "Please select business PAN card",
                    },
                  ]}
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e.fileList}
                  >
                    <Card className="card-content mt-2">
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexWrap:"wrap"
                        }}
                      >
                        <Typography.Title level={5} className="d-flex" style={{gap:"10px"}}>
                          <UploadFileIcon />
                          Business PAN card{" "}
                          <span className="text-danger">*</span>
                        </Typography.Title>
                        <Upload
                         className="upload-control"
                          action={false} 
                          maxCount={1}
                          onChange={(info) => handlebusinessPanCardChange(info)}
                          onRemove={() => {
                            form.setFieldsValue({ businessPanCard: [] });
                          }}
                          
                        >
                          Upload
                        </Upload>{" "}
                      </Col>
                    </Card>
                  </Form.Item>
                  <Form.Item name="aadharCard" 
                   rules={[
                    {
                      required: true,
                      message: "Please select Owner/ Partner/ Director’s Aadhar",
                    },
                  ]}
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e.fileList}
                  >
                    <Card className="card-content  mt-2">
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexWrap:"wrap"
                        }}
                      >
                        <Typography.Title level={5} className="d-flex" style={{gap:"10px"}}>
                        <Col>
                          <UploadFileIcon />
                          </Col>
                          <Col>
                          <span className="font-com-text"> Owner/ Partner/ Director’s Aadhar{" "}</span> 
                          <span className="text-danger">*</span><br/>
                          <span  className="text-subhead-font-owner">Add for all the directors</span>
                          </Col>
                        </Typography.Title>
                        <Upload
                         className="upload-control"
                          multiple
                          maxCount={5}
                          action={false} 
                          onChange={(info) => handleaadharCardChange(info)}
                          onRemove={() => {
                            form.setFieldsValue({ aadharCard: [] });
                          }}
                        >
                          Upload
                        </Upload>
                      </Col>
                    </Card>
                  </Form.Item>
                  <Form.Item name="directorPanCard" 
                  rules={[
                    {
                      required: true,
                      message: "Please select Owner/ Partner/ Director’s PAN card",
                    },
                  ]}
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e.fileList}
                  >
                    <Card className="card-content mt-2">
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexWrap:"wrap"
                        }}
                      >
                        <Typography.Title level={5} className="d-flex" style={{gap:"10px"}}>
                        <Col>
                          <UploadFileIcon />
                          </Col>
                          <Col>
                          <span className="font-com-text">  Owner/ Partner/ Director’s PAN card</span>
                          <span className="text-danger">*</span><br/>
                          <span className="text-subhead-font-owner">Add for all the directors</span>
                          </Col>
                        </Typography.Title>
                        <Upload
                         className="upload-control"
                          multiple
                          action={false} 
                          maxCount={5}
                          onChange={(info) => handledirectorPanCardChange(info)}
                          onRemove={() => {
                            form.setFieldsValue({ directorPanCard: [] });
                          }}
                        >
                          Upload
                        </Upload>
                      </Col>
                    </Card>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={16} type="flex" className="mt-4">
            <Col span={24}>
              <Typography.Title level={4}>Testimonials</Typography.Title>
              <Carousel
                touchDraggable={true}
                // autoplay
                dotPosition="bottom"
                slidesToShow={1}
                slidesToScroll={1}
                initialSlide={1}
                className="d-block d-sm-block d-lg-none d-xl-none"
               
                style={{ textAlign: "left" }}
              >
                {testimonialsData.map((testimonial, index) => (
                  <div key={index}>
                    <Card className="card-testimonial text-left bg-testimonial">
                      <Row className="d-flex" style={{alignItems:"center", justifyContent:"space-between"}}>
                        <Col className="p-0">
                        <img  src={testimonial.Logo} alt={testimonial.Logo} className={testimonial.logoclass} />
                        </Col>
                        <Col>
                        <Text className="testimonial-badge">{testimonial.firmtype}</Text>
                        </Col>
                      </Row>
                      <Row>
                      <Col className="p-0 mt-2">
                      <Col style={{height:"110px"}}>
                      <Typography.Text className="font-italic">{testimonial.quote}</Typography.Text>
                      </Col>
                      <Col className="d-flex mt-4" style={{gap:"10px",alignItems:"center"}}>
                      <Col> <img  src={testimonial.authorimage} alt={testimonial.authorimage} /></Col>
                      <Col style={{marginTop: "8px"}}>
                      <Typography.Title className="authorname" level={5}>
                        {testimonial.author}
                      </Typography.Title>
                      <Typography.Text className="font-italic" style={{marginTop:"-10px"}}>{testimonial.position}</Typography.Text></Col>
                      </Col>
                      
                      </Col>
                      </Row>
                    </Card>
                  </div>
                ))}
              </Carousel>
              <Carousel
                touchDraggable={true}
                // autoplay
                dotPosition="bottom"
                slidesToShow={2}
                slidesToScroll={1}
                initialSlide={1}
                className="d-none d-md-block d-lg-block d-xl-block"
               
                style={{ textAlign: "left" }}
              >
                {testimonialsData.map((testimonial, index) => (
                  <div key={index}>
                    <Card className="card-testimonial text-left bg-testimonial">
                      <Row className="d-flex" style={{alignItems:"center", justifyContent:"space-between"}}>
                        <Col className="p-0">
                        <img  src={testimonial.Logo} alt={testimonial.Logo} className={testimonial.logoclass} />
                        </Col>
                        <Col>
                        <Text className="testimonial-badge">{testimonial.firmtype}</Text>
                        </Col>
                      </Row>
                      <Row>
                      <Col className="p-0 mt-2">
                      <Col style={{height:"110px"}}>
                      <Typography.Text className="font-italic">{testimonial.quote}</Typography.Text>
                      </Col>
                      <Col className="d-flex mt-4" style={{gap:"10px",alignItems:"center"}}>
                      <Col> <img  src={testimonial.authorimage} alt={testimonial.authorimage} /></Col>
                      <Col style={{marginTop: "8px"}}>
                      <Typography.Title className="authorname" level={5}>
                        {testimonial.author}
                      </Typography.Title>
                      <Typography.Text className="font-italic" style={{marginTop:"-10px"}}>{testimonial.position}</Typography.Text></Col>
                      </Col>
                      
                      </Col>
                      </Row>
                    </Card>
                  </div>
                ))}
              </Carousel>
            </Col>
          </Row>
          <Row gutter={16} type="flex" className="mt-4 custom-bottom-margin">
            <Col span={24}>
              <Typography.Title level={4}>Your plan</Typography.Title>
              {isFetchingPlan ? (
                <Card loading={isFetchingPlan} className="card-content"></Card>
              ) : (
                <>
                  <Form.Item name="planId"
                  // initialValue={defaultPlanId}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select a plan",
                  //   },
                  // ]}
                  >
                    <Radio.Group style={{ width: "100%" }} defaultValue={defaultPlanId||1} onChange={(e) => setDefaultPlanId(e.target.value)}>
                      {allPlanData &&
                        allPlanData?.data.map((plan, index) => (
                          <Card key={index} className="card-planlist">
                            <Row>
                            <Col
                            xs={22} sm={12} md={12} lg={12} xl={22}                              
                            >
                              <Row style={{ width: "100%" }}>
                              <Col  xs={24} sm={12} md={12} lg={12} xl={18}>
                              <Text>
                                <Typography.Title level={4}>
                                  {plan?.subscription_name}
                                </Typography.Title>
                                <Typography.Text>
                                  {plan?.subscription_details}
                                </Typography.Text>
                              </Text></Col>
                              <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                              <Col   className="price-text-alignments">
                                <Text
                                  className="text-price-big"
                                  style={{ marginRight: "6px" }}
                                >
                                  ₹ {plan?.price} <Text
                                  className="text-price-sm"
                                  style={{ marginRight: "6px" }}
                                >/month</Text>
                                </Text><br />
                                <Text
                                  className="text-price-save"
                                  style={{ marginRight: "6px" }}
                                >
                                 SAVE 70% <Text
                                  className="text-price-offer"
                                  style={{ marginRight: "6px" }}
                                >₹ 999 /month</Text>
                                </Text>
                                </Col>
                              </Col>
                              </Row>
                              
                              
                            </Col>
                            <Col xs={2} sm={12} md={12} lg={12} xl={2} className="d-flex" style={{justifyContent:"flex-end"}}>
                              
                                <Col>
                                <Radio   className="radio-price" value={plan?.id}></Radio>
                                </Col>
                              </Col>
                              </Row>
                          </Card>
                        ))}
                    </Radio.Group>
                  </Form.Item>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Form>
    </>
  );
};

export default React.memo(Activation);
