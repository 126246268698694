import React from "react";

const Settlements = ({ fillColor, strokeColor }) => {
  const svgStyle = {
    fill: fillColor,
    stroke: strokeColor,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    // strokeWidth: "0.5px",
  };

  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="24"
    //   height="24.25"
    //   viewBox="0 0 24 24.25"
    //   style={svgStyle}
    // >
    //   <g data-name="Group 10400">
    //     <g data-name="Group 10399">
    //       <path
    //         data-name="Path 46356"
    //         d="M148.109 75.6a.628.628 0 0 1 1.1-.408l2.223 2.261 2.1-2.581a.628.628 0 0 1 .956-.035l.019.025.009.009 2.095 2.581 2.239-2.277a.624.624 0 0 1 .922 0l2.3 2.346 2.559-2.355a.628.628 0 0 1 1.082.433v7.221h4.368a.629.629 0 0 1 .627.627v11.064a3.131 3.131 0 0 1-3.127 3.127h-16.346a3.135 3.135 0 0 1-3.127-3.127V75.6zm17.615 8.47v10.442a1.871 1.871 0 0 0 3.741 0V84.075zm-1.254-7.044-1.985 1.825a.626.626 0 0 1-.869-.022l-2.3-2.336-2.3 2.336a.626.626 0 0 1-.887.006l-2.107-2.59-2.107 2.59a.622.622 0 0 1-.884-.006l-1.671-1.7v17.383a1.877 1.877 0 0 0 1.872 1.872H165.1a3.086 3.086 0 0 1-.627-1.872z"
    //         transform="translate(-147.109 -73.638)"
    //         style={{ fill: 'none', strokeWidth: '1px' }}
    //        // style={{ fill: 'none', strokeWidth: ".5px" }} // Remove the stroke color inline style
    //       />
    //       <path
    //         data-name="Path 46357"
    //         d="M307.457 213.042a.8.8 0 0 0 0-1.608h-5.228a.8.8 0 0 0 0 1.608h1.488a1.569 1.569 0 0 1 1.207.8h-2.694a.8.8 0 0 0 0 1.608h2.694c-.281.4-.684.4-1.207.4h-1.488a.8.8 0 0 0-.724.563.764.764 0 0 0 .161.885l3.74 3.539a.765.765 0 0 0 .563.2.723.723 0 0 0 .563-.241.764.764 0 0 0 0-1.126l-2.332-2.091a3.58 3.58 0 0 0 2.453-2.131h.8a.8.8 0 0 0 0-1.608h-.8a3.058 3.058 0 0 0-.241-.8z"
    //         transform="translate(-294.91 -201.761)"
    //         style={{ fill: 'none', strokeWidth: '1px' }}
    //        // style={{ fill: 'none', strokeWidth: ".2px" }} // Remove the stroke color inline style
    //       />
    //     </g>
    //   </g>
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.109"
      height="23.498"
      viewBox="0 0 23.109 23.498"
      style={svgStyle}
    >
      <g data-name="Group 10399">
        <path
          data-name="Union 2"
          d="M19935.375 22415.5a3.377 3.377 0 0 1-3.375-3.375V22393l.027-.006a.879.879 0 0 1 .855-.652.858.858 0 0 1 .652.3l2.025 2.061 1.918-2.369a.868.868 0 0 1 .682-.326.9.9 0 0 1 .652.275l.045.051 1.918 2.369 2.041-2.082a.9.9 0 0 1 .643-.275.886.886 0 0 1 .641.281l2.133 2.172 2.373-2.189a.885.885 0 0 1 1.232-.027.852.852 0 0 1 .275.641v6.965h4.119a.878.878 0 0 1 .877.877v11.066a3.378 3.378 0 0 1-3.375 3.375zm-1.621-3.375a1.62 1.62 0 0 0 1.621 1.619h13.4a3.352 3.352 0 0 1-.416-1.619v-16.91l-1.564 1.439a.886.886 0 0 1-.592.23.865.865 0 0 1-.623-.264l-2.121-2.156-2.121 2.156a.873.873 0 0 1-.625.264.863.863 0 0 1-.613-.254l-.021-.021-1.908-2.352-1.934 2.373a.866.866 0 0 1-.619.254.857.857 0 0 1-.619-.264l-1.244-1.266zm16.359 0a1.554 1.554 0 0 0 1.643 1.6 1.514 1.514 0 0 0 1.6-1.6v-10.189h-3.24z"
          transform="translate(-19932 -22392.002)"
          style={{ fill:"#384853"}}
          //style={{ fill: fillColor, strokeWidth: '0.5px' }}
        />
        <path
          data-name="Subtraction 5"
          d="M19936.67 22401.613a.035.035 0 0 1-.02 0h-.012a.288.288 0 0 1-.041 0 .654.654 0 0 1-.441-.17l-3.744-3.547a.652.652 0 0 1-.141-.766v-.012a.707.707 0 0 1 .631-.494h1.484c.525 0 .979 0 1.293-.449l.107-.152h-2.885a.705.705 0 0 1 0-1.41h2.861l-.078-.146a1.668 1.668 0 0 0-1.287-.855h-1.5a.705.705 0 0 1 0-1.41h5.229a.705.705 0 0 1 0 1.41h-1.2l.063.141a2.671 2.671 0 0 1 .234.775l.012.086h.895a.705.705 0 0 1 0 1.41h-.877l-.023.063a3.464 3.464 0 0 1-2.377 2.068l-.2.033 2.484 2.232a.632.632 0 0 1 .215.484.669.669 0 0 1-.215.494v.006a.632.632 0 0 1-.467.209z"
          transform="translate(-19926.336 -22383.18)"
          // style="fill:#384853"
          style={{ fill:"#384853"}}
        />
      </g>
    </svg>
  );
};

export default Settlements;
