import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Modal,
  Typography,
  Row,
  Divider,
  Card,
} from "antd";
import "./ConfirmOrderModal.css";
import { ReactComponent as MobCreditIcon } from "../../../../assets/seller/icons/credit.svg";
import { ReactComponent as CalenderIcon } from "../../../../assets/seller/icons/calender.svg";
const { Title, Text } = Typography;

const ConfirmOrderModal = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    editQuoteData,
    handleConfirmOrder,
    phone_number,
    email,
    loading,
    orderButtonText,
    isCreditOrder,
    totalAmount,
    mobCharges,
    // eslint-disable-next-line 
    balanceAmount,
    paymentReceived,
    mobCreditAmount,
    mobChargesGST,
    mobCreditRecieveAmt,
    // eslint-disable-next-line 
    dueAmount
  } = props;
   

  const [checkboxState, setCheckboxState] = useState({
    email: false,
    phone_number: false,
  });

  const handleCheckboxChange = (e, type) => {
    const targetValue = e.target.checked;
    setCheckboxState({ ...checkboxState, [type]: targetValue });
  };

  const handleSave = () => {
    handleConfirmOrder(checkboxState, editQuoteData?.id);
    // setIsModalOpen(false);
    // setCheckboxState({ email: false, phone_number: false });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setCheckboxState({ email: false, phone_number: false });
  };

  return (
    <>
      <Modal
        onCancel={handleCancel}
        title={
          isCreditOrder
            ? "Convert to mobCREDIT online order?"
            : "Are you sure?"
        }
        // open={true}
        open={isModalOpen}
        style={{ color: "#0a243f", padding: "0px" }}
        footer={[
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              // borderTop: "1px solid #dedede",
              borderRadius: "0px 0px 8px 8px",
              padding: "20px 0px",
              // boxShadow: "0 -6px 10px 0 rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
              width: "100%",
            }}
          >
            <Button
              onClick={handleCancel}
              style={{
                height: "48px",
                fontWeight: 500,
                fontSize: "14px",
                maxWidth: "120px",
                width: "100%",
              }}
            >
              CANCEL
            </Button>
            <Button
              loading={loading}
              disabled={loading}
              onClick={handleSave}
              style={{
                height: "48px",
                maxWidth: "280px",
                width: "100%",
                fontWeight: 500,
                fontSize: "14px",
                color: "white",
                backgroundColor: isCreditOrder ? "#535dd8" : "#015fe5",   
                border: "none",
              }}
            >
              {orderButtonText}
            </Button>
          </Col>,
        ]}
      >
        <Col
          style={{
            display: "flex",
            height: isCreditOrder ? "550px":"350px",
            flexDirection: "column",
            justifyContent: "flex-start",
            padding: "20px",
            overflowY: "scroll",
            marginBottom:"20px"
          }}
        >
          {!isCreditOrder ? (
            <>
            <Title
              level={4}
              style={{
                color: "#0a243f",
                margin: 0,
                marginBottom: "30px",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              Are you sure that you want to create a new order? please make sure
              that all the details mentioned are okay
            </Title>
            <Row>
                <Col span={24}>
                  <Card
                    className="edit-Order-model"
                    title=""
                  >
                    <p className="card-content-para">
                      <Text>Total:</Text> <Text>₹ {totalAmount}</Text>
                    </p>   
                    <p className="card-content-para">
                      <Text>Paid:</Text> <Text>₹ {paymentReceived}</Text>
                    </p>                 
                    {/* <p className="card-content-para">
                      <Typography.Title level={4} className="mt-0">
                        You’ll recieve
                      </Typography.Title>{" "}
                      <Typography.Title className="mt-0" level={4}>
                        ₹ {paymentReceived}
                      </Typography.Title>
                    </p> */}
                  </Card>
                </Col>
              </Row>
          </>
          ) : (
            <>
             <Row className="card-content-para">
             <Col style={{paddingLeft:"6px"}}>
               <Typography.Title level={5}>
               Order conversion (Invoice treatment)
              </Typography.Title>
              </Col>
              </Row>
              <Row className=" d-flex" gutter={[16,16]}  style={{gap:"10px",justifyContent:"center"}}>
                
                <Col  className="btn-caption-step"><span className="text-strong"> Step 1:</span> You are billing to mob</Col>
               
                <Col  className="btn-caption-step"> <span className="text-strong">Step 2: </span>mob is billing the customer</Col>
                
               
              </Row>
              <Divider />
              <Row className="card-content-para">
                <Col>
                  <Typography.Title level={5}>
                    Payment: Delivery + 2 working days
                  </Typography.Title>
                  <Text>
                    Payment will be done 2 working days after delivery
                  </Text>
                </Col>
                <Col>
                  <CalenderIcon />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Card
                    className="Confirm-Order-model"
                    title={<MobCreditIcon />}
                  >
                    <p className="card-content-para">
                      <Text>Order total:</Text> <Text className="text-bold">₹ {totalAmount}</Text>
                    </p>
                    <p className="card-content-para">
                      <Text>mobCREDIT total:</Text> <Text className="text-bold">₹ {mobCreditAmount}</Text>
                    </p>
                    <div className="bg-light-credit">
                    <p className="card-content-para text-bold">
                      <Text>Total MOB charge:</Text> <Text>-₹ {(Number(mobCharges)+Number(mobChargesGST)).toFixed(2)}</Text>
                    </p>
                    <p className="card-content-para">
                      <Text>MOB charges (1.75%):</Text> <Text>₹ {Number(mobCharges).toFixed(2)}</Text>
                    </p>
                    <p className="card-content-para">
                      <Text>GST (18%):</Text> <Text>₹ {Number(mobChargesGST).toFixed(2)}</Text>
                    </p>
                    </div>
                    <p className="card-content-para">
                      <Typography.Title level={4} className="mt-0">
                        You’ll recieve
                      </Typography.Title>{" "}
                      <Typography.Title className="mt-0" level={4}>
                        ₹ {Number(mobCreditRecieveAmt).toFixed(2)}
                      </Typography.Title>
                    </p>
                  </Card>
                </Col>
              </Row>
              <Typography.Title className="mt-4" level={5}>
                Post conversion order EDIT
              </Typography.Title>
              <Row>
                <Text>
                  Edits can be made until the order has been marked DELIVERED
                  <br />
                  1. Increase in mobCREDIT amount is NOT possible
                  <br />
                  2. Decrease in mobCREDIT amount is possible{" "}
                  <span style={{ fontStyle: "italic" }}>
                    (System will auto adjust client’s mobCREDIT amount once
                    marked delivered)
                  </span>
                </Text>
              </Row>

              <Row className="mt-2">
                <Text>
                  NOTE: Any amount increase can be collected by other payment
                  methods OR GENERATE A NEW ORDER
                </Text>
              </Row>
            </>
          )}
          <Col
            style={{ borderBottom: "1px solid #dedede", marginBottom: "20px",display:"none" }}
          ></Col>
          <Col style={{display:"none"}}>
            <Title
              level={5}
              style={{
                color: "#0a243f",
                margin: 0,
                marginBottom: "20px",
                fontWeight: 500,
              }}
            >
              How do you want to share with the customer?
            </Title>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              
              }}
            >
              {email && (
                <Checkbox
                  onChange={(e) => handleCheckboxChange(e, "email")}
                  checked={checkboxState?.email}
                  style={{
                    color: "#0a243f",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  {`Send mail to ${email}`}
                </Checkbox>
              )}
              <Checkbox
                onChange={(e) => handleCheckboxChange(e, "phone_number")}
                checked={checkboxState?.phone_number}
                style={{
                  color: "#0a243f",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {`Send text message to ${phone_number}`}
              </Checkbox>
            </Col>
          </Col>
        </Col>
      </Modal>
    </>
  );
};

export default React.memo(ConfirmOrderModal);
