import { React, useEffect, useState } from "react";
import {
  useLazySellerOrderQuery,
  useLazySellerPickupOrderQuery,
} from "../../../apis/Seller/SellerOrdersApi";
import { Row, Tabs, Col, Pagination } from "antd";
import { useLocation } from "react-router-dom";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import SellerNewOrders from "./SellerNewOrders";
import { useUpdateOrderStatusMutation } from "../../../apis//Seller/SellerOrdersApi";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
const { TabPane } = Tabs;

function SellerOrders() {
  // eslint-disable-next-line
  const [searchedValue, setSearchedValue] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPagePickup, setCurrentPagePickup] = useState(1);
  const location = useLocation();
  // eslint-disable-next-line
  const [sorterState, setSorterState] = useState({
    created_at: "",
  });
  const status = location.state ? location.state.status : "New orders";
  const [
    updateOrderStatusApi,
    {
      isSuccess: successUpdateOrderStatusApi,
      isLoading: updateOrderStatusLoading,
    },
  ] = useUpdateOrderStatusMutation();
  const SortingOptions = [
    {
      key: 1,
      value: "created_at",
      label: "Date: Ascending",
    },
    {
      key: 1,
      value: "-created_at",
      label: "Date: Descending",
    },
  ];
  const sorterChange = (item) => {
    let key = item;
    setSorterState({
      // ...sorterState,
      [key.replace("-", "")]: item,
    });
    SellerOrder({
      sort_by: item,
      ...(searchedValue?.search && { search: searchedValue?.search }),
      page: 1,
    });
  };
  const PickupsorterChange = (item) => {
    let key = item;
    setSorterState({
      // ...sorterState,
      [key.replace("-", "")]: item,
    });
    SellerPickupOrder({
      sort_by: item,
      ...(searchedValue?.search && { search: searchedValue?.search }),
      page: 1,
    });
  };
  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    // setCurrentPage(1);
  };
  const [SellerOrder, { data: OrdersData, isFetching }] =
    useLazySellerOrderQuery();

  const [
    SellerPickupOrder,
    { data: PickupOrdersData, isFetching: isPickupFetching },
  ] = useLazySellerPickupOrderQuery();

  useEffect(() => {
    SellerOrder({ sort_by: "-created_at" });
    SellerPickupOrder({ sort_by: "-created_at" });
    //SellerOrder();
  }, [
    SellerOrder,
    successUpdateOrderStatusApi,
    updateOrderStatusLoading,
    SellerPickupOrder,
  ]);

  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
    };
    setCurrentPage(currPage);
    await SellerOrder(params);
  };
  const handlePageChangePickup = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
    };
    setCurrentPagePickup(currPage);
    await SellerPickupOrder(params);
  };
  return (
    <>
      <>
        <Row className="mt-4 mb-2">
          <Tabs className="width-100" defaultActiveKey={status}>
            <TabPane
              tab={
                <span
                // onClick={() => {
                //   SellerOrder({ sort_by: "-created_at" });
                // }}
                >
                  New orders{" "}
                  <span className="order-badge-rounded">
                    {OrdersData?.data?.new_orders?.results?.length || 0}
                  </span>
                </span>
              }
              key="New orders"
            >
              
                <>
                  <Col
                    className="action-sub-container"
                    style={{ justifyContent: "space-between" }}
                  >
                    <SearchInput
                      size={"large"}
                      placeholder={"Search by order no"}
                      getLibraryApi={SellerOrder}
                      width={"400px"}
                      isLoading={isFetching}
                      handleSearchedValue={handleSearchedValue}
                    />
                    <CustomSelect
                      placeholder={"Sort by"}
                      width={"150px"}
                      options={SortingOptions}
                      handleChange={sorterChange}
                      // value={exlLoading ? <LoadingOutlined /> : "Export"}
                      // disabled={exlLoading ? true : false}
                      // loading={exlLoading}
                    />
                  </Col>
                  <SellerNewOrders
                    data={OrdersData?.data?.new_orders?.results}
                    updateOrderStatusLoading={updateOrderStatusLoading}
                    updateOrderStatusApi={updateOrderStatusApi}
                    setSearchedValue={setSearchedValue}
                    SellerOrder={SellerOrder}
                    isFetching={isFetching}
                  />
                  <Col className="pagination-wrapper">
                    <Pagination
                      simple
                      // showQuickJumper
                      responsive
                      showSizeChanger={false}
                      pageSize={
                        OrdersData?.data?.new_orders?.pagination?.end_index ||
                        30
                      }
                      current={currentPage}
                      onChange={handlePageChange}
                      total={
                        OrdersData?.data?.new_orders?.pagination?.total_entries
                      }
                      // onResize= {handleResize}
                    />
                  </Col>
                </>
              
            </TabPane>
            <TabPane
              tab={
                <span
                // onClick={() => {
                //   SellerOrder({ sort_by: "-created_at" });
                // }}
                >
                  Ready for pickup{" "}
                  <span className="order-badge-rounded">
                    {PickupOrdersData?.data?.results?.length || 0}
                  </span>
                </span>
              }
              key="Ready for pickup"
            >
              
                  <Col
                    className="action-sub-container"
                    style={{ justifyContent: "space-between" }}
                  >
                    <SearchInput
                      size={"large"}
                      placeholder={"Search by order no"}
                      getLibraryApi={SellerPickupOrder}
                      width={"400px"}
                      isLoading={isPickupFetching}
                      handleSearchedValue={handleSearchedValue}
                    />
                    <CustomSelect
                      placeholder={"Sort by"}
                      width={"150px"}
                      options={SortingOptions}
                      handleChange={PickupsorterChange}
                      // value={exlLoading ? <LoadingOutlined /> : "Export"}
                      disabled={isPickupFetching ? true : false}
                      loading={isPickupFetching}
                    />
                  </Col>
                  <SellerNewOrders
                    data={PickupOrdersData?.data?.results}
                    updateOrderStatusLoading={updateOrderStatusLoading}
                    updateOrderStatusApi={updateOrderStatusApi}
                    setSearchedValue={setSearchedValue}
                    isFetching={isPickupFetching}
                  />
                  <Col className="pagination-wrapper">
                    <Pagination
                      simple
                      // showQuickJumper
                      responsive
                      showSizeChanger={false}
                      pageSize={
                        PickupOrdersData?.data?.pagination?.end_index || 30
                      }
                      current={currentPagePickup}
                      onChange={handlePageChangePickup}
                      total={PickupOrdersData?.data?.pagination?.total_entries}
                      // onResize= {handleResize}
                    />
                  </Col>              
             
            </TabPane>
            {/* <TabPane
                tab={
                  <span>
                    Upcoming{" "}
                    <span className="order-badge-rounded">
                      {OrdersData?.data?.new_orders.length}
                    </span>
                  </span>
                }
                key="Upcoming"
              >
                <SellerOrdersComponent data={OrdersData?.data?.new_orders} />
              </TabPane> */}
          </Tabs>
        </Row>
      </>
    </>
  );
}

export default SellerOrders;
