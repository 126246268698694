import { React, useState, useEffect } from "react";
import {
  Typography,
  Modal,
  Card,
  Upload,
  Col,
  message,
  Form,
  Button,
} from "antd";
import { SellerDashboardSkeleton } from "../../../components/skeleton/SellerDashboardSkeleton";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { CheckCircleFilled } from "@ant-design/icons";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import SellerPasswordChange from "./SellerPasswordChange";
import SellerStoreTiming from "./SellerStoreTiming";
import { useChangePasswordMutation } from "../../../apis/Seller/SellerAccountsAPI";
const Text = Typography;
//export default function SellerBasicDetails(SellerData,handleChildFieldChange,name) {
const SellerBasicDetails = (prop) => {
  const {
    SellerData,
    handleChildFieldChange,
    name,
    form,
    handleEditFormData,
    handleEditedTimingsData,
    ShopTimingForm,
    setIsTimingModalOpen,
    isTimingModalOpen,
  } = prop;
  const [fileChanged, setFileChanged] = useState(false);
 // eslint-disable-next-line 
  const [fileList, setFileList] = useState([]);
  
  const [info, setInfo] = useState(null);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [imageUrl, setImageUrl] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );
  const [changePasswordApi] = useChangePasswordMutation();
  const [Passwordform] = Form.useForm();
  const [StoreTimingform] = Form.useForm();

  const handleReset = () => {
    setIsModalOpen(false);
    Passwordform.resetFields();
  };
  const handleResetStoreTiming = () => {
    setIsTimingModalOpen(false);
    StoreTimingform.resetFields();
  };

  const handlePasswordChange = async (values) => {
    if (values) {
      const response = await changePasswordApi({
        old_password: values.currentPassword,
        new_password: values.password2,
      });
      if (response?.error?.data?.status === false) {
        showErrorToast(response?.error?.data?.message);
        Passwordform.resetFields();
      } else {
        showSuccessToast(response?.data?.message);
        Passwordform.resetFields();
      }
    }
  };

  const handleFileChange = async (info) => {
    if (info?.file?.status !== "error") {
      let fileList = info.file;
      // fileList = fileList.slice(-1);
      // fileList = fileList.filter((file) => {
      if (fileList.type.includes("image")) {
        setFileChanged(true);
        setInfo(info);
        //setFileList(fileList);
        if (fileChanged) {
          form.submit();
          setFileChanged(false);
        }
        return true;
      } else {
        message.error(`${fileList.name} is not a valid image file.`);
        return false;
      }
    }
    //});
  };
  useEffect(() => {
    const file = info?.fileList[0];
    let url = file?.originFileObj;
    const inputValues = {
      [name]: url,
    };

    handleChildFieldChange(inputValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info, fileList]);
  const copyLinkToClipboardRow = async (params) => {
    try {
      await navigator.clipboard.writeText(params);
      showSuccessToast("Link copied to clipboard successfully");
    } catch (err) {
      showErrorToast("Failed to copy link to clipboard", err);
    }
  };
  return (
    <>
      {SellerData?.isLoading ? (
        <SellerDashboardSkeleton />
      ) : (
        <>
          <Card className="card card-design">
            <div className="card-header">
              <Text className="text-header">Basic Information</Text>
            </div>
            <div className="col-md-2 mt-4 mb-3">
              <div className="Pos-img-upload useraccountimg">
                <div className="Pos-logo-preview" id="logo-image">
                  <Form form={form} onFinish={handleEditFormData}>
                    <Upload
                      name={name}
                      listType="picture-circle"
                      className="avatar-uploader"
                      showUploadList={false}
                      onChange={(info) => {
                        handleFileChange(info);
                        //form.setFieldsValue({ [name]: info.file }); // update form values
                      }}
                    >
                      {SellerData?.seller_display_image ? (
                        <img
                          src={SellerData?.seller_display_image}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </Form>
                </div>
              </div>

              {/* <div className="pos-logo-remove1">
                  <Text for="ImageFile">
                    <img
                      src="/MOBAdmin/Content/asset/dist/img/camera-icon.png"
                      alt=""
                    ></img>
                  </Text>
                </div> */}
            </div>
            {/* <span className="text-danger pl-4 small">
              1.Supported formats(.png,.jpeg,.jpg)
              <br />
            </span>
            <span className="text-danger pl-4 small">
              2.Maximum file size: 100kb
              <br />
            </span> */}
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <Text className="text-head-light">Business Name</Text>
                <Text className="text-head">{SellerData?.vendor_name}</Text>
              </li>

              <li className="list-group-item">
                <Text className="text-head-light">Email Address</Text>

                <Col className="row-style">
                  <Text className="text-head mr-2">
                    {SellerData?.email || "NA"}{" "}
                  </Text>
                  <CheckCircleFilled
                    style={{ fontSize: "16px", color: "green" }}
                  />{" "}
                  Verified{" "}
                </Col>
              </li>
              <li className="list-group-item">
                <Text className="text-head-light">Phone Number</Text>
                <Col className="row-style">
                  <Text className="text-head mr-2">
                    {SellerData?.phone_number || "NA"}
                  </Text>
                  {SellerData?.phone_number && (
                    <>
                      <CheckCircleFilled
                        style={{ fontSize: "16px", color: "green" }}
                      />{" "}
                      Verified
                    </>
                  )}
                </Col>
              </li>
              {/* <li class="list-group-item">
            <span class="font-14">Category</span>
            <br />{" "}
            <strong class="">
              Bathroom and plumbing,Hardware,Living and decoration,Paints and
              wallpapers{" "}
            </strong>
          </li> */}
            </ul>
          </Card>
          <Card className="card card-design">
            <div className="card-header">
              <Text className="text-header">Store timings</Text>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                {/* <Button
                  type="button"
                  className="btn-outline-nocolor float-right"
                  onClick={() => setIsTimingModalOpen(true)}
                >
                  Edit
                </Button> */}
                <p></p>{" "}
                <Text className="text-head">
                  {SellerData?.shop_opening_time || "NA"} -{" "}
                  {SellerData?.shop_closing_time || "NA"}{" "}
                </Text>
              </li>
            </ul>
          </Card>
          {/* <Card className="card card-design">
            <div className="card-header">
              <Text className="text-header">Your password</Text>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <Col className="google-link ">
                  <Col className="link-wrapper">
                    <span className="ant-typography">
                      Password
                      <br />
                      {Array(16)
                        .fill(0)
                        .map((_, i) => (
                          <span key={i} className="dot"></span>
                        ))}
                    </span>
                  </Col>
                  <Col
                    className=" link-wrapperI"
                    onClick={() => setIsModalOpen(true)}
                  >
                    <Button className="ant-typography btn-outline-nocolor">
                      Change password
                    </Button>
                  </Col>
                </Col>
              </li>
            </ul>
          </Card> */}
          <Card className="card card-design">
            <div className="card-header">
              <Text className="text-header">Address & GST</Text>
            </div>

            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <Text className="text-head-light">Address</Text>
                <Text className="text-head">{SellerData?.address?.address || "NA"}</Text>
                <Col className="address-content">
                  <span>This address will be visible on the invoice</span>
                </Col>
              </li>

              <li className="list-group-item">
                <Text className="text-head-light">GST</Text>

                <Text className="text-head mr-2">
                  {" "}
                  {SellerData?.gst_number || "NA"}
                </Text>
              </li>
            </ul>
          </Card>
          <Card className="card card-design">
            <div className="card-header">
              <Text className="text-header">Pickup address/es</Text>
            </div>

            {SellerData?.pickup_address?.map((address, index) => (
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <Text className="text-head">{address?.name}</Text>
                  <Text className="text-head-light">
                    {address?.address}
                    {", "}
                    {address?.address_line_2}
                    {", "}
                  </Text>
                  <Text className="text-head-light">
                    {address?.city}
                    {", "}
                    {address?.state}
                    {" - "}
                    {address?.pincode}
                  </Text>
                  <Text className="text-head-light">
                    Mobile: {address?.phone_number}
                  </Text>
                  {address?.google_map_link && (
                    <Col className="google-link">
                      <Col className="link-wrapper">
                        <span className="ant-typography">
                          Google map link
                          <br />
                          <a
                            href={address?.google_map_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="payment-links"
                          >
                            {address?.google_map_link}
                          </a>
                        </span>
                      </Col>
                      <Col
                        className=" link-wrapperI"
                        onClick={() =>
                          copyLinkToClipboardRow(address?.google_map_link)
                        }
                      >
                        <Button className="ant-typography">Copy link</Button>
                      </Col>
                    </Col>
                  )}
                </li>
              </ul>
            ))}
          </Card>
          <Card className="card card-design">
            <div className="card-header">
              <Text className="text-header">Other details</Text>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <Text className="text-head-light">Key Person</Text>
                <Text className="text-head">
                  {" "}
                  {SellerData?.person_full_name || "NA"}{" "}
                </Text>
              </li>
              <li className="list-group-item">
                <Text className="text-head-light">
                  Shop act registration number
                </Text>
                <Text className="text-head">
                  {SellerData?.shop_act_registration_number || "NA"}
                </Text>
              </li>
              <li className="list-group-item">
                <Text className="text-head-light">Pancard number</Text>
                <Text className="text-head">
                  {SellerData?.pan_card_number || "NA"}
                </Text>
              </li>
              <li className="list-group-item">
                <Text className="text-head-light">Aadharcard number</Text>
                <Text className="text-head">
                  {SellerData?.adhar_card_number || "NA"}
                </Text>
              </li>
              <li className="list-group-item">
                <Text className="text-head-light">Bank name and branch</Text>
                <Text className="text-head">
                  {SellerData?.bank_details?.bank_name || "NA"}
                  {", "}
                  {SellerData?.bank_details?.bank_branch || "NA"}
                </Text>
              </li>
              <li className="list-group-item">
                <Text className="text-head-light">
                  Name of the account holder
                </Text>
                <Text className="text-head">
                  {SellerData?.bank_details?.name_of_account_holder || "NA"}
                </Text>
              </li>
              <li className="list-group-item">
                <Text className="text-head-light">Bank account number</Text>
                <Text className="text-head">
                  {SellerData?.bank_details?.bank_account_number || "NA"}
                </Text>
              </li>
              <li className="list-group-item">
                <Text className="text-head-light">Bank IFSC code</Text>
                <Text className="text-head">
                  {SellerData?.bank_details?.bank_ifsc || "NA"}
                </Text>
              </li>
              <li className="list-group-item">
                <Text className="text-head-light">Bank account type</Text>
                <Text className="text-head">
                  {SellerData?.bank_details?.bank_account_type || "NA"}
                </Text>
              </li>
            </ul>
          </Card>
          <Card className="card card-design">
            <div className="card-header">
              <Text className="text-header">Preferences</Text>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <Text className="text-head-light">Language</Text>
                <Text className="text-head">English</Text>
              </li>
              <li className="list-group-item">
                <Text className="text-head-light"> Time Zone</Text>

                <Text className="text-head">Your timezone is- GMT+5.5</Text>
              </li>
            </ul>
          </Card>

          <Modal
            title="Change Password"
            visible={isModalOpen}
            Passwordform={Passwordform}
            onCancel={handleReset}
            footer={[
              <Form
                form={Passwordform}
                name="passwordChange"
                autoComplete="off"
                style={
                  {
                    // maxWidth: 500,
                  }
                }
                layout="vertical"
                onFinish={handlePasswordChange}
              >
                <div className="modal-footer d-block text-center footer-bottom-border-line">
                  <Button
                    key="cancel"
                    className="btn-cancel order-cancel-btn-style btnI"
                    // onClick={handleModalClose}
                    onClick={handleReset}
                  >
                    CANCEL
                  </Button>
                  <Button
                    key="gotIt"
                    type="primary"
                    className="btn-theme order-got-btn-style btnI"
                    htmlType="submit"
                  >
                    CHANGE PASSWORD
                  </Button>
                </div>
              </Form>,
            ]}
          >
            <SellerPasswordChange
              Passwordform={Passwordform}
              handlePasswordChange={handlePasswordChange}
            />
          </Modal>
          <Modal
            title="Store timings"
            visible={isTimingModalOpen}
            onCancel={handleResetStoreTiming}
            footer={[
              <Form
                form={ShopTimingForm}
                name="ShopTimingForm"
                autoComplete="off"
                style={
                  {
                    // maxWidth: 500,
                  }
                }
                layout="vertical"
                onFinish={handleEditedTimingsData}
              >
                <div className="modal-footer d-block text-center footer-bottom-border-line">
                  <Button
                    key="cancel"
                    className="btn-cancel order-cancel-btn-style btnI"
                    // onClick={handleModalClose}
                    onClick={handleResetStoreTiming}
                  >
                    CANCEL
                  </Button>
                  <Button
                    key="gotIt"
                    type="primary"
                    className="btn-theme order-got-btn-style btnI"
                    htmlType="submit"
                  >
                    SAVE
                  </Button>
                </div>
              </Form>,
            ]}
          >
            <SellerStoreTiming
              ShopTimingForm={ShopTimingForm}
              handleEditedTimingsData={handleEditedTimingsData}
              ShopOpeningTime={SellerData?.shop_opening_time}
              ShopClosingTime={SellerData?.shop_closing_time}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default SellerBasicDetails;
