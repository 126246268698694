import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import "./CustomDatePicker.css";

const CustomDatePicker = ({
  name,
  handleChildFieldChange = () => {},
  value,
  datePikerEmpty,
  disabled
  
}) => {
  const [inputDate, setInputDate] = useState("");
  const onChange = (date, dateString) => {
    setInputDate(date);
    const formattedDate = date ? dayjs(dateString).format("YYYY-MM-DD") : "";
    const inputValues = {
      [name]: formattedDate,
    };
    handleChildFieldChange(inputValues);
  };
  useEffect(() => {
    if (datePikerEmpty) {
      setInputDate("");
    }
    if (value) {
      const newDateValue = dayjs(value);
      onChange(newDateValue, value);
    } else {
      setInputDate(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, datePikerEmpty]);


  

  return (
    <>
      <DatePicker
        className="styled-date-picker"
        suffixIcon={""}
        name={name}
        value={inputDate || undefined}
        onChange={onChange}
        format={"DD-MMM-YYYY"}
        allowClear={true}
        disabled={disabled}
      />
    </>
  );
};

export default CustomDatePicker;
