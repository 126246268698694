import React, { useState,useEffect } from "react";
import "./SellerCustomerView.css";
import { useLazyGetAllTransactionDataQuery,useAddPayablesPaymentMutation,useLazyGetAllOrdersDataQuery,useAddNewPaymentMutation } from "../../../../apis/Seller/SellerPayablesAPI";
import { Button, Col, Typography, Row, Tabs } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as DownArrowIcon } from "../../../../assets/seller/icons/downarrow_green.svg";
import { ReactComponent as UnsettledIcon } from "../../../../assets/seller/icons/unsettledbills.svg";
import { ReactComponent as BalanceClearIcon } from "../../../../assets/seller/icons/blanceclear.svg";
import SellerCustomview from "../../../../components/SellerComponents/SellerCustomview/SellerCustomview";
import AddPaymentModal from "../../../../components/SellerComponents/SellerPayable/AddPaymentModal/AddPaymentModal";
import CustomerAllTransactions from "./CustomerAllTransactions";
import CustomerOrders from "./CustomerOrders";
// import dayjs from "dayjs";
// import customParseFormat from "dayjs/plugin/customParseFormat";
// eslint-disable-next-line 
import {
  // dateFormat,
  // eslint-disable-next-line
  handlePdfDownload,
  // onlyDateFormat,
  // dateFormat,
  // handlePdfDownload,
} from "../../../../commonFuntions/CommonUtilFunctions";
const Text = Typography;
const SellerCustomerView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const searchValue = params?.id;
  const [formData, setFormData] = useState({});
  const [datePickerEmpty, setDatePickerEmpty] = useState(false);  
  const [paymentMode, setPaymentMode] = useState();
  const [inputDate, setInputDate] = useState("");
  const [titleText, settitleText] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [editPayment, setEditPayment] = useState({
    state: false,
    check: true,
  });
  const [
    getAllTrasactionData,
    { data: transactionList, isLoading: isAllDataLoading },
  ] = useLazyGetAllTransactionDataQuery();
  const [
    getAllOrderData,
    { data: OrderList, isLoading: isAllOrderDataLoading },
  ] = useLazyGetAllOrdersDataQuery();
  const [
    orderPaymentApi,
     // eslint-disable-next-line 
    { isSuccess: successAddOrderPayment, isLoading: isFetching },
  ] = useAddPayablesPaymentMutation();
  const [
    orderNewPaymentApi,
     // eslint-disable-next-line 
    { isSuccess: successnewOrderPayment, isLoading: isPaymentFetching },
  ] = useAddNewPaymentMutation();
  useEffect(() => {
    getAllOrderData({ id: searchValue });
    // getSettlementSearchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);
  const handleOk = () => {
    setOpenUploadModal(false);
    setPaymentMode("");
    setInputDate("");   
    // setDatePickerEmpty(false);
    // setFormData({});
  };

  const items = [
    {
      key: "Alltransactions",
      label: "All transactions",
      children: (
        <CustomerAllTransactions
          searchValue={searchValue}
          isAllDataLoading={isAllDataLoading}
          getAllTrasactionData={getAllTrasactionData}
          transactionList={transactionList}
          orderPaymentApi={orderPaymentApi}
          orderNewPaymentApi={orderNewPaymentApi}
   
        />
      ),
    },
    {
      key: "Ordersplaced",
      label: "Orders placed",
      children: <CustomerOrders 
      searchValue={searchValue}
      OrderList={OrderList}
      isAllOrderDataLoading={isAllOrderDataLoading}
      getAllOrderData={getAllOrderData}    
      orderNewPaymentApi={orderPaymentApi}
    
       />,
    },
  ];
  const handleBackNavigate = () => {
    navigate(-1);
  };

  return (
    <>
      <Row>
        <Col>
          <Button
            style={{
              height: "48px",
              fontWeight: 500,
              fontSize: "14px",
              minWidth: "140px",
              width: "100%",
            }}
            icon={<ArrowLeftOutlined />}
            onClick={handleBackNavigate}
          >
            Back
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          {" "}
          <Text className="settleStatement">Payables/ recievables</Text>
        </Col>
        {/* <Col xs={24} sm={12} md={12} lg={12} xl={12} className="text-right">
          Edit profile
        </Col> */}
      </Row>
      <Row className="mt-4 payables">
        <SellerCustomview
          isLoading={isAllDataLoading}
          data={transactionList?.data?.customer}
          editCondition={"false"}
          check={"viewDetail"}
        />
      </Row>
      <Row className={"tag-main-container-payable"}>
        <Col xs={12} sm={12} md={12} lg={12} xl={6}>
          {Number(OrderList?.data?.pending_amount) > 0 ? (
            <Col className={`icon-payable-container`}>
              <DownArrowIcon />
              <Col className="ml-4">
                <Text className={`tag-text-heading`}>To receive</Text>
                <Text className="tag-text text-success">
                  ₹ {Number(OrderList?.data?.pending_amount ||0).toFixed(2)}
                </Text>
              </Col>
            </Col>
          ) : (
            <Col className={`icon-payable-container`}>
              <BalanceClearIcon />
              <Col className="ml-4">
                <Text className={`tag-text-heading`}>To Give</Text>
                <Text className="tag-text text-blue">
                  ₹ {Number(OrderList?.data?.pending_amount||0).toFixed(2)}
                </Text>
              </Col>
            </Col>
          )}
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6}>
          <Col className={`icon-payable-container`}>
            <UnsettledIcon />
            <Col className="ml-4">
              <Text className={`tag-text-heading`}>No of unsettled bills</Text>
              <Text className="tag-text text-danger">{OrderList?.data?.un_settled||0}</Text>
            </Col>
          </Col>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12} className="text-right">
          <Button
            size="large"
            className="btn-outline-danger mr-2"
            onClick={() => {
              setOpenUploadModal(true);
              setDatePickerEmpty(true);
              setEditPayment({ state: false, check: true });
              settitleText("Give money");
            }}
          >
            GIVE MONEY
          </Button>
          <Button
            size="large"
            className="btn-success"
            onClick={() => {
              setOpenUploadModal(true);
              setDatePickerEmpty(true);
              setEditPayment({ state: false, check: true });
              settitleText("Collect money");
            }}
          >
            COLLECT MONEY
          </Button>
        </Col>
      </Row>
      <Row>
        <Tabs
          className="payable-tab mt-4"
          size="large"
          defaultActiveKey="1"
          items={items}
          // onChange={onChange}
        />
      </Row>
      {openUploadModal &&(
      <AddPaymentModal
        openAddFile={openUploadModal}
        setOpenAddFile={setOpenUploadModal}
        titleText={titleText}
        custData={transactionList?.data?.customer}
        handleOk={handleOk}
        formData={formData}
        setFormData={setFormData}
        datePickerEmpty={datePickerEmpty}
        setDatePickerEmpty={setDatePickerEmpty}
        paymentMode={paymentMode}
        setPaymentMode={setPaymentMode}       
        editPayment={editPayment}
        setEditPayment={setEditPayment}
        setOpenUploadModal={setOpenUploadModal}
        setInputDate={setInputDate}
        inputDate={inputDate}
        setAmountError={setAmountError}
        amountError={amountError}
        dateError={dateError}
         setDateError={setDateError}
         orderPaymentApi={orderPaymentApi}
         getAllTrasactionData={getAllTrasactionData}
         OrderData={OrderList?.data?.results}
         getAllOrderData={getAllOrderData}
      />
    )}
      {/* <ConfirmPaymentModal
        open={confirmModal}
        data={formData}
        onBack={handleBack}
        handleOk={handleOk}
        handleSave={handleAddPayment}
      /> */}
    </>
  );
};

export default SellerCustomerView;
