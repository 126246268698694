import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./../baseQuery";

export const SellerPayablesAPI = createApi({
  reducerPath: "SellerPayablesAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getPayableData: builder.query({
      query: (params) => {
        return {
          url: `/api/seller/payable/`,
          params,
        };
      },
    }),
    getAllTransactionData: builder.query({
      query: ({id,param}) => {
        const params = new URLSearchParams(param);
        return {
          url: `/api/seller/payable/${id}/get_all_transactions`,             
          params,
        };
      },
    }),
    getAllOrdersData: builder.query({
      query: ({ id, param  }) => {
        const params = new URLSearchParams(param);
        return {
          url: `/api/seller/payable/${id}/get_placed_order`,              
          params ,
        };
      },
    }),
    addPayablesPayment: builder.mutation({    
      query: (paymentData) => ({
        url: "/api/seller/payable/collect_money/",
        method: "POST",
        body: paymentData,
      }),
    }),
    addNewPayment: builder.mutation({    
      query: (paymentData) => ({
        url: "/api/seller/order/add_payment/",
        method: "POST",
        body: paymentData,
      }),
    }),
    deleteCustomer: builder.mutation({
      query: (phone_number) => ({
        url: `/api/seller/customer/delete_customer/`,
        method: "DELETE",
        body: phone_number,
      }),
    }),
  }),
});

export const { useLazyGetPayableDataQuery,useLazyGetAllTransactionDataQuery,useLazyGetAllOrdersDataQuery,useAddPayablesPaymentMutation,useAddNewPaymentMutation,useDeleteCustomerMutation } =
SellerPayablesAPI;

export default SellerPayablesAPI;
