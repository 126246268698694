import React, { useEffect, useState } from "react";
import {
  Col,
  // Dropdown,
  // Menu,
  Typography,
} from "antd";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import {
  useLazyGetCustomerDetailsQuery,
  useLazyGetDownloadCustomerDetailsReportsQuery,
} from "../../../apis/reports";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
import {
  // DashOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { saveAs } from "file-saver";
import {
  dateFormat,
  // onlyDateFormat,
} from "../../../commonFuntions/CommonUtilFunctions";
import { Link } from "react-router-dom";

const { Text } = Typography;
const options = [
  {
    key: 1,
    value: "Export",
    label: "Export",
  },
];

// const dateOptions = [
//   {
//     key: 1,
//     value: "Today",
//     label: "Today",
//   },
//   {
//     key: 2,
//     value: "Yesterday",
//     label: "Yesterday",
//   },
//   {
//     key: 3,
//     value: "last_7_day",
//     label: "Last 7 day",
//   },
//   {
//     key: 4,
//     value: "last_30_day",
//     label: "Last 30 day",
//   },
//   {
//     key: 5,
//     value: "last_60_day",
//     label: "Last 60 day",
//   },
//   {
//     key: 6,
//     value: "Custom",
//     label: "Custom",
//   },
// ];
// const tableDataSource = [
//   {
//     key: 1,
//     BMP: "BENG 1",
//     seller_name: "Sri Venkateshwara hardware and paints",
//     sales_amount: "₹ 2898700",
//   },
//   {
//     key: 2,
//     BMP: "BENG 1",
//     seller_name: "Sri Venkateshwara hardware and paints",
//     sales_amount: "₹ 2898700",
//   },
//   {
//     key: 3,
//     BMP: "BENG 1",
//     seller_name: "Sri Venkateshwara hardware and paints",
//     sales_amount: "₹ 2898700",
//   },
// ];
const ReportCustomerDetails = () => {
  const [searchedValue, setSearchedValue] = useState({});

  const [currentPage, setCurrentPage] = useState(1);

  const [
    getCustomerOrderReportApi,
    { data: customerOrderReportData, isFetching },
  ] = useLazyGetCustomerDetailsQuery();
  const [getDownloadCustomerDetailsReportapi, { isFetching: downloadReport }] =
    useLazyGetDownloadCustomerDetailsReportsQuery();
  useEffect(() => {
    getCustomerOrderReportApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    // {
    //   key: "icon",
    //   dataIndex: "icon",
    //   width: "50px",
    //   fixed: "left",

    //   // render: (params, record) => {
    //   //   return (
    //   //     <>
    //   //       <Dropdown
    //   //         overlay={
    //   //           <Menu>
    //   //             <>
    //   //               <Menu.Item
    //   //                 //   onClick={() => onEdit(record)}
    //   //                 style={{ padding: "7px 12px" }}
    //   //               >
    //   //                 <Col className="container-list">
    //   //                   <Text className="select-text">Edit</Text>
    //   //                 </Col>
    //   //               </Menu.Item>
    //   //               <Menu.Item
    //   //                 //   onClick={() => handleDeleteModalOpen(record)}
    //   //                 style={{ padding: "7px 12px" }}
    //   //               >
    //   //                 <Col className="container-list">
    //   //                   <Text className="select-text">Delete</Text>
    //   //                 </Col>
    //   //               </Menu.Item>
    //   //             </>
    //   //           </Menu>
    //   //         }
    //   //         trigger={"click"}
    //   //       >
    //   //         <DashOutlined
    //   //         //   onClick={() => _onRowClick(record)}
    //   //         />
    //   //       </Dropdown>
    //   //     </>
    //   //   );
    //   // },
    //   // height: "60px",
    // },

    {
      title: "Name",
      dataIndex: "full_name",
      key: "full_name",
      width: "300px",
      fixed: "left",
      render: (params, record) => {
        return (
          <Link
            // onClick={() => handleUserDetailsNavigation(record)}
            to={`/admin/customer/customerDetails/${record?.phone_number}`}
            target="_blank"
            style={{ fontWeight: 500, color: "#2973f0", cursor: "pointer" }}
          >
            {params}
          </Link>
        );
      },
    },
    {
      title: "Business mobile",
      dataIndex: "phone_number",
      key: "phone_number",
      width: "200px",
      render: (params, record) => {
        return (
          <Link
            // onClick={() => handleUserDetailsNavigation(record)}
            to={`/admin/customer/customerDetails/${record?.phone_number}`}
            target="_blank"
            style={{ fontWeight: 500, color: "#2973f0", cursor: "pointer" }}
          >
            {params}
          </Link>
        );
      },
    },
    {
      title: "Email Id",
      dataIndex: "email",
      key: "email",
      width: "300px",
      render: (params, record) => {
        return (
          <Col
            // onClick={() => handleUserDetailsNavigation(record)}
            style={{ fontWeight: 400 }}
          >
            {params || "N/A"}
          </Col>
        );
      },
    },
    //     {
    //       title: "DOB",
    //       dataIndex: "date_of_birth",
    //       key: "date_of_birth",
    //       width: "250px",
    //       render:(params,record)=>{
    // return(
    //   <Col>
    //   {(!params || params==="None")? "None":onlyDateFormat(params)}

    //   </Col>
    // )

    //       }
    //     },
    {
      title: "Date of Registeration",
      dataIndex: "date_joined",
      key: "date_joined",
      width: "250px",
      render: (params, record) => {
        return (
          <Col>
            <Text>{params ? dateFormat(params) : ""}</Text>
          </Col>
        );
      },
    },
    // {
    //   title: "Gender",
    //   dataIndex: "gender",
    //   key: "gender",
    //   width: "150px",
    // },

    {
      title: "GSTIN",
      dataIndex: "gst_number",
      key: "gst_number",
      width: "250px",
      render: (params, record) => {
        return (
          <Col>
            <Text>{params}</Text>
          </Col>
        );
      },
    },
    {
      title: "Address Line 1",
      dataIndex: "address",
      key: "address",
      width: "350px",
      render: (parmas, record) => {
        return (
          <Col>
            <Text>{parmas}</Text>
          </Col>
        );
      },
    },
    {
      title: "Road/ Area/ Colony or google maps link",
      dataIndex: "address_line_2",
      key: "address_line_2",
      width: "350px",
      render: (parmas, record) => {
        return (
          <Col>
            <Text>{parmas}</Text>
          </Col>
        );
      },
    },

    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: "150px",
      render: (parmas, record) => {
        return (
          <Col>
            <Text>{parmas}</Text>
          </Col>
        );
      },
    },
    {
      title: "Pincode",
      dataIndex: "pincode",
      key: "pincode",
      width: "150px",
      render: (parmas, record) => {
        return (
          <Col>
            <Text>{parmas}</Text>
          </Col>
        );
      },
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: "150px",
      render: (parmas, record) => {
        return (
          <Col>
            <Text>{parmas}</Text>
          </Col>
        );
      },
    },

    {
      title: "Total orders",
      dataIndex: "total_orders",
      key: "total_orders",
      width: "150px",
      render: (parmas, record) => {
        return (
          <Col>
            <Text>{parmas}</Text>
          </Col>
        );
      },
    },

    {
      title: "Wallet Amount",
      dataIndex: "wallet_amount",
      key: "wallet_amount",
      width: "150px",
      render: (parmas, record) => {
        return <Col>{`₹ ${parseFloat(parmas).toFixed(2)}`}</Col>;
      },
    },
  ];

  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),

      // ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
      // ...(sorterState.created_at && { sort_by: sorterState.created_at }),
    };
    setCurrentPage(currPage);
    await getCustomerOrderReportApi(params);
  };

  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };
  const handleExportApi = async () => {
    console.log("dgdgh");
    let response = await getDownloadCustomerDetailsReportapi({
      ...(searchedValue.search && { search: searchedValue.search }),

      download: true,
    });
    if (response?.status === "fulfilled") {
      // handlePdfDownload(response?.data?.data?.url);
      saveAs(response?.data?.data?.url);
    }
  };

  return (
    <Col className="SalesReport-wrapper">
      <Text className="heading">Customer Details</Text>
      <Col style={{ display: "flex", gap: "1rem" }}>
        <SearchInput
          size={"large"}
          placeholder={"Search by customer name, mobile no, email"}
          width={"400px"}
          getLibraryApi={getCustomerOrderReportApi}
          handleSearchedValue={handleSearchedValue}
          isLoading={isFetching}
        />

        <CustomSelect
          placeholder={"Export"}
          width={"150px"}
          options={options}
          handleChange={handleExportApi}
          value={downloadReport ? <LoadingOutlined /> : "EXPORT"}
          disabled={downloadReport ? true : false}
          loading={downloadReport}
        />
      </Col>
      <CustomTableWithPagination
        className="order-list-table"
        //   tableExpandable={defaultExpandable}
        // tableDataSource={tableDataSource || []}
        tableDataSource={customerOrderReportData?.data?.results}
        tableColumns={columns}
        locale={{
          emptyText: isFetching ? <TableSkeleton length={10} /> : "",
        }}
        isLoading={customerOrderReportData?.data?.results.length && isFetching}
        scroll={{ x: "100%" }}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        itemsPerPage={30}
        totalEntries={
          !isFetching &&
          customerOrderReportData?.data?.pagination?.total_entries
        }
      />
    </Col>
  );
};

export default ReportCustomerDetails;
