import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../baseQuery";
export const SellerCommercialsAPI = createApi({
  reducerPath: "SellerCommercialsAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllPlanDetails: builder.query({
      query: (params) => {
        return {
          url: "/api/seller/plans",
        };
      },
    }),
    uploadDocuments: builder.mutation({
      query: (data) => {
        console.log("🚀 ~ formData:", data?.data?.businessPanCard)
        // console.log("🚀 ~ imageFile:", values?.values)
         const formData = new FormData();
         
        Object.keys(data?.data?.businessGstinCertificate
        ).forEach((key) => {
          formData.append("gstin_certificate", data?.data?.businessGstinCertificate[key]?.originFileObj);
        });
        Object.keys(data?.data?.businessPanCard).forEach((key) => {         
          formData.append("business_pan_card", data?.data?.businessPanCard[key]?.originFileObj);
        });
        Object.keys(data?.data?.directorPanCard).forEach((key,index) => {
          const fieldName = index === 0 ? 'owner_pan_card' : `owner_pan_card_${index + 1}`;
          formData.append(fieldName, data?.data?.directorPanCard[key]?.originFileObj);
        });
        Object.keys(data?.data?.aadharCard).forEach((key,index) => {
          const fieldName = index === 0 ? 'adhar_card' : `adhar_card_${index + 1}`;
          formData.append(fieldName, data?.data?.aadharCard[key]?.originFileObj);
        });
        
        return {
          url: "/api/seller/subscription/upload_documents/",
          method: "POST",
          body: formData,
        };
      },
    }),
    purchasePlan: builder.mutation({
      query: (paymentdata) => {       
        return {
          url: "/api/seller/subscription/purchase_plan/",
          method: "POST",
          body: paymentdata,
        };
      },
    }),
    getRazorPayPayment: builder.query({
      query: ({params}) => ({
        method: "GET",
        url: `/api/seller/subscription/plan_purchased/`,
        params,
      }),
    }),
    
  }),
  
});
export const { useLazyGetAllPlanDetailsQuery,useUploadDocumentsMutation,usePurchasePlanMutation ,useLazyGetRazorPayPaymentQuery} = SellerCommercialsAPI;

export default SellerCommercialsAPI;
