import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyGetDashboardQuery } from "../../../apis/Seller/sellerDashboard";

import "./SellerDashboard.css";
import { SellerDashboardSkeleton } from "../../../components/skeleton/SellerDashboardSkeleton";
import SellerActiveDashboard from "./SellerActiveDashboard";
import SellerDashboardInactive from "./SellerDashboardInactive";

const SellerDashboard = () => {
  const bmp_isactive = localStorage.getItem("bmp_isactive"); 
  const [requestedFlag, setrequestedFlag] = useState(false);
  const isAuthenticated = localStorage.getItem("accessToken") !== null;
  const isBMPIdAuthenticated = localStorage.getItem("bmp_id") !== null;
  const navigate = useNavigate();
  useEffect(() => {       
    if (!isAuthenticated || !isBMPIdAuthenticated) {
        navigate("/SellerLogin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [cacheKey, setCacheKey] = useState(0);
  const [SellerdashboardApi, { data: dashboardData, isFetching }] =  
    useLazyGetDashboardQuery(cacheKey);
   
  useEffect(() => {
    SellerdashboardApi(); 
    // eslint-disable-next-line  
  }, []);

  useEffect(() => {     
    if (dashboardData) {   
   
    localStorage.setItem(
      "store_order_count",
      dashboardData?.data?.store_order_count
    );
    localStorage.setItem("out_of_stock", dashboardData?.data?.out_of_stock);
    localStorage.setItem(
      "bmp_credit_enable",
      dashboardData?.data?.mob_credit_enable
    );
    localStorage.setItem("bmp_plan_expiry", dashboardData?.data?.plan_expiry);
    localStorage.setItem("bmp_isactive", dashboardData?.data?.is_active);  
    setrequestedFlag(dashboardData?.data?.requested);
    setCacheKey(cacheKey + 1);
  }
  // eslint-disable-next-line 
  }, [dashboardData]);

  return (
    <>
      {isFetching ? (
        <SellerDashboardSkeleton />
      ) : (
        <>
        {bmp_isactive === "true" ? (
        <SellerActiveDashboard dashboardData={dashboardData} />
      ) : (
        <SellerDashboardInactive
          dashboardData={dashboardData}
          requestedFlag={requestedFlag}
          setrequestedFlag={setrequestedFlag}
        />
        )}
        </>
      )}
    </>
  );
};

export default SellerDashboard;
