import { React, useState } from "react";
import { Card, Row, Col, Button, Tag } from "antd";
import "./SellerOrdersComponent.css";
import mobcreditwhiteicon from "../../../assets/seller/icons/mobstar_white.svg";
import { dateFormat } from "../../../commonFuntions/CommonUtilFunctions";
import { useNavigate } from "react-router-dom";
import SellerConfirmationModal from "../../../modules/Seller/SellerOrders/SellerOrderDetails/SellerOrderModals/SellerConfirmationModal/SellerConfirmationModal";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
// eslint-disable-next-line
function SellerOrdersComponent(
  orderdata,
  updateOrderStatusLoading,
  updateOrderStatusApi
) {
  const [ConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const navigate = useNavigate();
  const handlerandom = () => {};
  const handleViewDetals = (id, status) => {
    navigate(`/Seller/orders/SellerViewDetails/${id}`, {
      state: { id, status },
    });
  };
  // eslint-disable-next-line
  const handleConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };
  const handleChangeStatus = async (status, index) => {
    const response = await updateOrderStatusApi({
      id: index,
      params: { status },
    });
    if (response?.data?.status === true) {
      showSuccessToast(response?.data?.message);
      setConfirmationModalOpen(false);
    } else {
      showErrorToast(response?.error?.data?.message);
      setConfirmationModalOpen(false);
    }
  };
  if (!orderdata?.data) {
    return <div className="no-records">No orders available!</div>;
  }
  return (
    <>
      {orderdata?.data.map((item, index) => (
       
          <Row className="order-list" key={index}>
            <Col span={24}>
              <div className={item?.ismobCredit ? "mobcredit-highlight" : ""}>
                {item?.ismobCredit && (
                  <img
                    className="mobcredit-image"
                    src={mobcreditwhiteicon}
                    alt=""
                  ></img>
                )}
                <Card className="info-box-hover p-0" key={index}>
                  <p className="card-title seller-title-text">
                    Seller Order ID: {item.suborder_id}{" "}
                 
                    <span>
                      <Tag
                        className={`status-tag-style ${
                          item?.status === "Waiting"
                            ? "status-waiting"
                            : item?.status === "Ready for Pickup"
                            ? "status-ready"
                            : item?.status === "Rejected by seller"
                            ? "status-rejected"
                            : "status-default"
                        }`}
                      >
                        {item?.status}
                      </Tag>
                    </span>
                  </p>
                  <p className="item-style-process">
                    {dateFormat(item.created_at)}
                  </p>
                  <Row>
                    <Col span={12}>
                      <p>
                        <span className="item-number-style">Items: </span>{" "}
                        <span className="total-bold-style"> {item.quantity} </span>
                      </p>
                      <p>
                        <span className="item-number-style">Total:</span>
                        <span className="total-bold-style">
                          {" "}
                          ₹ {item.total.toFixed(2)}
                        </span>
                      </p>
                    </Col>
                    {/* <Col >
                <Button  className="btn btn-theme accept-btn-style">VIEW TO ACCEPT</Button>
              </Col> */}
                    <Col span={12} className="text-right mt-3">
                      {(item?.status === "Ready for Pickup" ||
                        item?.status === "Out for delivery" ||
                        item?.status === "Delivered" ||
                        item?.status === "Cancelled") && (
                        <Button
                          style={{ marginRight: "6px" }}
                          className="btnI accept-btn-style"
                          onClick={() =>
                            handleViewDetals(item?.suborder_id, item?.status)
                          }
                        >
                          VIEW DETAILS
                        </Button>
                      )}
                      {/* {item?.status === "Ready for Pickup" && (
                        <>
                          <Button
                            className="btn-theme accept-btn-style btnI"
                            onClick={handleConfirmationModal}
                          >
                            ORDER SHIPPED
                          </Button>
                        </>
                      )} */}
                      {item?.status === "Waiting" && (
                        <Button
                          type="primary"
                          className="btn-theme accept-btn-style btnI"
                          onClick={() => {
                            item?.status === "Waiting"
                              ? handleViewDetals(
                                  item?.suborder_id,
                                  item?.status
                                )
                              : handlerandom();
                          }}
                        >
                          VIEW TO ACCEPT
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Card>
              </div>
            </Col>
            <SellerConfirmationModal
            title={"Order Shipped"}
            subId={item?.suborder_id}
            isModalOpen={ConfirmationModalOpen}
            setIsModalOpen={setConfirmationModalOpen}
            // updateOrderStatusLoading={updateOrderStatusLoading}
            handleOk={() => handleChangeStatus("Out for delivery", item?.id)}
          />
          </Row>
          
       
      ))}
    </>
  );
}

export default SellerOrdersComponent;
