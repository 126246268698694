import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../baseQuery";

export const SellerStoreSalesAPI = createApi({
  reducerPath: "SellerStoreSalesAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({

    createOrderApi: builder.mutation({
      query: (newVendorData) => ({
        url: "api/seller/order/create_store_order/",
        method: "POST",
        body: newVendorData,
      }),
    }),
    createNewUser: builder.mutation({
      query: (data) => ({
        url: "api/seller/customer/create_customer/",
        method: "POST",
        body: data,
      }),
    }),
    getCreateOrderUsers: builder.query({
      query: (searchValue) => {
        return {
          url: `/api/seller/customer/`,
          params: searchValue,
        };
      },
    }),
    getEditOrderAddress: builder.query({
      query: (params) => {
        return {
          url: `/api/seller/customer/get_customer_address/`,
          params,
        };
      },
    }),
    SellerGetSubOrderDetails: builder.query({
      query: (id) => {
        return {
          url: `api/seller/order/${id}/get_store_order_details/`,        
        };
      },
    }), 
    SellerGetStockDetails: builder.query({
      query: (id) => {
        return {
          url: `api/seller/order/${id}/get_removed_stock_detail/`,        
        };
      },
    }),
    getEditOrderUsers: builder.query({
      query: (id) => {
        return {
          url: `api/seller/order/${id}/get_store_order_details/`,
        };
      },
    }),
    addNewAddress: builder.mutation({
      query: (addNewAddress) => ({
        url: "/api/seller/customer/create_customer/",
        method: "POST",
        body: addNewAddress,
      }),
    }),
	
	   updateAddress: builder.mutation({
      query: (updateAddress) => ({
        url: "/api/seller/customer/update_customer_address/",
        method: "PATCH",
        body: updateAddress,
      }),
    }),

   
    updateOrderApi: builder.mutation({
      query: (newVendorData) => ({
        url: "/api/seller/order/update_store_order/",
        method: "PUT",
        body: newVendorData,
      }),
    }),
    deleteAddress: builder.mutation({
      query: (addressId) => ({
        url: `/api/seller/customer/delete_customer_address/`,
        method: "DELETE",
        body: addressId,
      }),
    }),
    UpdateOrderStatus: builder.mutation({
      query: (updateData) => ({
        url: "/api/seller/order/update_store_order_details/",
        method: "PATCH",
        body: updateData,
      }),
    }),
    StockupdateApi: builder.mutation({
      query: (updateData) => ({
        url: "/api/seller/order/remove_stock/",
        method: "POST",
        body: updateData,
      }),
    }),
    CreateCreditCustomerApi: builder.mutation({
      query: (updateData) => ({
        url: "/api/seller/customer/create_mobcredit_customer/",
        method: "POST",
        body: updateData,
      }),
    }),
  }),
});

export const {
  useLazyGetCreateOrderUsersQuery,
  useCreateOrderApiMutation,
  useUpdateOrderApiMutation,
  useLazyGetEditOrderUsersQuery,
  useCreateNewUserMutation,
  useLazyGetEditOrderAddressQuery,
  useLazySellerGetSubOrderDetailsQuery,
  useAddNewAddressMutation,
useUpdateAddressMutation,
useDeleteAddressMutation,
useUpdateOrderStatusMutation,
useStockupdateApiMutation,
useLazySellerGetStockDetailsQuery,
useCreateCreditCustomerApiMutation
} = SellerStoreSalesAPI;

export default SellerStoreSalesAPI;
