import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Menu,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import {
  // useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import "./StoreSales.css";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import SellerCustomerProfile from "./SellerCustomerProfile/SellerCustomerProfile";
import SellerStockCustomerProfile from "./SellerCustomerProfile/SellerStockCustomerProfile";
import { ReactComponent as UploadImage } from "../../../assets/icons/uploadImage.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/DeleteIconTable.svg";
import { ReactComponent as CreditButtonIcon } from "../../../assets/seller/icons/creditlogo_button.svg";
import TableHeader from "./TableHeader/TableHeader";
import TableFooter from "./TableFooter/TableFooter";
import EmptyTable from "./EmptyTable";
import {
  addressFlagSelector,
  billingAddressSelector,
  bmpPriceSelector,
  // catalogueSelector,
  deliveryAddressSelector,
  editQuoteDataSelector,
  grandPriceSelector,
  vendorListSelector,
} from "../../../redux/slices/createQuote/selector";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import UnitNameCell from "./TableComponents/UnitNameCell/UnitNameCell";
import GstCell from "./TableComponents/GstCell/GstCell";
import {
  priceAfterTax,
  totalPrice,
} from "../../../commonFuntions/CommonUtilFunctions";
import AddImages from "../../../components/Modal/Table/AddImages";
import Checkout from "./Checkout";
import { baseUrl } from "../../../commonUtils/commonUtils";
import { debounce } from "lodash";
import {
  useDeleteQuoteItemMutation,
  useDeleteQuoteTableMutation,
  useLazyGetSellerCatalogueDrawerQuery,
  useLazyGetCatalogueByVendorModalQuery,
  useLazyGetSellerCatalogueQuery,
} from "../../../apis/createQuote";
import {
  getCreateOrderBillingAddress,
  getCreateOrderDeliveryAddress,
  getCreateOrderDrawerProductData,
  getCreateOrderModalProductData,
  getCreateOrderProductList,
  getCreateOrderUserData,
} from "../../../redux/slices/createOrder/action";
import {
  createOrderProductListSelector,
  usersDataSelector,
} from "../../../redux/slices/createOrder/selector";
import {
  getAllVendors,
  getBillingAddress,
  getBillingAddressList,
  getBmpPrice,
  getDeliveryAddress,
  getDeliveryAddressList,
  getGrandPrice,
  getRFQQuoteData,
  updateAddressFlag,
  getDeliveryFee,
} from "../../../redux/slices/createQuote/action";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import {
  useCreateOrderApiMutation,
  useLazyGetEditOrderUsersQuery,
  useUpdateOrderApiMutation,
  useLazyGetEditOrderAddressQuery,
  useStockupdateApiMutation
} from "../../../apis/Seller/SellerStoreSalesAPI";

import ConfirmOrderModal from "./ConfirmOrderModal/ConfirmOrderModal";
import UpdateOrderModal from "./UpdateOrderModal/UpdateOrderModal";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
// eslint-disable-next-line
import { QuoteLoading } from "../../Animations/QuoteLoading/QuoteLoading";
// eslint-disable-next-line
import CanceledQuoteTable from "../../CanceledQuote/CanceledQuoteTable";
// eslint-disable-next-line
import dayjs from "dayjs";
import StockCheckout from "./StockCheckout";
import StockUnitNameCell from "./TableComponents/UnitNameCell/StockUnitNameCell";
import StockGstCell from "./TableComponents/GstCell/StockGstCell";
import StockNotes from "./TableComponents/GstCell/StockNotes";
import ConfirmStockModal from "./ConfirmOrderModal/ConfirmStockModal";
import UpdateStockModal from "./UpdateOrderModal/UpdateStockModal";
import CancelCreateOrderModal from "./CancelModal/CancelCreateOrderModal";


const data = [
  {
    key: 1,
    input: "",
    unit_name: "",
    imageURL: "",
    hsn_sac: "",
    before_tax: "",
    gst: 0,
    after_tax: "",
    amount_in: "amount",
    amount: "",
    bmp_price: "",
    discount: 0,
    quantity: 0,
    total: 0,
    mob_margin: { amount_in: "amount", amount: 0 },
  },
];
const StoreSales = () => {
  const { order_id } = useParams();
  const bmpId = localStorage.getItem("bmp_id");
  const store_order_count= localStorage.getItem("store_order_count");
  const out_of_stock=  localStorage.getItem("out_of_stock");
  // const bmpName = localStorage.getItem('bmp_name');
  const scroll = { x: "100%" };
  const [dueAmount, setdueAmount] = useState(0);
  const editCondition = order_id ? true : false;

  const [paymentmodeItems, setpaymentmodeItems] = useState([
    { value: "", dropdownValue: "", disabled: false },
  ]);
  const [selectedSaleType, setSelectedSaleType] = useState("Store sale");
  
  const hasSavedRef = useRef(false);
  const [paymentReceived, setpaymentReceived] = useState(0);
  const [totalAmount, settotalAmount] = useState(0);
  const [mobCharges, setmobCharges] = useState(0);
  const [mobChargesGST, setmobChargesGST] = useState(0);
  const [mobCreditAmount, setmobCreditAmount] = useState(0);
  const [balanceAmount, setbalanceAmount] = useState(0);
  const [mobCreditRecieveAmt, setmobCreditRecieveAmt] = useState(0);
  const [addDeliveryNotes, setAddDeliveryNotes] = useState("");
  const [datePikerEmpty, setDatePickerEmpty] = useState(new Date());
  const [isCreditOrder, setisCreditOrder] = useState(false);
  const [oldTotalAmount, setOldTotalAmount] = useState(false);
  const [orderButtonText, setOrderButtonText] = useState(
    editCondition ? "UPDATE PAID ORDER" : "CONVERT TO PAID ORDER"
  );
  const [addCustName, setaddCustName] = useState("");
  const [addCustMobileNo, setaddCustMobileNo] = useState("");
  const [addCustEmail, setaddCustEmail] = useState("");
  const [addCustGSTIN, setaddCustGSTIN] = useState("");
  const { Text } = Typography;
  const dispatch = useDispatch();
  // const location = useLocation();
  const navigate = useNavigate();
  const sellerList = useSelector(vendorListSelector);
  const productList = useSelector(createOrderProductListSelector);
  const userData = useSelector(usersDataSelector);
  // console.log("🚀 ~ userData:", userData)
  const bmpPrice = useSelector(bmpPriceSelector);
  const grandPrice = useSelector(grandPriceSelector);
  const editQuoteData = useSelector(editQuoteDataSelector);
  const delivery_address = useSelector(deliveryAddressSelector);
  const addressFlag = useSelector(addressFlagSelector);
 

  const billing_address = useSelector(billingAddressSelector);
  const [dataSource, setDataSource] = useState([data]);
  const [count, setCount] = useState([data.length]);
  const [selectedSeller, setSelectedSeller] = useState([]);
  const [addDeliveryCharge, setAddDeliveryCharge] = useState("");
  // const [selectedRows, setSelectedRows] = useState([]);
  const [addImageModal, setAddImageModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentIndexTable, setCurrentIndexTable] = useState(null);
  // const [isFixedBarVisible, setIsFixedBarVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [modalStockOpen, setmodalStockOpen] = useState(false);
  const [warningStockModal, setwarningStockModal] = useState(false);
  const [paginationWidth, setPaginationWidth] = useState(0);
  const [cancelModal, setCancelModal] = useState(false);
  // const [getEditOrderAddressAPI, { data: addressListData }] =
  // useLazyGetEditOrderAddressQuery();
  const [getEditOrderAddressAPI, { data: addressListData }] =
    useLazyGetEditOrderAddressQuery();
  const [trigger, { data: createOrderCatalogueList }] =
  useLazyGetSellerCatalogueQuery();
  const [getcatalogueListDrawerApi, { data: catalogueListDrawerData }] =
  useLazyGetSellerCatalogueDrawerQuery();
  const [
    getProductCatalogueDataApi,
    {
      data: productCatalogueData,
      isSuccess: isSuccessProductCatalogueData,
      isFetching: isFetchingProductCatalogueData,
    },
  ] = useLazyGetCatalogueByVendorModalQuery();
  const [
    getEditOrderUserApi,
    { data: editOrderData, isLoading: isLoadingEditData },
  ] = useLazyGetEditOrderUsersQuery();
  const [createOrderAPI, { isLoading: isCreateLoading }] =
    useCreateOrderApiMutation();
  const [updateOrderAPI, { isLoading: isUpdateLoading }] =
    useUpdateOrderApiMutation();
    // eslint-disable-next-line
    const [StockupdateAPI, { isLoading: isStockUpdateLoading }] =
    useStockupdateApiMutation();
  const [deletTableRowApi] = useDeleteQuoteItemMutation();
  const [deletTableApi] = useDeleteQuoteTableMutation();

  const handleConfirmOrderModal = () => {
    setModalOpen(true);
  };
  const handleCancelModal = () => {
    setCancelModal(false);
  };
  const handleCancelOpenModal = () => {
    setCancelModal(true);
  };
  const handleWarnigModal = () => {
    setWarningModal(true);
  };
  const handleConfirmStockModal = () => {   
    setmodalStockOpen(true);   
  };
  
  const handleStockWarningModal = () => {
    setwarningStockModal(true);
  };
  useEffect(() => {
    const tempmobCreditAmount =
      paymentmodeItems.find((item) => item.dropdownValue === "mobCredit")
        ?.value || 0;
    setmobCreditAmount(tempmobCreditAmount);
    if (selectedSaleType === "Stock") {
      setOrderButtonText("REMOVE FROM STOCK");
       setaddCustName("");
       setaddCustMobileNo("");
       setaddCustEmail("");
      setaddCustGSTIN("");    
    }
    else
    {
    if (paymentmodeItems.some((item) => item.dropdownValue === "mobCredit")) {
      if (editCondition) {
        //setOrderButtonText("UPDATE MOBCREDIT ORDER");
        setOrderButtonText(
          <span className="btn-mob-credit">
            UPDATE <CreditButtonIcon className="ml-8 mr-8" /> ORDER{" "}
          </span>
        );
      } else {
        setOrderButtonText(
          <span className="btn-mob-credit">
            CONVERT TO <CreditButtonIcon className="ml-8 mr-8" /> ORDER{" "}
          </span>
        );
        // setOrderButtonText("CONVERT TO MOBCREDIT ORDER");
      }
      setisCreditOrder(true);
    }  else {
      setisCreditOrder(false);
      if (dueAmount > 0) {
        if (editCondition) {
          setOrderButtonText("UPDATE PARTLY PAID ORDER");
        } else {
          setOrderButtonText("CONVERT TO PARTLY PAID ORDER");
        }
      } else {
        if (editCondition) {
          setOrderButtonText("UPDATE PAID ORDER");
        } else {
          setOrderButtonText("CONVERT TO PAID ORDER");
        }
      }
    }
  }
  }, [paymentmodeItems, dueAmount, editCondition, selectedSaleType]);
  const handleResize = (_, newWidth) => {
    setPaginationWidth(newWidth);
  };

  useEffect(() => {
    if (editQuoteData?.payments) {
      const paymentModesitems = editQuoteData?.payments.map((item, index) => ({
        value: Number(item.amount).toFixed(2),
        dropdownValue: item.payment_mode,
        disabled: true,
      }));

      setpaymentmodeItems(paymentModesitems);
    }
  }, [editQuoteData?.payments]);
  const handleEditDataSource = (editData) => {
    // console.log("🚀 ~ handleEditDataSource ~ editData:", editData)

    if (!editData?.id) return {};
    const grandPrice = { terms_and_condition: editData?.terms_and_conditions };
    const grandPriceNote = { customer_notes: editData?.customer_notes };
    const delivery_fee = { delivery_fee: editData?.delivery_fee };
    const OrderTotal = { OrderTotal: editData?.total };
    const tempCustMobileNo = {
      customer_mobile: editData?.cutomer?.phone_number,
    };
    const tempCustName = { customer_name: editData?.cutomer?.name };
    //setAddDeliveryNotes(editData?.customer_notes);

    const venderList = [{ bmpId: bmpId }];
    const bmpData = [];
    const tableData = venderList?.map((row1, index) => {
      // bmpData.push({
      //   additional_bmp_discount: item[1].additional_bmp_discount,
      //   additional_bmp_discount_in: item[1].additional_bmp_discount_in,
      //   delivery_fee: item[1].delivery_fee,
      //   quote_delivery_dates: item[1]?.quote_delivery_dates,
      //   quote_delivery_slot: item[1]?.quote_delivery_slot,
      //   quote_delivery_notes: item[1]?.quote_delivery_notes,
      //   quote_vendor: item[1]?.quote_vendor,
      //   is_cancelled: item[1]?.is_cancelled,
      //   index: index,
      // });

      const table = editData?.items?.map((row, i) => {
        const obj = {
          before_tax: row?.price_before_tax,
          after_tax: row?.price_after_tax,
          gst: row.gst,
          index: row.index,
          id: row.id,
          imageURL: row?.product?.product_image,
          key: i + 1,
          input: row?.product?.product_name,
          unit_name: row.unit,
          hsn_sac: row?.product?.hsn_code || "",
          amount_in: row.total,
          amount: row.total,
          bmp_price: 0,
          discount: row?.discount,
          quantity: row.quantity,
          total: row.total,
          mob_sku: row.product.mob_sku,
          quote_vendor: 0,
        };
        return obj;
      });
      return table;
    });
    return {
      venderList,
      tableData,
      bmpData,
      grandPrice,
      grandPriceNote,
      delivery_fee,
      OrderTotal,
      tempCustMobileNo,
      tempCustName,
    };
  };

  const initialValue =
    editCondition && editOrderData?.status
      ? handleEditDataSource(editQuoteData)
      : "";
  const dropDownList = productList?.map((element, index) => {
    return { ...element, label: element.product_name, key: index };
  });

  const handleSetSelector = (data) => {  
    dispatch(getCreateOrderUserData(data));
  };
  useEffect(() => {
    if (editCondition && order_id) {
      getEditOrderUserApi(order_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_id, editCondition]);

  useEffect(() => {
    if (editCondition && order_id && addressFlag) {
    //  getEditOrderUserApi(order_id);
      dispatch(updateAddressFlag(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressFlag]);

  useEffect(() => {
    if (editOrderData && editOrderData?.status) {
      // console.log("🚀 ~ useEffect ~ editOrderData:", editOrderData)
      if (
        editOrderData?.data?.cutomer?.phone_number !== "" &&
        editOrderData?.data?.cutomer?.phone_number !== undefined
      ) {
        getEditOrderAddressAPI({
          phone_number: editOrderData?.data?.cutomer?.phone_number,
        });
      }
      dispatch(getRFQQuoteData(editOrderData?.data));
      dispatch(getBillingAddress(editOrderData?.data?.billing_address));
      dispatch(getDeliveryAddress(editOrderData?.data?.delivery_address));
      dispatch(getDeliveryAddressList([editOrderData?.data?.delivery_address]));
      dispatch(getBillingAddressList([editOrderData?.data?.billing_address]));
      dispatch(getCreateOrderUserData(editOrderData?.data?.cutomer));
    }

   
  }, [editOrderData,getEditOrderAddressAPI,dispatch]);
  // useEffect(() => {
  //   if (addressListData) {
  //     console.log("🚀 ~ useEffect ~ addressListData:", addressListData)
  //     dispatch(
  //       getDeliveryAddressList([
  //         addressListData?.data,
  //       ])
  //     );
  //     dispatch(
  //       getBillingAddressList([
  //         addressListData?.data,
  //       ])
  //     );
  //   }
  // }, [addressListData,dispatch]);

  useEffect(() => {
    if (editCondition) {
      const contKey = [];
      initialValue?.tableData?.map((element, i) => {
        contKey.push(element?.length);
        return null;
      });

      setDataSource(initialValue?.tableData || []);
      setCount(contKey);
      dispatch(getBmpPrice(initialValue?.bmpData || []));
      dispatch(getGrandPrice(initialValue?.grandPrice || {}));
      dispatch(getDeliveryFee(initialValue?.delivery_fee || {}));
      setAddDeliveryNotes(initialValue?.grandPriceNote?.customer_notes || "");
      setAddDeliveryCharge(initialValue?.delivery_fee?.delivery_fee || 0.0);
      setOldTotalAmount(initialValue?.OrderTotal?.OrderTotal || 0.0);
      setaddCustMobileNo(initialValue?.tempCustMobileNo?.customer_mobile);
      setaddCustName(initialValue?.tempCustName?.customer_name);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editQuoteData]);
  const showModal = (record, tableIndex) => {
    setAddImageModal(true);
    setCurrentRecord(record);
    setCurrentIndexTable(tableIndex);
  };
  const handleOk = () => {
    setAddImageModal(false);
  };
  const handleCancel = () => {
    setAddImageModal(false);
  };
  // const deselectAll = () => {
  //   setSelectedRows([]);
  //   setIsFixedBarVisible(false);
  // };
  const handleTrigger = (params) => {
    trigger(params);
  };
  useEffect(() => {
    if (createOrderCatalogueList) {
      dispatch(
        getCreateOrderProductList(
          createOrderCatalogueList?.data?.length
            ? createOrderCatalogueList?.data
            : []
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderCatalogueList]);
  useEffect(() => {
    if (catalogueListDrawerData) {
      dispatch(getCreateOrderDrawerProductData(catalogueListDrawerData || {}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogueListDrawerData]);
  useEffect(() => {
    if (productCatalogueData?.status || isSuccessProductCatalogueData) {
      dispatch(getCreateOrderModalProductData(productCatalogueData?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productCatalogueData,
    isSuccessProductCatalogueData,
    isFetchingProductCatalogueData,
  ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchTextDebouncedTrigger = useCallback(
    debounce(handleTrigger, 1000),
    []
  );
  const handleSaveProduct = (e, tableIndex, record) => {
    const productKey = e.key;
    const productDetail = productList[productKey];
    const total = totalPrice({
      gst: productDetail.product_tax_code.tax,
      before_tax: productDetail?.store_selling_price,
      discount: record.discount,
      quantity: record.quantity,
    });
    const after_tax = priceAfterTax({
      gst: productDetail.product_tax_code.tax,
      before_tax: productDetail?.store_selling_price,
    });
    setDataSource((prevData) => {
      return prevData.map((table, index) => {
        return tableIndex === index
          ? table.map((item) => {
              if (item.key === record.key) {
                return {
                  ...item,
                  input: productDetail.product_name,
                  mob_sku: productDetail.mob_sku,
                  hsn_sac: productDetail.hsn_code,
                  before_tax: productDetail?.store_selling_price,
                  rfq_price: productDetail?.store_selling_price,
                  vendor_selling_price: productDetail?.store_selling_price,
                  imageURL: productDetail.product_image,
                  total: total,
                  gst: productDetail.product_tax_code.tax,
                  after_tax: after_tax,
                  amount: 0,
                  bmp_price: total,
                };
              }
              return item;
            })
          : table;
      });
    });
    // dispatch(getCatalogueByVendor([]));
  };
  const handleSearchText = (e, tableIndex, row, currentSeller) => {
    const targetValue = e.target.value;
    setDataSource((prevData) => {
      return prevData.map((table, index) => {
        return tableIndex === index
          ? table.map((item) => {
              if (item.key === row.key) {
                return { ...item, input: targetValue, mob_sku: "" };
              }
              return item;
            })
          : table;
      });
    });
    if (targetValue.length >= 3) {
      searchTextDebouncedTrigger({
        id: currentSeller.bmp_id,
        searchValue: targetValue,
      });
    }
  };

  const handleDeleteTable = async (index, quote_vendor) => {
    const updatedTables = dataSource.filter((_, i) => i !== index);
    const sellerList = selectedSeller.filter((_, i) => i !== index);
    const countList = count.filter((_, i) => i !== index);
    // const bmp = bmpPrice.filter((_, i) => i !== index);
    // dispatch(getBmpPrice(bmp));
    setCount(countList);
    setSelectedSeller(sellerList);
    setDataSource(updatedTables);
    if (editCondition && quote_vendor?.quote_vendor) {
      const params = { quote_vendor: quote_vendor?.quote_vendor };
      const response = await deletTableApi(params);
      if (response?.data?.status) {
        showSuccessToast(response?.data?.message);
      } else {
        showErrorToast(response?.error?.data?.message);
      }
    }
  };
  // const handleAddRow = (tableIndex) => {
  //   const newRow = {
  //     key: count[tableIndex] + 1,
  //     input: "",
  //     unit_name: "",
  //     before_tax: "",
  //     hsn_sac: "",
  //     gst: 0,
  //     imageURL: "",
  //     after_tax: "",
  //     discount: 0,
  //     mob_margin: {amount_in: "amount", amount: 0},
  //     amount_in: "amount",
  //     amount: "",
  //     bmp_price: "",
  //     quantity: 0,
  //     total: 0,
  //   };
  //   setDataSource((table) => {
  //     const updatedData = table.map((tableRows, i) =>
  //       i === tableIndex ? [...tableRows, newRow] : tableRows
  //     );
  //     return updatedData;
  //   });
  //   setCount((prevCount) => {
  //     return prevCount.map((item, index) =>
  //       tableIndex === index ? item + 1 : item
  //     );
  //   });
  // };
 
  const handleAddRow = (tableIndex, rowData) => {
    let newRow = {};

    if (rowData) {
      newRow = {
        key: count[tableIndex] + 1,
        index: count[tableIndex] + 1,
        unit_name: rowData.unit || "",
        discount: 0,
        mob_margin: { amount_in: "amount", amount: 0 },
        amount_in: "amount",
        amount: "",
        quantity: 1,
        input: rowData.product_name || rowData.item_name_title || "",
        mob_sku: rowData.mob_sku || "",
        hsn_sac: rowData.hsn_code || "",
        before_tax: rowData.store_selling_price || rowData.vendor?.store_selling_price || "",
        rfq_price: rowData.store_selling_price || rowData.vendor?.store_selling_price || "",
        vendor_selling_price:
          rowData.store_selling_price ||
          rowData.vendor?.store_selling_price ||
          "",
        imageURL: rowData.product_image || rowData.images?.[0]?.image || "",
        gst: rowData.product_tax_code?.tax || "",
      };
    } else {
      newRow = {
        key: count[tableIndex] + 1,
        index: count[tableIndex] + 1,
        input: "",
        unit_name: "",
        before_tax: "",
        hsn_sac: "",
        gst: 0,
        imageURL: "",
        discount: 0,
        mob_margin: { amount_in: "amount", amount: 0 },
        amount_in: "amount",
        amount: "",
        quantity: 0,
        total: 0,
      };
    }

    setDataSource((table) => {
      const updatedData = table.map((tableRows, i) => {
        if (i === tableIndex) {
          const existingProductIndex = tableRows.findIndex((row) => {
            // Add a condition to skip empty rows

            return (
              row.mob_sku === newRow.mob_sku &&
              newRow.mob_sku !== "" &&
              newRow.mob_sku !== undefined
            );
          });

          if (existingProductIndex !== -1) {
            const newRowRows = [...tableRows]; // Create a copy of the array

            // Check and handle NaN case
            if (isNaN(newRowRows[existingProductIndex].quantity)) {
              newRowRows[existingProductIndex].quantity = 0;
            }

            // Update quantities
            // newRowRows[existingProductIndex].qty += 1;
            newRowRows[existingProductIndex].quantity += 1; // Assuming quantity is another property
            return newRowRows;
          } else {
            // If newRow is not found, push it to the array
            return [...tableRows, newRow];
          }
        } else {
          return tableRows; // Return the original array if it's not the target index
        }
      });

      return updatedData; // Return the updated state
    });
    hasSavedRef.current = false;
    setCount((prevCount) =>
      prevCount.map((item, index) => (tableIndex === index ? item + 1 : item))
    );
    if (rowData) {
      showSuccessToast("Product added!");
    }
  };

  const handleDeleteRow = async (key, tableIndex, record) => {
    setDataSource((prevDataSource) => {
      const updatedData = prevDataSource.map((tableRows, i) => {
        if (i === tableIndex) {
          const updatedTableRows = tableRows.filter((row) => row.key !== key);
          return updatedTableRows;
        }
        return tableRows;
      });
      return updatedData;
    });

    if (editCondition && record?.id) {
      const params = { quote_item_id: record?.id };
      const response = await deletTableRowApi(params);
      if (response?.data?.status) {
        showSuccessToast(response?.data?.message);
      } else {
        showErrorToast(response?.error?.data?.message);
      }
    }
  };
  const handleErrorMsg = (errorMsg) => {
    switch (errorMsg) {
      // case "key delivery_address inside address is missing":
      //   return "Please enter delivery address!";
      case "key billing_address inside address is missing":
        return "Please enter billing address!";
      case "vendor_product":
        return "Please select vendor!";
      case "price_before_tax":
        return "Please enter price before tax!";
      case "must_select_one_quote":
        return "Please create atleast one quote!";
      case "input":
        return "Please enter product name!";
      case "before_tax":
        return "Please enter price before tax!";
      case "quantity":
        return "Please enter product quantity!";
      default:
        return "Please enter all required information!";
    }
  };
  const handleCheckError = () => {
    let check = false,
      message = "";
    // if (!delivery_address?.id) {
    //   return {
    //     check: true,
    //     message: handleErrorMsg(
    //       "key delivery_address inside address is missing"
    //     ),
    //   };
    // }
    if (!billing_address?.id) {
      return {
        check: true,
        message: handleErrorMsg(
          "key billing_address inside address is missing"
        ),
      };
    }
    // if (dataSource[0].length === 0) {
    //   return {
    //     check: true,
    //     message: handleErrorMsg("must_select_one_quote"),
    //   };
    // }
    if (!selectedSeller[0]) {
      return {
        check: true,
        message: handleErrorMsg("vendor_product"),
      };
    }
    for (let key in dataSource[0][0]) {
      if (key === "before_tax" || key === "quantity" || key === "input") {
        let field = "";
        if (!dataSource[0][0][key]) {
          if (key === "input") {
            field = "input";
          } else if (key === "before_tax") {
            field = "before_tax";
          } else if (key === "quantity") {
            field = "quantity";
          }
          return {
            check: true,
            message: handleErrorMsg(field),
          };
        }
      }
    }
    return { check, message };
  };
  // const handleCheckboxChange = (e, record, tableIndex) => {
  //   const newSelectedRows = e.target.checked
  //     ? [...selectedRows, { ...record, tableIndex }]
  //     : selectedRows.filter(
  //         (item) => !(tableIndex === item.tableIndex && record.key === item.key)
  //       );
  //   setSelectedRows(newSelectedRows);
  //   setIsFixedBarVisible(newSelectedRows.length > 0);
  // };
  const handleMoveRow = (key, direction, tableIndex) => {
    const movedArray = dataSource.map((subArray) => [...subArray]);
    const subArray = movedArray[tableIndex];
    const index = subArray.findIndex((item) => item.key === key);
    if (index === -1) return;
    const [movedItem] = subArray.splice(index, 1);
    const newIndex = direction === "up" ? index - 1 : index + 1;
    subArray.splice(newIndex, 0, movedItem);
    movedArray[tableIndex] = subArray;
    setDataSource([...movedArray]);
  };
  const handleCancelOrder = (check) => {
    setDataSource([data]);
    dispatch(getRFQQuoteData({}));
    dispatch(getCreateOrderDeliveryAddress({}));
    dispatch(getDeliveryAddress({}));
    dispatch(getBillingAddress({}));
    dispatch(getCreateOrderBillingAddress({}));
    dispatch(getCreateOrderUserData({}));
    dispatch(getBmpPrice([]));
    dispatch(getGrandPrice({}));
    setLoader(false);
    setModalOpen(false);
    setWarningModal(false);
    // dispatch(getRFQListFlag(true));
    if (check === "updateOrder") {
      navigate(`/Seller/storeorder/OrderDetails/${order_id}`, {
        state: { id: order_id },
      });
    } else {
      // navigate(`/Seller/storeorder/OrderDetails/${order_id}`, {
      //   state: { id: order_id },
      // });
      navigate(-1);
    }
  };
  const handleOrderSuccess = (currentOrderId) => {
    setDataSource([data]);
    dispatch(getRFQQuoteData({}));
    dispatch(getCreateOrderDeliveryAddress({}));
    dispatch(getDeliveryAddress({}));
    dispatch(getBillingAddress({}));
    dispatch(getCreateOrderBillingAddress({}));
    dispatch(getCreateOrderUserData({}));
    dispatch(getBmpPrice([]));
    dispatch(getGrandPrice({}));
    setLoader(false);
    // dispatch(getRFQListFlag(true));
    if (currentOrderId !== "") {
      navigate(`/Seller/storeorder/OrderDetails/${currentOrderId}`, {
        state: { id: currentOrderId },
      });
    }
  };
  const handleStockUpdateSuccess = (currentOrderId) => {
    setDataSource([data]);
    dispatch(getRFQQuoteData({}));
    dispatch(getCreateOrderDeliveryAddress({}));
    dispatch(getDeliveryAddress({}));
    dispatch(getBillingAddress({}));
    dispatch(getCreateOrderBillingAddress({}));
    dispatch(getCreateOrderUserData({}));
    dispatch(getBmpPrice([]));
    dispatch(getGrandPrice({}));
    setLoader(false);
    // dispatch(getRFQListFlag(true));
    if (currentOrderId !== "") {
      navigate(`/Seller/storeorder/outofstock/${currentOrderId}`, {
        state: { id: currentOrderId },
      });
    }
  };
  function checkBmpIdPresence(arrayOfObjects) {
    for (let obj of arrayOfObjects) {
      if (!obj.hasOwnProperty("bmp_id")) {
        return false; // Return false if bmp_id key is not present in any object
      }
    }
    return true; // Return true if all objects have bmp_id key
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleCreateOrder = async (data) => {

   
   
    let tempDiscount_in = "";
    setLoader(true);
  
    let errorCheck = handleCheckError();
    if (errorCheck?.check) {
      showErrorToast(errorCheck?.message);
      setLoader(false);
      setModalOpen(false);
      return;
    }
    if (!checkBmpIdPresence(selectedSeller)) {
      showErrorToast("Please select vendor");
      setLoader(false);
      return;
    }
    if (grandPrice?.discount_in === 1 || grandPrice?.discount_in === "1") {
      tempDiscount_in = "percentage";
    } else {
      tempDiscount_in = "amount";
    }
    
    const params = {
      ...(!editCondition && {
        phone_number: data?.phone_number,
        email: data?.email,
      }),
      customer: userData?.id,
      ...(order_id && { order_id: order_id }),
      terms_and_conditions: grandPrice.terms_and_condition || "",
      // additional_discount:
      //   Number(grandPrice?.add_additional_discount || 0) || 0,
      customer_notes: addDeliveryNotes,
     // order_date: dayjs(new Date()).format("YYYY-MM-DD"),
      order_date: datePikerEmpty,
      delivery_fee: addDeliveryCharge || 0,
      delivery_address: delivery_address?.id || null,
      billing_address: billing_address?.id,
      //   delivery_address: 1,
      // billing_address: 1,
      sub_total: Number(grandPrice?.sub_total || 0),
      //discount: Number(grandPrice?.discount || 0),
      sgst: Number(grandPrice?.cgst || 0),
      cgst: Number(grandPrice?.cgst || 0),
      igst: Number(grandPrice?.igst || 0),
      tax: Number(grandPrice?.cgst || 0) + Number(grandPrice?.cgst || 0),
      discount: Number(grandPrice?.add_additional_discount || 0) || 0,
      additional_discount:
        Number(grandPrice?.add_additional_discount || 0) || 0,

      total: Number(grandPrice?.total || 0),
      items: {},

      payments: paymentmodeItems
        .filter((item) => item.dropdownValue !== "")
        .map((item) => {
          return {
            amount: item.value,
            payment_mode: item.dropdownValue,
          };
        }),
      mob_credit_order: paymentmodeItems.some(
        (item) => item.dropdownValue === "mobCredit"
      ),
    };

    for (let i = 0; i < selectedSeller.length; i++) {
      const tableData = [];
      dataSource[i].map((rows, index) => {
        tableData.push({
          //vendor: selectedSeller[i].bmp_id,
          product_name: rows.mob_sku || rows.input,
          product_image: rows.imageURL || "No Image",
          unit: rows.unit_name || 0,
          hsn_code: rows.hsn_sac || "",
          index: index,
          gst: Number(rows?.gst || 0),
          price_before_tax: Number(rows?.before_tax || 0),
          price_after_tax: Number(rows?.after_tax || 0),
          discount: Number(rows?.discount || 0) || 0,
          quantity: Number(rows?.quantity || 0),
          notes: rows?.notes || "",
          total: Number(rows?.total || 0),
          // mob_margin: {
          //   amount_in: rows.amount_in,
          //   amount: Number(rows?.amount || 0) || 0,
          // },
          // bmp_price: Number(rows?.bmp_price || 0) || 0,
          // ...(quote_id && {
          //   product: {
          //  vendor: selectedSeller[i].bmp_id,
          //  product_name: rows.mob_sku || rows.input,
          //  product_image: rows.imageURL || "No Image",
          //   },
          // }),
          // ...(quote_id && {
          //   quote_vendor: rows.quote_vendor,
          // }),
          ...(rows.id && {
            id: rows.id,
          }),
        });
        return null;
      });
      params.discount_in = tempDiscount_in;
      params.items = tableData;
      // console.log("tableData",tableData);
      // console.log("hasBmpId",hasBmpId);
      // params.items = {
      // ...(quote_id && { quote_vendor: bmpPrice[i]?.quote_vendor }),
      // total_bmp_price: Number(bmpPrice[i]?.total_bmp_price || 0) || 0,
      // additional_bmp_price:
      //   Number(bmpPrice[i]?.additional_bmp_price || 0) || 0,
      // delivery_fee: Number(bmpPrice[i]?.delivery_fee || 0) || 0,
      // discount: Number(bmpPrice[i]?.discountPrice || 0) || 0,
      // total_of_quote_items: Number(bmpPrice[i]?.subTotal || 0),
      // quote_delivery_notes: bmpPrice[i].quote_delivery_notes,
      // quote_delivery_dates: bmpPrice[i].quote_delivery_dates,
      // quote_delivery_slot: bmpPrice[i].quote_delivery_slot,
      // is_cancelled: bmpPrice[i].is_cancelled,
      // sgst: Number(bmpPrice[i]?.sgst || 0) || 0,
      // cgst: Number(bmpPrice[i]?.sgst || 0) || 0,
      // igst: Number(bmpPrice[i]?.igst || 0) || 0,
      // additional_bmp_discount:
      //   Number(bmpPrice[i]?.additional_bmp_discount || 0) || 0,
      // additional_bmp_discount_in: bmpPrice[i]?.additional_bmp_discount_in,
      // final_bmp_price: Number(bmpPrice[i]?.final_bmp_price || 0) || 0,
      // sub_total: Number(bmpPrice[i]?.subTotal || 0) || 0,
      // items: tableData,
      // };
    }

    if (!editCondition) {
      // if (userId && !location?.state?.data) {
      const response = await createOrderAPI(params);
      // console.log("🚀 ~ response:", response);
      if (response?.data?.message && response?.data?.status) {
        // const itemsValidation = Object.values(
        //   response?.data?.data?.quote_items
        // );
        // ------------If every row needs to be filled
        // const itemWithZeroQuantity = itemsValidation?.find((obj) => {
        //   return obj?.items.find((item) => {
        //       return item.quantity === 0
        // });

        // ------------If alteast one row needs to be filled
        // const itemWithZeroQuantity = itemsValidation?.some((obj) => {
        //   return obj?.items.some((item) => {
        //     return item?.quantity > 0 && item?.gst;
        //   });
        // });
        // if (!itemsValidation.length) {
        //   showErrorToast("Please add atleast one quote!");
        //   return;
        // }
        // if (itemsValidation.length && !itemWithZeroQuantity) {
        //   showErrorToast("Please enter all required information!");
        //   return;
        // }
        showSuccessToast(response.data.message);

        setTimeout(() => {
          handleOrderSuccess(response?.data?.data?.order_id);
        }, 5000);
      } else {
        const inputFieldsValidation = response?.error?.data
          ? Object.keys(response?.error?.data)[0]
          : "Please check quote fields!";
        const errorMsg = handleErrorMsg(
          response?.error?.data?.message || inputFieldsValidation
        );
        showErrorToast(errorMsg);
        setLoader(false);
      }
    } else {
      const response = await updateOrderAPI(params);
      // console.log("🚀 ~ response:", response);
      if (response?.data?.message && response?.data?.status) {
        // const itemsValidation = Object.values(
        //   response?.data?.data?.quote_items
        // );
        // ------------If every row needs to be filled
        // const itemWithZeroQuantity = itemsValidation?.find((obj) => {
        //   return obj?.items.find((item) => {
        //       return item.quantity === 0
        // });
        // ------------If alteast one row needs to be filled
        // const itemWithZeroQuantity = itemsValidation?.some((obj) => {
        //   return obj?.items?.some((item) => {
        //     return item?.quantity > 0;
        //   });
        // });
        // if (!itemsValidation?.length) {
        //   showErrorToast("Please add atleast one quote!");
        //   return;
        // }
        // if (itemsValidation?.length && !itemWithZeroQuantity) {
        //   showErrorToast("Please enter all required information!");
        //   return;
        // }

        showSuccessToast(response?.data?.message);
        setTimeout(() => {
          handleOrderSuccess(response?.data?.data?.order_id);
        }, 5000);
      } else {
        const inputFieldsValidation = response?.error?.data
          ? Object.keys(response?.error?.data?.data)[0]
          : "Please check quote fields!";
        const errorMsg = handleErrorMsg(
          inputFieldsValidation || response?.error?.data?.message
        );
        showErrorToast(errorMsg);
        setLoader(false);
      }
    }
  };
  const handleCreateStock = async (data) => {
    setLoader(true);
    // let errorCheck = handleCheckError();
    // if (errorCheck?.check) {
    //   showErrorToast(errorCheck?.message);
    //   setLoader(false);
    //   setModalOpen(false);
    //   return;
    // }
    if (!checkBmpIdPresence(selectedSeller)) {
      showErrorToast("Please select vendor");
      setLoader(false);
      return;
    }
    

    const params = {
      // ...(!editCondition && {
      //   phone_number: data?.phone_number,
      //   email: data?.email,
      // }),
      // customer: userData?.id,
      // ...(order_id && { order_id: order_id }), 
      notes: addDeliveryNotes||"NA",
      // order_date: dayjs(new Date()).format("YYYY-MM-DD"),     
    };
    for (let i = 0; i < selectedSeller.length; i++) {
      const tableData = [];
      dataSource[i].map((rows, index) => {
        tableData.push({
          //vendor: selectedSeller[i].bmp_id,
          // product_name: rows.mob_sku || rows.input,
          // product_image: rows.imageURL || "No Image",
          mob_sku:rows.mob_sku,
          unit: rows.unit_name || "",
          // hsn_code: rows.hsn_sac || "",
          // index: index,        
          stock: Number(rows?.quantity || 0),
          notes: rows?.notes || "NA",            
          // ...(rows.id && {
          //   id: rows.id,
          // }),
        });
        return null;
      });     
      params.products = tableData;      
    }

    if (!editCondition) {
      // if (userId && !location?.state?.data) {
      const response = await StockupdateAPI(params);
      // console.log("🚀 ~ response:", response);
      if (response?.data?.message && response?.data?.status) {        
        showSuccessToast(response.data.message);
        setTimeout(() => {
          handleStockUpdateSuccess(response?.data?.data?.out_of_stock_id);
        }, 5000);
      } else {
        const inputFieldsValidation = response?.error?.data
          ? Object.keys(response?.error?.data)[0]
          : "Please check quote fields!";
        const errorMsg = handleErrorMsg(
          response?.error?.data?.message || inputFieldsValidation
        );
        showErrorToast(errorMsg);
        setLoader(false);
      }
    } else {
      const response = await updateOrderAPI(params);
      // console.log("🚀 ~ response:", response);
      if (response?.data?.message && response?.data?.status) {
        // const itemsValidation = Object.values(
        //   response?.data?.data?.quote_items
        // );
        // ------------If every row needs to be filled
        // const itemWithZeroQuantity = itemsValidation?.find((obj) => {
        //   return obj?.items.find((item) => {
        //       return item.quantity === 0
        // });
        // ------------If alteast one row needs to be filled
        // const itemWithZeroQuantity = itemsValidation?.some((obj) => {
        //   return obj?.items?.some((item) => {
        //     return item?.quantity > 0;
        //   });
        // });
        // if (!itemsValidation?.length) {
        //   showErrorToast("Please add atleast one quote!");
        //   return;
        // }
        // if (itemsValidation?.length && !itemWithZeroQuantity) {
        //   showErrorToast("Please enter all required information!");
        //   return;
        // }

        showSuccessToast(response?.data?.message);
        setTimeout(() => {
          handleStockUpdateSuccess(response?.data?.data?.order_id);
        }, 5000);
      } else {
        const inputFieldsValidation = response?.error?.data
          ? Object.keys(response?.error?.data?.data)[0]
          : "Please check quote fields!";
        const errorMsg = handleErrorMsg(
          inputFieldsValidation || response?.error?.data?.message
        );
        showErrorToast(errorMsg);
        setLoader(false);
      }
    }
  };
  const handleConfirmOrder = (data) => {
    handleCreateOrder(data);
  };

  const handleUpdateOrder = (data) => {
    handleCreateOrder(data);
    setWarningModal(false);
  };
  const handleConfirmStock = (data) => {
    handleCreateStock(data);
  };

  const handleUpdateStock = (data) => {
    handleCreateStock(data);
    setWarningModal(false);
  };
  const columsHeader = (table, tableIndex, currentSeller, editCondition) => {
    let defaultColumns = [];
    defaultColumns = [
      {
        title: `${table.length} Items`,
        key: "icon",
        dataIndex: "input",
        width: "30%",
        // minWidth: "300px",
        // maxWidth: "400px",
        render: (params, record, index) => {
          return (
            <>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "8px",
                }}
              >
                {/* <Checkbox
                  onChange={(e) => handleCheckboxChange(e, record, tableIndex)}
                  checked={selectedRows.some(
                    (selectedRow) =>
                      tableIndex === selectedRow.tableIndex &&
                      selectedRow.key === record.key
                  )}
                /> */}
                <Avatar
                  shape="square"
                  size={60}
                  style={{ backgroundColor: "#e5e5e5", cursor: "pointer" }}
                  icon={<UploadImage />}
                  onClick={() => showModal(record, tableIndex)}
                  src={`${baseUrl}${record?.imageURL}`}
                />
                <Dropdown
                  overlay={
                    <Menu
                      style={{
                        maxHeight: "250px",
                        maxWidth: "600px",
                        // overflow: "auto",
                        overflowX: "hidden",
                      }}
                      onClick={(e) => handleSaveProduct(e, tableIndex, record)}
                    >
                      {/* {console.log("BMP", selectedSeller[tableIndex].bmp_id)}
                      {console.log("dropDownList", dropDownList)} */}
                      {dropDownList?.map((item, index) => (
                        <>
                          {item.bmp_id ===
                            selectedSeller[tableIndex].bmp_id && (
                            <Menu.Item
                              key={item.key}
                              value={item}
                              style={{ padding: "7px 12px" }}
                            >
                              {item.product_name}
                            </Menu.Item>

                            // {index !== dropDownList.length - 1 && (
                            //   <Divider style={{margin: "5px"}} />
                            // )}
                          )}
                        </>
                      ))}
                    </Menu>
                  }
                  style={{ fontWeight: 500, color: "#0a243f" }}
                  trigger={["click"]}
                >
                  <TextArea
                    className="custom-textarea"
                    rows={3}
                    placeholder="Enter item area"
                    value={params}
                    style={{
                      width: "75%",
                      border: "1px solid #fff",
                      borderRadius: "8px",
                      overflowY: "auto",
                    }}
                    onChange={(e) =>
                      handleSearchText(e, tableIndex, record, currentSeller)
                    }
                  />
                </Dropdown>
              </Col>
            </>
          );
        },
      },

      {
        // minWidth: "300px",
        // maxWidth: "400px",
        width: "22%",
        title: (
          <Col
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ fontFamily: "Inter", fontWeight: 500 }}>
              Unit name
            </Text>
            <Text style={{ fontFamily: "Inter", fontWeight: 500 }}>
              Price (Before tax)
            </Text>
          </Col>
        ),
        key: 1,
        dataIndex: "unit_name",
        height: "60px",
        render: (params, record) => {
          return (
            <>
              <UnitNameCell
                params={params}
                record={record}
                dataSource={dataSource}
                tableIndex={tableIndex}
                setDataSource={setDataSource}
              />
            </>
          );
        },
        unit_name: "unit_name",
        before_tax: "before_tax",
        after_tax: "after_tax",
        hsn_sac: "hsn_sac",
        title1: "Unit Name",
        title2: "Before Tax",
        tableIndex: tableIndex,
        total: "total",
        bmp_price: "bmp_price",
      },
      {
        width: "35%",
        flex: 4,
        title: (
          <Col
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ fontFamily: "Inter", fontWeight: 500 }}>GST%</Text>
            <Text style={{ fontFamily: "Inter", fontWeight: 500 }}>
              Price (After tax)
            </Text>
            <Text style={{ fontFamily: "Inter", fontWeight: 500 }}>
              Discount
            </Text>
            <Text style={{ fontFamily: "Inter", fontWeight: 500 }}>
              Quantity
            </Text>
          </Col>
        ),
        key: 2,
        dataIndex: "gst",
        render: (params, record) => {
          return (
            <GstCell
              params={params}
              record={record}
              dataSource={dataSource}
              tableIndex={tableIndex}
              setDataSource={setDataSource}
              editCondition={editCondition}
              hasSavedRef={hasSavedRef}
            />
          );
        },
        gst: "gst",
        after_tax: "after_tax",
        discount: "discount",
        quantity: "quantity",
        mob_margin: "mob_margin",
        amount_in: "amount_in",
        amount: "amount",
        bmp_price: "bmp_price",
        total: "total",
        title1: "Column 1 Title",
        title2: "Column 2 Title",
        tableIndex: tableIndex,
      },
      {
        flex: 1,
        width: "13%",
        // width: "150px",
        title: "Total",
        dataIndex: "total",
        align: "center",
        render: (_, record) => {
          return (
            <>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  height: "100%",
                  marginBottom: "20px",
                  gap: "10px",
                }}
              >
                <Text
                  level={5}
                  style={{
                    fontWeight: 500,
                    cursor: "not-allowed",
                  }}
                >
                  ₹ {totalPrice(record).toFixed(2)}
                </Text>
                <Popconfirm
                  key={record.key}
                  title={
                    <Text
                      style={{
                        display: "block",
                      }}
                    >
                      Are you sure ?
                    </Text>
                  }
                  placement="left"
                  trigger="click"
                  style={{ width: "150px" }}
                  onConfirm={() =>
                    handleDeleteRow(record.key, tableIndex, record)
                  }
                >
                  <Col
                    className="col-delete-icon"
                    style={{
                      height: "30px",
                      width: "30px",
                      cursor: "pointer",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DeleteIcon />
                  </Col>
                </Popconfirm>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button.Group size="small">
                  <Button
                    value="large"
                    disabled={
                      record?.key ===
                      dataSource[tableIndex][dataSource[tableIndex].length - 1]
                        ?.key
                    }
                    style={{
                      backgroundColor: "#f3f2ef",
                      borderRadius: "8px 0 0 8px",
                      height: "27px",
                    }}
                  >
                    <CaretDownOutlined
                      onClick={() =>
                        handleMoveRow(record.key, "down", tableIndex)
                      }
                    />
                  </Button>
                  <Button
                    value="small"
                    disabled={record.key === dataSource[tableIndex][0]?.key}
                    style={{
                      backgroundColor: "#f3f2ef",
                      borderRadius: "0 8px 8px 0",
                      height: "27px",
                    }}
                  >
                    <CaretUpOutlined
                      onClick={() =>
                        handleMoveRow(record.key, "up", tableIndex)
                      }
                    />
                  </Button>
                </Button.Group>
              </Col>
            </>
          );
        },
      },
    ];
    return defaultColumns;
  };
  const StockcolumsHeader = (
    table,
    tableIndex,
    currentSeller,
    editCondition
  ) => {
    let defaultColumns = [];
    defaultColumns = [
      {
        title: `${table.length} Items`,
        key: "icon",
        dataIndex: "input",
        width: "30%",
        // minWidth: "300px",
        // maxWidth: "400px",
        render: (params, record, index) => {
          return (
            <>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "8px",
                }}
              >
                {/* <Checkbox
                  onChange={(e) => handleCheckboxChange(e, record, tableIndex)}
                  checked={selectedRows.some(
                    (selectedRow) =>
                      tableIndex === selectedRow.tableIndex &&
                      selectedRow.key === record.key
                  )}
                /> */}
                <Avatar
                  shape="square"
                  size={60}
                  style={{ backgroundColor: "#e5e5e5", cursor: "pointer" }}
                  icon={<UploadImage />}
                  onClick={() => showModal(record, tableIndex)}
                  src={`${baseUrl}${record?.imageURL}`}
                />
                <Dropdown
                  overlay={
                    <Menu
                      style={{
                        maxHeight: "250px",
                        maxWidth: "600px",
                        // overflow: "auto",
                        overflowX: "hidden",
                      }}
                      onClick={(e) => handleSaveProduct(e, tableIndex, record)}
                    >
                      {/* {console.log("BMP", selectedSeller[tableIndex].bmp_id)}
                      {console.log("dropDownList", dropDownList)} */}
                      {dropDownList?.map((item, index) => (
                        <>
                          {item.bmp_id ===
                            selectedSeller[tableIndex].bmp_id && (
                            <Menu.Item
                              key={item.key}
                              value={item}
                              style={{ padding: "7px 12px" }}
                            >
                              {item.product_name}
                            </Menu.Item>

                            // {index !== dropDownList.length - 1 && (
                            //   <Divider style={{margin: "5px"}} />
                            // )}
                          )}
                        </>
                      ))}
                    </Menu>
                  }
                  style={{ fontWeight: 500, color: "#0a243f" }}
                  trigger={["click"]}
                >
                  <TextArea
                    className="custom-textarea"
                    rows={3}
                    placeholder="Enter item area"
                    value={params}
                    style={{
                      width: "75%",
                      border: "1px solid #fff",
                      borderRadius: "8px",
                      overflowY: "auto",
                    }}
                    onChange={(e) =>
                      handleSearchText(e, tableIndex, record, currentSeller)
                    }
                  />
                </Dropdown>
              </Col>
            </>
          );
        },
      },

      {
        // minWidth: "300px",
        // maxWidth: "400px",
        width: "20%",
        title: (
          <Col
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ fontFamily: "Inter", fontWeight: 500 }}>
              Unit name
            </Text>
          </Col>
        ),
        key: 1,
        dataIndex: "unit_name",
        height: "60px",
        render: (params, record) => {
          return (
            <>
              <StockUnitNameCell
                params={params}
                record={record}
                dataSource={dataSource}
                tableIndex={tableIndex}
                setDataSource={setDataSource}
              />
            </>
          );
        },
        unit_name: "unit_name",
        hsn_sac: "hsn_sac",
        title1: "Unit Name",
        tableIndex: tableIndex,
      },
      {
        width: "20%",
        flex: 4,
        align:"right",
        title: (
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Text className="text-right" style={{ fontFamily: "Inter", fontWeight: 500 }}>
              Quantity
            </Text>
           
          </Col>
        ),
        key: 2,
        dataIndex: "gst",
        render: (params, record) => {
          return (
            <StockGstCell
              params={params}
              record={record}
              dataSource={dataSource}
              tableIndex={tableIndex}
              setDataSource={setDataSource}
              editCondition={editCondition}
              hasSavedRef={hasSavedRef}
            />
          );
        },

        quantity: "quantity",
        total: "total",
        notes: "notes",
        tableIndex: tableIndex,
      },
      {
        flex: 1,
        width: "30%",
        // width: "150px",
        title: "Notes",
        dataIndex: "Notes",
        align: "left",
        render: (params, record) => {
          return (
            <StockNotes
              params={params}
              record={record}
              dataSource={dataSource}
              tableIndex={tableIndex}
              setDataSource={setDataSource}
              editCondition={editCondition}
              hasSavedRef={hasSavedRef}
            />
          );
        },

        quantity: "quantity",
        total: "total",
        notes: "notes",
        tableIndex: tableIndex,
      },
      {
        flex: 1,
        width: "10%",
        // width: "150px",
        title: "",
        dataIndex: "",
        align: "center",
        render: (_, record) => {
          return (
            <>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  height: "100%",
                  marginBottom: "20px",
                  gap: "10px",
                }}
              >
                <Popconfirm
                  key={record.key}
                  title={
                    <Text
                      style={{
                        display: "block",
                      }}
                    >
                      Are you sure ?
                    </Text>
                  }
                  placement="left"
                  trigger="click"
                  style={{ width: "150px" }}
                  onConfirm={() =>
                    handleDeleteRow(record.key, tableIndex, record)
                  }
                >
                  <Col
                    className="col-delete-icon"
                    style={{
                      height: "30px",
                      width: "30px",
                      cursor: "pointer",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DeleteIcon />
                  </Col>
                </Popconfirm>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button.Group size="small">
                  <Button
                    value="large"
                    disabled={
                      record?.key ===
                      dataSource[tableIndex][dataSource[tableIndex].length - 1]
                        ?.key
                    }
                    style={{
                      backgroundColor: "#f3f2ef",
                      borderRadius: "8px 0 0 8px",
                      height: "27px",
                    }}
                  >
                    <CaretDownOutlined
                      onClick={() =>
                        handleMoveRow(record.key, "down", tableIndex)
                      }
                    />
                  </Button>
                  <Button
                    value="small"
                    disabled={record.key === dataSource[tableIndex][0]?.key}
                    style={{
                      backgroundColor: "#f3f2ef",
                      borderRadius: "0 8px 8px 0",
                      height: "27px",
                    }}
                  >
                    <CaretUpOutlined
                      onClick={() =>
                        handleMoveRow(record.key, "up", tableIndex)
                      }
                    />
                  </Button>
                </Button.Group>
              </Col>
            </>
          );
        },
      },
    ];
    return defaultColumns;
  };
  return (
    <>
      {selectedSaleType && selectedSaleType === "Stock" ? (
        <>
        <Col>
        <Text className="txt-outofstock">  Out of stock no: {out_of_stock}</Text>
        </Col>
          <Col>
            <SellerStockCustomerProfile
              handleSetSelector={handleSetSelector}
              userData={userData}
              editQuoteData={editQuoteData}
              cancelOrder={handleCancelOrder}
              editCondition={editCondition}
              handleCreateOrder={
                editCondition ? handleStockWarningModal : handleConfirmStockModal
              }
              loading={
                editCondition
                  ? isUpdateLoading || loader
                  : isCreateLoading || loader
              }
              orderButtonText={orderButtonText}
              addCustName={addCustName}
              addCustMobileNo={addCustMobileNo}
              addCustEmail={addCustEmail}
              addCustGSTIN={addCustGSTIN}
              setaddCustName={setaddCustName}
              setaddCustMobileNo={setaddCustMobileNo}
              setaddCustEmail={setaddCustEmail}
              setaddCustGSTIN={setaddCustGSTIN}
              TempaddressListData={addressListData}
              //handleCreateOrder={handleCreateOrder}
              isCreditOrder={isCreditOrder}
              selectedSaleType={selectedSaleType}
              setSelectedSaleType={setSelectedSaleType}
              datePikerEmpty={datePikerEmpty}
              setDatePickerEmpty={setDatePickerEmpty}
            />
            {isLoadingEditData ? (
              <>
                <TableSkeleton />
              </>
            ) : (
              <>
                {dataSource?.map((table, tableIndex) => {
                  const tableColumns = StockcolumsHeader(
                    table,
                    tableIndex,
                    selectedSeller[tableIndex],
                    editCondition
                  );

                  return (
                    <>
                      <Table
                        key={`${tableIndex}`}
                        className={
                          "rfq-table order-list-table table-fixed table no-wrap "
                        }
                        style={{
                          borderRadius: "8px",
                          boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.16)",
                          border: "solid 1px #eaeaea",
                          marginBottom: "48px",
                          marginTop: "48px",
                        }}
                        scroll={{
                          ...scroll,
                          x: paginationWidth || "100%",
                        }}
                        columns={
                          selectedSeller[tableIndex] || tableIndex !== 0
                            ? tableColumns
                            : []
                        }
                        dataSource={
                          selectedSeller[tableIndex] || tableIndex !== 0
                            ? table
                            : []
                        }
                        title={() => (
                          <TableHeader
                            handleAddRow={handleAddRow}
                            tableIndex={tableIndex}
                            handleDeleteTable={handleDeleteTable}
                            selectedSeller={selectedSeller}
                            setSelectedSeller={setSelectedSeller}
                            sellerList={sellerList}
                            getAllVendors={getAllVendors}
                            initialValue={initialValue.venderList}
                            editCondition={editCondition}
                            key={tableIndex}
                            bmpPrice={bmpPrice}
                            getBmpPrice={getBmpPrice}
                            subOrderID={true}
                            getProductApi={getcatalogueListDrawerApi}
                            getProductModalApi={getProductCatalogueDataApi}
                            QuoteCreationFlag={1}
                          />
                        )}
                        // footer={() => (
                        //   <TableFooter
                        //     handleAddRow={handleAddRow}
                        //     tableIndex={tableIndex}
                        //     selectedSeller={selectedSeller}
                        //     dataSource={dataSource}
                        //     key={tableIndex}
                        //     editCondition={editCondition}
                        //     bmpPrice={bmpPrice}
                        //     getBmpPrice={getBmpPrice}
                        //     billingAddressSelector={billing_address}
                        //   />
                        // )}
                        locale={{
                          emptyText: selectedSeller[tableIndex]?.vendor_name ? (
                            ""
                          ) : (
                            <EmptyTable
                              mainText={"Please add items to the Order."}
                            />
                          ),
                        }}
                        pagination={false}
                      />
                    </>
                  );
                })}
              </>
            )}
            <AddImages
              addImageModal={addImageModal}
              setAddImageModal={setAddImageModal}
              showModal={showModal}
              handleOk={handleOk}
              handleCancel={handleCancel}
              record={currentRecord}
              currentIndexTable={currentIndexTable}
              dataSource={dataSource}
              setDataSource={setDataSource}
            />

            <StockCheckout
              selectedSeller={selectedSeller}
              dataSource={dataSource}
              editCondition={editCondition}
              buttonText={orderButtonText}
              type={"ConfirmOrder"}
              handleCreateQuote={
                editCondition ? handleStockWarningModal : handleConfirmStockModal
              }
              // handleCreateQuote={handleCreateOrder}
              cancelQuote={handleCancelOrder}
              // cancelOrder={handleCancelOrder}
              grandPrice={grandPrice}
              bmpPrice={bmpPrice}
              editQuoteData={editQuoteData}
              getGrandPrice={getGrandPrice}
              disableCondition={
                editCondition ? !editQuoteData?.id : !userData?.id
              }
              loading={
                editCondition
                  ? isUpdateLoading || loader
                  : isCreateLoading || loader
              }
              billingAddressSelector={billing_address}
              data={editCondition ? editQuoteData?.user : userData}
              handleAddRow={handleAddRow}
              tableIndex={0}
              paymentmodeItems={paymentmodeItems}
              setpaymentmodeItems={setpaymentmodeItems}
              paymentReceived={paymentReceived}
              setpaymentReceived={setpaymentReceived}
              addDeliveryNotes={addDeliveryNotes}
              setAddDeliveryNotes={setAddDeliveryNotes}
              isCreditOrder={isCreditOrder}
              dueAmount={dueAmount}
              setdueAmount={setdueAmount}
              addDeliveryCharge={addDeliveryCharge}
              setAddDeliveryCharge={setAddDeliveryCharge}
              settotalAmount={settotalAmount}
              setmobCharges={setmobCharges}
              setmobChargesGST={setmobChargesGST}
              setbalanceAmount={setbalanceAmount}
              mobCreditAmount={mobCreditAmount}
              setmobCreditRecieveAmt={setmobCreditRecieveAmt}
            />
           <ConfirmStockModal
            isModalOpen={modalStockOpen}
            setIsModalOpen={setmodalStockOpen}
            handleConfirmOrder={handleConfirmStock}
            editQuoteData={editCondition ? editQuoteData : userData}            
            loading={editCondition ? isUpdateLoading : isCreateLoading}
            orderButtonText={orderButtonText}
            isCreditOrder={isCreditOrder}
            totalAmount={totalAmount}
            mobCharges={mobCharges}
            balanceAmount={balanceAmount}
            paymentReceived={paymentReceived}
            dueAmount={dueAmount}
            mobCreditAmount={mobCreditAmount}
            mobChargesGST={mobChargesGST}
            mobCreditRecieveAmt={mobCreditRecieveAmt}
          />
          <UpdateStockModal
            isModalOpen={warningStockModal}
            setIsModalOpen={setwarningStockModal}
            handleSaveApi={handleUpdateStock}
            loading={editCondition ? isUpdateLoading : isCreateLoading}
            orderButtonText={orderButtonText}
            isCreditOrder={isCreditOrder}
            totalAmount={totalAmount}
            mobCharges={mobCharges}
            balanceAmount={balanceAmount}
            paymentReceived={paymentReceived}
            dueAmount={dueAmount}
            oldTotalAmount={oldTotalAmount}
            mobCreditAmount={mobCreditAmount}
            mobChargesGST={mobChargesGST}
          />
          </Col>
        </>
      ) : (
        <>
        {!editCondition&&(
        <Col>
        <Text className="txt-outofstock">  Proforma invoice no: {store_order_count}</Text> <br/>
        <Text className="txt-outofstock-sm">(Can be treated as RFQ)</Text>
        </Col>
        )}
        <Col>
          <SellerCustomerProfile
            handleSetSelector={handleSetSelector}
            userData={userData}
            editQuoteData={editQuoteData}
            cancelOrder={ editCondition ? handleCancelOpenModal : handleCancelOrder }
            editCondition={editCondition}
            handleCreateOrder={
              editCondition ? handleWarnigModal : handleConfirmOrderModal
            }
            loading={
              editCondition
                ? isUpdateLoading || loader
                : isCreateLoading || loader
            }
            orderButtonText={orderButtonText}
            addCustName={addCustName}
            addCustMobileNo={addCustMobileNo}
            addCustEmail={addCustEmail}
            addCustGSTIN={addCustGSTIN}
            setaddCustName={setaddCustName}
            setaddCustMobileNo={setaddCustMobileNo}
            setaddCustEmail={setaddCustEmail}
            setaddCustGSTIN={setaddCustGSTIN}
            TempaddressListData={addressListData}
            //handleCreateOrder={handleCreateOrder}
            isCreditOrder={isCreditOrder}
            selectedSaleType={selectedSaleType}
            setSelectedSaleType={setSelectedSaleType}
            datePikerEmpty={datePikerEmpty}
            setDatePickerEmpty={setDatePickerEmpty}
          />
          {isLoadingEditData ? (
            <>
              <TableSkeleton />
            </>
          ) : (
            <>
              {dataSource?.map((table, tableIndex) => {
                const tableColumns = columsHeader(
                  table,
                  tableIndex,
                  selectedSeller[tableIndex],
                  editCondition
                );

                return (
                  <>
                    <Table
                      key={`${tableIndex}`}
                      className={
                        "rfq-table order-list-table table-fixed table no-wrap "
                      }
                      style={{
                        borderRadius: "8px",
                        boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.16)",
                        border: "solid 1px #eaeaea",
                        marginBottom: "48px",
                        marginTop: "48px",
                      }}
                      scroll={{
                        ...scroll,
                        x: paginationWidth || "100%",
                      }}
                      columns={
                        selectedSeller[tableIndex] || tableIndex !== 0
                          ? tableColumns
                          : []
                      }
                      dataSource={
                        selectedSeller[tableIndex] || tableIndex !== 0
                          ? table
                          : []
                      }
                      title={() => (
                        <TableHeader
                          handleAddRow={handleAddRow}
                          tableIndex={tableIndex}
                          handleDeleteTable={handleDeleteTable}
                          selectedSeller={selectedSeller}
                          setSelectedSeller={setSelectedSeller}
                          sellerList={sellerList}
                          getAllVendors={getAllVendors}
                          initialValue={initialValue.venderList}
                          editCondition={editCondition}
                          key={tableIndex}
                          bmpPrice={bmpPrice}
                          getBmpPrice={getBmpPrice}
                          subOrderID={true}
                          getProductApi={getcatalogueListDrawerApi}
                          getProductModalApi={getProductCatalogueDataApi}
                          QuoteCreationFlag={1}
                        />
                      )}
                      footer={() => (
                        <TableFooter
                          handleAddRow={handleAddRow}
                          tableIndex={tableIndex}
                          selectedSeller={selectedSeller}
                          dataSource={dataSource}
                          key={tableIndex}
                          editCondition={editCondition}
                          bmpPrice={bmpPrice}
                          getBmpPrice={getBmpPrice}
                          billingAddressSelector={billing_address}
                        />
                      )}
                      locale={{
                        emptyText: selectedSeller[tableIndex]?.vendor_name ? (
                          ""
                        ) : (
                          <EmptyTable
                            mainText={"Please add items to the Order."}
                          />
                        ),
                      }}
                      pagination={false}
                    />
                  </>
                );
              })}
            </>
          )}
          <AddImages
            addImageModal={addImageModal}
            setAddImageModal={setAddImageModal}
            showModal={showModal}
            handleOk={handleOk}
            handleCancel={handleCancel}
            record={currentRecord}
            currentIndexTable={currentIndexTable}
            dataSource={dataSource}
            setDataSource={setDataSource}
          />

          <Checkout
            selectedSeller={selectedSeller}
            dataSource={dataSource}
            editCondition={editCondition}
            buttonText={orderButtonText}
            type={"ConfirmOrder"}
            handleCreateQuote={
              editCondition ? handleWarnigModal : handleConfirmOrderModal
            }
            // handleCreateQuote={handleCreateOrder}
            // cancelQuote={handleCancelOrder}
            cancelQuote={ editCondition ? handleCancelOpenModal : handleCancelOrder }
            // cancelOrder={handleCancelOrder}
            grandPrice={grandPrice}
            bmpPrice={bmpPrice}
            editQuoteData={editQuoteData}
            getGrandPrice={getGrandPrice}
            disableCondition={
              editCondition ? !editQuoteData?.id : !userData?.id
            }
            loading={
              editCondition
                ? isUpdateLoading || loader
                : isCreateLoading || loader
            }
            billingAddressSelector={billing_address}
            data={editCondition ? editQuoteData?.user : userData}
            handleAddRow={handleAddRow}
            tableIndex={0}
            paymentmodeItems={paymentmodeItems}
            setpaymentmodeItems={setpaymentmodeItems}
            paymentReceived={paymentReceived}
            setpaymentReceived={setpaymentReceived}
            addDeliveryNotes={addDeliveryNotes}
            setAddDeliveryNotes={setAddDeliveryNotes}
            isCreditOrder={isCreditOrder}
            dueAmount={dueAmount}
            setdueAmount={setdueAmount}
            addDeliveryCharge={addDeliveryCharge}
            setAddDeliveryCharge={setAddDeliveryCharge}
            settotalAmount={settotalAmount}
            setmobCharges={setmobCharges}
            setmobChargesGST={setmobChargesGST}
            setbalanceAmount={setbalanceAmount}
            mobCreditAmount={mobCreditAmount}
            setmobCreditRecieveAmt={setmobCreditRecieveAmt}
            totalAmount={totalAmount}
          />
          <ConfirmOrderModal
            isModalOpen={modalOpen}
            setIsModalOpen={setModalOpen}
            handleConfirmOrder={handleConfirmOrder}
            editQuoteData={editCondition ? editQuoteData : userData}
            phone_number={
              editCondition
                ? editQuoteData?.cutomer?.phone_number
                : userData?.phone_number || addCustMobileNo
            }
            email={
              editCondition
                ? editQuoteData?.user?.email
                : userData?.email || addCustEmail
            }
            loading={editCondition ? isUpdateLoading : isCreateLoading}
            orderButtonText={orderButtonText}
            isCreditOrder={isCreditOrder}
            totalAmount={totalAmount}
            mobCharges={mobCharges}
            balanceAmount={balanceAmount}
            paymentReceived={paymentReceived}
            dueAmount={dueAmount}
            mobCreditAmount={mobCreditAmount}
            mobChargesGST={mobChargesGST}
            mobCreditRecieveAmt={mobCreditRecieveAmt}
          />
          <UpdateOrderModal
            isModalOpen={warningModal}
            setIsModalOpen={setWarningModal}
            handleSaveApi={handleUpdateOrder}
            loading={editCondition ? isUpdateLoading : isCreateLoading}
            orderButtonText={orderButtonText}
            isCreditOrder={isCreditOrder}
            totalAmount={totalAmount}
            mobCharges={mobCharges}
            balanceAmount={balanceAmount}
            paymentReceived={paymentReceived}
            dueAmount={dueAmount}
            oldTotalAmount={oldTotalAmount}
            mobCreditAmount={mobCreditAmount}
            mobChargesGST={mobChargesGST}
          />
<QuoteLoading
        open={
          editCondition ? isUpdateLoading || loader : isCreateLoading || loader
        }
      />
            
        </Col>
        <CancelCreateOrderModal
        open={cancelModal}
        handleOk={handleCancelModal}
        handleSave={() => handleCancelOrder("updateOrder")}        
      />
        </>
      )}
    </>
  );
};

export default StoreSales;
