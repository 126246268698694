import {
  // Button,  
  Table, 
} from "antd";
import { React, useState, useEffect } from "react";
import "./SellerDeliveryTable.css";


const SellerStockTable = ({
  columns,
  data,
  deliveryStatus,
  editSubOrderData, 

}) => {
 
  const scroll = "100%";
  
  const [paginationWidth, setPaginationWidth] = useState(0);

  const handleResize = (_, newWidth) => {
    setPaginationWidth(newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Table
        className={`deliveryBy-table custom-tablehead order-list-table  ${
          deliveryStatus === "Rejected by seller" ? "rejected-table" : ""
        }`}
        columns={columns}
        pagination={false}
        scroll={{
          ...scroll,
          x: paginationWidth || "100%",
        }}
        footer={() => (
          <>
            
          </>
        )}
       
        dataSource={data?.map((itemData) => ({
          ...itemData,        
        }))}
      />
    </>
  );
};

export default SellerStockTable;
