import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col, Input, Upload, message, Modal,Divider  } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Typography } from "antd";
import { ReactComponent as EyeIcon } from "../../../assets/seller/icons/visible.svg";
import "./SellerAccounts";
import TextArea from "antd/es/input/TextArea";

const Text = Typography;
const SellerInvoiceCustomization = (prop) => {
  const {
    handleEditQRCode,
    QRForm,
    setQRFileList,
    SellerData,
    setUPIPhoneNo,
    setUPIID,
    UPIId,
    UPIPhoneNo,    
    bankDetails, setBankDetails,
    handleSavebankdetails,
    handleSaveTermsdetails,
    Notesandterms,
    setNotesandterms
  } = prop;
 

  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isTermsEditing, setIsTermsEditing] = useState(false);
  const [isUPIIdEditable, setIsUPIIdEditable] = useState(false);
  const [isUPIMobileEditable, setIsUPIMobileEditable] = useState(false);
  const handleEditUPIIdClick = () => {
    setIsUPIIdEditable(true);
  };

  const handleSaveUPIIdClick = () => {
    // Call API or perform any necessary actions to save the changes
    setIsUPIIdEditable(false);
    if (UPIId) {
      handleEditQRCode();
    }
  };
  const handleEditUPIMobileClick = () => {
    setIsUPIMobileEditable(true);
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handlePreviewClick = () => {
    setIsModalVisible(true);
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  const handleSaveUPIMobileClick = () => {
    // Call API or perform any necessary actions to save the changes
    setIsUPIMobileEditable(false);
    if (UPIPhoneNo) {
      handleEditQRCode();
    }
  };

  const [fileChanged, setFileChanged] = useState(false);
  const handleFileChange = async (info) => {
    if (info?.file?.status !== "error") {
      let fileList = info.file;
      // fileList = fileList.slice(-1);
      // fileList = fileList.filter((file) => {
      if (fileList.type.includes("image")) {
        setFileChanged(true);

        //setFileList(fileList);
        const file = info?.fileList[0];
        let url = file?.originFileObj;

        setQRFileList(url);
        // handleQRFieldChange(inputValues);
        if (fileChanged) {
          QRForm.submit();
          setFileChanged(false);
        }
        return true;
      } else {
        message.error(`${fileList.name} is not a valid image file.`);
        return false;
      }
    }
    //});
  };
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleSaveClick = () => {
    setIsEditing(false);
    if (bankDetails) {
      handleSavebankdetails();
    }

  };
  const handleTermsEditClick = () => {
    setIsTermsEditing(true);
  };
  const handleTermsSaveClick = () => {
    setIsTermsEditing(false);
    if (Notesandterms) {
      handleSaveTermsdetails();
    }

  };
  const handleInputChange = (event, field) => {
    setBankDetails({ ...bankDetails, [field]: event.target.value });
  };
  const handleTermsInputChange = (event, field) => {
    setNotesandterms(event.target.value);
  };
  // useEffect(() => {
  //   const file = info?.fileList[0];
  //   let url = file?.originFileObj;
  //   const inputValues = {
  //     [name]: url,
  //   };

  //   handleQRFieldChange(inputValues);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [info, fileList]);
  useEffect(()=>{
    setBankDetails({
      name_of_account_holder: SellerData?.bank_details?.name_of_account_holder,
      bank_account_type: SellerData?.bank_details?.bank_account_type,
      bank_account_number: SellerData?.bank_details?.bank_account_number,
      bank_ifsc: SellerData?.bank_details?.bank_ifsc,
      bank_name: SellerData?.bank_details?.bank_name,
      bank_branch: SellerData?.bank_details?.bank_branch,
    })
    setUPIID(SellerData?.upi_id);   
    setUPIPhoneNo(SellerData?.upi_phone_number);
    setNotesandterms(SellerData?.notes_and_terms)
  }, [SellerData,setBankDetails,setUPIID,setUPIPhoneNo,setNotesandterms]);
  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );
  const beforeUpload = (file) => {
    // You can validate the file type and size here
    return true;
  };

  return (
    <>
          
    {/* <Card title="Logo">
      
    </Card> */}
    <Card className="mt-4" title="Notes & terms" 
    extra={
      isTermsEditing ? (
        <>
        <Button className="save-link btn-cancel-link" onClick={() => setIsTermsEditing(false)}>       
          Cancel
        </Button>
        <Button className="save-link btn-preview" onClick={handleTermsSaveClick}>
          Save
        </Button>
        </>
      ) : (
        <Text className="edit-link " onClick={handleTermsEditClick}>
          Edit
        </Text>
      )
    }>
     <Col className="terms-notes-strip">
     Please insert the terms and conditions relating to your store, it’s deliveries, return policy et cetera. You may choose to add any note you like here for your clients/ customers. It will appear in the invoice.
     </Col>
     <Col className="mt-4">
     <Col span={24}>             
              <TextArea
                  className="custom-input"
                  value={Notesandterms}
                  rows={10}
                  cols={30}
                  disabled={!isTermsEditing}
                  onChange={(event) =>
                    handleTermsInputChange(event)
                  }
                />
            </Col>
     </Col>
    </Card>
   
      <Card
        className="mt-4"
        title="Bank details"
        extra={
          isEditing ? (
            <>
             <Button className="save-link btn-cancel-link" onClick={() => setIsEditing(false)}>       
          Cancel
        </Button>
            <Button className="save-link btn-preview" onClick={handleSaveClick}>
              Save
            </Button>
            </>
          ) : (
            <Text className="edit-link " onClick={handleEditClick}>
              Edit
            </Text>
          )
        }
      >
        {/* <Col class="">
                <Text className="text-head-light">Company name</Text>
                <Text className="text-head">
                  {" "}
                  {SellerData?.person_full_name || "NA"}{" "}
                </Text>
              </Col> */}

        {!isEditing ? (
          <>
            <Col span={12}>
              <Text className="text-head-light">
                Name of the account holder
              </Text>
              <Text className="text-head">
                {SellerData?.bank_details?.name_of_account_holder || "NA"}
              </Text>
            </Col>
            <Divider />
            <Col span={12}>
              <Text className="text-head-light">Bank account type</Text>
              <Text className="text-head">
                {SellerData?.bank_details?.bank_account_type || "NA"}
              </Text>
            </Col>
            <Divider />
            <Col span={12}>
              <Text className="text-head-light">Bank account number</Text>
              <Text className="text-head">
                {SellerData?.bank_details?.bank_account_number || "NA"}
              </Text>
            </Col>
            <Divider />
            <Col span={12} >
              <Text className="text-head-light">Bank IFSC code</Text>
              <Text className="text-head">
                {SellerData?.bank_details?.bank_ifsc || "NA"}
              </Text>
            </Col>
            <Divider />
            <Col span={12}>
              <Text className="text-head-light">Bank name</Text>
              <Text className="text-head">
                {SellerData?.bank_details?.bank_name || "NA"}
              </Text>
            </Col>
            <Divider />
            <Col span={12}>
              <Text className="text-head-light">Branch</Text>
              <Text className="text-head">
                {SellerData?.bank_details?.bank_branch || "NA"}
              </Text>
            </Col>
          </>
        ) : (
          <>
          
            <Form            
              layout="vertical"
              autoComplete="off"
              form={QRForm}
              
            >
              <Form.Item label={<span>Account Holder Name</span>}>
                <Form.Item
                  label="Account Holder Name"
                  name="name_of_account_holder"
                  noStyle
                ></Form.Item>
                <Input
                  className="custom-input"
                  value={bankDetails.name_of_account_holder}
                  onChange={(event) =>
                    handleInputChange(event, "name_of_account_holder")
                  }
                />
              </Form.Item>
              <Form.Item label={<span>Bank account type</span>}>
                <Form.Item
                  label="Bank account type"
                  name="bank_account_type"
                  noStyle
                ></Form.Item>
                <select
  className="custom-input select-custom-dropdown"
  value={bankDetails.bank_account_type}
  onChange={(event) =>
    handleInputChange(event, "bank_account_type")
  }
>
  <option value="Current">Current</option>
  <option value="Savings">Savings</option>
  <option value="Others">Others</option>
</select>
              </Form.Item>

              <Form.Item label={<span>Bank account number</span>}>
                <Form.Item
                  label="Bank account number"
                  name="bank_account_number"
                  noStyle
                ></Form.Item>
                <Input
                  className="custom-input"
                  value={bankDetails.bank_account_number}
                  onChange={(event) =>
                    handleInputChange(event, "bank_account_number")
                  }
                />
              </Form.Item>

              <Form.Item label={<span>Bank IFSC code</span>}>
                <Form.Item
                  label="Bank IFSC code"
                  name="BankIFSCcode"
                  noStyle
                ></Form.Item>
                <Input
                  className="custom-input"
                  value={bankDetails.bank_ifsc}
                  onChange={(event) => handleInputChange(event, "bank_ifsc")}
                />
              </Form.Item>

              <Form.Item label={<span>Bank name</span>}>
                <Form.Item
                  label="Bank name"
                  name="Bankname"
                  noStyle
                ></Form.Item>
                <Input
                  className="custom-input"
                  value={bankDetails.bank_name}
                  onChange={(event) => handleInputChange(event, "bank_name")}
                />
              </Form.Item>

              <Form.Item label={<span>Branch</span>}>
                <Form.Item label="Branch" name="Branch" noStyle></Form.Item>
                <Input
                  className="custom-input"
                  value={bankDetails.bank_branch}
                  onChange={(event) => handleInputChange(event, "bank_branch")}
                />
              </Form.Item>
            </Form>
          </>
        )}
      </Card>
      <Card
        className="mt-4"
        title={
          <>
            <Text>Payment QR</Text>
            <Text>This will be visible in the invoice shared.</Text>
          </>
        }
        extra={
          <Button
            icon={<EyeIcon />}
            className="btn-preview"
            onClick={handlePreviewClick}
          >
            Preview
          </Button>
        }
      >
        <Form
          name="form"
          layout="vertical"
          autoComplete="off"
          form={QRForm}
          onFinish={handleEditQRCode}
        >
          <Form.Item
            label={
              <span>
                UPI ID <span style={{ color: "red" }}>*</span>
              </span>
            }
          >
            <Form.Item label="UPIID" name="UPIID" noStyle>
              <Row
                className="d-flex"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Col span={12}>
                {isUPIIdEditable ?(
                  <Input
                    className="custom-input"                   
                    onChange={(e) => {                     
                      setUPIID(e.target.value);
                    }}
                    style={{
                      height: "40px",
                    }}
                     value={UPIId}
                  />
                ):(
                  <>{SellerData?.upi_id}</>
                )}
                </Col>
                <Col span={12} className="text-right">
                  {isUPIIdEditable ? (
                    <>
                    <Col style={{display:"flex",justifyContent:"flex-end",gap:"4px"}}>
                      <Button onClick={handleSaveUPIIdClick}>Save</Button>
                      <Button onClick={() => setIsUPIIdEditable(false)}>
                        Cancel
                      </Button>
                      </Col>
                    </>
                  ) : (
                    <Text className="edit-link" onClick={handleEditUPIIdClick}>
                      Edit
                    </Text>
                  )}
                </Col>
              </Row>
            </Form.Item>
          </Form.Item>
          <Divider />
          <Form.Item label={<span>UPI phone no (optional)</span>}>
            <Form.Item
              label="UPI phone no (optional)"
              name="UPIphoneno"
              noStyle
            >
              <Row
                className="d-flex"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Col span={12}>
                {isUPIMobileEditable ?(
                  <Input
                    className="custom-input"
                    disabled={!isUPIMobileEditable}
                    style={{
                      height: "40px",
                    }}
                    onChange={(e) => {                    
                      setUPIPhoneNo(e.target.value);
                    }}
                    maxLength={"10"}
                    //value={SellerData?.upi_phone_number}
                    value={UPIPhoneNo}
                  />
                ):(
                  <>
                  {SellerData?.upi_phone_number}
                  </>
                )}
                </Col>
                <Col span={12} className="text-right">
                  {isUPIMobileEditable ? (
                    <>
                      <Col style={{display:"flex",justifyContent:"flex-end",gap:"4px"}}>
                      <Button onClick={handleSaveUPIMobileClick}>Save</Button>
                      <Button onClick={() => setIsUPIMobileEditable(false)}>
                        Cancel
                      </Button>
                      </Col>
                    </>
                  ) : (
                    <Text
                      className="edit-link"
                      onClick={handleEditUPIMobileClick}
                    >
                      Edit
                    </Text>
                  )}
                </Col>
              </Row>
            </Form.Item>
          </Form.Item>
          <Divider />
          <Form.Item>
            <Form.Item
              label="UPIID"
              name="UPIID"
              noStyle
              rules={[
                {
                  required: true,
                  message: "Please select QR code",
                },
              ]}
            >
              <Row className="mt-2">
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader upload-image-details"
                    showUploadList={false}
                    maxCount={1}
                    action={""}
                    onChange={(info) => {
                      handleFileChange(info);
                      //form.setFieldsValue({ [name]: info.file }); // update form values
                    }}
                    beforeUpload={beforeUpload}
                  >
                    {SellerData?.qr_scanner ? (
                      <img
                        src={SellerData?.qr_scanner}
                        alt="avatar"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} className="d-flex">
                  <Text className="txt-recommended">
                    We recommend size of 660x330 pixels
                    <br /> (.jpg or .png)
                  </Text>
                </Col>
              </Row>
            </Form.Item>
          </Form.Item>
        </Form>
      </Card>
      <Modal
        title="Invoice preview"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
        width={800}
      >
        {/* Modal content goes here */}
        <Col
          style={{
            display: "flex",
            height: "500px",
            flexDirection: "column",
            justifyContent: "flex-start",
            padding: "20px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Row>
            <Col span={24} className="text-center">
              <Typography.Title
                className="text-center"
                level={4}
                style={{ color: "#0354a3" }}
              >
                INVOICE
              </Typography.Title>
            </Col>
          </Row>
          <Row className="mt-4" gutter={16}>
            <Col span={12}>
              <Card
                loading={false}
                style={{ minWidth: "100%", background: "#f3f2ef" }}
              ></Card>
            </Col>
            <Col span={12}>
              <Card
                loading={false}
                style={{ minWidth: "100%", background: "#f3f2ef" }}
              ></Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Typography.Title level={5}>Items</Typography.Title>
            <Card
              loading={false}
              style={{ minWidth: "100%", background: "#f3f2ef" }}
            ></Card>
          </Row>
          <Row className="mt-4">
            <Typography.Title level={5}>Terms & Conditions</Typography.Title>
            <Card
              className="card-terms-static"
              loading={true}
              style={{ minWidth: "100%" }}
            ></Card>
          </Row>
          <Row className="mt-4">
            <Col span={15}></Col>
            <Col className="text-center" span={9}>
              <Text className="text-danger">
                Payment QR will be visible here
              </Text>
            </Col>
          </Row>
          <Row className="mt-2" gutter={16}>
            <Col span={15}>
              <Card className="card-bankdetails">
                <Typography.Title level={5}>Bank Details</Typography.Title>
                <Row gutter={16}>
                  <Col span={12}>
                    <Text className="text-caption-title">
                      Company name: <br />{" "}
                      <span className="text-caption-details">
                        {SellerData?.vendor_name}
                      </span>
                    </Text>
                    <Text className="text-caption-title mt-2">
                      Account type: <br />
                      <span className="text-caption-details">
                        {SellerData?.bank_details?.bank_account_type}
                      </span>
                    </Text>
                    <Text className="text-caption-title  mt-2">
                      Account Number: <br />
                      <span className="text-caption-details">
                        {SellerData?.bank_details?.bank_account_number}
                      </span>
                    </Text>
                  </Col>
                  <Col span={12}>
                    <Text className="text-caption-title">
                      IFSC Code:
                      <br />
                      <span className="text-caption-details">
                        {SellerData?.bank_details?.bank_ifsc}
                      </span>
                    </Text>
                    <Text className="text-caption-title  mt-2">
                      Bank Name:
                      <br />
                      <span className="text-caption-details">
                        {SellerData?.bank_details?.bank_name}
                      </span>
                    </Text>
                    <Text className="text-caption-title  mt-2">
                      Branch:
                      <br />
                      <span className="text-caption-details">
                        {SellerData?.bank_details?.bank_branch}
                      </span>
                    </Text>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={9}>
              <Card className="card-QR-code">
                <Text className="text-caption-title">
                  UPI ID:
                  <span className="text-caption-details">
                    {SellerData?.upi_id}
                  </span>
                </Text>
                <Text className="text-caption-title">
                  UPI Phone no:{" "}
                  <span className="text-caption-details">
                    {SellerData?.upi_phone_number}
                  </span>
                </Text>
                {SellerData?.qr_scanner ? (
                  <img
                    src={SellerData?.qr_scanner}
                    alt="QR"
                    style={{ width: "70%" }}
                  />
                ) : (
                  <></>
                )}
              </Card>
            </Col>
          </Row>
        </Col>
      </Modal>
    </>
  );
};

export default SellerInvoiceCustomization;
