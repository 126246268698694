import React from "react";


export default function SellerPreRegistration() {
 
    const URL = "https://form.jotform.com/Vivek_Kumar_founders/mob-partner---pre-registration-form"
    
    
  return <iframe title="your title" style={{width:"100%",height:"750px"}}  src={URL}></iframe>;
}
