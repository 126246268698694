import React, { useEffect, useState } from "react";
import "./SellerCustomerProfile.css";
import {
  Button,
  //  Card,
  Col,
  Row,
  Skeleton,
  Typography,
  Space,
  Input,Select
} from "antd";
// import CustomSelect from "../../../../components/SelectableDropdown/CustomSelect/CustomSelect";
// eslint-disable-next-line
import { DownOutlined } from "@ant-design/icons";
// eslint-disable-next-line
import { ReactComponent as ClockIcon } from "../../../../assets/icons/clock.svg";

import CustomDatePicker from "../../../ManageOrders/OrderDetails/OrderModals/AddPaymentModal/DatePicker/CustomDatePicker";
// import { ReactComponent as SearchIcon } from "../../../../assets/icons/search-3.svg";
// import { ReactComponent as FileIcon } from "../../../../assets/icons/file.svg";

import {
  // eslint-disable-next-line
  ActivityContainer,
  // eslint-disable-next-line
  ActivityText,
  QuoteHeader,

  // RFQDate,
  // RFQDetailContainer,
  // RFQFileContainer,
  // RFQFileText,
  // RFQHeading,
  // RFQID,
  // RFQMainContainer,
  // RFQTag,
  // RowContainer,
} from "../../../Request/RFQ/CreateQuote/CreateQuoteProfileSection/ProfileStyled";

import { StoreSalestype } from "../../../../commonUtils/commonUtils";

import {
  addressFlagSelector,
  billingAddressSelector,
} from "../../../../redux/slices/createQuote/selector";
import { useDispatch, useSelector } from "react-redux";
// import CreateOrderDrawer from "../../../../components/Drawer/CreateOrderDrawer/CreateOrderDrawer";
import SearchSuggestionInput from "../../../../components/Input/SellerInput/SearchSuggestionInput/SearchSuggestionInput";
import {  
  useLazyGetCreateOrderUsersQuery,
  useLazyGetEditOrderAddressQuery,
} from "../../../../apis/Seller/SellerStoreSalesAPI";
import {
  getBillingAddress,
  getDeliveryAddress,
  getRFQUserById,
  updateAddressFlag,
} from "../../../../redux/slices/createQuote/action";
// eslint-disable-next-line
import dayjs from "dayjs";
const SellerStockCustomerProfile = (props) => {
  // const navigate = useNavigate();
  const {
    editCondition,
    editData,
    quoteData,
    handleSetSelector,
    userData,
    editQuoteData,
    cancelOrder,
    handleCreateOrder,
    loading,
    orderButtonText,    
    addCustMobileNo,
    addCustEmail,
    addCustGSTIN,
    setaddCustName,
    setaddCustMobileNo,
    setaddCustEmail,
    setaddCustGSTIN,
    isCreditOrder,
    selectedSaleType,
    setSelectedSaleType,
    setDatePickerEmpty
  } = props;

   
  const dispatch = useDispatch();

  const addressFlag = useSelector(addressFlagSelector);
  // eslint-disable-next-line 
  const billing_address = useSelector(billingAddressSelector);  
  const { Title } = Typography;
  // const [drawerVisible, setDrawerVisible] = useState(false);
  const [getUserApi, { data: usersList }] = useLazyGetCreateOrderUsersQuery();
  const [gstError, setGstError] = useState("");

  const [getEditOrderAddressAPI, { data: addressListData }] =
    useLazyGetEditOrderAddressQuery();
// eslint-disable-next-line 
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const handleChangeDate = (value) => {
    setDatePickerEmpty(value);    
};
  // eslint-disable-next-line
  // const showDrawer = () => {
  //   setDrawerVisible(true);
  // };
  const handleShowAddCustomerModal = () => {
    setAddCustomerModal(true);
  };
  const handleRemoveSelector = () => {
    handleSetSelector({});
    dispatch(getBillingAddress({}));
    dispatch(getDeliveryAddress({}));
  };
  const handleAddressAPI = (item) => {   
    getEditOrderAddressAPI({ phone_number: item?.phone_number });
    dispatch(getRFQUserById(item));
  };
  const handleSaleTypeChange = (value) => {   
    setSelectedSaleType(value);
 
  };

  useEffect(() => {
   
  
    if (userData?.phone_number && addressFlag) {
      getEditOrderAddressAPI({ phone_number: userData?.phone_number });
      dispatch(updateAddressFlag(false)); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressFlag,addCustMobileNo]);
  // useEffect(() => {
   
  //   if(editCondition && addCustMobileNo)
  //   {
  //     console.log("🚀 ~ useEffect ~ editCondition:", editCondition)
  //     console.log("addCustMobileNo123",addCustMobileNo)
  //     getEditOrderAddressAPI({ phone_number: addCustMobileNo});
  //     dispatch(updateAddressFlag(false));
  //  }
 
 
  //    // eslint-disable-next-line react-hooks/exhaustive-deps
  //  }, [addCustMobileNo]);

  useEffect(() => {
    if (
      userData?.mobCredit?.account_status === "ACTIVE" &&
      addressListData?.data?.length
    ) {
      let mob_credit_address_data = addressListData?.data?.filter(
        (item) => item?.mob_credit
      );
      dispatch(
        getBillingAddress(
          mob_credit_address_data?.length ? mob_credit_address_data[0] : {}
        )
      );
    } else {
      dispatch(getBillingAddress({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressListData, userData]);

  const handleInputChange = (field, value) => {
    switch (field) {
      case "gst":
        if (value !== "") {
          const gstinRegex =
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
          if (!gstinRegex.test(value)) {
            setGstError("Invalid GSTIN format");
          } else {
            setGstError("");
            setaddCustGSTIN(value);
          }
        } else {
          setGstError("");
        }
        break;
      case "PO_Number":
        setaddCustName(value);
        // handle PO Number input change
        break;
      case "PhoneNumber":
        setaddCustMobileNo(value);
        // handle Phone Number input change
        break;
      case "Email":
        setaddCustEmail(value);
        // handle Email input change
        break;
      case "Name":
        setaddCustName(value);
        // handle Email input change
        break;
      default:
        break;
    }
  };

  // const handleCreateNewUser = () => {};
  return (
    <>
      <Row style={{ gap: "40px", marginBottom: "48px" }}>
        <QuoteHeader>
          <Title level={3} style={{ margin: 0, color: "#0a243f" }}>
            {editCondition ? "Out of stock" : ""}
          </Title>          
        </QuoteHeader>
      </Row>
      <Row className="mb-4">
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          {editData?.isLoading || quoteData?.isLoading ? (
            <Col className="skeleton-input">
              <Skeleton.Input active size={"small"} block={true} />
            </Col>
          ) : (
            <>
              <Space direction="vertical">
                <Typography.Title level={5}>Date</Typography.Title>
                <Col className="date-picker-container">
                  {/* <Input
                    name="Order date"
                    className="input-style"
                    readOnly={true}
                    value={dayjs(new Date()).format("DD MMM YYYY")}
                  ></Input> */}
                  <CustomDatePicker
                      name="Orderdate"
                      className=""
                      value={dayjs(new Date()).format("DD MMM YYYY")}
                      handleChildFieldChange={handleChangeDate}
                      maxDate={dayjs(new Date()).format("DD MMM YYYY")}
                      // datePikerEmpty={datePikerEmpty}
                    />
                </Col>
              </Space>
            </>
          )}
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12} className="col-right">
          <Button
            className="create-order-cancel-button mr-2"
            onClick={() => cancelOrder(editCondition ? "updateOrder" : "")}
          >
            CANCEL
          </Button>
          <Button
            className="create-order-confirm-button"
            onClick={handleCreateOrder}
            style={{
              padding: "13px 60px 13px 60px",
              fontFamily: "Inter",
              fontWeight: "500",
              backgroundColor: isCreditOrder ? "#535dd8" : "#015fe5",              
              color: "#fff",
              height: "48px",
              borderRadius: "8px",
             
            }}
            // disabled={
            //   editCondition || loading
            //     ? !editQuoteData?.id || loading
            //     : !userData?.id || loading
            // }
            loading={loading}
          >
            {orderButtonText}
          </Button>
        </Col>
      </Row>
      <Row style={{ gap: "91px", marginBottom: "48px" }}>
        <Col className="input-actions-container">
          <Col style={{ width: "100%" }}>
            {editData?.isLoading || quoteData?.isLoading ? (
              <Col className="skeleton-input">
                <Skeleton.Input active size={"small"} block={true} />
              </Col>
            ) : (
              <>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Typography.Title level={5}>
                      Name 
                    </Typography.Title>
                    <SearchSuggestionInput
                      size={"large"}
                      placeholder={"Type here..."}
                      getLibraryApi={getUserApi}
                      list={usersList?.data?.results}
                      // editValue={editQuoteData?.user?.full_name}
                      editValue={editQuoteData?.billing_address?.name}                     
                      handleSetSelector={handleSetSelector}
                      showModal={handleShowAddCustomerModal}
                      editCondition={editCondition}
                      handleRemoveSelector={handleRemoveSelector}
                      addressApi={handleAddressAPI}
                      handleInputChange={handleInputChange}
                      setaddCustName={setaddCustName}
                      setaddCustMobileNo={setaddCustMobileNo}
                      setaddCustEmail={setaddCustEmail}
                      setaddCustGSTIN={setaddCustGSTIN}
                      disabled={true}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Typography.Title level={5}>Sale type </Typography.Title>
                    {/* <CustomSelect
                      placeholder={"Sale type"}
                      options={StoreSalestype}
                      width={"100%"}
                      // value={selectedSaleType}
                      disabled={editCondition}
                      customStyle={"ddl-style"}
                      onChange={handleSaleTypeChange}
                    /> */}
                    <Select
  className={"ddl-style"}
  value={selectedSaleType} // uncommented and passed the selectedSaleType value
  placeholder={"Sale type"}
  style={{
    width: "100%",
    height: "46px",
  }}
  onChange={handleSaleTypeChange}
  options={StoreSalestype}
  disabled={editCondition}
  //loading={loading}
/>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Typography.Title level={5}>
                      Purchase order ref no (optional)
                    </Typography.Title>
                    <Input
                      className="input-style"
                      name="PO_Number"
                      disabled={true}
                      placeholder=""
                      onChange={(e) =>
                        handleInputChange("PO_Number", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]} className="mt-4">
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Typography.Title level={5}>
                      Phone number 
                    </Typography.Title>
                    <Input
                      name="PhoneNumber"                     
                      maxLength={10}
                      disabled={true}
                      value={
                        editCondition
                          ? editQuoteData?.cutomer?.phone_number
                          : userData?.phone_number || addCustMobileNo
                      }
                      className="input-style"
                      placeholder=""
                      onKeyDown={(e) => {
                        if (
                          (e.key < "0" || e.key > "9") &&
                          e.key !== "Backspace" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight" &&
                          e.key !== "Delete" &&
                          e.key !== "Tab" &&
                          !(e.ctrlKey && (e.key === "c" || e.key === "v")) // allow Ctrl+C and Ctrl+V
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        handleInputChange("PhoneNumber", e.target.value)
                      }
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Typography.Title level={5}>Email</Typography.Title>
                    <Input
                      name="Email"
                      disabled={true}
                      value={
                        editCondition
                          ? editQuoteData?.cutomer?.email
                          : userData?.email || addCustEmail
                      }
                      className="input-style"
                      placeholder=""
                      onChange={(e) =>
                        handleInputChange("Email", e.target.value)
                      }
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Typography.Title level={5}>
                      GSTIN (optional)
                    </Typography.Title>
                    <Input
                      name="GSTIN"
                      disabled={true}
                      value={
                        editCondition
                          ? editQuoteData?.cutomer?.gst_number
                          : userData?.GST || addCustGSTIN
                      }
                      maxLength={15}
                      className="input-style"
                      placeholder=""
                      onChange={(e) => handleInputChange("gst", e.target.value)}
                    />
                    {gstError && <p style={{ color: "red" }}>{gstError}</p>}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Col>
      </Row>
      
      

     
      
    </>
  );
};

export default SellerStockCustomerProfile;
