import React from "react";
import { ReactComponent as TickIcon } from "../../../assets/seller/icons/shieldtick.svg";
import { ReactComponent as CommisionIcon } from "../../../assets/seller/icons/mobcommision.svg";
import { ReactComponent as CreditIcon } from "../../../assets/seller/icons/mobCreditAmount.svg";
import { ReactComponent as RepaymentIcon } from "../../../assets/seller/icons/repayment.svg";
import { ReactComponent as LampLogo } from "../../../assets/seller/icons/lamp.svg";
import MaximiseLogo from "../../../assets/seller/icons/maximisesales.webp";
import { Col, Typography, Row, Card,Table } from "antd";
import "./SellerMobCreditOnboarding.css";
// import { ReactComponent as MobCreditIcon } from "../../../../assets/seller/icons/credit.svg";
// import { ReactComponent as CalenderIcon } from "../../../../assets/seller/icons/calender.svg";
const { Text } = Typography;

const CommercialsTerms = (props) => {
  //   const {

  //   } = props;
  // eslint-disable-next-line
  const dataSource = [
    {
      key: '1',
      SlNo: '1',
      Particulars: 'Product',
      Terms: '<span class="highlight-bold">BNPL:</span> 21 + 69 days* Buy Now Pay Later (BNPL) Program (*69 Days Credit Period starts from the Fulfillment date, i.e., the delivery date of the goods by the Marketplace/Seller to the Borrower)',
    },
    {
      key: '2',
      SlNo: '2',
      Particulars: 'Eligible mob Client',
      Terms: 'Proprietorships, Partnerships and Private Limited Entities Min Age of Borrowing Business Owner – 25 years, Max Age – 60 years* (Subject to credit policy changes) (*For applicants above 60 years, a co-applicant is required)',
    },
    {
      key: '3',
      SlNo: '3',
      Particulars: 'Credit Limits',
      Terms: 'Min. 20,000, Max. 50 Lakhs',
    },
    {
      key: '4',
      SlNo: '4',
      Particulars: 'Credit Cycle/Tenor',
      Terms: '21 + 69  Days (Bill to Bill)',
    },
    {
      key: '5',
      SlNo: '5',
      Particulars: 'Security',
      Terms: 'Unsecured',
    },
    {
      key: '6',
      SlNo: '6',
      Particulars: 'Margin / Collateral',
      Terms: 'Nil',
    },
    {
      key: '7',
      SlNo: '7',
      Particulars: 'Security',
      Terms: 'Unsecured',
    },
    {
      key: '8',
      SlNo: '8',
      Particulars: 'Default Interest/Late Fee',
      Terms: 'In case of a default by the Borrower, the Lender shall charge default interest or late fee @ 0.1% per day after the credit period',
    },
    {
      key: '9',
      SlNo: '9',
      Particulars: 'Review of credit limits for Client',
      Terms: 'Periodic Assessment every month for credit limit increase or on a per need basis (post 2-3 months of performance)',
    },
    {
      key: '10',
      SlNo: '10',
      Particulars: 'Repayment option',
      Terms: 'Only bank or UPI transfers are applicable for repayment DIRECTLY to <span class="highlight-bold">Rupifi account</span>. Credit Card/Debit Card based repayment options will NOT be allowed.',
    },
  ];
  
  const columns = [
    {
      title: 'Sl. No.',
      dataIndex: 'SlNo',
      align:"left",
      key: 'SlNo',
      width:'10%',
      className:'sno-column'
      
    },
    {
      title: 'Particulars',
      dataIndex: 'Particulars',
       key: 'Particulars',
       width:'40%',
       className: 'particulars-column'
    },
    {
      title: 'Terms',
      dataIndex: 'Terms',
      key: 'Terms',
       width:'50%',
       render: text => (
        <div
          dangerouslySetInnerHTML={{ __html: text }}
          className="terms-column"
        />
      )
       
    },
  ];
  return (
    <>
      <Row className="mt-4">
        <Col span={24} className="risk-strip d-flex">
          <TickIcon />{" "}
          <span className="text-heading-sm">
            {" "}
            You carry zero risk on client’s default
          </span>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Card bordered={false} className="noborder-card">
                <Row>
                  <Col className="d-flex" style={{ gap: "16px" }}>
                    <Col>
                      <CommisionIcon />{" "}
                    </Col>
                    <Col>
                      <Text className="text-comission"> 1.75% </Text>
                      <br />
                      <Text className="text-comission-sub">mob commission</Text>
                    </Col>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="d-flex" style={{ gap: "16px" }}>
                    <Col>
                      <CreditIcon />{" "}
                    </Col>
                    <Col>
                      <Text className="text-comission">₹ 20000 to 50L</Text>
                      <br />{" "}
                      <Text className="text-comission-sub">
                        Line of credit amount
                      </Text>{" "}
                    </Col>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="d-flex" style={{ gap: "16px" }}>
                    {" "}
                    <Col>
                      <RepaymentIcon />
                    </Col>
                    <Col>
                      <Text className="text-comission">90days</Text>
                      <br />{" "}
                      <Text className="text-comission-sub">Repayment</Text>
                    </Col>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Card className="card-tips-section" bordered={false}>
                <Text className="text-heading-sm">
                  {" "}
                  <LampLogo /> Maximise your sales
                </Text>{" "}
                <br />
                <Text className="text-comission-sub">
                  We have designed this technology so that you can access line
                  of credit of architects contractors & builders to sell to them
                  who are registered on mobCREDIT platform irrespective of
                  whether you onboarded them or not.
                </Text>
                <Col>
                  {" "}
                  <img alt="" className="money-hand" src={MaximiseLogo}></img>
                </Col>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16,16]} className="mt-4">
        <Text className="text-heading-sm">
          How acceptance and rejection happen and how limit is allocated
        </Text>
        <Col className="mt-2" xs={24} sm={24} md={24} lg={24} xl={12}>
        <Row gutter={[16,16]}>
        <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
             <Card className="document-strip" bordered={false}>
              <Text >
                <span className="text-head-white"> KYC Documents </span><br/>
                <span className="text-head-white-big">Private Limited Company/ LLP</span>
                </Text>
              </Card>
              </Col>
             <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card className="card-content" bordered={false}>
                <Typography.Title level={4}> Personal KYC</Typography.Title>
                <Text className="particulars-column">All directors</Text> <br/>
                <Text className="mt-4">Aadhaar Card and PAN</Text>
              </Card>
              </Col>
              <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card className="card-content fixed-height-card">
            <Typography.Title level={4}> Business KYC</Typography.Title>
            <Text className="particulars-column">Private Limited Company/ LLP</Text>

            <ul className="static-list-content">
              <li>a. GST Registration Certificate</li>
              <li>b. Consent Letter for CRIF pull (for all Directors)</li>
              <li>c. Company PAN</li>
              <li>
                d. Uploading 6 months of primary business bank account statement
              </li>
              <li>e. Board Resolution/s (formats provided)</li>
              <li>f. MoA and AoA</li>
              <li>g. Certificate of Incorporation</li>
            </ul>

            <Col>
              <Text>
                Post limit allocation for activation (either 1 below is
                Mandatory)
              </Text>
              <br />
              <Text>- Need for e-NACH setup upon LoC approval</Text>
              <br />
              <Text>
                (NOTE: E-Nach will be triggered after 90 days, if payment is
                declined)
              </Text>
            </Col>
          </Card>
         </Col>
         </Row>
        </Col>
        <Col className="mt-2" xs={24} sm={24} md={24} lg={24} xl={12}>
        <Row gutter={[16,16]}>
        <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card className="document-strip"  bordered={false}>
               
                <Text >
                <span className="text-head-white"> KYC Documents </span><br/>
                <span className="text-head-white-big"> Proprietorship/ Partnership</span>
                </Text>
              </Card>
              </Col>
              <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card className="card-content" bordered={false}>
                <Typography.Title level={4}> Personal KYC</Typography.Title>
                <Text className="particulars-column">Owner/ All partners</Text><br/>
                <Text className="mt-4">Aadhaar Card and PAN</Text>
              </Card>
            </Col>
            <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card className="card-content fixed-height-card" style={{ flex: 1 }}>
            <Typography.Title level={4}> Business KYC</Typography.Title>
            <Text className="particulars-column">Proprietorship/ Partnership</Text>
            <ul className="static-list-content ">
              <li>a. GST Registration Certificate (Mandatory)</li>
              <li>b. Consent Letter for CRIF pull (Mandatory for all)</li>
              <li>c. Business PAN (Mandatory)</li>
              <li>
                d. Uploading 6 months of primary business bank account statement
                (Mandatory)
              </li>
            </ul>
            <Col>
              <Text>
                Post limit allocation for activation (either 1 below is
                Mandatory)
              </Text>
              <br />
              <Text>- Need for e-NACH setup upon LoC approval</Text>
              <br />
              <Text>
                (NOTE: E-Nach will be triggered after 90 days, if payment is
                declined)
              </Text>
            </Col>
          </Card>
          </Col>
          </Row>
        </Col>


        
      </Row>
     
      <Row gutter={16} type="flex" className="mt-4">
      <Typography.Title level={4}> General terms of mobCREDIT</Typography.Title>
      <Col span={24}>
      <Table className="static-table" dataSource={dataSource} columns={columns}
       pagination={false}
       rowClassName={(record, index) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
      }}
      />
      </Col>
      </Row>
    </>
  );
};

export default React.memo(CommercialsTerms);
