import React, { useEffect } from "react";
import "./SellerPayables.css";
import PayableHeader from "./SellerpayableHeader";
import PayableDetails from "./SellerPayableDetails";
import { useNavigate } from "react-router-dom";
import { useLazyGetPayableDataQuery } from "../../../apis/Seller/SellerPayablesAPI";

const SellerPayables = () => {
  const bmpId = localStorage.getItem("bmp_id");

  const navigate = useNavigate();

  const [
    getPayableData,
    { data: payableData, isFetching: isFetchingpayables },
  ] = useLazyGetPayableDataQuery();

  useEffect(() => {
    getPayableData({ bmp_id: bmpId });
    // getSettlementSearchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bmpId]);

  const handleBackNavigate = () => {
    navigate(-1);
  };
  return (
    <>
      <PayableHeader
        data={payableData?.data?.payable_data}
        handleBackNavigate={handleBackNavigate}
        isFetching={isFetchingpayables}
      />
      <PayableDetails
        data={payableData?.data}
        getSettlementByIDAPI={getPayableData}

        // handleRefetch={handleHitApi}
      />
    </>
  );
};

export default SellerPayables;
