import React, { useState,useEffect } from "react";
import { Col, Row, Typography, Select } from "antd";

import { stateOptions, cityOptions } from "../../../commonUtils/commonUtils";
import SearchDropdown from "../../../components/DropDown/SearchDropdown";
import { Button, Form, Input } from "antd";

import { useLazyGetSellerAddVendorDetailsQuery } from "../../../apis/sellerCatalogue";
import { useSellerNewRegistrationMutation } from "../../../apis/Seller/SellerRegistration.js";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";

const SellerRegistration = (props) => {
  const {
    handleAddNewSellerModalClose,reloadAPI
  }= props;
  const [sellercategory, setsellercategory] = useState([]); 

  const [emailOrPhone, setEmailOrPhone] = useState("");

  // const [ispageLoading, setispageLoading] = useState(false);
  // const [getSellerRegistration] = useSellerNewRegistrationMutation();
  const [searchCityValue, setSearchCityValue] = useState("");
  const [searchStateValue, setSearchStateValue] = useState("");

  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  useEffect(() => {
    if(localStorage.getItem("bmp_mobile") !== null)
    {
        setEmailOrPhone(localStorage.getItem("bmp_mobile"));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCitySelectChange = (value) => {
    setSearchCityValue(value);
    if (value) {
      setCityError("");
    }
  };
  const handleStateSelectChange = (value) => {
    setSearchStateValue(value);
    if (value) {
      setStateError("");
    }
  };
  const handleValidation = () => {
    if (searchCityValue === "") {
      setCityError("Please select your city");
    }
    if (searchStateValue === "") {
      setStateError("Please select your state");
    }
  };
  const [getSellerRegistration] = useSellerNewRegistrationMutation();
  const [
    getVendorCategoryAPI,
    { data: getVendorCategorydata, isLoading: isCategoryLoading },
  ] = useLazyGetSellerAddVendorDetailsQuery();

  // const handleForgetPassword = () => {
  //   setFogetPassword(true);
  // };
  // const handleForgetBack = () => {
  //   setFogetPassword(false);
  // };
  // const handleLoginPage = () => {
  //   setIsEmailValid(true);
  //   setRenderOtp(false);
  //   setOtpModal(!otpModal);
  // };
  useEffect(() => {
    if(localStorage.getItem("accessToken") !== null)
    {
    getVendorCategoryAPI();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFinalRegistration = async (values) => {
    // setispageLoading(true);
    try {
      if (!searchCityValue) {
        setCityError("Please select your city");
        return;
      } else {
        setCityError("");
      }
      if (!searchStateValue) {
        setStateError("Please select your state");
        return;
      } else {
        setStateError("");
      }
     
      if (values) {
       
        const typesOfMaterial = sellercategory.map((category) => category.key);

        const APIresponse = await getSellerRegistration({
          phone_number: values?.mobileno || emailOrPhone,
          vendor_name: values?.BusinessName,
          gst_number: values?.gstin,
          email: values?.EmailId,
          types_of_material: typesOfMaterial || [],
          address: {
            name: "",
            phone_number: values?.mobileno || emailOrPhone,
            address: values?.address1 || "",
            address_line_2: values?.area || "",
            // state: values?.state,
            // city: values?.city,
            state: searchStateValue,
            city: searchCityValue,
            pincode: values?.pincode,
            google_map_link: values?.googlelink,
          },
        });

        // console.log("🚀 ~ handleFinalRegistration ~ response:", response)
        if (APIresponse?.data?.status === true) {
          showSuccessToast(APIresponse.data.message);
          handleAddNewSellerModalClose();
          reloadAPI();

        } else if (!APIresponse?.data?.status) {
          showErrorToast(APIresponse?.error?.data?.message);
        }
      }
    } catch (error) {
      // console.log("🚀 ~ handleFinalRegistration ~ APIresponse:", error);
    } finally {
      // setispageLoading(false); // set loading state to false
    }
  };

  return (
    <>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          style={{
            // width: "50%",
            // height: "100vh",
            // display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Form
            name="fromFinalRegistration"
            className="mt-4"
            initialValues={{
              remember: false,
            }}
            layout="vertical"
            autoComplete="off"
            onFinish={(values) => {
              handleFinalRegistration(values);
            }}
          >
            <Col
              style={{
                overflowY: "scroll",
                height: "500px",
                padding: "20px 26px",
              }}
            >
              <Typography.Title level={4}>
                Store/ Business information
              </Typography.Title>
              <Form.Item
                label={
                  <span>
                    Business name <span style={{ color: "red" }}>*</span>
                  </span>
                }
              >
                <Form.Item
                  label="BusinessName"
                  name="BusinessName"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "Please input your business name!",
                    },
                  ]}
                >
                  <Row>
                    <Col span={24}>
                      <Input
                        className="custom-input"
                        style={{
                          height: "40px",
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<span>Email id (optional)</span>}
                style={{
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                <Form.Item label="Emailid" name="EmailId" noStyle>
                  <Row>
                    <Col span={24}>
                      <Input className={"custom-input"} />
                    </Col>
                  </Row>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    Business mobile (for OTP){" "}
                    <span style={{ color: "red" }}>*</span>
                  </span>
                }
              >
                <Form.Item
                  label="Business mobile (for OTP)"
                  name="mobileno"
                  noStyle
                >
                  <Row>
                    <Col span={24}>
                      <Input
                        className={"custom-input"}
                        disabled="true"
                        value={emailOrPhone}
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    GSTIN <span style={{ color: "red" }}>*</span>
                  </span>
                }
              >
                <Form.Item
                  label="GSTIN"
                  name="gstin"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "Please input your GSTIN!",
                    },
                    {
                      pattern: /^[0-9]{2}[A-Za-z]{3}[A-Za-z0-9]{10}$/,
                      message: "Invalid GSTIN format.",
                    },
                  ]}
                >
                  <Row>
                    <Col span={24}>
                      <Input maxLength={15} className={"custom-input"} />
                    </Col>
                  </Row>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    Store category{" "}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                }
              >
                <Form.Item
                  label="Store category"
                  name="storecategory"
                  noStyle
                  // rules={[
                  //   {
                  //     required: true,
                  //     message:
                  //       "Please select store category!",
                  //   },
                  // ]}
                >
                  <Row>
                    <Col span={24}>
                      <SearchDropdown
                        list={getVendorCategorydata?.data}
                        label={"Store category"}
                        placeholder={"Store category"}
                        selectedItems={sellercategory}
                        setSelectedItems={setsellercategory}
                        isCustomBorder={true}
                        isLoading={isCategoryLoading}
                        className={"custom-ddl"}
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Form.Item>
              <Typography.Title level={4}>Address</Typography.Title>
              <Form.Item
                label={<span>House no/ Building name (Address line 1)</span>}
              >
                <Form.Item label="Address line 1" name="address1" noStyle>
                  <Row>
                    <Col span={24}>
                      <Input className={"custom-input"} />
                    </Col>
                  </Row>
                </Form.Item>
              </Form.Item>
              <Form.Item label={<span>Road/ Area/ Colony</span>}>
                <Form.Item label="Road/ Area/ Colony" name="area" noStyle>
                  <Row>
                    <Col span={24}>
                      <Input className={"custom-input"} />
                    </Col>
                  </Row>
                </Form.Item>
              </Form.Item>
              <Form.Item label={<span>Pincode</span>}>
                <Form.Item label="Pincode" name="pincode" noStyle>
                  <Row>
                    <Col span={24}>
                      <Input maxLength={6} className={"custom-input"} />
                    </Col>
                  </Row>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    City <span style={{ color: "red" }}>*</span>
                  </span>
                }
              >
                <Form.Item
                  label="City"
                  name="city"
                  noStyle
                  validateTrigger="onChange"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please input your city",
                  //   },

                  // ]}
                >
                  <Row>
                    <Col span={24}>
                      {/* <Input className={"custom-input"} /> */}
                      <Select
                        onChange={handleCitySelectChange}
                        className={`custom-input ${
                          cityError ? "input-error" : ""
                        }`}
                        showSearch
                        value={searchCityValue}
                        placeholder="Select city"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {cityOptions.map((option) => (
                          <Select.Option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </Select.Option>
                        ))}
                      </Select>
                      {cityError && (
                        <span className="ant-form-item-explain-error">
                          {cityError}
                        </span>
                      )}
                    </Col>
                  </Row>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    State <span style={{ color: "red" }}>*</span>
                  </span>
                }
              >
                <Form.Item
                  label="State"
                  name="state"
                  noStyle
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select state!",
                  //   },
                  // ]}
                >
                  <Row>
                    <Col span={24}>
                      {/* <Input className={"custom-input"} /> */}
                      <Select
                        value={searchStateValue}
                        onChange={handleStateSelectChange}
                        className={`custom-input ${
                          stateError ? "input-error" : ""
                        }`}
                        showSearch
                        placeholder="Select state"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {stateOptions.map((option) => (
                          <Select.Option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </Select.Option>
                        ))}
                      </Select>
                      {stateError && (
                        <span className="ant-form-item-explain-error">
                          {stateError}
                        </span>
                      )}
                    </Col>
                  </Row>
                </Form.Item>
              </Form.Item>
              <Form.Item label={<span>Google link</span>}>
                <Form.Item label="Google link" name="googlelink" noStyle>
                  <Row>
                    <Col span={24}>
                      <Input className={"custom-input"} />
                    </Col>
                  </Row>
                </Form.Item>
              </Form.Item>
              {/* <Form.Item>
                <Form.Item
                  label="Terms"
                  name="Terms"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "Accept terms & conditions",
                    },
                  ]}
                >
                  <p>
                    <Checkbox
                   
                    >
                      By clicking agree and continue you agree to our{" "}
                      <Text className="hyperlink-text">
                        <a href="/Termsandcondition" target="_blank" rel="">
                          Terms and Conditions
                        </a>
                      </Text>{" "}
                      and{" "}
                      <Text className="hyperlink-text">
                        <a href="/privacypolicy" target="_blank" rel="">
                          Privacy Statement
                        </a>
                      </Text>
                      .
                    </Checkbox>
                  </p>
                </Form.Item>
              </Form.Item> */}
            </Col>
            <Col className="footer-class">
              <Form.Item>
                <Button
                  style={{
                    width: "100%",
                    height: "40px",
                    fontWeight: 500,
                  }}
                  type={"primary"}
                  htmlType="submit"
                  onClick={handleValidation}
                >
                  {"ADD NEW STORE"}
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default SellerRegistration;
