import React, { useEffect, useState } from "react";
// eslint-disable-next-line 
import { Col, Form, Skeleton, Tabs, Typography, Button,Select ,Table,Input} from "antd";
import "./CreateSellerProduct.css";
// import BasicDetails from "./EditChildren/BasicDetails";
// import VariationInfo from "./EditChildren/VariationInfo";
// import BasicInfo from "./EditChildren/BasicInfo";
import { useDispatch, useSelector } from "react-redux";
import { manageEditProductDataSelector } from "../../../redux/slices/manageLibrary/selector";
import ProductImageUpload from "./ProductImageUpload";
// import ComplianceAndBatteryInfo from "./EditChildren/ComplianceAndBatteryInfo";
import { useNavigate, useParams } from "react-router-dom";
import {  
  useUpdateSellerProductDetailsMutation,
  useCreateProductDetailsMutation,
  useLazyGetSellerCategoryDetailsQuery,
  useLazyGetSellerProductDetailsQuery,
  useLazyGetSellerSubCategoryDetailsQuery
} from "../../../apis/manageLibrary";
import { getEditProductData } from "../../../redux/slices/manageLibrary/action";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import ActionContainer from "./ProductComponents/ActionContainer/ActionContainer";
import EditContainerProduct from "./ProductComponents/EditChildren/EditDetails/EditContainerProduct";
// eslint-disable-next-line
import VariationOptions from "./ProductComponents/EditChildren/VariationOptions";



const CreateSellerProduct = (props) => {
  // eslint-disable-next-line 
  const { data, isFetching } = props;
  const [selectedCategoryOption, setselectedCategoryOption] = useState("");
  const editProductData = useSelector(manageEditProductDataSelector);
  // console.log("🚀 ~ CreateSellerProduct ~ editProductData:", editProductData)
  const [
    getVendorCategoryAPI,
      // eslint-disable-next-line 
    { data: getVendorCategorydata },   
    
  ] = useLazyGetSellerCategoryDetailsQuery();
  const [
    getVendorSubCategoryAPI,
    { data: getVendorSubCategorydata },  
   
    
  ] = useLazyGetSellerSubCategoryDetailsQuery();
  
  const [
    getEditProductApi,
    { data: editProductDetails, isFetching: isFetchingEditProductDetails },
  ] = useLazyGetSellerProductDetailsQuery();

  const itemDetails = [
    {
      name: "item_name_title",
      toolTip: false,
      label: "Item name",
      type: "Input",
      placeholder: "Enter Item Name",
      required: true,
      value: editProductData?.product?.item_name_title || "",
    },
    {
      name: "brand_name",
      label: "Brand/ manufacturer name",
      type: "Input",
      placeholder: "Enter Brand Name",
      toolTip: false,
      value: editProductData?.product?.brand_name || "",
      // disabled: true,
    },
    {
      name: "category",
      label: "Category",
       type: "SelectableCatgeory",
      //  type: "Input",
       multiLevel: false,
      placeholder: "Type or select",
      toolTip: false,
      required: true,
      options: getVendorSubCategorydata?.data,
      value:[editProductData?.product?.sub_category_id_2?.name ||"",editProductData?.product?.sub_category_id_2?.id ]
      // value: [
      //   editProductData?.product?.category?.id || "",
      //   editProductData?.product?.sub_category_id?.name || "",
      //   editProductData?.product?.sub_category_id_2?.name ||"",
      // ],
    },
    {
      name: "Categorylabel",
      label: "",
      type: "labelText",
      placeholder: "",
      toolTip: false,
      value:selectedCategoryOption
      
    },
    {
      name: "sub_category_id",
      label: "",
      type: "hidden",
      placeholder: "",
      toolTip: false,
      
    },
    {
      name: "sub_category_id_2",
      label: "",
      type: "hidden",
      placeholder: "",
      toolTip: false,
     
    },
    {
      name: "product_description",
      label: "Description",
      type: "TextArea",
      placeholder: "Enter Description",
      toolTip: false,
      value: editProductData?.product?.product_description || "",
    },
  ];
  // eslint-disable-next-line 
  const columns = [
    {
      title: 'Variation',
      dataIndex: 'variation',
      key: 'variation',
      render: (text, record) => (
        <Input
          value={record.variation}
          onChange={(e) => handleInputChange(e, record, 'variation')}
        />
      ),
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      render: (text, record) => (
        <Input
          value={record.sku}
          onChange={(e) => handleInputChange(e, record, 'sku')}
        />
      ),
    },
    {
      title: 'HSN',
      dataIndex: 'hsn',
      key: 'hsn',
      render: (text, record) => (
        <Input
          value={record.hsn}
          onChange={(e) => handleInputChange(e, record, 'hsn')}
        />
      ),
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      render: (text, record) => (
        <Select
          value={record.unit}
          onChange={(value) => handleSelectChange(value, record, 'unit')}
        >
          <Select.Option value="Per item">Per item</Select.Option>
          <Select.Option value="ft">ft</Select.Option>
          <Select.Option value="kg">kg</Select.Option>
        </Select>
      ),
    },
    {
      title: 'MRP',
      dataIndex: 'mrp',
      key: 'mrp',
      render: (text, record) => (
        <Input
          value={record.mrp}
          onChange={(e) => handleInputChange(e, record, 'mrp')}
        />
      ),
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      key: 'tax',
      render: (text, record) => (
        <Input
          value={record.tax}
          onChange={(e) => handleInputChange(e, record, 'tax')}
        />
      ),
    },
    {
      title: 'Selling Price',
      dataIndex: 'sellingPrice',
      key: 'sellingPrice',
      render: (text, record) => (
        <Input
          value={record.sellingPrice}
          onChange={(e) => handleInputChange(e, record, 'sellingPrice')}
        />
      ),
    },
    {
      title: 'Stock',
      dataIndex: 'stock',
      key: 'stock',
      render: (text, record) => (
        <Input
          value={record.stock}
          onChange={(e) => handleInputChange(e, record, 'stock')}
        />
      ),
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      render: (text, record) => (
        <Button
          type="danger"
          // icon={<DeleteOutlined/>}
          onClick={() => handleDelete(record)}
        />
      ),
    },
  ];
  const handleInputChange = (e, record, column) => {
    // Update the record with the new value
    record[column] = e.target.value;
  };
  const handleDelete = (record) => {
    // Remove the record from the data array
    data.splice(data.indexOf(record), 1);
  };
  const handleSelectChange = (value, record, column) => {
    // Update the record with the new value
    record[column] = value;
  };
  const variations = [
    {
      name: "sku",
      toolTip: true,
      label: "Product SKU",
      type: "Input",
      placeholder: "Enter SKU Name",
      value: editProductData?.product?.mob_sku || "",
      required: true,
      disabled: false,
    },
    {
      name: "hsn_code",
      toolTip: true,
      label: "HSN Code",
      type: "Input",
      placeholder: "Enter HSN Code",
      value: editProductData?.product?.hsn_code || "",
      required: true,
      disabled: false,
    },
    {
      name: "unit",
      toolTip: false,
      label: "Unit",
      type: "Input",
      placeholder: "Select Unit",
      value: editProductData?.product?.unit || "",
      required: true,
      disabled: false,
    },
    {
      name: "mrp",
      toolTip: true,
      label: "MRP",
      type: "Input",
      placeholder: "Enter Price",
      value: editProductData?.maximum_retail_price || "",
      required: true,
      disabled: false,
    },
    {
      name: "tax",
      label: "Product tax code",
      type: "Select",
      placeholder: "Select Tax",
      toolTip: false,
      value: editProductData?.product?.product_tax_code || "",
      options: [
        {
          key: "TAX12_STD",
          label: "12% GST",
          value: "TAX12_STD",
        },
        {
          key: "TAX18_STD",
          label: "18% GST",
          value: "TAX18_STD",
        },
        {
          key: "TAX28_STD",
          label: "28% GST",
          value: "TAX28_STD",
        },
      
      ],
    },
    {
      name: "your_selling_price",
      toolTip: true,
      label: "Your Selling Price",
      type: "Input",
      placeholder: "Enter Your Selling Price",
      value: editProductData?.vendor_selling_price || "",
      required: true,
      disabled: false,
    },
    {
      name: "stock",
      toolTip: true,
      label: "Stock",
      type: "Input",
      placeholder: "Enter Stock",
      value: editProductData?.stock || "",
      required: true,
      disabled: false,
    },
  ];
  // eslint-disable-next-line
  const pricingDetails = [
    {
      name: "mob_sku",
      toolTip: true,
      label: "MOB SKU",
      type: "Input",
      placeholder: "Enter Item Name",
      value: editProductData?.product?.mob_sku || "",
      required: true,
      disabled: true,
    },
    {
      name: "hsn_code",
      label: "HSN",
      type: "Input",
      placeholder: "Enter HSN",
      toolTip: true,
      value: editProductData?.product?.hsn_code || "",
      // disabled: true,
    },
    {
      name: "fulfillment_latency",
      label: "Fulfillment latency",
      type: "Input",
      placeholder: "Enter Fulfillment latency",
      toolTip: true,
      value: editProductData?.product?.fulfillment_latency || "",
      // disabled: true,
    },
    {
      name: "product_tax_code",
      label: "Product tax code",
      type: "Select",
      placeholder: "Select Tax",
      toolTip: false,
      value: editProductData?.product?.product_tax_code || "",
      options: [
        {
          key: "TAX5_STD",
          label: "5% GST",
          value: "TAX5_STD",
        },
        {
          key: "TAX12_STD",
          label: "12% GST",
          value: "TAX12_STD",
        },
        {
          key: "TAX18_STD",
          label: "18% GST",
          value: "TAX18_STD",
        },
        {
          key: "TAX28_STD",
          label: "28% GST",
          value: "TAX28_STD",
        },
        {
          key: "TAX32_STD",
          label: "32% GST",
          value: "TAX32_STD",
        },
      ],
    },

    {
      name: "mrp",
      label: "MRP",
      type: "Input",
      placeholder: "Enter MRP",
      toolTip: false,
      value: editProductData?.maximum_retail_price ? `${editProductData?.maximum_retail_price}` : "",
      prefix: "₹",
      reg: true,
    },
    {
      name: "your_selling_price",
      label: "Your selling price",
      type: "Input",
      placeholder: "Enter selling price",
      toolTip: false,
      value: editProductData?.vendor_selling_price ? `${editProductData?.vendor_selling_price}` : "",
      // disabled: true,
      prefix: "₹",
      reg: true,
    },
    {
      name: "commission_percent",
      label: "Comission percent",
      type: "Input",
      placeholder: "Enter Comission percent",
      toolTip: false,
      value: editProductData?.product?.commission_percent ? `${editProductData?.commission_percent}%` : "",
      // disabled: true,
      suffix: "%",
      reg: true,
    },
  ];

  const { Text } = Typography;
  // eslint-disable-next-line
  const { TabPane } = Tabs;
  const params = useParams();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [fileList, setFileList] = useState([]);
  // eslint-disable-next-line 
  const [addVariation1Data, setaddVariation1Data] = useState([{
    id: 1,
    Variations:"",  
   
  }]);
  const [updateApi, { isLoading: isUpdateLoading }] =
  useUpdateSellerProductDetailsMutation();
 // eslint-disable-next-line 
  const [createApi, { isLoading: isCreateLoading }] =
    useCreateProductDetailsMutation();
    useEffect(() => {
      getVendorCategoryAPI();
    
    },[getVendorCategoryAPI]);
    useEffect(() => {
   
      getVendorSubCategoryAPI();
    },[getVendorSubCategoryAPI]);
  useEffect(() => {
    if (editProductDetails?.status) {
      dispatch(getEditProductData(editProductDetails?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editProductDetails]);
  useEffect(() => {
    if(params?.slug)
    {
    getEditProductApi(params?.slug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleEditFormData = async (val) => {
   
    const data = {
      ...val,
      sub_category_id: val?.sub_category_id,
      category: val?.category[0] || val?.category,
      sub_sub_category: val?.sub_category_id_2 || 0,
      imageFile:fileList,
      option_name:null,
      variation_theme:"",
      variation_type:"Parent only",
     
    };
    if (params?.slug) {
      const response = await updateApi({ id: params?.slug, data });
      if (response?.data?.status) {
        showSuccessToast(response?.data?.message);
        setTimeout(() => {
          navigate("/seller/catalogue");
        }, 800);
        dispatch(getEditProductData({}));
      } else {
        showErrorToast(response?.error?.data?.message);
      }
    } else {
      const response = await createApi({ data });
      if (response?.data?.status) {
        showSuccessToast(response?.data?.message);
        setTimeout(() => {
          navigate("/seller/catalogue");
        }, 800);
        dispatch(getEditProductData({}));
      } else {
        showErrorToast(response?.error?.data?.message);
      }
    }
  };
// eslint-disable-next-line 
  const [VariationDataSource, setVariationDataSource] = useState([]);
  

  const handleChildFieldChange = (inputValues) => {
    setFormData({ ...formData, ...inputValues });
    form.setFieldsValue(inputValues);
  };
  const handleCancel = () => {
    navigate("/seller/catalogue");
    dispatch(getEditProductData({}));
  };
  return (
    <Col className="product-wrapper">
      <Form form={form} onFinish={handleEditFormData}>
        <Col className="headerWrapper">
          <Col>
            <Text className="heading-title">Add new item</Text>
          </Col>
          <Col style={{gap:"1rem",display:"flex"}}>
            <Button className="cancelBtn" onClick={handleCancel}>
              CANCEL
            </Button>
            <Button
              loading={isUpdateLoading}
              className="saveBtn"
              type="primary"
              htmlType="submit"
            >
              SAVE
            </Button>
          </Col>
        </Col>
        <Col className="contentWrapper">
          <Col className="leftContent">
            <Col>
              <EditContainerProduct
                title={"Item details"}
                formData={itemDetails}
                handleChildFieldChange={handleChildFieldChange}
                isFetching={false}
                setselectedCategoryOption={setselectedCategoryOption}
              />
              {/* <VariationOptions setVariationDataSource={setVariationDataSource} addVariation1Data={addVariation1Data} setaddVariation1Data={setaddVariation1Data}/>
              <Table pagination={false} columns={columns} dataSource={VariationDataSource} /> */}
              <EditContainerProduct
                title={"Variations"}
                formData={variations}
                handleChildFieldChange={handleChildFieldChange}
                isFetching={isFetching}
                setselectedCategoryOption={setselectedCategoryOption}
              />

              {/* </Form> */}
            </Col>
          </Col>
          <Col className="rightContent-image headingItem">
            <Text className="heading-text ">Image</Text>
            <Col className="rightSubContent headingItem">
              {isFetchingEditProductDetails ? (
                <>
                  <Skeleton active />
                </>
              ) : (
                <ProductImageUpload data={editProductData} fileList={fileList} setFileList={setFileList} />
              )}
            </Col>
          </Col>
        </Col>
        <ActionContainer
          position={"bottom"}
          handleCancel={handleCancel}
          loadingButton={isUpdateLoading}
        />
      </Form>
    </Col>
  );
};

export default CreateSellerProduct;
