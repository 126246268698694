import React, { useEffect, useState } from "react";
import "./ExistingOrders.css";
// import { RightOutlined } from "@ant-design/icons";
// import { useNavigate } from "react-router";
import {  Col, Row, Typography, Tag } from "antd";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import "./ExistingOrders.css";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
// import { useLazyGetExistingOrdertTableQuery } from "../../apis/ManageOrders";
import { useDispatch, useSelector } from "react-redux";
import { getmanageOrdersTableData } from "../../../redux/slices/manageOrders/action";
import { manageOrdersDataSelector } from "../../../redux/slices/manageOrders/selector";
// import { dateFormat } from "../../commonFuntions/CommonUtilFunctions";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import {  
  statusColorMapping,
} from "../../../commonFuntions/CommonUtilFunctions";
import {

  useLazyGetDownloadPdfMobPaymentQuery,
} from "../../../apis/Seller/SellerSettlementAPI";
import {
  colorMapping,
  dateFormat,
 // handleExcelDownload,
} from "../../../commonFuntions/CommonUtilFunctions";
import { saveAs } from "file-saver";
// import { iteratee } from "lodash";


const { Text } = Typography;
const handleTableData = (data) => {
  let tableData = [];
  data?.results?.map((item) => {
    tableData.push({
      ...item,
      checkbox: false,
      // order_number: "898DRY57YUF",
      // date: "23 Jun 2022, 09:13am",
      // invoice_no: "897688",
      // status: "Awaiting",
      // revenue: 10200,
      // tax: 1500,
      // settlement_amount: 8200,
    });
    return null;
  });
  return tableData;
};

const SellerMobOrders = (props) => {
  const { orderTableApi, orderData, isLoading } = props;
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const selectorData = useSelector(manageOrdersDataSelector);  
  const [searchInput, setSearchInput] = useState("");
  const [searchedValue, setSearchedValue] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const [
    getDownloadMobPdfApi,
    //  { data: mobPdfData }
  ] = useLazyGetDownloadPdfMobPaymentQuery();

  const [tableDataSource, setTableDataSource] = useState([]);

  // useEffect(() => {
  //   orderTableApi({ bmp_id });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (orderData?.status) {
      dispatch(getmanageOrdersTableData(orderData?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData]);

  useEffect(() => {
    if (selectorData) {
      const data = selectorData ? handleTableData(selectorData) : "";
      setTableDataSource(data);
    }
  }, [selectorData]);
  const handleSearchedValue = async (params) => {   
    setSearchedValue(params);
    setCurrentPage(1);
    
  };
  // const goToSellerPage = (record) => {
  //   navigate(`/admin/settlement`);
  // };
  const handleChange = async (value) => { 
    const response = await getDownloadMobPdfApi({    
      ...(searchInput && { search: searchInput }),
    });   
    //handleExcelDownload(response?.data?.data?.url, true);
    saveAs(response?.data?.data?.url);
  };
   

  const handlePageChange = async (currPage) => {   
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
    };
    setCurrentPage(currPage);
    await orderTableApi(params);
  };

 

  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //   },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.name === "Disabled User", // Column configuration not to be checked
  //     name: record.name,
  //   }),
  // };

  const downloadOptions = [
    {
      key: 1,
      value: "Excel",
      label: "Excel",
    },
  ];

 
  
  const columns = [
    
    {
      // width: "220px",
      fixed: typeof window !== 'undefined' && window.innerWidth > 768 ? "left" : "",
      title: "Seller order number",
      dataIndex: "sub_order",
      className: "table_text",
    },
    {
      // width: "200px",
      title: "Order date",
      dataIndex: "created_at",
      render: (params, record) => {
        return (
          <>
            <Text className="table_text">{params && dateFormat(params)}</Text>
          </>
        );
      },
    },
    {
      title: "Invoice no",
      // width: "200px",
      dataIndex: "invoice_number",
      className: "table_text",
    },
    {
      // fixed: "left",
      title: "Payment status",
      // width: "200px",
      dataIndex: "payment_status",
      render: (params, record) => {
        return (
          <Tag className={`tag-font-style ${colorMapping[params]}`}>
            {params}
          </Tag>
        );
      },
    },
    {
      title: "Revenue(Inc Taxes)",
      // width: "200px",
      dataIndex: "revenue",
      render: (params, record) => {
        return <Text className="table_text">₹ {(params || 0).toFixed(2)}</Text>;
      },
    },
    {
      title: "Tax",
      // width: "200px",
      dataIndex: "tax",
      render: (params, record) => {
        return <Text className="table_text">₹ {(params || 0).toFixed(2)}</Text>;
      },
    },
    {
      title: "Order status",
      // width: "200px",
      dataIndex: "status",
      render: (params, record) => {
        return <Text className={`tag-style ${statusColorMapping[params]}`}>{params || "N/A"}</Text>;
      },
    },
    {
      title: "Settlement amount",
      // width: "200px",
      dataIndex: "settlement_amount",
      render: (params, record) => {
        return (
          <Col style={{ display: "flex", justifyContent: "space-around" }}>
            <Text style={{ color: "#01a685" }} className="table_text">
              ₹ {(params || 0).toFixed(2)}
            </Text>
            {/* <RightOutlined onClick={() => goToSellerPage(record)} /> */}
          </Col>
        );
      },
    },
  ];

  return (
    <>
      <Col>
       
        <Row className="action-container">
          <Col className="action-sub-container">
            <SearchInput
              size={"large"}
              placeholder={"Search by order"}
              getLibraryApi={orderTableApi}
              width={"400px"}
              parentSetterState={setSearchInput}            
              isLoading={isLoading}
              handleSearchedValue={handleSearchedValue}
            />
          </Col>
          <Col className="action-sub-container">
            <CustomSelect
              placeholder={"Download"}
              width={"150px"}
              height={"46px"}
              options={downloadOptions}
              handleChange={handleChange}
              value={"Download"}
            />
            {/* <Button
              size="large"
              style={{
                backgroundColor: "#0354a3",
                color: "#fff",
                fontWeight: 500,
                height: "48px",
              }}
              onClick={() => setOpenUploadModal(true)}
            >
              ADD PAYMENT
            </Button> */}
          </Col>
        </Row>
        <Col>
          <CustomTableWithPagination
            className="order-list-table no-wrap"
            tableDataSource={tableDataSource || []}
            tableColumns={columns}
            locale={{
              emptyText: isLoading ? <TableSkeleton length={30} /> : "",
            }}
            scroll={{ x: "100%" }}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            itemsPerPage={20}
            totalEntries={
              !isLoading && orderData?.data?.pagination?.total_entries
            }
            isLoading={tableDataSource.length && isLoading}
          />
        </Col>
      </Col>
     
      
      
    </>
  );
};

export default SellerMobOrders;
