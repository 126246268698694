import React, { useEffect, useState } from "react";

import { Col, Row, Typography, Spin } from "antd";
import { ReactComponent as LogoIcon } from "../../../assets/icons/mob logo final (1).svg";
import { ReactComponent as BannerIcon } from "../../../assets/icons/loginBanner.svg";
import { ReactComponent as LeftArrowIcon } from "../../../assets/icons/chevron-left.svg";
import { ReactComponent as PartnerLogoIcon } from "../../../assets/seller/icons/mobapartner.svg";

import OtpInput from "react-otp-input";
import { Button, Form, Input } from "antd";
import {
  useSellerLoginMutation,
  useSellerRegistrationOtpMutation,
  useSellerVerifyOtpMutation,
  useSellerFinalRegistrationMutation,
} from "../../../apis/Seller/SellerLogin.js";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import {
  getSellerAuthToken,
  getSellerLoginUserDetail,
} from "../../../redux/slices/SellerModule/SellerLogin/action.js";
import "./SellerRegistration.css";
const SellerRegistration = () => {
  const dispatch = useDispatch();
  const { Text } = Typography;
  const [basicModal, setbasicModal] = useState(true);
  const [renderOtp, setRenderOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [adminLoginApi, { isLoading }] = useSellerLoginMutation();
  const [verifyOtpApi] = useSellerVerifyOtpMutation();
  const [getOtpApi] = useSellerRegistrationOtpMutation();
  const [getSellerRegistration] = useSellerFinalRegistrationMutation();
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [storeName, setstoreName] = useState("");
  const [SellerName, setSellerName] = useState("");
  const [Password, setPassword] = useState("");
  const [SellerEmail, setSellerEmail] = useState("");
  const navigate = useNavigate();
  const [ispageLoading, setispageLoading] = useState(false);
  const [IsNewRegister, setIsNewRegister] = useState(true);
  // const handleForgetPassword = () => {
  //   setFogetPassword(true);
  // };
  const resendOtp = () => {
    setOtp("");
    setTimer(60);
    getOtpApi({ phone_number: emailOrPhone });
  };
  const handleOtp = async () => {
    try {
      if (!emailOrPhone && !storeName) {
        return;
      }
      if (basicModal) {
        setTimer(60);
        const response = await getOtpApi({
          phone_number: emailOrPhone,
          vendor_name: storeName,
        });

        if (response?.data?.status === true) {
          setRenderOtp(false);
          showSuccessToast(response.data.message);
          setIsNewRegister(response?.data?.data?.is_new);
        } else if (!response?.data?.status) {
          showErrorToast(response?.error?.data?.message);
          setIsEmailValid(false);
          setbasicModal(true);
          setRenderOtp(true);
          setIsNewRegister(response?.data?.data?.is_new);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setispageLoading(false);
    }
  };
  const handleFinalRegistration = async () => {
    setispageLoading(true);
    try {
      if (!SellerEmail && !storeName && !Password) {
        return;
      }
      if (!basicModal) {
        setTimer(60);
        const response = await getSellerRegistration({
          phone_number: emailOrPhone,
          person_full_name: SellerName,
          email: SellerEmail,
          password: Password,
          vendor_name: storeName,
        });

        if (response?.data?.status === true) {
          // showSuccessToast(response.data.message);
          setbasicModal(true);
          setRenderOtp(true);
          onFinish({ Password: Password, emailOrPhone: emailOrPhone });
        } else if (!response?.data?.status) {
          showErrorToast(response?.error?.data?.message);
        }
      }
    } catch (error) {
      // handle error
    } finally {
      setispageLoading(false); // set loading state to false
    }
  };
  const handleBack = () => {
    setbasicModal(true);
  };
  const handleBackmain = () => {
    setbasicModal(true);
    setRenderOtp(true);
  };
  const onFinish = async (values) => {
    try {
      if (!basicModal) {
        const response = await adminLoginApi({
          email_or_phone: emailOrPhone,
          password: values.Password,
        });

        if (response?.data?.status) {
          const accessToken = response?.data?.data?.access;
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem(
            "selleruserName",
            response?.data?.data?.data?.person_full_name
          );
          localStorage.setItem("bmp_id", response?.data?.data?.data?.bmp_id);
          localStorage.setItem(
            "bmp_name",
            response?.data?.data?.data?.vendor_name
          );
          localStorage.setItem(
            "bmp_name",
            response?.data?.data?.data?.vendor_name
          );
          localStorage.setItem(
            "bmp_isactive",
            response?.data?.data?.data?.is_active
          );

          setIsEmailValid(true);
          dispatch(getSellerAuthToken(accessToken));
          dispatch(getSellerLoginUserDetail(response?.data?.data));
          navigate("/seller/sellerdashboard");
          showSuccessToast(response?.data?.message);
        } else {
          const errorMsg =
            response?.data?.message || response?.error?.data?.data?.email[0];
          if (response?.error?.data?.data?.email[0]) {
            setIsEmailValid(false);
          } else {
            setIsEmailValid(true);
          }
          showErrorToast(errorMsg);
          navigate("/seller/SellerLogin");
        }
      }
    } catch (error) {
      showErrorToast(error);
      console.error("Login failed", error);
    }
  };

  const otpVerify = async () => {
    const response = await verifyOtpApi({
      email_or_phone: emailOrPhone,
      otp: otp,
    });

    if (response?.data?.status) {
      console.log("New", IsNewRegister);
      if (!IsNewRegister) {
        const accessToken = response?.data?.data?.access;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem(
          "selleruserName",
          response?.data?.data?.data?.person_full_name
        );
        localStorage.setItem("bmp_id", response?.data?.data?.data?.bmp_id);
        localStorage.setItem(
          "bmp_name",
          response?.data?.data?.data?.vendor_name
        );
        localStorage.setItem(
          "bmp_name",
          response?.data?.data?.data?.vendor_name
        );
        localStorage.setItem(
          "bmp_isactive",
          response?.data?.data?.data?.is_active
        );
        setIsEmailValid(true);
        dispatch(getSellerAuthToken(accessToken));
        dispatch(getSellerLoginUserDetail(response?.data?.data));
        navigate("/seller/sellerdashboard");
        showSuccessToast(response?.data?.message);
      } else {
        showSuccessToast(response.data.message);
        setbasicModal(false);
      }
    } else if (!response.error.data.status) {
      showErrorToast(response.error.data.message);
    }
  };

  useEffect(() => {
    setbasicModal(true);
    setRenderOtp(true);
  }, []);
  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);
  return ispageLoading ? (
    <div className="full-page-loader text-center">
      <Spin />
    </div>
  ) : (
    <Row>
      {/* d-none d-lg-block d-md-block */}
      <Col
        span={12}
        className="left-section"
        style={{
          // width: "50%",
          height: "100vh",
          backgroundImage: "linear-gradient(143deg, #0354a3 25%, #74d8ad 86%)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <LogoIcon style={{ height: "40px", paddingTop: "1rem" }} />

        <BannerIcon style={{ width: "80%", height: " auto" }} />
      </Col>

      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 12 }}
        lg={{ span: 12 }}
        style={{
          // width: "50%",
          // height: "100vh",
          // display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Row
          className="register-topbutton"
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <Col
            className="d-block d-sm-block d-lg-none d-xl-none"
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <a href="/">
              <PartnerLogoIcon style={{ height: "40px", paddingTop: "1rem" }} />
            </a>
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 24 }}
          >
            <a
              href="/SellerLogin"
              className="btn btn-primary register-now-outline"
              id="btnRegister"
            >
              Login
            </a>
          </Col>
        </Row>

        <div className="Seller-login-card"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <Row>
            <Col
              className="Seller-login-conatiner"
              span={24}
              style={{
                padding: "20px 26px",
                // width: "470px",
                height: "470px",
                boxShadow: "0 13px 36px 0 rgba(0, 0, 0, 0.16)",
                borderRadius: "16px",
              }}
            >
              <>
                {basicModal ? (
                  <>
                    {renderOtp && (
                      <>
                        <Form
                          name="basicStep1"
                          initialValues={{
                            remember: true,
                          }}
                          layout="vertical"
                          // onFinish={onFinish}
                          autoComplete="off"
                          validateTrigger={["onChange", "onBlur"]}
                        >
                          <Col
                            style={{
                              paddingBottom: "20px",
                              textAlign: "center",
                            }}
                          >
                            <Text style={{ fontSize: "20px", fontWeight: 500 }}>
                              Create your account
                            </Text>
                          </Col>
                          <Form.Item
                            label={
                              <span>
                                Store name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </span>
                            }
                          >
                            <Form.Item
                              label="Storename"
                              name="Storename"
                              noStyle
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Store name!",
                                },
                              ]}
                            >
                              <Input
                                className={"custom-input"}
                                style={{
                                  height: "40px",
                                }}
                                defaultValue={storeName}
                                onChange={(e) => setstoreName(e.target.value)}
                              />
                            </Form.Item>
                          </Form.Item>
                          <Form.Item
                            label={
                              <span>
                                Mobile number{" "}
                                <span style={{ color: "red" }}>*</span>
                              </span>
                            }
                            style={{
                              marginTop: "20px",
                              marginBottom: "10px",
                            }}
                          >
                            <Form.Item
                              label="MobileNo"
                              name="MobileNo"
                              noStyle
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Mobile no!",
                                },
                              ]}
                            >
                              <Row>
                                <Col span={24}>
                                  <Input
                                    className={
                                      isEmailValid ? "custom-input" : ""
                                    }
                                    maxLength={10}
                                    pattern="[0-9]*"
                                    style={{
                                      height: "40px",
                                      borderColor: isEmailValid
                                        ? ""
                                        : "#bf2600",
                                      backgroundColor: isEmailValid
                                        ? ""
                                        : "#ffebe6",
                                    }}
                                    defaultValue={emailOrPhone}
                                    onChange={(e) => {
                                      const mobileNumber = e.target.value;
                                      const isValidMobileNumber =
                                        /^\d{10}$/.test(mobileNumber);
                                      if (isValidMobileNumber) {
                                        setEmailOrPhone(mobileNumber);
                                      } else {
                                        // Handle invalid mobile number input
                                        setEmailOrPhone("");
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key.match(/[a-zA-Z]/)) {
                                        if (
                                          e.key === "Backspace" ||
                                          e.key === "Delete" ||
                                          e.key === "Tab"
                                        ) {
                                          return; // Allow these keys to perform their default actions
                                        }
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Form.Item>
                          </Form.Item>

                          <div
                            style={{
                              position: "absolute",
                              bottom: 0,
                              marginRight: "22px",
                            }}
                          >
                            <p>
                              By clicking agree and continue you agree to our
                              <Text className="hyperlink-text">
                                {" "}
                               <a href="https://madoverbuildings.com/home/faq" rel="noreferrer" target="_blank"> Terms and Conditions</a>{" "}
                              </Text>{" "}
                              and{" "}
                              <Text className="hyperlink-text">
                                {" "}
                                <a href="https://madoverbuildings.com/home/faq" rel="noreferrer" target="_blank"> Privacy Statement</a>{" "}
                              </Text>
                              .
                            </p>
                            <Form.Item>
                              <Button
                                style={{
                                  width: "100%",
                                  // backgroundColor:
                                  //   otpModal && !emailOrPhone && !emailOrPhone
                                  //     ? "#dedede"
                                  //     : "#0354a3",
                                  height: "40px",
                                  fontWeight: 500,
                                  color: "#fff",
                                }}
                                // disabled={!emailOrPhone && !storeName}
                                type={"primary"}
                                htmlType="submit"
                                onClick={handleOtp}
                                loading={ispageLoading}
                              >
                                {"Send OTP"}
                              </Button>
                            </Form.Item>
                          </div>
                        </Form>
                      </>
                    )}
                    {!renderOtp && (
                      <>
                        <Form
                          name="fromOTP"
                          initialValues={{
                            remember: true,
                          }}
                          layout="vertical"
                          // onFinish={onFinish}
                          autoComplete="off"
                        >
                          <Row className="mb-5">
                            <Col
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Col style={{ width: "40%" }}>
                                <LeftArrowIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={handleBackmain}
                                />{" "}
                              </Col>
                              <Text
                                style={{
                                  fontSize: "20px",
                                  fontWeight: 500,
                                  textAlign: "center",
                                }}
                              >
                                Enter OTP
                              </Text>
                            </Col>
                          </Row>
                          <Row className="mb-5">
                            <Col span={24} className="text-center">
                              Enter OTP sent to {emailOrPhone}
                            </Col>
                          </Row>
                          <Row className="OTP-form">
                            <Col span={24} className="text-center OTP-form">
                              <Form.Item label="">
                                <Form.Item
                                  label="Enter OTP"
                                  name="EnterOTP"
                                  noStyle
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your OTP!",
                                    },
                                  ]}
                                >
                                  <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    renderInput={(props) => (
                                      <Input
                                        {...props}
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          marginRight: "10px",
                                          textAlign: "center",
                                        }}
                                      />
                                    )}
                                  />
                                </Form.Item>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Col style={{ marginTop: "1rem" }}>
                            {timer > 0 ? (
                              <>
                                <Text>Resend OTP in {timer} seconds</Text>
                              </>
                            ) : (
                              <Button
                                style={{
                                  width: "100%",
                                  fontWeight: 500,
                                  height: "40px",
                                  marginTop: "1rem",
                                }}
                                onClick={resendOtp}
                              >
                                RESEND OTP
                              </Button>
                            )}
                          </Col>
                          <div
                            style={{
                              position: "absolute",
                              bottom: 0,
                              marginRight: "22px",
                            }}
                          >
                            <p>
                              By clicking agree and continue you agree to our
                              <Text className="hyperlink-text">
                                Terms and Conditions
                              </Text>{" "}
                              and{" "}
                              <Text className="hyperlink-text">
                                Privacy Statement
                              </Text>
                              .
                            </p>
                            <Form.Item>
                              <Button
                                style={{
                                  width: "100%",
                                  backgroundColor: !otp ? "#dedede" : "#0354a3",
                                  height: "40px",
                                  fontWeight: 500,
                                }}
                                disabled={!otp}
                                type={"primary"}
                                htmlType="submit"
                                onClick={otpVerify}
                                loading={isLoading}
                              >
                                {"VERIFY"}
                              </Button>
                            </Form.Item>
                          </div>
                        </Form>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Col style={{ width: "30%" }}>
                        <LeftArrowIcon
                          style={{ cursor: "pointer" }}
                          onClick={handleBack}
                        />{" "}
                      </Col>
                      <Text
                        style={{
                          fontSize: "20px",
                          fontWeight: 500,
                          width: "70%",
                        }}
                      >
                        Complete sign up
                      </Text>
                    </Col>

                    <Form
                      name="fromFinalRegistration"
                      style={{
                        maxWidth: 500,
                      }}
                      initialValues={{
                        remember: false,
                      }}
                      layout="vertical"
                      // onFinish={onFinish}
                      //  onFinish={async (values) => {
                      //   await new Promise(resolve => setTimeout(resolve, 3000));
                      //   onFinish(values);
                      // }}
                      autoComplete="off"
                    >
                      <Form.Item
                        label={
                          <span>
                            Name <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                      >
                        <Form.Item
                          label="Name"
                          name="Name"
                          noStyle
                          rules={[
                            {
                              required: true,
                              message: "Please input your name!",
                            },
                          ]}
                        >
                          <Row>
                            <Col span={24}>
                              <Input
                                className="custom-input"
                                style={{
                                  height: "40px",
                                }}
                                // className={SellerName ? "custom-input" : ""}
                                // style={{
                                //   height: "40px",
                                //   borderColor: SellerName ? "" : "#bf2600",
                                //   backgroundColor: SellerName ? "" : "#ffebe6",
                                // }}
                                onChange={(e) => setSellerName(e.target.value)}
                              />
                            </Col>
                          </Row>
                        </Form.Item>
                        <Form.Item
                          label={
                            <span>
                              Email id <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          style={{ marginTop: "20px", marginBottom: "10px" }}
                        >
                          <Form.Item
                            label="EmailId"
                            name="EmailId"
                            noStyle
                            rules={[
                              {
                                required: true,
                                message: "Please input your EmailId!",
                              },
                            ]}
                          >
                            <Row>
                              <Col span={24}>
                                <Input
                                  className={SellerEmail ? "custom-input" : ""}
                                  style={{
                                    height: "40px",

                                    borderColor: SellerEmail ? "" : "#bf2600",
                                    backgroundColor: SellerEmail
                                      ? ""
                                      : "#ffebe6",
                                  }}
                                  onChange={(e) =>
                                    setSellerEmail(e.target.value)
                                  }
                                />
                              </Col>
                            </Row>
                          </Form.Item>
                        </Form.Item>

                        <Form.Item
                          label={
                            <span>
                              Password <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          style={{ marginTop: "20px", marginBottom: "10px" }}
                        >
                          <Form.Item
                            label="Password"
                            name="Password"
                            noStyle
                            rules={[
                              {
                                required: true,
                                message: "Please input your Password!",
                              },
                            ]}
                          >
                            <Row>
                              <Col span={24}>
                                <Input
                                  type="password"
                                  className={Password ? "custom-input" : ""}
                                  style={{
                                    height: "40px",

                                    borderColor: Password ? "" : "#bf2600",
                                    backgroundColor: Password ? "" : "#ffebe6",
                                  }}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                              </Col>
                            </Row>
                          </Form.Item>
                        </Form.Item>
                      </Form.Item>
                      <p>
                        By clicking agree and continue you agree to our
                        <Text className="hyperlink-text">
                          Terms and Conditions
                        </Text>{" "}
                        and
                        <Text className="hyperlink-text">
                          Privacy Statement{" "}
                        </Text>
                        .
                      </p>
                      <Form.Item>
                        <Button
                          style={{
                            width: "100%",
                            //backgroundColor:
                            // otpModal && !emailOrPhone ? "#dedede" : "#0354a3",
                            height: "40px",
                            fontWeight: 500,
                          }}
                          disabled={!emailOrPhone}
                          type={"primary"}
                          htmlType="submit"
                          onClick={handleFinalRegistration}
                          loading={isLoading}
                        >
                          {"AGREE AND CONTINUE"}
                        </Button>
                      </Form.Item>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                      ></Col>
                    </Form>
                  </>
                )}{" "}
              </>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default SellerRegistration;
