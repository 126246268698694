import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQueryWithReauth} from "./baseQuery";

export const sellerCatalogueAPI = createApi({
  reducerPath: "sellerCatalogueAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    //  ---------------  Seller Pricing api
    addSellerPricingFile: builder.mutation({
      query: ({file, is_update}) => {
        const formData = new FormData();
        formData.append("file", file);
        // if (is_update) {
        //   formData.append("is_update", is_update);
        // }

        return {
          url: "api/product/products_catalogue/upload_product_listing/",
          method: "POST",
          body: formData,
        };
      },
    }),
    uploadSellerCataloguePricingFile: builder.mutation({
      query: ({file, is_update}) => {
        const formData = new FormData();
        formData.append("file", file);
        // if (is_update) {
        //   formData.append("is_update", is_update);
        // }

        return {
          url: "api/product/seller_catalogue/upload_seller_catagloue/",
          method: "POST",
          body: formData,
        };
      },
    }),
    getExistingSellerTable: builder.query({
      query: ({id, params, bmp_id}) => {
        return {
          url: `/api/product/seller_catalogue/${
            id || bmp_id
          }/get_seller_products/`,
          params,
        };
      },
    }),
    getExistingSellerInventoryPrice: builder.query({
      query: ({id, params, bmp_id}) => {
        return {
          url: `/api/product/seller_catalogue/${
            id || bmp_id
          }/get_seller_catalogue/`,
          params,
        };
      },
    }),
    deleteSellerCatalogueProduct: builder.mutation({
      query: (id) => ({
        url: `api/product/products_catalogue/delete_vendor_catalogue_product/`,
        method: "DELETE",
        body: id,
      }),
    }),
    activateTable: builder.mutation({
      query: (params) => ({
        url: `accounts/mob_vendor/update_vendor/`,
        method: "PATCH",
        body: params,
      }),
    }),
    updateSellerCatalogueProduct: builder.mutation({
      query: (data) => ({
        url: `api/product/products_catalogue/update_vendor_catalogue_product/`,
        method: "PATCH",
        body: data,
      }),
    }),
    updateStoreSalesProduct: builder.mutation({
      query: (data) => ({
        url: `api/seller/catalogue/update_vendor_catalogue_product/`,
        method: "PATCH",
        body: data,
      }),
    }),
    updateProductFlagTax: builder.mutation({
      query: (params) => ({
        url: `/api/seller/catalogue/raise_tax_flag/`,
        method: "POST",
        body: params
      }),
    }),
    createSellerCatalogueProduct: builder.mutation({
      query: (data) => ({
        url: `/api/product/products_catalogue/create_vendor_catalogue/`,
        method: "POST",
        body: data,
      }),
    }),
    createStoreProduct: builder.mutation({
      query: (data) => ({
        url: `/api/seller/catalogue/create_vendor_catalogue/`,
        method: "POST",
        body: data,
      }),
    }),
    getSearchSellerProduct: builder.query({
      query: ({params, id}) => {
        return {
          url: `api/product/seller_catalogue/${id}/product_search_suggest/`,
          params,
        };
      },
    }),
    getSellerList: builder.query({
      query: (params) => {
        return {
          url: `/accounts/mob_vendor/get_mob_all_vendors/`,
          // url: `accounts/mob_vendor/`,
          params,
        };
      },
    }),
    getSellerVendorDetails: builder.query({
      query: (params) => {
        return {
          url: `accounts/mob_vendor/get_vendor_detail/`,
          params,
        };
      },
    }),
    getSellerEditVendorDetails: builder.query({
      query: (params) => {
        return {
          url: `/accounts/mob_vendor/get_vendor_details/`,
          params,
        };
      },
    }),
    getSellerAddVendorDetails: builder.query({
      query: () => {
        return {
          url: `/api/product/products_catalogue/get_categories/`,
        };
      },
    }),
    getAllSellerAddress: builder.query({
      query: (params) => {
        return {
          url: `/accounts/mob_vendor/get_vendor_address/`,
          params,
        };
      },
    }),
    createSellerVender: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        formData.append("vendor_name", data?.vendor_name);
        formData.append("bmp_id", data?.bmp_id);
        formData.append("email", data?.email);
        formData.append("phone_number", data?.phone_number);
        formData.append("address", data?.address);
        formData.append("location", data?.location);
        formData.append("state", data?.state);
        formData.append("city", data?.city);
        formData.append("pincode", data?.pincode);
        formData.append("person_full_name", data?.person_full_name);
        formData.append("shop_opening_time", data?.shop_opening_time);
        formData.append("shop_closing_time", data?.shop_closing_time);
        formData.append("website", data?.website);
        formData.append(
          "shop_act_registration_number",
          data?.shop_act_registration_number
        );
        formData.append("gst_number", data?.gst_number);
        formData.append("adhar_card_number", data?.adhar_card_number);
        formData.append("pan_card_number", data?.pan_card_number);
        formData.append("remarks", data?.remarks);
        formData.append("seller_group", data?.seller_group);
        formData.append("google_map_link", data?.google_map_link);
        formData.append("types_of_material", [
          JSON.stringify(data?.types_of_material || []),
        ]);
        formData.append("seller_display_image", data?.seller_display_image);
        if (data?.bank_details) {
          const bankDetails = data?.bank_details;

          formData.append(
            "bank_details",
            JSON.stringify({
              bank_name: bankDetails.bank_name || "",
              bank_branch: bankDetails.bank_branch || "",
              name_of_account_holder: bankDetails.name_of_account_holder || "",
              bank_account_number: bankDetails.bank_account_number || "",
              bank_ifsc: bankDetails.bank_ifsc || "",
              bank_account_type: bankDetails.bank_account_type || "",
            })
          );
        }
        return {
          url: `/accounts/mob_vendor/create_vendor/`,
          method: "POST",
          body: formData,
        };
      },
    }),
    updateSellerVender: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        formData.append("vendor_name", data?.vendor_name);
        formData.append("mob_vendor_id", data?.bmp_id);
        formData.append("email", data?.email);
        formData.append("phone_number", data?.phone_number);
        formData.append("address", data?.address);
        formData.append("location", data?.location);
        formData.append("state", data?.state);
        formData.append("city", data?.city);
        formData.append("pincode", data?.pincode);
        formData.append("person_full_name", data?.person_full_name);
        formData.append("shop_opening_time", data?.shop_opening_time);
        formData.append("shop_closing_time", data?.shop_closing_time);
        formData.append("website", data?.website);
        formData.append("google_map_link", data?.google_map_link);
        formData.append(
          "shop_act_registration_number",
          data?.shop_act_registration_number
        );
        formData.append("gst_number", data?.gst_number);
        formData.append("adhar_card_number", data?.adhar_card_number);
        formData.append("pan_card_number", data?.pan_card_number);
        formData.append("remarks", data?.remarks);
        formData.append("seller_group", data?.seller_group);
        formData.append("types_of_material", [
          JSON.stringify(data?.types_of_material || []),
        ]);
        // for (let i = 0; i < data?.types_of_material?.length; i++) {
        //   formData.append(
        //     "types_of_material[" + i + "]",
        //     data?.types_of_material[i]
        //   );
        // }
        formData.append("seller_display_image", data?.seller_display_image);
        if (data?.bank_details) {
          const bankDetails = data?.bank_details;

          formData.append(
            "bank_details",
            JSON.stringify({
              bank_name: bankDetails.bank_name || "",
              bank_branch: bankDetails.bank_branch || "",
              name_of_account_holder: bankDetails.name_of_account_holder || "",
              bank_account_number: bankDetails.bank_account_number || "",
              bank_ifsc: bankDetails.bank_ifsc || "",
              bank_account_type: bankDetails.bank_account_type || "",
            })
          );
        }

        return {
          url: `/accounts/mob_vendor/update_vendor/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    deleteSellerAddress: builder.mutation({
      query: (id) => ({
        url: `/accounts/mob_vendor/delete_vendor_address/`,
        method: "DELETE",
        body: id,
      }),
    }),
    deleteVendorProduct: builder.mutation({
      query: (id) => ({
        url: `api/product/products_catalogue/delete_vendor_catalogue_product/`,
        method: "DELETE",
        body: id,
      }),
    }),
    createSellerAddress: builder.mutation({
      query: (data) => ({
        url: `/accounts/mob_vendor/create_vendor_address/`,
        method: "POST",
        body: data,
      }),
    }),
    updateSellerAddress: builder.mutation({
      query: (data) => ({
        url: `/accounts/mob_vendor/update_vendor_address/`,
        method: "PATCH",
        body: data,
      }),
    }),
    updateServiceRequestStatus: builder.mutation({
      query: (data) => ({
        url: `/api/orders/order/update_service_request/`,
        method: "PATCH",
        body: data,
      }),
    }),
    addCommentServiceRequest: builder.mutation({
      query: (data) => {
        return {
          url: `/api/orders/order/add_comment_to_service_request/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrderFile: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        formData.append("file", data?.file);
        formData.append("sub_order", data?.sub_order);
        formData.append("file_name", data?.file_name);
        return {
          url: `/api/orders/order/add_suborder_file/`,
          method: "POST",
          body: formData,
        };
      },
    }),
    addServiceRequest: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        const list = [];
        data?.images?.map((element) => list?.push(element?.originFileObj));
        for (let i = 0; i <= list.length - 1; i++) {
          formData.append("images", list[i]);
        }
        formData.append("suborder", data?.suborder);
        formData.append("issue_type", data?.issue_type);
        formData.append("comments", data?.comments);
        return {
          url: `/api/orders/order/create_service_request/`,
          method: "POST",
          body: formData,
        };
      },
    }),
    getInventoryPriceExcel: builder.query({
      query: ({params, id}) => {
        return {
          url: `api/product/seller_catalogue/${id}/download_seller_products/`,
          params,
        };
      },
    }),
    getServiceRequestData: builder.query({
      query: (id) => {
        return {
          url: `/api/orders/order/${id}/get_service_requests/`,
        };
      },
    }),
    getBrandPriceExcel: builder.query({
      query: ({params, id}) => {
        return {
          url: `/api/seller/product/download_products/`,
          params,
        };
      },
    }),
    downloadSellerCatalogue: builder.query({
      query: ({params, id}) => {
        return {
          url: `/api/seller/product/download_catalogue_products/`,
          params,
        };
      },
    }),
    uploadSellerBrandPricingFile: builder.mutation({
      query: ({file, is_update}) => {
        const formData = new FormData();
        formData.append("file", file);
        // if (is_update) {
        //   formData.append("is_update", is_update);
        // }

        return {
          url: "api/product/seller_catalogue/upload_seller_catagloue/",
          method: "POST",
          body: formData,
        };
      },
    }),
    uploadSellerPricingFile: builder.mutation({
      query: ({file, is_update}) => {
        const formData = new FormData();
        formData.append("file", file);
        // if (is_update) {
        //   formData.append("is_update", is_update);
        // }

        return {
          url: "/api/seller/product/upload_products/",
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useAddSellerPricingFileMutation,
  useLazyGetExistingSellerTableQuery,
  useDeleteSellerCatalogueProductMutation,
  useUpdateSellerCatalogueProductMutation,
  useUpdateStoreSalesProductMutation,
  useUpdateProductFlagTaxMutation,
  useCreateSellerCatalogueProductMutation,
  useCreateStoreProductMutation,
  useLazyGetSearchSellerProductQuery,
  useLazyGetSellerListQuery,
  useLazyGetSellerVendorDetailsQuery,
  useLazyGetSellerEditVendorDetailsQuery,
  useLazyGetSellerAddVendorDetailsQuery,
  useCreateSellerVenderMutation,
  useUpdateSellerVenderMutation,
  useLazyGetAllSellerAddressQuery,
  useDeleteSellerAddressMutation,
  useCreateSellerAddressMutation,
  useUpdateSellerAddressMutation,
  useAddOrderFileMutation,
  useActivateTableMutation,
  useUploadSellerCataloguePricingFileMutation,
  useDeleteVendorProductMutation,
  useLazyGetExistingSellerInventoryPriceQuery,
  useLazyGetInventoryPriceExcelQuery,
  useAddServiceRequestMutation,
  useLazyGetServiceRequestDataQuery,
  useUpdateServiceRequestStatusMutation,
  useAddCommentServiceRequestMutation,
  useLazyGetBrandPriceExcelQuery,
  useUploadSellerBrandPricingFileMutation,
  useUploadSellerPricingFileMutation,
  useLazyDownloadSellerCatalogueQuery

} = sellerCatalogueAPI;

export default sellerCatalogueAPI;
