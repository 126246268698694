import React from "react";
import { Col, Typography, Row, Card } from "antd";
import "./SellerMobCreditOnboarding.css";


const { Text } = Typography;

const AboutMobCredit = (props) => {
  //   const {

  //   } = props;
  // eslint-disable-next-line

  return (
    <>
      <Col
        className="mt-4"
      
      >
      
       
        <Row gutter={16} className="mt-4">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card className="card-content">
              <Typography.Title level={4}>What is mobCREDIT - Line of Credit (LoC)?</Typography.Title>
              <Text>mobCREDIT is India’s first fully curated and embedded ‘Line of Credit’ (LoC) designed and developed for your clients, namely, architects, contractors, builder, civil engineers and interior designers with GSTIN.</Text>
              <Text>While the construction and interior industry grapples with a vicious cycle of extending LoC to your clients and then the task of follow ups to collect the bills receivables ensues. The long awaited solution with end-to-end digitisation is here.</Text>
            </Card>
          </Col>
        </Row>
        <Row gutter={16} className="mt-4">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card className="card-content">
            <Typography.Title level={4}>You as a supplier carry <span className="txt-risk-color"> ZERO risk</span>.</Typography.Title>
              <Text>You as a supplier carry NO risk in any form. The risk is directly borne by the NBFC (Non-Bank Financial Company) in accordance with the RBI guidelines, who sanction’s the recurring loan for this LoC program.</Text>
            </Card>
          </Col>
        </Row>
        <Row gutter={16} className="mt-4">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card className="card-content">
            <Typography.Title level={4}>What are the key qualifying criteria for my clients?</Typography.Title>
            <Text>
            There are two primary qualifying criteria which also helps in allocating line of credit sanction amount, <br />
            1. CIBIL score/s of all the owners/ partners/ directors of the company. <br />
            2. GSTIN filing frequency and volume of the respective company.
            </Text>
            <Text>(NOTE: next page will cover the commercial details)</Text>
            </Card>
          </Col>
        </Row>
        
      </Col>
    </>
  );
};

export default React.memo(AboutMobCredit);
