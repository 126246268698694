import React from "react";
import { Col, Typography, Row, Card } from "antd";
import "./SellerMobCreditOnboarding.css";
import Place1Logo from "../../../assets/seller/icons/placeorder1.webp";
import Place2Logo from "../../../assets/seller/icons/placeorder2.webp";
import Place3Logo from "../../../assets/seller/icons/placeorder3.webp";
import Place4Logo from "../../../assets/seller/icons/placeorder4.webp";
import Place5Logo from "../../../assets/seller/icons/placeorder5.webp";
import Place6Logo from "../../../assets/seller/icons/placeorder6.webp";
const { Text } = Typography;

const howtouse = (props) => {
  //   const {

  //   } = props;
  // eslint-disable-next-line

  return (
    <>
      <Col className="mt-4">
        <Card className="card-content-normal">
          <Typography.Title level={5}> 
            How to place order through mobCREDIT
          </Typography.Title>
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <img src={Place1Logo} alt="" style={{width:"100%"}}></img>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{display: "flex",alignItems:"center"}}>
              <Text className="text-comission-sub-bold">1. Click on “Add new store sale” 
                <br/>
                <Col className="text-comission-sub">You can find it on the “Dashboard” page</Col>
                </Text>
             
            </Col>
          </Row>
          <Row gutter={16} className="mt-4">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <img src={Place2Logo} alt="" style={{width:"100%"}}></img>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{display: "flex",alignItems:"center"}}>
              <Text className="text-comission-sub-bold">2. View customer details<br/>
              <Text className="text-comission-sub">
                You can view the customer details and the mobCREDIT status on
                “Store sale” page
              </Text>
              </Text>
            </Col>
          </Row>
          <Row gutter={16} className="mt-4">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <img src={Place3Logo} alt="" style={{width:"100%"}}></img>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{display: "flex",alignItems:"center"}}>
              <Text className="text-comission-sub-bold">3. Order through mob credit<br/>
              <Text className="text-comission-sub">
                Place the order through mobCREDIT and get paid in T+3 days
              </Text>
              </Text>
            </Col>
          </Row>
          <Row gutter={16} className="mt-4">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <img src={Place5Logo} alt="" style={{width:"100%"}}></img>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{display: "flex",alignItems:"center"}}>
              <Text className="text-comission-sub-bold">4. View mobCREDIT details<br/>
              <Text className="text-comission-sub">
              Place the order through mobCREDIT and get paid in T+2 working days. 1.75% of total mobCREDIT amount used will be charged as Line of credit fees.
              </Text>
              </Text>
            </Col>
          </Row>
          <Row gutter={16} className="mt-4">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <img src={Place6Logo} alt="" style={{width:"100%"}}></img>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{display: "flex",alignItems:"center"}}>
              <Text className="text-comission-sub-bold">5. Share payment link with customer<br/>
              <Text className="text-comission-sub">
              For customer to CONFIRM using the link and mobile OTP.
              </Text>
              </Text>
            </Col>
          </Row>
        </Card>

        <Card className="card-content-normal mt-4">
          <Typography.Title level={5}>
            mobCREDIT activation for customers
          </Typography.Title>
          <Row gutter={16} className="">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <img src={Place4Logo} alt="" style={{width:"100%"}}></img>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{display: "flex",alignItems:"center"}}>
              <Text className="text-comission-sub-bold">Click on “Apply now”<br/>
              <Text className="text-comission-sub">
                You can request for mobCREDIT for your customers from “Store
                order” page or on “Dashboard”
              </Text>
              </Text>
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
};

export default React.memo(howtouse);
