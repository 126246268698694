import React, { useEffect, useState } from "react";
import "./payables.css";
import { Button, Col, Row, Typography, Modal } from "antd";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import { Link } from "react-router-dom";
import { ReactComponent as EditIcon } from "../../../assets/seller/icons/edit-2.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/seller/icons/delete.svg";
import cancelmodalicon from "../../../assets/seller/icons/cancelmodal.svg";
// import TableSkeleton from "../../components/skeleton/TableSkeleton";
// import { useDispatch, useSelector } from "react-redux";
// import { getmanageOrdersTableData } from "../../redux/slices/manageOrders/action";
// import { manageOrdersDataSelector } from "../../redux/slices/manageOrders/selector";
import {
  // dateFormat,
  // eslint-disable-next-line
  handlePdfDownload,
  // eslint-disable-next-line
  onlyDateFormat,
  dateFormat,
  // handlePdfDownload,
} from "../../../commonFuntions/CommonUtilFunctions";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
// import UploadPaymentModel from "../../components/Modal/SettlementModal/UploadPaymentModel";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";

// import ConfirmPaymentModal from "../../../components/Modal/SettlementModal/AddPaymentModal/ConfirmPaymentModal";
import { useLazyGetDownloadPdfPaymentQuery } from "../../../apis/Seller/SellerSettlementAPI";
import dayjs from "dayjs";
// eslint-disable-next-line
import customParseFormat from "dayjs/plugin/customParseFormat";
import { baseUrl } from "../../../commonUtils/commonUtils";
import AddPaymentModal from "../../../components/SellerComponents/SellerPayable/AddPaymentModal/AddPaymentModal";
import AddNewCustomerModal from "../SellerStoreSales/AddNewCustomerModal/AddNewCustomerModal";
import { useDeleteCustomerMutation } from "../../../apis/Seller/SellerPayablesAPI";
const { Text } = Typography;
const handleTableData = (data) => {
  let tableData = [];
  data?.map((item) => {
    tableData.push({
      ...item,
      id: item?.id,
      key: item?.id,
      date: item?.created_at,
      payment_ref_no: item?.payment_ref_no,
      settlement_amount: item?.settlement_amount,
    });
    return null;
  });
  return tableData;
};

const SellerPayableDetails = (props) => {
  const {
    data,
    getSettlementByIDAPI,
    seller_name,
    //handleRefetch,
  } = props;

  // const dispatch = useDispatch();
  // const selectorData = useSelector(manageOrdersDataSelector);
  const [editDataVal, setEditDataVal] = useState({});
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [dateSearch, setDateSearch] = useState({
    start_date: "",
    end_date: "",
  });
  // eslint-disable-next-line
  const [titleText, settitleText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dateFilter, setDateFilter] = useState();
  const [editPayment, setEditPayment] = useState({
    state: false,
    check: true,
  });
  const [tableDataSource, setTableDataSource] = useState([]);
  // const [addPaymentApi] = useAddSettlementPaymentMutation();
  // const [updatePaymentApi] = useUpdateSettlementPaymentMutation();
  const [
    getDownloadPdfPaymentAPi,
    //  { data: pdfData }
  ] = useLazyGetDownloadPdfPaymentQuery();

  const [delCustomerdata] = useDeleteCustomerMutation();
  const DeleteCustomer = async (phone_number) => {
    const response = await delCustomerdata(phone_number);
    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      setIsDelConfirmOpen("");
      setDeleteCustomerNo("");
      getSettlementByIDAPI({ bmp_id: dateSearch?.bmp_id });
    } else if (response?.error) {
      showErrorToast(response?.error?.data?.message);
    }
  };

  const [formData, setFormData] = useState({});
  const [datePickerEmpty, setDatePickerEmpty] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [paymentMode, setPaymentMode] = useState();
  const [inputDate, setInputDate] = useState("");
  const [IsDelConfirmOpen, setIsDelConfirmOpen] = useState(false);
  const [deleteCustomerNo, setDeleteCustomerNo] = useState(false);
  useEffect(() => {
    if (data?.results?.length >= 0) {
      const tableData = data?.results ? handleTableData(data?.results) : "";
      setTableDataSource(tableData);
    }
    if (seller_name) {
      setFormData({ ...formData, seller_name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChange = (value) => {
    let formattedDate = null;

    switch (value) {
      case "Today":
        formattedDate = dayjs().format("YYYY-MM-DD");
        break;
      case "Yesterday":
        formattedDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        break;
      case "last_7_day":
        formattedDate = dayjs().subtract(6, "day").format("YYYY-MM-DD");
        break;
      case "last_30_day":
        formattedDate = dayjs().subtract(29, "day").format("YYYY-MM-DD");
        break;
      case "last_60_day":
        formattedDate = dayjs().subtract(59, "day").format("YYYY-MM-DD");
        break;
      case "Custom":
        formattedDate = "Custom";
        break;
      default:
        break;
    }

    const currentDate = dayjs().format("YYYY-MM-DD");
    if (value === "clear_date") {
      setDateSearch({
        start_date: "",
        end_date: "",
      });
    } else {
      setDateSearch({
        start_date: formattedDate,
        end_date: currentDate,
      });
    }
    setDateFilter(value);
  };
  const handleSearchOnDate = () => {
    if (dateFilter === "clear_date") {
      getSettlementByIDAPI({ bmp_id: dateSearch?.bmp_id });
    } else {
      getSettlementByIDAPI(dateSearch);
    }
  };

  const handleCustDeleteConfirm = (record) => {
    setDeleteCustomerNo(record?.phone_number);
    setIsDelConfirmOpen(true);
  };
  const handleCustEditModal = (record) => {
    setEditDataVal(record);
    setAddCustomerModal(true);
  };
  const handlePageChange = async (currPage) => {
    // const params = {
    //   page: currPage,
    // };
    setCurrentPage(currPage);
  };

  const handleOk = () => {
    setOpenUploadModal(false);
    setPaymentMode("");
    setInputDate("");
    setFileList([]);
    // setDatePickerEmpty(false);
    setFormData({ seller_name });
  };

  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates && dates.length === 2) {
      const startDateFormatted = dates[0].format("YYYY-MM-DD");
      const endDateFormatted = dates[1].format("YYYY-MM-DD");
      setDateSearch({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
      });
    }
  };

  const options = [
    {
      key: 1,
      value: "Today",
      label: "Today",
    },
    {
      key: 2,
      value: "Yesterday",
      label: "Yesterday",
    },
    {
      key: 3,
      value: "last_7_day",
      label: "Last 7 day",
    },
    {
      key: 4,
      value: "last_30_day",
      label: "Last 30 day",
    },
    {
      key: 5,
      value: "last_60_day",
      label: "Last 60 day",
    },
    {
      key: 6,
      value: "Custom",
      label: "Custom",
    },
    {
      key: 7,
      value: "clear_date",
      label: (
        <Col style={{ color: "#006644", fontWeight: "500" }}>Clear Date</Col>
      ),
    },
  ];
  // eslint-disable-next-line
  const handleDownload = async () => {
    const response = await getDownloadPdfPaymentAPi({
      ...(dateSearch?.start_date && { start_date: dateSearch?.start_date }),
      ...(dateSearch?.end_date && { end_date: dateSearch?.end_date }),
    });

    if (response?.status === "fulfilled") {
      window.open(`${baseUrl}${response?.data?.data?.url}`, "_self");
    }
  };
  // eslint-disable-next-line
  const downloadOptions = [
    {
      key: 1,
      value: "Excel",
      label: "Excel",
    },
  ];
  const columns = [
    {
      width: "35%",
      // fixed: "left",
      flex: 1,
      title: "Customer name",
      key: 1,
      // dataIndex: "name",
      render: (params, record) => {
        return (
          <>
            <Link
              to={`/seller/CustomerViewDetails/${params?.phone_number}`}
              style={{ display: "block" }}
            >
              <Text className="table_text">{params?.name}</Text>

              <br />
              <Text className="text-light-sm">
                {params ? dateFormat(params?.updated_at) : ""}
              </Text>
            </Link>
          </>
        );
      },
    },
    {
      // fixed: "left",
      flex: 2,
      // width: "150px",
      title: "Phone number",
      key: 2,
      dataIndex: "phone_number",
      className: "table_text",
    },
    {
      // fixed: "left",
      flex: 3,
      title: "Payables",
      // width: "250px",
      align: "right",
      key: 3,
      dataIndex: "amount",
      render: (params, record) => {
        if (params < 0) {
          return (
            <>
              <Text className="table_text text-right text-danger">
                ₹ {Number(params).toFixed(2)}
              </Text>
              <br />
              <Text className="text-light-sm">To give</Text>
            </>
          );
        } else {
        }
      },
    },
    {
      // fixed: "left",
      flex: 3,
      title: "Recievables",
      align: "right",
      // width: "250px",
      key: 3,
      dataIndex: "amount",
      render: (params, record) => {
        if (params > 0) {
          return (
            <>
              <Text className="table_text text-right text-success">
                ₹ {Number(params).toFixed(2)}
              </Text>
              <br />
              <Text className="text-light-sm">To receive</Text>
            </>
          );
        } else {
        }
      },
    },
    {
      // fixed: "left",
      flex: 3,
      title: "",
      align: "right",
      key: 3,

      render: (params, record) => {
        return (
          <>
            <Col
              style={{
                color: "#0354a3",
                cursor: "pointer",
                // width: "110px",
                textAlign: "right",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Text onClick={() => handleCustDeleteConfirm(record)}>
                <DeleteIcon />
              </Text>{" "}
              |
              <Text onClick={() => handleCustEditModal(record)}>
                <EditIcon />
              </Text>
            </Col>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Col>
        <Row className="action-container">
          <Col className="action-sub-container">
            <CustomSelect
              placeholder={"Date"}
              width={"150px"}
              options={options}
              handleChange={handleChange}
              value={dateFilter}
            />
            {dateFilter === "Custom" && (
              <DateRangePicker
                onChange={handleChangeRangePicker}
                format={"DD-MMM-YYYY"}
              />
            )}
            <Button
              size="large"
              style={{
                backgroundColor: "#0354a3",
                color: "#fff",
                fontWeight: 500,
                height: "48px",
              }}
              onClick={() => handleSearchOnDate()}
            >
              Search
            </Button>
          </Col>
          <Col className="action-sub-container">
            {/* <CustomSelect
              placeholder={"Download"}
              width={"150px"}
              height={"46px"}
              options={downloadOptions}
              handleChange={handleDownload}
              value={"Download"}
            /> */}
            {/* <Button
              size="large"
              className="btn-outline-danger"        
              onClick={() => {
                setOpenUploadModal(true);
                setDatePickerEmpty(true);
                setEditPayment({ state: false, check: true });
                settitleText("Give money");
              }}
            >
              GIVE MONEY
            </Button>
            <Button
              size="large"
              className="btn-success"              
              onClick={() => {
                setOpenUploadModal(true);
                setDatePickerEmpty(true);
                setEditPayment({ state: false, check: true });
                settitleText("Collect money");
              }}
            >
              COLLECT MONEY
            </Button> */}
          </Col>
        </Row>
        <Col>
          <CustomTableWithPagination
            className="order-list-table no-wrap"
            tableDataSource={tableDataSource || []}
            tableColumns={columns}
            // locale={{
            //   emptyText: isLoading ? <TableSkeleton length={30} /> : "",
            // }}
            scroll={{ x: "100%" }}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            itemsPerPage={30}
            totalEntries={data?.pagination?.total_entries}
          />
        </Col>
      </Col>
      <AddPaymentModal
        openAddFile={openUploadModal}
        titleText={titleText}
        setOpenAddFile={setOpenUploadModal}
        handleOk={handleOk}
        formData={formData}
        setFormData={setFormData}
        datePickerEmpty={datePickerEmpty}
        setDatePickerEmpty={setDatePickerEmpty}
        fileList={fileList}
        setFileList={setFileList}
        paymentMode={paymentMode}
        setPaymentMode={setPaymentMode}
        seller_name={seller_name}
        editPayment={editPayment}
        setEditPayment={setEditPayment}
        setOpenUploadModal={setOpenUploadModal}
        setInputDate={setInputDate}
        inputDate={inputDate}
      />

      <Modal
        title=""
        visible={IsDelConfirmOpen}
        onCancel={() => setIsDelConfirmOpen(false)}
        footer={[
          <div className="modal-footer d-block text-center footer-bottom-border-line">
            <Button
              key="cancel"
              className="btn-cancel order-cancel-btn-style btnI"
              onClick={() => setIsDelConfirmOpen(false)}
            >
              CANCEL
            </Button>

            <Button
              key="gotIt"
              type="primary"
              className="btn-theme order-got-btn-style btnI"
              onClick={() => DeleteCustomer({ phone_number: deleteCustomerNo })}
            >
              DELETE CUSTOMER
            </Button>
          </div>,
        ]}
      >
        <Col
          style={{ height: "250px" }}
          className="create-modal-container text-center"
        >
          <img
            width={100}
            height={100}
            src={cancelmodalicon}
            style={{}}
            alt=""
            className="mt-4"
          />
          <Col className="create-modal-sub-container ">
            <Text className="create-modal-recommed-text">
              Are you sure you want to delete this customer?
            </Text>{" "}
          </Col>
        </Col>
      </Modal>

      <AddNewCustomerModal
        addCustomerModal={addCustomerModal}
        setAddCustomerModal={setAddCustomerModal}
        // handleApi={createNewUserAPI}
        // setIsModalOpen={setIsModalOpen}
        editData={editDataVal}
        // initialFormData={initialFormData}
        // formData={formData}
        // setFormData={setFormData}
        // addressListModal={showModal}
      />
    </>
  );
};

export default SellerPayableDetails;
