import { configureStore } from "@reduxjs/toolkit";
import { rfqListAPI } from "./apis/rfqList";
// import { setupListeners } from "@reduxjs/toolkit/query/react";
import rfqListReducer from "./redux/slices/rfqList/action";
import { createQuoteAPI } from "./apis/createQuote";
import { walletAPI } from "./apis/wallet";
import { createOrderAPI } from "./apis/createOrder";
import { manageLibraryAPI } from "./apis/manageLibrary";
import { manageOrdersAPI } from "./apis/ManageOrders";
import { settlementAPI } from "./apis/settlement";
import { sellerCatalogueAPI } from "./apis/sellerCatalogue";
import createQuoteReducer from "./redux/slices/createQuote/action";
import componentStateReducer from "./redux/slices/componentState/action";
import createOrderReducer from "./redux/slices/createOrder/action";
import publishQuoteReducer from "./redux/slices/publishQuote/action";
import loginAuthReducer from "./redux/slices/loginAuth/action";
import manageLibraryReducer from "./redux/slices/manageLibrary/action";
import manageOrdersReducer from "./redux/slices/manageOrders/action";
import settlementReducer from "./redux/slices/settlement/action";
import sellerCatalogueReducer from "./redux/slices/sellerCatalogue/action";
import publishQuoteAPI from "./apis/publishQuote";
import authenticationAPI from "./apis/login";
import sellerAuthenticationAPI from "./apis/Seller/SellerLogin";
import SellerRegistrationAPI from "./apis/Seller/SellerRegistration";
import customersAPI from "./apis/customer";
import dashboardAPI from "./apis/dashboard";
import userManagementAPI from "./apis/userManagement";
import formsAPI from "./apis/forms";
import reportsAPI from "./apis/reports";
import SellerdashboardAPI from "./apis/Seller/sellerDashboard";
import SellerOrdersAPI from "./apis/Seller/SellerOrdersApi";
import SellerAccountsAPI from "./apis/Seller/SellerAccountsAPI";
import SellerReportsAPI from "./apis/Seller/SellerReportsAPI";
import { SellerSettlementAPI } from "./apis/Seller/SellerSettlementAPI";
import { SellerStoreSalesAPI } from "./apis/Seller/SellerStoreSalesAPI";
import SellerPayablesAPI from "./apis/Seller/SellerPayablesAPI";
import SellerCommercialsAPI from "./apis/Seller/SellerCommercialsAPI";
import RequestMobCreditApi from "./apis/Seller/RequestMobCreditApi";


export const store = configureStore({
  reducer: {
    [rfqListAPI.reducerPath]: rfqListAPI.reducer,
    [createQuoteAPI.reducerPath]: createQuoteAPI.reducer,
    [walletAPI.reducerPath]: walletAPI.reducer,
    [createOrderAPI.reducerPath]: createOrderAPI.reducer,
    [manageLibraryAPI.reducerPath]: manageLibraryAPI.reducer,
    [manageOrdersAPI.reducerPath]: manageOrdersAPI.reducer,
    [settlementAPI.reducerPath]: settlementAPI.reducer,
    [publishQuoteAPI.reducerPath]: publishQuoteAPI.reducer,
    [authenticationAPI.reducerPath]: authenticationAPI.reducer,
    [sellerAuthenticationAPI.reducerPath]: sellerAuthenticationAPI.reducer,
    [SellerRegistrationAPI.reducerPath]: SellerRegistrationAPI.reducer,    
    [sellerCatalogueAPI.reducerPath]: sellerCatalogueAPI.reducer,
    [customersAPI.reducerPath]: customersAPI.reducer,
    [dashboardAPI.reducerPath]: dashboardAPI.reducer,
    [formsAPI.reducerPath]: formsAPI.reducer,
    [userManagementAPI.reducerPath]: userManagementAPI.reducer,
    [reportsAPI.reducerPath]: reportsAPI.reducer,
    [SellerReportsAPI.reducerPath]: SellerReportsAPI.reducer,
    [SellerSettlementAPI.reducerPath]: SellerSettlementAPI.reducer,
    [SellerStoreSalesAPI.reducerPath]: SellerStoreSalesAPI.reducer,
    rfqList: rfqListReducer,
    createQuote: createQuoteReducer,
    componentState: componentStateReducer,
    createOrder: createOrderReducer,
    publishQuote: publishQuoteReducer,
    loginAuthReducer: loginAuthReducer,
    manageLibraryReducer: manageLibraryReducer,
    manageOrdersReducer: manageOrdersReducer,
    settlementReducer: settlementReducer,
    sellerCatalogueReducer: sellerCatalogueReducer,

    [SellerdashboardAPI.reducerPath]: SellerdashboardAPI.reducer,
    [SellerOrdersAPI.reducerPath]: SellerOrdersAPI.reducer,
    [SellerAccountsAPI.reducerPath]: SellerAccountsAPI.reducer,
    [SellerPayablesAPI.reducerPath]: SellerPayablesAPI.reducer,
    [SellerCommercialsAPI.reducerPath]: SellerCommercialsAPI.reducer,
    [RequestMobCreditApi.reducerPath]: RequestMobCreditApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      rfqListAPI.middleware,
      createQuoteAPI.middleware,
      createOrderAPI.middleware,
      manageLibraryAPI.middleware,
      manageOrdersAPI.middleware,
      settlementAPI.middleware,
      publishQuoteAPI.middleware,
      authenticationAPI.middleware,
      sellerAuthenticationAPI.middleware,
      sellerCatalogueAPI.middleware,
      customersAPI.middleware,
      walletAPI.middleware,
      dashboardAPI.middleware,
      userManagementAPI.middleware,
      formsAPI.middleware,
      reportsAPI.middleware,
      SellerdashboardAPI.middleware,
      SellerOrdersAPI.middleware,
      SellerAccountsAPI.middleware,
      SellerReportsAPI.middleware,
      SellerSettlementAPI.middleware,
      SellerStoreSalesAPI.middleware,
      SellerPayablesAPI.middleware,
      SellerRegistrationAPI.middleware,
      SellerCommercialsAPI.middleware,
      RequestMobCreditApi.middleware,
    ),
});
