import React, { useEffect, useState } from "react";
import "./SellerCustomerProfile.css";
import {
  Button,
  //  Card,
  Col,
  Row,
  Skeleton,
  Typography,
  Space,
  Input,
  Select,
} from "antd";
// import CustomSelect from "../../../../components/SelectableDropdown/CustomSelect/CustomSelect";
// eslint-disable-next-line
import { DownOutlined } from "@ant-design/icons";

import { ReactComponent as WarningIcon } from "../../../../assets/seller/icons/warning.svg";
// import { ReactComponent as TableInfo } from "../../../../assets/icons/TableInfo.svg";
import { ReactComponent as MobCreditWhiteIcon } from "../../../../assets/icons/mobCreditWhiteIcon.svg";

// import { ReactComponent as SearchIcon } from "../../../../assets/icons/search-3.svg";
// import { ReactComponent as FileIcon } from "../../../../assets/icons/file.svg";

import {
  // eslint-disable-next-line
  ActivityContainer,
  // eslint-disable-next-line
  ActivityText,
  AddressCard,
  AddressContainer,
  CardCheckbox,
  CardContainer,
  CardContent,
  CardHeader,
  CardHeaderText,
  ContentText,
  GST_TEXT,
  QuoteHeader,

  // RFQDate,
  // RFQDetailContainer,
  // RFQFileContainer,
  // RFQFileText,
  // RFQHeading,
  // RFQID,
  // RFQMainContainer,
  // RFQTag,
  // RowContainer,
} from "../../../Request/RFQ/CreateQuote/CreateQuoteProfileSection/ProfileStyled";

import {
  checkPhoneNumberValidation,
  StoreSalestype,
} from "../../../../commonUtils/commonUtils";
import SellerCustomview from "../../../../components/SellerComponents/SellerCustomview/SellerCustomview";
// import { colorMapping } from "../../../../commonFuntions/CommonUtilFunctions";
import EditAddressModal from "../EditAddressModal/EditAddressModal";
import {
  addressFlagSelector,
  billingAddressSelector,
  deliveryAddressSelector,
} from "../../../../redux/slices/createQuote/selector";
import { useDispatch, useSelector } from "react-redux";
// import CreateOrderDrawer from "../../../../components/Drawer/CreateOrderDrawer/CreateOrderDrawer";
import SearchSuggestionInput from "../../../../components/Input/SellerInput/SearchSuggestionInput/SearchSuggestionInput";
import {
  useCreateNewUserMutation,
  useLazyGetCreateOrderUsersQuery,
  useLazyGetEditOrderAddressQuery,
  useCreateCreditCustomerApiMutation,
} from "../../../../apis/Seller/SellerStoreSalesAPI";
import {
  getBillingAddress,
  getDeliveryAddress,
  getRFQUserById,
  updateAddressFlag,
} from "../../../../redux/slices/createQuote/action";
import CustomDatePicker from "../../../ManageOrders/OrderDetails/OrderModals/AddPaymentModal/DatePicker/CustomDatePicker";
// import { useNavigate } from "react-router-dom";
// import CustomRFQSection from "../../../../components/CustomContainer/CustomRFQSection/CustomRFQSection";
import AddNewCustomerModal from "../AddNewCustomerModal/AddNewCustomerModal";
// import ActivityLogSidebar from "../../../Request/RFQ/CreateQuote/ActivityLogSidebar";
import { rupifiStatusCheck } from "../../../../commonUtils/commonUtils";
import dayjs from "dayjs";
const SellerCustomerProfile = (props) => {
  // const navigate = useNavigate();
  const {
    editCondition,
    editData,
    quoteData,
    handleSetSelector,
    userData,
    editQuoteData,
    cancelOrder,
    handleCreateOrder,
    loading,
    orderButtonText,
    addCustName,
    addCustMobileNo,
    addCustEmail,
    addCustGSTIN,
    setaddCustName,
    setaddCustMobileNo,
    setaddCustEmail,
    setaddCustGSTIN,
    TempaddressListData,
    isCreditOrder,
    selectedSaleType,
    setSelectedSaleType,
    setDatePickerEmpty,
  } = props;
    
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const delivery_address = useSelector(deliveryAddressSelector);
  const addressFlag = useSelector(addressFlagSelector);
  // eslint-disable-next-line
  const billing_address = useSelector(billingAddressSelector);
  const { Title, Text } = Typography;
  // const [drawerVisible, setDrawerVisible] = useState(false);
  const [getUserApi, { data: usersList }] = useLazyGetCreateOrderUsersQuery();
  const [gstError, setGstError] = useState("");
  const [createNewUserAPI] = useCreateNewUserMutation();
  const [createMobCustomerAPI, { data: userresponse }] =
    useCreateCreditCustomerApiMutation();
  const [getEditOrderAddressAPI, { data: addressListData }] =
    useLazyGetEditOrderAddressQuery();
    const bmp_credit_enable = localStorage.getItem("bmp_credit_enable") === "true";
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const handleSaveBillingAddress = (e) => {
    const checked = e.target.checked;
    if (checked) {
      dispatch(getBillingAddress(delivery_address));
    } else {
      dispatch(getBillingAddress({}));
    }
  };
  // eslint-disable-next-line
  // const showDrawer = () => {
  //   setDrawerVisible(true);
  // };
  const handleShowAddCustomerModal = () => {
    setAddCustomerModal(true);
  };
  const handleRemoveSelector = () => {
    handleSetSelector({});
    dispatch(getBillingAddress({}));
    dispatch(getDeliveryAddress({}));
  };
  const handleAddressAPI = (item) => {
    getEditOrderAddressAPI({ phone_number: item?.phone_number });
    dispatch(getRFQUserById(item));
  };
  const handleSaleTypeChange = (value) => {
    setSelectedSaleType(value);
  };
  const handleChangeDate = (value) => {
    setDatePickerEmpty(value?.Orderdate);
  };

  useEffect(() => {
    // console.log("🚀 ~ useEffect ~ userresponse:", userresponse);
    if (userresponse) {
      handleSetSelector(userresponse?.data);
    
      if (userresponse?.data) {
        if (userresponse?.data?.address?.length > 0) {
          handleAddressAPI(userresponse?.data);
        }
      }
      if (userresponse?.data?.name) {
        setaddCustName(userresponse?.data?.name);
        setValue(userresponse?.data?.name);
      }
      if (userresponse?.data?.phone_number) {
        setaddCustMobileNo(userresponse?.data?.phone_number);
      }
      if (userresponse?.data?.email) {
        setaddCustEmail(userresponse?.data?.email);
      }
      if (userresponse?.data?.gst_number) {
        setaddCustGSTIN(userresponse?.data?.gst_number);
      }
    }
    // eslint-disable-next-line
  }, [userresponse]);

  useEffect(() => {
    if (userData?.phone_number && addressFlag) {
      getEditOrderAddressAPI({ phone_number: userData?.phone_number });
      dispatch(updateAddressFlag(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressFlag, addCustMobileNo]);
  // useEffect(() => {

  //   if(editCondition && addCustMobileNo)
  //   {
  //     console.log("🚀 ~ useEffect ~ editCondition:", editCondition)
  //     console.log("addCustMobileNo123",addCustMobileNo)
  //     getEditOrderAddressAPI({ phone_number: addCustMobileNo});
  //     dispatch(updateAddressFlag(false));
  //  }

  //    // eslint-disable-next-line react-hooks/exhaustive-deps
  //  }, [addCustMobileNo]);

  useEffect(() => {
    if (
      userData?.mobCredit?.account_status === "ACTIVE" &&
      addressListData?.data?.length
    ) {
      let mob_credit_address_data = addressListData?.data?.filter(
        (item) => item?.mob_credit
      );
      dispatch(
        getBillingAddress(
          mob_credit_address_data?.length ? mob_credit_address_data[0] : {}
        )
      );
    } else {
      // dispatch(getBillingAddress({}));
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressListData, userData]);

  const handleInputChange = (field, value) => {
    switch (field) {
      case "gst":
        if (value !== "") {
          const gstinRegex = /^[0-9]{2}[A-Za-z]{3}[A-Za-z0-9]{10}$/;
          if (!gstinRegex.test(value)) {
            setaddCustGSTIN(value);
            setGstError("Invalid GSTIN format");
          } else {
            setGstError("");
            setaddCustGSTIN(value);
          }
        } else {
          setaddCustGSTIN(value);
          setGstError("");
        }
        break;
      case "PO_Number":
        setaddCustName(value);
        // handle PO Number input change
        break;
      case "PhoneNumber":
        setaddCustMobileNo(value);
        if (checkPhoneNumberValidation(value)) {
          if (value && value.length === 10) {
            createMobCustomerAPI({ phone_number: value });
          }
        }
        // handle Phone Number input change
        break;
      case "Email":
        setaddCustEmail(value);
        // handle Email input change
        break;
      case "Name":
        setaddCustName(value);
        if (checkPhoneNumberValidation(value)) {
          if (value && value.length === 10) {
            createMobCustomerAPI({ phone_number: value });
          }
        }
        // handle Email input change
        break;
      default:
        break;
    }
  };

  // const handleCreateNewUser = () => {};
  return (
    <>
      <Row className="mt-2" style={{ gap: "40px", marginBottom: "48px" }}>
        <QuoteHeader>
          <Title level={3} style={{ margin: 0, color: "#0a243f" }}>
            {editCondition ? "Update order" : ""}
          </Title>
          {/* <ActivityContainer onClick={showDrawer}>
            <ClockIcon style={{ height: "20px", width: "20px" }} />
            <ActivityText level={5}>Activity log</ActivityText>
          </ActivityContainer> */}
          {/* {editCondition && (
            <>
              <ActivityContainer onClick={showDrawer}>
                <ClockIcon style={{ height: "20px", width: "20px" }} />
                <ActivityText level={5}>Activity log</ActivityText>
              </ActivityContainer>
            </>
          )} */}
        </QuoteHeader>
      </Row>
      <Row className="mb-4">
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          {editData?.isLoading || quoteData?.isLoading ? (
            <Col className="skeleton-input">
              <Skeleton.Input active size={"small"} block={true} />
            </Col>
          ) : (
            <>
              <Space direction="vertical">
                <Typography.Title level={5}>Order date</Typography.Title>
                <Col className="date-picker-container">
                  {/* <Input
                    name="Order date"
                    className="input-style"
                    readOnly={true}
                    value={dayjs(new Date()).format("DD MMM YYYY")}
                  ></Input> */}
                  <CustomDatePicker
                    name="Orderdate"
                    className=""
                    value={dayjs(new Date()).format("DD MMM YYYY")}
                    handleChildFieldChange={handleChangeDate}
                    maxDate={dayjs(new Date()).format("DD MMM YYYY")}
                    // datePikerEmpty={datePikerEmpty}
                  />
                </Col>
              </Space>
            </>
          )}
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12} className="col-right">
          <Button
            className="create-order-cancel-button mr-2"
            onClick={() => cancelOrder(editCondition ? "updateOrder" : "")}
          >
            CANCEL
          </Button>
          <Button
            className="create-order-confirm-button"
            onClick={handleCreateOrder}
            style={{
              padding: "13px 60px 13px 60px",
              fontFamily: "Inter",
              fontWeight: "500",
              backgroundColor: isCreditOrder ? "#535dd8" : "#015fe5",
              color: "#fff",
              height: "48px",
              borderRadius: "8px",
            }}
            disabled={
              editCondition || loading
                ? !editQuoteData?.id || loading
                : !userData?.id || loading
            }
            loading={loading}
          >
            {orderButtonText}
          </Button>
        </Col>
      </Row>
      <Row style={{ gap: "91px", marginBottom: "48px" }}>
        <Col className="input-actions-container">
          <Col style={{ width: "100%" }}>
            {editData?.isLoading || quoteData?.isLoading ? (
              <Col className="skeleton-input">
                <Skeleton.Input active size={"small"} block={true} />
              </Col>
            ) : (
              <>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Typography.Title level={5}>
                      Name <span className="text-danger">*</span>
                    </Typography.Title>
                    <SearchSuggestionInput
                      size={"large"}
                      placeholder={"Searching with name/phone number"}
                      getLibraryApi={getUserApi}
                      list={usersList?.data?.results}
                      // editValue={editQuoteData?.user?.full_name}
                      editValue={editQuoteData?.billing_address?.name}
                      disabled={editCondition}
                      handleSetSelector={handleSetSelector}
                      showModal={handleShowAddCustomerModal}
                      editCondition={editCondition}
                      handleRemoveSelector={handleRemoveSelector}
                      addressApi={handleAddressAPI}
                      handleInputChange={handleInputChange}
                      setaddCustName={setaddCustName}
                      setaddCustMobileNo={setaddCustMobileNo}
                      setaddCustEmail={setaddCustEmail}
                      setaddCustGSTIN={setaddCustGSTIN}
                      value={value}
                      setValue={setValue}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Typography.Title level={5}>Sale type </Typography.Title>
                    {/* <CustomSelect
                      placeholder={"Sale type"}
                      options={StoreSalestype}
                      width={"100%"}
                      // value={selectedSaleType}
                      disabled={editCondition}
                      customStyle={"ddl-style"}
                      onChange={handleSaleTypeChange}
                    /> */}
                    <Select
                      className={"ddl-style"}
                      value={selectedSaleType} // uncommented and passed the selectedSaleType value
                      placeholder={"Sale type"}
                      style={{
                        width: "100%",
                        height: "46px",
                      }}
                      onChange={handleSaleTypeChange}
                      options={StoreSalestype}
                      disabled={editCondition}
                      //loading={loading}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Typography.Title level={5}>
                      Purchase order ref no (optional)
                    </Typography.Title>
                    <Input
                      className="input-style"
                      name="PO_Number"
                      placeholder=""
                      onChange={(e) =>
                        handleInputChange("PO_Number", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]} className="mt-4">
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Typography.Title level={5}>
                      Phone number(10 digits){" "}
                      <span className="text-danger">*</span>
                    </Typography.Title>
                    <Input
                      name="PhoneNumber"
                      disabled={editCondition}
                      maxLength={10}
                      value={
                        editCondition
                          ? editQuoteData?.cutomer?.phone_number
                          : addCustMobileNo
                        // : userData?.phone_number || addCustMobileNo
                      }
                      className="input-style"
                      placeholder=""
                      // onKeyDown={(e) => {
                      //   if (
                      //     (e.key < "0" || e.key > "9") &&
                      //     e.key !== "Backspace" &&
                      //     e.key !== "ArrowLeft" &&
                      //     e.key !== "ArrowRight" &&
                      //     e.key !== "Delete" &&
                      //     e.key !== "Tab" &&
                      //     !(e.ctrlKey && (e.key === "c" || e.key === "v")) // allow Ctrl+C and Ctrl+V
                      //   ) {
                      //     e.preventDefault();
                      //   }
                      // }}
                      onChange={(e) =>
                        handleInputChange("PhoneNumber", e.target.value)
                      }
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Typography.Title level={5}>Email</Typography.Title>
                    <Input
                      name="Email"
                      disabled={editCondition}
                      value={
                        editCondition
                          ? editQuoteData?.cutomer?.email
                          : userData?.email || addCustEmail
                      }
                      className="input-style"
                      placeholder=""
                      onChange={(e) =>
                        handleInputChange("Email", e.target.value)
                      }
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Typography.Title level={5}>
                      GSTIN (optional)
                    </Typography.Title>
                    <Input
                      name="GSTIN"
                      disabled={editCondition}
                      value={
                        editCondition
                          ? editQuoteData?.cutomer?.gst_number
                          : userData?.GST || addCustGSTIN
                      }
                      maxLength={15}
                      className="input-style"
                      placeholder=""
                      onChange={(e) => handleInputChange("gst", e.target.value)}
                    />
                    {gstError && <p style={{ color: "red" }}>{gstError}</p>}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Col>
      </Row>
      <Row style={{ gap: "48px" }}>
        {userData?.id && (
          <>
            <SellerCustomview
              isLoading={editData?.isLoading || quoteData?.isLoading}
              editCondition={editCondition}
              data={editCondition ? editQuoteData?.cutomer : userData}
              check={"editOrder"}
              // data={editCondition ? editQuoteData : selector}
            />
            {bmp_credit_enable &&(
              <>
            {rupifiStatusCheck.includes(
              userData?.mobCredit?.account_status ||
                editQuoteData?.user_details?.rupifiDetails?.account_status ||
                editQuoteData?.user?.rupifiDetails?.account_status
            ) && (
              <>
              {billing_address?.mob_credit &&(
                <Col
                className="credit-strip-font"
                  style={{
                    width: "100%",
                    height: "50px",
                    borderRadius: "16px",
                    border: "solid 1px rgba(0, 0, 0, 0)",
                    backgroundImage:
                      (userData?.mobCredit?.account_status ||
                        editQuoteData?.user_details?.rupifiDetails
                          ?.account_status ||
                        editQuoteData?.user?.rupifiDetails?.account_status) ===
                        "ACTIVE"
                        ? "linear-gradient(95deg, #e8f6c6 25%, #d1f4fa 65%)"
                        : "",
                    backgroundColor:
                      (userData?.mobCredit?.account_status ||
                        editQuoteData?.user_details?.rupifiDetails
                          ?.account_status ||
                        editQuoteData?.user?.rupifiDetails?.account_status) ===
                        "ACTIVE" 
                        ? "transparent"
                        : "#ffc5b7",
                    padding: "0px 16px",
                    display: "flex",
                    alignItems: "center",                    
                    fontWeight: 500,
                    color: "#1e1e20",
                  }}
                >
                  
                  {(userData?.mobCredit?.account_status ||
                    editQuoteData?.user_details?.rupifiDetails
                      ?.account_status ||
                    editQuoteData?.user?.rupifiDetails?.account_status) ===
                  "ACTIVE"
                    ? "For mobCREDIT to be active during checkout please select the registered RUPIFI billing"
                    : "mobCREDIT account is not active for this user."}
                    
                </Col>
                )}
              </>
              
            )}
            {rupifiStatusCheck.includes(
              userData?.mobCredit?.account_status ||
                editQuoteData?.user_details?.rupifiDetails?.account_status ||
                editQuoteData?.user?.rupifiDetails?.account_status
            ) && (
              <>
              {!billing_address?.mob_credit ? (
  <Row style={{ width: "100%" ,marginBottom: "-40px"}}>
    <Col xs={24} sm={12} md={12} lg={12} xl={12} />
    <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{
      width: "50%",
      height: "50px",
      borderRadius: "8px",
      border: "solid 1px rgba(0, 0, 0, 0)",
      // backgroundImage: billing_address?.mob_credit ? "linear-gradient(95deg, #e8f6c6 25%, #d1f4fa 65%)" : "",
      backgroundColor:  "#ffc5b7",
      padding: "0px 16px",
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
      fontWeight: 500,
      color: "#1e1e20",
      gap:"6px"     
    }}>
      <WarningIcon/>
      Select mobCREDIT tagged billing address to proceed
      
    </Col>
  </Row>
) : null}
              </>
              
            )}
            </>
            )}
            
            
          </>
        )}
        <AddressContainer className="address-card">
          {editData?.isLoading || quoteData?.isLoading ? (
            <>
              <Skeleton
                active
                block
                size={"large"}
                style={{
                  width: "50%",
                  padding: "20px",
                  backgroundColor: "#fff",
                }}
              />
            </>
          ) : (
            <AddressCard className="width-100" bordered={false}>
              <CardContainer>
                <CardHeader>
                  <Col
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <CardHeaderText level={4}>Delivery address<span style={{fontSize:"14px",marginLeft:"4px",fontWeight:"400"}}>(optional)</span></CardHeaderText>
                    {/* {!delivery_address?.id && <TableInfo />} */}
                  </Col>
                  <EditAddressModal
                    address={"Delivery"}
                    editState={editCondition}
                    addressID={delivery_address?.id}
                    deliveryAddress={
                      editCondition
                        ? TempaddressListData?.data || []
                        : addressListData?.data
                    }
                    // deliveryAddress={
                    //   editCondition
                    //     ? editQuoteData?.all_address?.delivery_address
                    //     : address?.delivery_address
                    // }
                    addCustName={addCustName}
                    addCustMobileNo={addCustMobileNo}
                    addCustEmail={addCustEmail}
                    addCustGSTIN={addCustGSTIN}
                    handleSetSelector={handleSetSelector}
                    getEditOrderAddressAPI={getEditOrderAddressAPI}
                    address1={addressListData?.data}
                  />
                </CardHeader>
                {delivery_address?.name ? (
                  <>
                   <span className="text-address-tag">
                    <CardContent>
                      <ContentText>{delivery_address?.name}</ContentText>

                      <ContentText>
                       
                        {delivery_address?.address_line_1},
                        {delivery_address?.address_line_2 && (
                          <> {delivery_address?.address_line_2}, </>
                        )}
                        {delivery_address?.state},{delivery_address?.city},{" "}
                        {delivery_address?.pincode}
                       
                      </ContentText>
                      <ContentText>
                        +91 {delivery_address?.phone_number}
                      </ContentText>
                    </CardContent>
                    <Col>
                      <CardCheckbox onClick={handleSaveBillingAddress}>
                        Billing address same as delivery address
                      </CardCheckbox>
                    </Col>
                    </span>
                  </>
                ) : (
                  <>
                    <Col>
                      <Text
                        style={{
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#0a243f",
                        }}
                      >
                        No address added
                      </Text>
                    </Col>
                  </>
                )}
              </CardContainer>
            </AddressCard>
          )}
          {editData?.isLoading || quoteData?.isLoading ? (
            <>
              <Skeleton
                active
                block
                size={"large"}
                style={{
                  width: "50%",
                  padding: "20px",
                  backgroundColor: "#fff",
                }}
              />
            </>
          ) : (
            <AddressCard className="width-100" bordered={false}>
              <CardContainer>
                <CardHeader>
                  <Col
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <CardHeaderText level={4}>Billing address<span style={{fontSize:"14px",marginLeft:"4px",fontWeight:"400"}}>(Required)</span></CardHeaderText>
                    {/* {!billing_address?.id && <TableInfo />} */}
                  </Col>
                  <EditAddressModal
                    address={"Billing"}
                    addressID={billing_address?.id}
                    deliveryAddress={
                      editCondition
                        ? TempaddressListData?.data || []
                        : addressListData?.data
                    }
                    // deliveryAddress={
                    //   editCondition
                    //     ? TempaddressListData
                    //     : addressListData?.data
                    // }
                    mob_credit_check={
                      userData?.rupifiDetails?.account_status ||
                      editQuoteData?.user_details?.rupifiDetails
                        ?.account_status ||
                      editQuoteData?.user?.rupifiDetails?.account_status
                    }
                    // }
                    addCustName={addCustName}
                    addCustMobileNo={addCustMobileNo}
                    addCustEmail={addCustEmail}
                    addCustGSTIN={addCustGSTIN}
                    handleSetSelector={handleSetSelector}
                    getEditOrderAddressAPI={getEditOrderAddressAPI}
                    bmp_credit_enable={bmp_credit_enable}
                    // deliveryAddress={
                    //   editCondition
                    //     ? editQuoteData?.all_address?.billing_address
                    //     : address?.billing_address
                    // }
                  />
                </CardHeader>
                {billing_address?.name ? (
                  <>
                   <span className="text-address-tag">
                    <CardContent>
                      <ContentText style={{ display: "flex", gap: "5px" }}>
                        {billing_address?.name}{" "}
                        {(billing_address?.mob_credit && bmp_credit_enable) && <MobCreditWhiteIcon />}
                      </ContentText>
                      {billing_address?.gst_number && (
                        <Col style={{ margin: "12px 0" }}>
                          <GST_TEXT>
                            GST NO: {billing_address?.gst_number}
                          </GST_TEXT>
                        </Col>
                      )}
                      <ContentText>
                        {billing_address?.address_line_1} ,{" "}
                        {billing_address?.address_line_2 && (
                          <>{billing_address?.address_line_2} , </>
                        )}
                        {billing_address?.state},{billing_address?.city}
                        {billing_address?.pincode}
                      </ContentText>
                      <ContentText>
                        +91 {billing_address?.phone_number}
                      </ContentText>
                    </CardContent>
                    </span>
                  </>
                ) : (
                  <>
                    <Col>
                      <Text
                        style={{
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#0a243f",
                        }}
                      >
                        No address added
                      </Text>
                    </Col>
                  </>
                )}
              </CardContainer>
            </AddressCard>
          )}
        </AddressContainer>
        {/* <Col style={{ width: "100%" }}>
          <CustomRFQSection
            title={"RFQ details"}
            isLoading={editData?.isLoading || quoteData?.isLoading}
            editCondition={editCondition}
            data={userData}
            // RFQ_ID={userId}
          />
        </Col> */}
      </Row>
      {/* <CreateOrderDrawer
        drawerVisible={drawerVisible}
        setDrawerVisible={setDrawerVisible}
      /> */}

      {/* <ActivityLogSidebar
        drawerVisible={drawerVisible}
        setDrawerVisible={setDrawerVisible}
        data={editQuoteData?.activity_logs}
      /> */}
      <AddNewCustomerModal
        addCustomerModal={addCustomerModal}
        setAddCustomerModal={setAddCustomerModal}
        handleApi={createNewUserAPI}
        // setIsModalOpen={setIsModalOpen}
        // editData={editDataVal}
        // initialFormData={initialFormData}
        // formData={formData}
        // setFormData={setFormData}
        // addressListModal={showModal}
      />
    </>
  );
};

export default SellerCustomerProfile;
