import React from "react";
// import "./createOrderModal.css";
import { Col, Typography } from "antd";
import { MainModal } from "../../../../../../components/Modal/MainModal/MainModal";
import { ModalFooter } from "../../../../../../components/Modal/MainModal/ModalFooter";
// import { dateFormat } from "../../../../../../commonFuntions/CommonUtilFunctions";
import cancelmodalicon from "../../../../../../assets/seller/icons/cancelmodal.svg";

const { Text } = Typography;

export const SellerRejectOrderModal = (props) => {
  const {
    title,
    open,
    handleCloseModal,
    handleCancel,
    handleSave,
    cancelText,
    saveText,
  } = props;
  const footer = [
    <ModalFooter
      cancelText={cancelText}
      saveText={saveText}
      handleCancel={handleCancel}
      handleSave={handleSave}
      saveButtonCss={"cancel-order-button btn-danger"}
    />,
  ];

  return (
    <>
      <MainModal
        title={title}
        open={open}
        handleCloseModal={handleCloseModal}
        footer={footer}
      >
        <Col style={{ height: "250px" }} className="create-modal-container text-center">
          <img
            width={100}
            height={100}
            src={cancelmodalicon}
            style={{  }}
            alt=""
          />
          <Col className="create-modal-sub-container">
            <Text className="create-modal-recommed-text">
              Are you sure you want to reject the order?
            </Text>{" "}
            <br />
            <Text className="create-modal-recommed-text-2">
              {" "}
              Mad over buildings will attempt to reassign the order to another
              vendor. If you are not sure the please call mob at <a href={`tel:+91 8660423608`}>+91 8660423608</a>
            </Text>
          </Col>
        </Col>
      </MainModal>
          
    </>
  );
};
