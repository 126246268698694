import React from "react";
import { Col, Row, Checkbox,Typography } from "antd";
import "./SellerMobCreditOnboarding.css";
import CommercialsTerms from "./CommercialsTerms";
const Commercials = (props) => {
  const {
    checkedCommercials,
    handleCommercialsCheckboxChange,
    errorCommercials,
  } = props;

  return (
    <>
      <Col
        className="mt-4"
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 12, offset: 0 }}
        md={{ span: 8, offset: 4 }}
        lg={{ span: 8, offset: 4 }}
        xl={{ span: 24, offset: 2 }}
      >
         <Row className="mt-4">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Typography.Text className="txt-getstarted">Commercials & terms</Typography.Text>
          </Col>
        </Row>
        <CommercialsTerms />

        <Row className="mt-4 mb-4 custom-bottom-margin" >
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Checkbox
          className="chk-text"
            defaultChecked={checkedCommercials}
            onChange={handleCommercialsCheckboxChange}
          >
            I have read and understood the information provided regarding
            mobCredit commercials & terms
          </Checkbox>
          <br/>
          {errorCommercials && (
            <div style={{ color: "red" }}>Please accept commercials & terms to proceed.</div>
          )}  
          </Col>
        </Row>
       
      </Col>
    </>
  );
};

export default React.memo(Commercials);
