import React, { useEffect, useState } from "react";
import "../payables.css";
// eslint-disable-next-line 
import { Button, Col, Row, Typography,Dropdown,Menu } from "antd";
 // eslint-disable-next-line 
import { Link } from "react-router-dom";
// import {

//   DashOutlined,
// } from "@ant-design/icons";
// eslint-disable-next-line 
import { ReactComponent as Paymenticon } from "../../../../assets/seller/icons/payment.svg";
import TableSkeleton from "../../../../components/skeleton/TableSkeleton";
// import { useDispatch, useSelector } from "react-redux";
// import { getmanageOrdersTableData } from "../../redux/slices/manageOrders/action";
// import { manageOrdersDataSelector } from "../../redux/slices/manageOrders/selector";
import {
  // dateFormat,
  // eslint-disable-next-line
  handlePdfDownload,
  // onlyDateFormat,
   // eslint-disable-next-line 
  dateFormat,
  // handlePdfDownload,
} from "../../../../commonFuntions/CommonUtilFunctions";
import CustomSelect from "../../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import CustomTableWithPagination from "../../../../components/CustomTableWithPagination/CustomTableWithPagination";
// import UploadPaymentModel from "../../components/Modal/SettlementModal/UploadPaymentModel";
import DateRangePicker from "../../../../components/DateRangePicker/DateRangePicker";
import AddPaymentModal from "../../../../components/SellerComponents/SellerPayable/AddPaymentModal/AddPaymentModal";
// import ConfirmPaymentModal from "../../../components/Modal/SettlementModal/AddPaymentModal/ConfirmPaymentModal";
import { useLazyGetDownloadPdfPaymentQuery } from "../../../../apis/Seller/SellerSettlementAPI";

import dayjs from "dayjs";
// import customParseFormat from "dayjs/plugin/customParseFormat";
import { baseUrl } from "../../../../commonUtils/commonUtils";
const { Text } = Typography;
const handleTableData = (data) => {
  let tableData = [];
  data?.map((item) => {
    tableData.push({
      ...item,
      id: item?.id,
      key: item?.id,
      full_name: item?.customer?.full_name,
      phone_number: item?.customer?.phone_number,
      recievable: item?.amount,
      payable: item?.amount,
    });
    return null;
  });
  return tableData;
};

const CustomerAllTransactions = (props) => {
  const {
    searchValue,
     // eslint-disable-next-line 
    getSettlementByIDAPI,
    seller_name,
    getAllTrasactionData,
    transactionList,
    isAllDataLoading,
    orderPaymentApi,
    orderNewPaymentApi
  } = props;

  // const dispatch = useDispatch();
  // const selectorData = useSelector(manageOrdersDataSelector);
  // eslint-disable-next-line 
  const [titleText, settitleText] = useState("Add Payment");
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [dateSearch, setDateSearch] = useState({
    start_date: "",
    end_date: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [dateFilter, setDateFilter] = useState();
  const [editPayment, setEditPayment] = useState({
    state: false,
    check: true,
  });
  const [tableDataSource, setTableDataSource] = useState([]);
  // const [addPaymentApi] = useAddSettlementPaymentMutation();
  // const [updatePaymentApi] = useUpdateSettlementPaymentMutation();
  const [
    getDownloadPdfPaymentAPi,
    //  { data: pdfData }
  ] = useLazyGetDownloadPdfPaymentQuery();

  const [editOrderVal, seteditOrderVal] = useState({});
  const [formData, setFormData] = useState({});
  const [datePickerEmpty, setDatePickerEmpty] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [paymentMode, setPaymentMode] = useState();
  const [inputDate, setInputDate] = useState("");  
  const [amountError, setAmountError] = useState(false);
  const [dateError, setDateError] = useState(false);
  useEffect(() => {
    getAllTrasactionData({id:searchValue});
    // getSettlementSearchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);
  useEffect(() => {
    if (transactionList?.data?.results?.length >= 0) {
      const tableData = transactionList?.data?.results
        ? handleTableData(transactionList?.data?.results)
        : "";
      setTableDataSource(tableData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionList]);

  const handleChange = (value) => {
    let formattedDate = null;

    switch (value) {
      case "Today":
        formattedDate = dayjs().format("YYYY-MM-DD");
        break;
      case "Yesterday":
        formattedDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        break;
      case "last_7_day":
        formattedDate = dayjs().subtract(6, "day").format("YYYY-MM-DD");
        break;
      case "last_30_day":
        formattedDate = dayjs().subtract(29, "day").format("YYYY-MM-DD");
        break;
      case "last_60_day":
        formattedDate = dayjs().subtract(59, "day").format("YYYY-MM-DD");
        break;
      case "Custom":
        formattedDate = "Custom";
        break;
      default:
        break;
    }

    const currentDate = dayjs().format("YYYY-MM-DD");
    if (value === "clear_date") {
      setDateSearch({
        start_date: "",
        end_date: "",
      });
    } else {
      setDateSearch({
        start_date: formattedDate,
        end_date: currentDate,
      });
    }
    setDateFilter(value);
  };
  const handleSearchOnDate = () => {
    if (dateFilter === "clear_date") {
        getAllTrasactionData({ id:searchValue});
    } else {
        getAllTrasactionData({id:searchValue,param:dateSearch});
    }
  };


  const handlePageChange = async (currPage) => {
    // const params = {
    //   page: currPage,
    // };
    setCurrentPage(currPage);
  };

  const handleOk = () => {
    setOpenUploadModal(false);
    setPaymentMode("");
    setInputDate("");
    setFileList([]);
    // setDatePickerEmpty(false);
    setFormData({ seller_name });
  };
  // eslint-disable-next-line 
  const handleStatus = (record) => {  
    seteditOrderVal(record);
    setOpenUploadModal(true);   
  };
  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates && dates.length === 2) {
      const startDateFormatted = dates[0].format("YYYY-MM-DD");
      const endDateFormatted = dates[1].format("YYYY-MM-DD");
      setDateSearch({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
      });
    }
  };

  const options = [
    {
      key: 1,
      value: "Today",
      label: "Today",
    },
    {
      key: 2,
      value: "Yesterday",
      label: "Yesterday",
    },
    {
      key: 3,
      value: "last_7_day",
      label: "Last 7 day",
    },
    {
      key: 4,
      value: "last_30_day",
      label: "Last 30 day",
    },
    {
      key: 5,
      value: "last_60_day",
      label: "Last 60 day",
    },
    {
      key: 6,
      value: "Custom",
      label: "Custom",
    },
    {
      key: 7,
      value: "clear_date",
      label: (
        <Col style={{ color: "#006644", fontWeight: "500" }}>Clear Date</Col>
      ),
    },
  ];
  // eslint-disable-next-line
  const handleDownload = async () => {
    const response = await getDownloadPdfPaymentAPi({
      ...(dateSearch?.start_date && { start_date: dateSearch?.start_date }),
      ...(dateSearch?.end_date && { end_date: dateSearch?.end_date }),
    });

    if (response?.status === "fulfilled") {
      window.open(`${baseUrl}${response?.data?.data?.url}`, "_self");
    }
  };
  // eslint-disable-next-line
  const downloadOptions = [
    {
      key: 1,
      value: "Excel",
      label: "Excel",
    },
  ];
  const columns = [
    {
      width: "20%",
      // fixed: "left",
      flex: 1,
      title: "Date",
      key: 1,
     
      render: (params, record) => {
        return (
          <>           
            <Text className="table_text">{dateFormat(params?.created_at)}</Text>
            {/* <Text className="table_text">{params?.created_at}</Text> */}
          </>
        );
      },
    },
    {
      // width: "25%",
      // fixed: "left",
      flex: 1,
      title: "Payment mode",
      key: 1,
      dataIndex: "payment_mode",
      render: (params, record) => {
        return (
          <>           
            <Text className="table_text">{params||""}</Text>            
          </>
        );
      },
    },
    
    {
      // fixed: "left",
      flex: 3,
      title: "Billing no",
      // width: "250px",
      key: 3,
      dataIndex: "order",
      render: (params, record) => {
     
          return (
            <>
              <Text className="table_text text-right">
               {params?.order_id}
              </Text>            
            </>
          );
      
      },
    },
    {
      // fixed: "left",
      flex: 3,
      title: "You gave",
      align:"right",
      // width: "250px",
      key: 3,
      dataIndex: "amount",
      render: (params, record) => {
        if (params < 0) {
          return (
            <>
              <Text className="table_text text-right text-danger">
              ₹ {Number(params).toFixed(2)} 
              </Text>
            
            </>
          );
        } else {
        }
      },
    },
    {
      // fixed: "left",
      flex: 3,
      title: "You received",
      align:"right",
      // width: "250px",
      key: 3,
      dataIndex: "amount",
      render: (params, record) => {
        if (params > 0) {
          return (
            <>
              <Text className="table_text text-right text-success">
              ₹ {Number(params).toFixed(2)}
              </Text>
             
            </>
          );
        } else {
        }
      },
    },
    {
      // fixed: "left",
      flex: 2,
      // width: "150px",
      title: "Note",
      key: 2,
      dataIndex: "note",
      className: "table_text",
    },
    // {
    //   // fixed: "left",
    //   flex: 3,
    //   title: "",
    //   // width: "250px",
    //   key: 3,
    //   align:"right",
    //   // dataIndex: "order",
    //   render: (params, record) => {
    //     if(params?.store_order!=="" && params?.store_order!==null)
    //     {
    //     return (
    //       <Dropdown
    //           overlay={
    //             <Menu>
    //               <>                 
    //                 <Menu.Item
    //                   onClick={() => handleStatus(record)}
    //                   style={{ padding: "7px 12px" }}
    //                 >
    //                   <Col className="container-list">
    //                     <Text className="select-btn-text">
    //                   <Paymenticon/>   Add payment
    //                     </Text>
    //                   </Col>
    //                 </Menu.Item>
                    
    //               </>
    //             </Menu>
    //           }
    //           trigger={"click"}
    //         >
    //           <DashOutlined
    //           //   onClick={() => _onRowClick(record)}
    //           />
    //         </Dropdown>
    //     );
    //   }
    //   },
    // },
  ];

  return (
    <>
      <Col>
        <Row className="action-container">
          <Col className="action-sub-container">
            <CustomSelect
              placeholder={"Date"}
              width={"150px"}
              options={options}
              handleChange={handleChange}
              value={dateFilter}
            />
            {dateFilter === "Custom" && (
              <DateRangePicker
                onChange={handleChangeRangePicker}
                format={"DD-MMM-YYYY"}
              />
            )}
            <Button
              size="large"
              style={{
                backgroundColor: "#0354a3",
                color: "#fff",
                fontWeight: 500,
                height: "48px",
              }}
              onClick={() => handleSearchOnDate()}
            >
              Search
            </Button>
          </Col>
          <Col className="action-sub-container">
            {/* <CustomSelect
              placeholder={"Download"}
              width={"150px"}
              height={"46px"}
              options={downloadOptions}
              handleChange={handleDownload}
              value={"Download"}
            /> */}
          </Col>
        </Row>
        <Col>
          <CustomTableWithPagination
            className="order-list-table no-wrap"
            tableDataSource={tableDataSource || []}
            tableColumns={columns}
            locale={{
              emptyText: isAllDataLoading ? <TableSkeleton length={30} /> : "",
            }}
            scroll={{ x: "100%" }}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            itemsPerPage={30}
            totalEntries={
              !isAllDataLoading && transactionList?.data?.pagination?.total_entries
            }
          />
        </Col>
      </Col>
      {openUploadModal &&(
      <AddPaymentModal
         openAddFile={openUploadModal}
         setOpenAddFile={setOpenUploadModal}
         titleText={titleText}
         custData={transactionList?.data?.customer}
        handleOk={handleOk}
        formData={formData}
        setFormData={setFormData}
        datePickerEmpty={datePickerEmpty}
        setDatePickerEmpty={setDatePickerEmpty}
        fileList={fileList}
        setFileList={setFileList}
        paymentMode={paymentMode}
        setPaymentMode={setPaymentMode}
        orderPaymentApi={orderPaymentApi}
        editPayment={editPayment}
        setEditPayment={setEditPayment}
        setOpenUploadModal={setOpenUploadModal}
        setInputDate={setInputDate}
        inputDate={inputDate}
        setAmountError={setAmountError}
        amountError={amountError}
        dateError={dateError}
        setDateError={setDateError}
        editOrderVal={editOrderVal}   
        orderNewPaymentApi={orderNewPaymentApi}
        getAllTrasactionData={getAllTrasactionData}
       
      />
      )}
      {/* <ConfirmPaymentModal
        open={confirmModal}
        data={formData}
        onBack={handleBack}
        handleOk={handleOk}
        handleSave={handleAddPayment}
      /> */}
    </>
  );
};

export default CustomerAllTransactions;
