import React from "react";
import { Navigate } from "react-router-dom";
// import { Content } from "antd/es/layout/layout";
import SellerBlankLayout from "../../../layouts/SellerBlankLayout";

const SellerCommonRoute = (props) => {
  const { children } = props;
  const isAuthenticated = localStorage.getItem("accessToken") !== null;
  return isAuthenticated ? (
    <SellerBlankLayout>
      <section>{children}</section>
    </SellerBlankLayout>
  ) : (
    <Navigate to="/Sellerlogin" replace />
  );
};

export default SellerCommonRoute;
