import { React, useEffect } from "react";
import { Row, Col} from "antd";
// eslint-disable-next-line 
import { DownOutlined } from "@ant-design/icons";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { ReactComponent as Logo } from "../../../assets/seller/icons/mobapartner.svg";

import "./Navbar.css";
import {
  HeaderContainer,
  OpenCloseButton,
  LeftContainer,
  LeftContainerMobile,
} from "./NavbarStyled";
import { useNavigate } from "react-router-dom";


const NavbarEmpty = ({ setCollapsed, collapsed }) => {

  const navigate = useNavigate();
  const handleNavigateDashboard = () => {
    const dashboardPath = "/SellerLogin";  
      navigate(dashboardPath);
    
  };

  useEffect(() => window.scrollTo(0, 0));

  
  return (
    <>
      <HeaderContainer
        className=" d-none d-md-block d-lg-block d-xl-block"
        style={{
          backgroundColor: "#fff",
          color: "#0a243f",
          boxShadow: "0 3px 3px 0 rgba(0, 0, 0, 0.08)",
          borderBottom: "1px solid #dedede ",
          padding: "12px 0px",
        }}
      >
        <Row gutter={[24, 0]} style={{ margin: "0px" }}>
          <Col xs={12} sm={24} md={6} lg={6} xl={8}>
            <LeftContainer>        
              <Logo
                style={{ cursor: "pointer", width: "88px" }}
                onClick={handleNavigateDashboard}
              />

             
            </LeftContainer>
          </Col>

          

          
        </Row>
      </HeaderContainer>

      {/* Mobile part */}
      <HeaderContainer
        className="d-block d-sm-block d-lg-none d-xl-none pl-0"
        style={{
          backgroundColor: "#fff",
          color: "#0a243f",
          boxShadow: "0 3px 3px 0 rgba(0, 0, 0, 0.08)",
          borderBottom: "1px solid #dedede ",
          padding: "10px 0px 10px 0px",
         
        }}
      >
        <Row gutter={[24, 0]} style={{ margin: "0px" }}>
          <Col xs={13} sm={24} md={6} lg={6} xl={8} style={{ gap: "0rem" }}>
            <LeftContainerMobile>
              <OpenCloseButton
                style={{ color: "#0a243f",paddingLeft:"5px" }}
                onClick={() => setCollapsed(!collapsed)}
              >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </OpenCloseButton>
              <Logo
                style={{ cursor: "pointer", paddingBottom:"5px"}}
                onClick={handleNavigateDashboard}
              />
              {/* <Col className="fixed-font font-eclipse">               
              {bmp_id} ({bmp_name})
              </Col> */}
            </LeftContainerMobile>
          </Col>
        
        </Row>
       
      </HeaderContainer>

      
       
    </>
  );
};

export default NavbarEmpty;
