import React from "react";
import "./SellerOrderModal.css";
import { Col, Divider, Typography,Row } from "antd";
import { MainModal } from "../../../../../../components/Modal/MainModal/MainModal";
import { ModalFooter } from "../../../../../../components/Modal/MainModal/ModalFooter";
// import { dateFormat } from "../../../../../../commonFuntions/CommonUtilFunctions";
import {ReactComponent as Warningicon} from "../../../../../../assets/seller/icons/warning.svg";

const { Text } = Typography;

export const SellerCancelOrderModal = (props) => {
  const {
    title,
    open,
    handleCloseModal,
    handleCancel,
    handleSave,
    cancelText,
    saveText,
    data,
    totalAmountPaid
  } = props;
    // console.log("🚀 ~ SellerCancelOrderModal ~ data:", data)
  const mobCreditAmount = data?.payments?.reduce((acc, payment) => {
    if (payment.payment_mode === "mobCredit") {
      return acc + payment.amount;
    }
    return acc;
  }, 0);
  const RefundAmount= Number(mobCreditAmount)-Number(totalAmountPaid);
  const footer = [
    <ModalFooter
      cancelText={cancelText}
      saveText={saveText}
      handleCancel={handleCancel}
      handleSave={handleSave}
      saveButtonCss={"cancel-order-button btn-danger"}
    />,
  ];

  return (
    <>
      <MainModal
        title={title}
        open={open}
        handleCloseModal={handleCloseModal}
        footer={footer}
      >
        <Col style={{ height: "400px" }} className="create-modal-container">
        {data?.status==="Delivered" ?(
          <>
          <Col className="create-modal-sub-container">
          <Text className="create-modal-recommed-text">
          ORDER DELIVERED - cancellation
            </Text>
            
            <br />
            {data?.isMobCredit &&(
            <Col
                className="tax-info-text mob-cancel-warning width-100 mt-2"
                style={{display:"flex", justifyContent: "space-between" ,gap:"8px"}}
              >
                <Col className="mt-2">
                <Warningicon/>
                
                </Col>
                 <Text style={{fontSize:"14px"}}> 
                 As the order has already been marked delivered earlier, mobCREDIT amount has been processed and CANNOT BE UNDONE. You will receive the amount as per the terms. 
                 Refund the customer as per your agreed terms with the customer or in full.
                 </Text>
             
               
              </Col>
            )}
            <Col className="col-payment-strip mt-4">
            <Row
                className="d-flex tax-info-text"
                style={{ justifyContent: "space-between" }}
              >
                <Col>Total amount:</Col>
                <Col>
                  {" "}
                  ₹ {Number(data?.total || 0).toFixed(2)}
                </Col>
               
              </Row>
              <Row
                className="d-flex tax-info-text mt-2"
                style={{ justifyContent: "space-between" }}
              >
                <Col>Total paid:</Col>
                <Col>
                  {" "}
                  ₹ {Number(totalAmountPaid || 0).toFixed(2)}
                </Col>
               
              </Row>
          
            
            <Row>
           
            
            {/* </Col>   */}
            </Row>
            <Divider/>
            <Row
                className="d-flex tax-info-text mt-2"
                style={{ justifyContent: "space-between" }}
              >
                <Col className="text-refund-size">Customer refund by store:</Col>
                <Col className="text-refund-size">                 
                  ₹ {Number(totalAmountPaid || 0).toFixed(2)}
                </Col>
               
              </Row>
            </Col>
          </Col>
          </>
        ):(
          <>
          <Col className="create-modal-sub-container">
          <Text className="create-modal-recommed-text">
          Once cancelled it CANNOT BE UNDONE
            </Text>
            
            <br />
            <Col className="col-payment-strip mt-4">
            <Row
                className="d-flex tax-info-text"
                style={{ justifyContent: "space-between" }}
              >
                <Col>Total amount:</Col>
                <Col>
                  {" "}
                  ₹ {Number(data?.total || 0).toFixed(2)}
                </Col>
               
              </Row>
              <Row
                className="d-flex tax-info-text mt-2"
                style={{ justifyContent: "space-between" }}
              >
                <Col>Total paid:</Col>
                <Col>
                  {" "}
                  ₹ {Number(totalAmountPaid || 0).toFixed(2)}
                </Col>
               
              </Row>
            <Divider/>
            <Row>
              <Col>
            <Text className="create-modal-recommed-text">
            Cancellation refund details
            </Text><br/>
            <Text className="">
            Breakdown
            </Text>
            </Col>
           
            
            </Row>
            <Row>
            {/* <Col className="mob-credit-splitup"> */}
            {data?.isMobCredit &&(
            <Row
                className="d-flex tax-info-text mob-credit-splitup width-100 mt-2"
                style={{ justifyContent: "space-between" }}
              >
                <Col>mobCREDIT</Col>
                <Col className="text-right">
                  {" "}
                  ₹ {Number(mobCreditAmount || 0).toFixed(2)}<br/>
                 <span style={{fontSize:"10px"}}> Will be auto refunded to customer’s mobCREDIT in 45 mins</span>
                </Col>
               
              </Row>
            )}
            {/* </Col>   */}
            </Row>
            <Row
                className="d-flex tax-info-text mt-2"
                style={{ justifyContent: "space-between" }}
              >
                <Col>Customer refund by store:</Col>
                <Col>
                  {" "}
                  ₹ {Number(RefundAmount || 0).toFixed(2)}
                </Col>
               
              </Row>
            </Col>
          </Col>
          </>
        )}
        </Col>
      </MainModal>
          
    </>
  );
};
