import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../baseQuery";

export const SellerSettlementAPI = createApi({
  reducerPath: "SellerSettlementAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    
    getDownloadPdfPayment: builder.query({
      query: (params) => {
        return {
          url: `api/seller/settlements/download_payments/`,
          params,
        };
      },
    }),
    getDownloadPdfMobPayment: builder.query({
      query: (params) => {
        return {
          url: `api/seller/settlements/download_orders/`,
          params,
        };
      },
    }),
    getSettlementDataByID: builder.query({
      query: (params) => {
        return {
          url: `api/seller/settlements/`,
          params,
        };
      },
    }),
    getSearchSettlementVendors: builder.query({
        query: (params) => {
          return {
            url: `api/seller/settlements/`,
            params,
          };
        },
      }),
    getMobOrders: builder.query({
      query: (params ) => {
        return {
          url: `api/seller/settlements/get_seller_orders/`,
          params: params,
        };
      },
    }),
    
    
    
  }),
});

export const {

  useLazyGetSettlementDataByIDQuery,
  useLazyGetSearchSettlementVendorsQuery, 
  useLazyGetMobOrdersQuery, 
  useLazyGetDownloadPdfPaymentQuery,
  useLazyGetDownloadPdfMobPaymentQuery,
} = SellerSettlementAPI;
