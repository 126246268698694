import { Button, Col, Input, Menu, Typography } from "antd";
import styled from "styled-components";
const { TextArea } = Input;
const { Text } = Typography;

export const MenuItem = styled(Menu.Item)`
  width: 280px;
  height: 170px;
`;

export const MOBHeading = styled(Text)`
  font-weight: 500;
  color: #0a243f;
  font-size: 16px;
  display: block;
  margin-bottom: 3px;
`;

export const MOBSubHeading = styled(Text)`
  display: block;
  font-weight: normal;
  color: #6c7c8c;
  font-size: 12px;
  margin-bottom: 10px;
`;

export const MOBButton = styled(Button)`
  width: 100%;
  height: 40px;
  background-color: #015fe5;
  color: #fff;
  border-radius: 12px;
  &:hover {
    color: #fff !important;
  }
`;

export const UnitContainer = styled(Col)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const UnitNameInput = styled(Input)`
  width: 40%;
  font-weight: 500;
`;

export const BeforeTaxInput = styled(Input)`
  width: 40%;
  font-weight: 500;
  text-align: right;
`;

export const UnitInput = styled(Input)`
  border-radius: 8px;
  background-color: #f3f2ef;
  border: none;
  // height: 32px;
  font-weight: 500;
  color: #0a243f;
  font-size: 12px;
`;

export const GSTContainer = styled(Col)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
  // text-align: right;
`;

export const GstInput = styled(Input)`
  width: 20%;
  font-weight: 500;
  text-align: right;
`;

export const AfterTaxText = styled(Text)`
  width: 20;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: not-allowed;
`;

export const RowDropDownContainer = styled(Col)`
  display: flex;
  align-items: center;
`;

export const MobDropDownContainer = styled(Col)`
  width: 50%;
  height: 27px;
  background-color: #e6f6e9;
  // color: #0da72f;
  display: flex;
  padding: 0 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 0 0 8px;
  cursor: pointer;
`;

export const MobDropDownAddedText = styled(Text)`
  font-weight: 500;
  color: #0da72f;
  font-size: 12px;
`;

export const BMPPrice = styled(Text)`
  border-radius: 0 8px 8px 0;
  width: 50%;
  background-color: #f3f2ef;
  border: none;
  height: 27px;
  margin-left: -1px;
  cursor: not-allowed;
  font-weight: 500;
  font-size: 12px;
  padding: 4px 0 0 11px;
`;

export const NotesInput = styled(TextArea)`
  border-radius: 8px;
 // background-color: #f3f2ef;
  border: none;
  // height: 32px;
  font-weight: 500;
  color: #0a243f;
  font-size: 12px;
    height: 70px !important;
    
`;
