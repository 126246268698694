import React, { useEffect, useState } from "react";
import { Col, Row, Typography, Select, Checkbox } from "antd";
import { ReactComponent as LogoIcon } from "../../../assets/seller/icons/mobpartner_whitelogo.svg";
import { ReactComponent as PartnerLogoIcon } from "../../../assets/seller/icons/mobapartner.svg";
import { ReactComponent as BannerIcon } from "../../../assets/icons/loginBanner.svg";
import { ReactComponent as LeftArrowIcon } from "../../../assets/icons/chevron-left.svg";
import { ReactComponent as HomeblueIcon } from "../../../assets/seller/icons/homeblue.svg";

import {
  stateOptions,
  cityOptions,
  checkPhoneNumberValidation,
} from "../../../commonUtils/commonUtils";
import SearchDropdown from "../../../components/DropDown/SearchDropdown";
import "./SellerLogin.css";
import OtpInput from "react-otp-input";
import { Button, Form, Input,Spin } from "antd";
import {
  useSellerLoginMutation,
  useSellerGetOtpMutation,
  useSellerVerifyOtpMutation,
} from "../../../apis/Seller/SellerLogin.js";
import { useLazyGetSellerAddVendorDetailsQuery } from "../../../apis/sellerCatalogue";
import { useSellerNewRegistrationMutation } from "../../../apis/Seller/SellerRegistration.js";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import {
  getAuthToken,
  getLoginUserDetail,
  getSeller_list,
} from "../../../redux/slices/loginAuth/action";

const SellerLogin = () => {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  const dispatch = useDispatch();
  const { Text } = Typography;
  const [otpModal, setOtpModal] = useState(true);
  const [sellercategory, setsellercategory] = useState([]);
  const [otpEnterModal, setOtpEnterModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [renderOtp, setRenderOtp] = useState(true);
  // const [forgetPassword, setFogetPassword] = useState(false);
  const [registration, setRegistration] = useState(false);
  const [sellerList, setsellerList] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [sellerLoginApi, { isLoading }] = useSellerLoginMutation();
  const [verifyOtpApi] = useSellerVerifyOtpMutation();
  const [getOtpApi] = useSellerGetOtpMutation();
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [getSellerList, setGetSellerList] = useState([]);
  const [hasCalledApi, setHasCalledApi] = useState(false);
  const [IsNewSeller, setIsNewSeller] = useState(false);
  const [ispageLoading, setispageLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [getSellerRegistration] = useSellerNewRegistrationMutation();
  const [searchCityValue, setSearchCityValue] = useState("");
  const [searchStateValue, setSearchStateValue] = useState("");

  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");

  const handleCitySelectChange = (value) => {
    setSearchCityValue(value);
    if (value) {
      setCityError("");
    }
  };
  const handleStateSelectChange = (value) => {
    setSearchStateValue(value);
    if (value) {
      setStateError("");
    }
  };
  const handleValidation = () => {
    if (searchCityValue === "") {
      setCityError("Please select your city");
    }
    if (searchStateValue === "") {
      setStateError("Please select your state");
    }
  };
  const [getSellerRegistration] = useSellerNewRegistrationMutation();
  const [
    getVendorCategoryAPI,
    { data: getVendorCategorydata, isLoading: isCategoryLoading },
  ] = useLazyGetSellerAddVendorDetailsQuery();

  const navigate = useNavigate();

  // const handleForgetPassword = () => {
  //   setFogetPassword(true);
  // };
  // const handleForgetBack = () => {
  //   setFogetPassword(false);
  // };
  // const handleLoginPage = () => {
  //   setIsEmailValid(true);
  //   setRenderOtp(false);
  //   setOtpModal(!otpModal);
  // };
  // useEffect(() => {
  //   if(localStorage.getItem("accessToken") !== null)
  //   {
  //   getVendorCategoryAPI();
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const handleOtp = async () => {
    if (otpModal) {
      if (checkPhoneNumberValidation(emailOrPhone)) {
        console.log(checkPhoneNumberValidation(emailOrPhone));
        setTimer(60);
        const response = await getOtpApi({ email_or_phone: emailOrPhone });
        if (response?.data?.status) {
          setRenderOtp(!renderOtp);
          setOtpEnterModal(true);
          showSuccessToast(response.data.message);
        } else if (!response.error.data.status) {
          showErrorToast(response.error.data.message);
          setIsEmailValid(false);
        }
      }
    }
  };

  const handleBack = () => {
    setEmailOrPhone("");
    setRenderOtp(!renderOtp);
    setOtpModal(true);
    setRegistration(false);
    setOtpEnterModal(false);
  };
  const handleBacktoOTP = () => {
    setOtpModal(true);
    setRenderOtp(false);
    setRegistration(false);
    setsellerList(false);
    setOtpEnterModal(true);
  };
  const handleBackRegistration = () => {
    setRenderOtp(false);
    setsellerList(true);
    setRegistration(false);
  };
  const handleSelectedStore = (seller) => {

    localStorage.setItem("bmp_id", seller?.bmp_id);
    localStorage.setItem("bmp_name", seller?.vendor_name);
    localStorage.setItem("bmp_isactive", seller?.is_active);
    localStorage.setItem("bmp_credit_enable", seller?.mob_credit_enable);
    localStorage.setItem("bmp_plan_expiry", seller?.plan_expiry);
    localStorage.setItem("bmp_mobile", seller?.phone_number);
    navigate("/seller/sellerdashboard");
  };
  useEffect(() => {
    if (registration) {
      if (!hasCalledApi) {
        getVendorCategoryAPI();
        setHasCalledApi(true);
      }
    }
  }, [registration, getVendorCategoryAPI, hasCalledApi]);
  const sellerRegistration = () => {
    setRegistration(true);
    setRenderOtp(false);
    setOtpModal(false);
    setsellerList(false);
    // getVendorCategoryAPI();
  };
  const resendOtp = () => {
    setOtp("");
    setTimer(60);
    getOtpApi({ email_or_phone: emailOrPhone });
  };

  const isAuthenticated = localStorage.getItem("accessToken") !== null;
  const handleFinalRegistration = async (values) => {
      setispageLoading(true);
      setIsSubmitting(true);
    try {
      if (!searchCityValue) {
        setCityError("Please select your city");
        setispageLoading(false);
        setIsSubmitting(false);
        return;
      } else {
        setCityError("");
      }
      if (!searchStateValue) {
        setStateError("Please select your state");
        setispageLoading(false);
        setIsSubmitting(false);
        return;
      } else {
        setStateError("");
      }
      // console.log("🚀 ~ handleFinalRegistration ~ searchCityValue:", searchCityValue)
      // console.log("🚀 ~ handleFinalRegistration ~ searchCityValue:", searchStateValue)
      if (registration && values) {
        // setTimer(60);
        const typesOfMaterial = sellercategory.map((category) => category.key);

        const APIresponse = await getSellerRegistration({
          phone_number: values?.mobileno || emailOrPhone,
          vendor_name: values?.BusinessName,
          gst_number: values?.gstin,
          email: values?.EmailId,
          types_of_material: typesOfMaterial || [],
          address: {
            name: "",
            phone_number: values?.mobileno || emailOrPhone,
            address: values?.address1 || "",
            address_line_2: values?.area || "",
            // state: values?.state,
            // city: values?.city,
            state: searchStateValue,
            city: searchCityValue,
            pincode: values?.pincode,
            google_map_link: values?.googlelink,
          },
        });

        // console.log("🚀 ~ handleFinalRegistration ~ response:", response)
        if (APIresponse?.data?.status === true) {
          showSuccessToast(APIresponse.data.message);
          setRegistration(true);
          setRenderOtp(false);
          otpVerify();
        } else if (!APIresponse?.data?.status) {
          showErrorToast(APIresponse?.error?.data?.message);
        }
      }
    } catch (error) {
      setispageLoading(false);
      setIsSubmitting(false);
      // console.log("🚀 ~ handleFinalRegistration ~ APIresponse:", error);
    } finally {
       setispageLoading(false); 
       setIsSubmitting(false);
    }
  };
  const onFinish = async (values) => {
    try {
      if (!otpModal) {    
        const response = await sellerLoginApi({
          email_or_phone: values.username,
          password: values.password,
        });

        if (response?.data?.status) {
          const accessToken = response?.data?.data?.access;
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem(
            "selleruserName",
            response?.data?.data?.data[0]?.person_full_name
          );
          localStorage.setItem("bmp_id", response?.data?.data?.data[0]?.bmp_id);
          localStorage.setItem(
            "bmp_name",
            response?.data?.data?.data[0]?.vendor_name
          );
          localStorage.setItem(
            "bmp_isactive",
            response?.data?.data?.data[0]?.is_active
          );
          setIsEmailValid(true);
          dispatch(getAuthToken(accessToken));

          dispatch(getLoginUserDetail(response?.data?.data));
          //navigate("/seller/sellerdashboard");
          setsellerList(true);
          setRegistration(false);
          setOtpModal(false);
          showSuccessToast(response?.data?.message);
        } else {
          const errorMsg =
            response?.data?.message || response?.error?.data?.data?.email[0];
          if (response?.error?.data?.data?.email[0]) {
            setIsEmailValid(false);
          } else {
            setIsEmailValid(true);
          }
          showErrorToast(errorMsg);
        }
      }
    } catch (error) {
      showErrorToast(error);
      console.error("Login failed", error);
    }
  };

  const otpVerify = async () => {
    const response = await verifyOtpApi({
      email_or_phone: emailOrPhone,
      otp: otp,
    });
    // console.log("🚀 ~ otpVerify ~ response:", response);
    if (response?.data?.status) {
      // console.log(response?.data?.data);
      if (response?.data?.data?.data.length > 0) {
        // showSuccessToast(response.data.message);
        setIsNewSeller(false);
        const accessToken = response?.data?.data?.access;
        // console.log(response?.data?.data?.access);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem(
          "selleruserName",
          response?.data?.data?.data[0]?.person_full_name
        );

        // console.log("sellerdata", response?.data?.data?.data);
        setIsEmailValid(true);
        dispatch(getAuthToken(accessToken));
        // console.log("Success data", response?.data?.data);
        dispatch(getSeller_list(response?.data?.data?.data));
        dispatch(getLoginUserDetail(response?.data?.data));
        setsellerList(true);
        setRegistration(false);
        setOtpModal(false);
        setOtpEnterModal(false);
        setGetSellerList(response?.data?.data?.data);
        //navigate("/seller/sellerdashboard");
      } else {
        setIsNewSeller(true);
        showSuccessToast(response.data.message);
        const accessToken = response?.data?.data?.access;
        // console.log(response?.data?.data?.access);
        localStorage.setItem("accessToken", accessToken);
        dispatch(getAuthToken(accessToken));
        dispatch(getLoginUserDetail(response?.data?.data));
        setOtpEnterModal(false);
        setRegistration(true);
      }
    } else if (!response.error.data.status) {
      showErrorToast(response.error.data.message);
    }
  };

  useEffect(() => {
    const bmp_id= localStorage.getItem("bmp_id");    
    if (isAuthenticated && bmp_id) {
        navigate("/seller/sellerdashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);
  return ispageLoading ? (
    <>
      <div className="full-page-loader text-center">
      <Spin />
    </div>
    </>
  ):(
    <>
      <Row>
        {/* d-none d-lg-block d-md-block */}
        <Col
          span={12}
          className="left-section"
          style={{
            // width: "50%",
            Height: "100vh",
            backgroundImage:
              "linear-gradient(143deg, #0354a3 25%, #74d8ad 86%)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <LogoIcon style={{ height: "40px", paddingTop: "1rem" }} />

          <BannerIcon style={{ width: "80%", height: " auto" }} />
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          style={{
            // width: "50%",
            // height: "100vh",
            // display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Row className="register-topbutton" style={{ display: "flex", justifyContent: "left" }}>
            <Col
              className="d-block d-sm-block d-lg-none d-xl-none"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <a href="/">
                <PartnerLogoIcon
                  style={{ height: "40px", paddingTop: "1rem" }}
                />
              </a>
            </Col>
            {/* <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 24 }}
            >
              <a
                href="/SellerRegistration"
                className="btn btn-primary register-now-outline"
                id="btnRegister"
              >
                Register now
              </a>
            </Col> */}
          </Row>
          <div
            className="Seller-login-card"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "100%",
            }}
          >
            <Row>
              <Col span={24} className="Seller-login-conatiner">
                {/* {!forgetPassword ? ( */}
                <>
                  {renderOtp && (
                    <>
                      <Col
                        style={{
                          paddingBottom: "20px",
                          textAlign: "center",
                          padding: "20px 26px",
                        }}
                      >
                        <Text style={{ fontSize: "20px", fontWeight: 500 }}>
                          Log in or sign up
                        </Text>
                      </Col>
                      <Form
                        name="basic"
                        style={{
                          maxWidth: 500,
                          padding: "20px 26px",
                        }}
                        initialValues={{
                          remember: true,
                        }}
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off"
                      >
                        <Form.Item label="Business mobile (for OTP)">
                          <Form.Item
                            label="Business mobile (for OTP)"
                            name="username"
                            noStyle
                            rules={[
                              {
                                pattern: /^[0-9]{10}$/, // Ensure exactly 10 numeric digits
                                required: true,
                                message: "Please input your business number!",
                              },
                            ]}
                          >
                            <Input
                              // type="number"
                              inputMode="numeric"
                              maxLength={10}
                              className={isEmailValid ? "custom-input" : ""}
                              style={{
                                height: "40px",
                                borderColor: isEmailValid ? "" : "#bf2600",
                                backgroundColor: isEmailValid ? "" : "#ffebe6",
                              }}
                              // onKeyDown={(e) => {
                              //   const isAlphabet = /^[a-zA-Z]$/.test(e.key);
                              //   if (
                              //     isAlphabet
                              //   ) {
                              //     e.preventDefault();
                              //   }
                              // }}
                              onChange={(e) => setEmailOrPhone(e.target.value)}
                            />
                          </Form.Item>
                        </Form.Item>
                        {!otpModal && (
                          <>
                            <Form.Item label="Password">
                              <Form.Item
                                label="Password"
                                name="password"
                                noStyle
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your password!",
                                  },
                                ]}
                              >
                                <Input.Password
                                  className="custom-input-pass"
                                  style={{ height: "40px" }}
                                />
                              </Form.Item>
                            </Form.Item>
                            {/* <Col style={{ textAlign: "right" }}>
                          <Text
                            onClick={handleForgetPassword}
                            style={{ cursor: "pointer", color: "#2973f0" }}
                          >
                            Forgot password?
                          </Text>
                        </Col> */}
                          </>
                        )}

                        <Form.Item>
                          <Button
                            style={{
                              width: "100%",
                              backgroundColor:
                                otpModal && !emailOrPhone
                                  ? "#dedede"
                                  : "#0354a3",
                              height: "40px",
                              fontWeight: 500,
                            }}
                            disabled={otpModal && !emailOrPhone}
                            type={"primary"}
                            htmlType="submit"
                            onClick={handleOtp}
                            loading={isLoading}
                          >
                            {otpModal ? "SEND OTP" : "LOGIN"}
                          </Button>
                        </Form.Item>
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "1rem",
                          }}
                        >
                          {/* <Text>OR</Text> */}
                        </Col>
                        {/* <Form.Item>
                          <Button
                            style={{
                              width: "100%",
                              fontWeight: 500,
                              height: "40px",
                            }}
                            onClick={handleLoginPage}
                          >
                            {otpModal ? "Enter password" : "Request OTP"}
                          </Button>
                        </Form.Item> */}
                      </Form>
                    </>
                  )}

                  <>
                    {otpEnterModal && (
                      <>
                        <Row style={{ justifyContent: "center", gap: "1rem" }}>
                          <Col
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              padding: "20px 26px",
                            }}
                          >
                            <Col style={{ width: "35%" }}>
                              <LeftArrowIcon
                                style={{ cursor: "pointer" }}
                                onClick={handleBack}
                              />{" "}
                            </Col>
                            <Text
                              style={{
                                fontSize: "20px",
                                fontWeight: 500,
                                width: "65%",
                              }}
                            >
                              Enter OTP
                            </Text>
                          </Col>
                          <Col style={{ paddingBottom: "1rem" }}>
                            <Text>
                              Enter OTP sent to {emailOrPhone}
                              <Text
                                style={{
                                  color: "#2973f0",
                                  fontWeight: 500,
                                }}
                              >
                                {/* CHANGE */}
                              </Text>
                            </Text>
                          </Col>
                          <Col>
                            <OtpInput
                              value={otp}
                              onChange={setOtp}
                              numInputs={4}
                              shouldAutoFocus={true}
                              inputType={isMobile ? "number" : "text"}
                              renderInput={(props) => (
                                <Input
                                  {...props}
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    marginRight: "10px",
                                    textAlign: "center",
                                  }}
                                />
                              )}
                            />
                          </Col>
                        </Row>
                        <Col
                          style={{ marginTop: "1rem", padding: "20px 26px" }}
                        >
                          {timer > 0 ? (
                            <>
                              <Text>Resend OTP in {timer} seconds</Text>
                              <Button
                                style={{
                                  width: "100%",
                                  fontWeight: 500,
                                  height: "40px",
                                  backgroundColor: "#0354a3",
                                  marginTop: "1rem",
                                }}
                                disabled={!otp}
                                type="primary"
                                onClick={otpVerify}
                              >
                                VERIFY
                              </Button>
                            </>
                          ) : (
                            <Button
                              style={{
                                width: "100%",
                                fontWeight: 500,
                                height: "40px",
                                marginTop: "1rem",
                              }}
                              onClick={resendOtp}
                            >
                              RESEND OTP
                            </Button>
                          )}
                        </Col>
                      </>
                    )}
                    {sellerList && (
                      <>
                        <Row style={{ justifyContent: "center", gap: "1rem" }}>
                          <Col
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              padding: "20px 26px",
                            }}
                          >
                            <Col style={{ width: "30%" }}>
                              <LeftArrowIcon
                                style={{ cursor: "pointer" }}
                                onClick={handleBacktoOTP}
                              />{" "}
                            </Col>
                            <Text
                              style={{
                                fontSize: "20px",
                                fontWeight: 500,
                                width: "65%",
                              }}
                            >
                              Select your store
                            </Text>
                          </Col>
                          <div
                          className="fix-height"                           
                          >
                            {getSellerList &&
                              getSellerList.map((seller, index) => (
                                <Row
                                  className="mt-4 border-bottom-seller"
                                  key={index}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Col
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <Col style={{ display: "flex" }}>
                                      <Text className="bmp-box">
                                        {seller?.vendor_name}
                                      </Text>
                                    </Col>
                                    <Col>
                                      <Text>{seller?.vendor_name}</Text> <br />
                                      <Text>{seller?.bmp_id}</Text>
                                    </Col>
                                  </Col>
                                  <Col className="text-right">
                                    <Text>
                                      <Button
                                        style={{
                                          width: "100%",
                                          fontWeight: 500,
                                          height: "40px",
                                        }}
                                        onClick={() =>
                                          handleSelectedStore(seller)
                                        }
                                      >
                                        VIEW
                                      </Button>
                                    </Text>
                                  </Col>
                                </Row>
                              ))}
                          </div>
                        </Row>

                        <Col className="new-seller-reg"                         
                        >
                          <Button
                            icon={<HomeblueIcon />}
                            style={{
                              width: "100%",
                              fontWeight: 500,
                              height: "40px",
                              marginTop: "1rem",
                              color: "#0060e5",
                            }}
                            onClick={sellerRegistration}
                          >
                            ADD NEW STORE
                          </Button>
                        </Col>
                      </>
                    )}
                    {registration && (
                      <>
                        <>
                          <Col
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              padding: "20px 26px",
                            }}
                          >
                            <Col style={{ width: "30%" }}>
                              <LeftArrowIcon
                                style={{ cursor: "pointer" }}
                                onClick={
                                  IsNewSeller
                                    ? handleBacktoOTP
                                    : handleBackRegistration
                                }
                              />{" "}
                            </Col>
                            <Text
                              style={{
                                fontSize: "20px",
                                fontWeight: 500,
                                width: "70%",
                              }}
                            >
                              Complete sign up
                            </Text>
                          </Col>

                          <Form
                            name="fromFinalRegistration"
                            className="mt-4"
                            style={{
                              maxWidth: 500,
                            }}
                            initialValues={{
                              remember: false,
                            }}
                            layout="vertical"
                            autoComplete="off"
                            onFinish={(values) => {
                              handleFinalRegistration(values);
                            }}
                          >
                            <Col
                              style={{
                                overflowY: "scroll",
                                height: "500px",
                                padding: "20px 26px",
                              }}
                            >
                              <Typography.Title level={4}>
                              Store/ Business information
                              </Typography.Title>
                              <Form.Item
                                label={
                                  <span>
                                    Business name{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </span>
                                }
                              >
                                <Form.Item
                                  label="BusinessName"
                                  name="BusinessName"
                                  noStyle
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please input your business name!",
                                    },
                                  ]}
                                >
                                  <Row>
                                    <Col span={24}>
                                      <Input
                                        className="custom-input"
                                        style={{
                                          height: "40px",
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </Form.Item>
                              </Form.Item>
                              <Form.Item
                                label={<span>Email id (optional)</span>}
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "10px",
                                }}
                              >
                                <Form.Item
                                  label="Emailid"
                                  name="EmailId"
                                  noStyle
                                >
                                  <Row>
                                    <Col span={24}>
                                      <Input className={"custom-input"} />
                                    </Col>
                                  </Row>
                                </Form.Item>
                              </Form.Item>
                              <Form.Item
                                label={
                                  <span>
                                    Business mobile (for OTP){" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </span>
                                }
                              >
                                <Form.Item
                                  label="Business mobile (for OTP)"
                                  name="mobileno"
                                  noStyle
                                >
                                  <Row>
                                    <Col span={24}>
                                      <Input
                                        className={"custom-input"}
                                        disabled="true"
                                        value={emailOrPhone}
                                      />
                                    </Col>
                                  </Row>
                                </Form.Item>
                              </Form.Item>
                              <Form.Item
                                label={
                                  <span>
                                    GSTIN{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </span>
                                }
                              >
                                <Form.Item
                                  label="GSTIN"
                                  name="gstin"
                                  noStyle
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your GSTIN!",
                                    },
                                    {
                                      pattern:
                                        /^[0-9]{2}[A-Za-z]{3}[A-Za-z0-9]{10}$/,
                                      message: "Invalid GSTIN format.",
                                    },
                                  ]}
                                >
                                  <Row>
                                    <Col span={24}>
                                      <Input
                                        maxLength={15}
                                        className={"custom-input"}
                                      />
                                    </Col>
                                  </Row>
                                </Form.Item>
                              </Form.Item>
                              <Form.Item
                                label={
                                  <span>
                                    Store category{" "}
                                    {/* <span style={{ color: "red" }}>*</span> */}
                                  </span>
                                }
                              >
                                <Form.Item
                                  label="Store category"
                                  name="storecategory"
                                  noStyle
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message:
                                  //       "Please select store category!",
                                  //   },
                                  // ]}
                                >
                                  <Row>
                                    <Col span={24}>
                                      {/* <Select
  mode="multiple"
  placeholder="Select store categories"
  dropdownRender={(menu) => (
    <div>
      {menu}
      <div style={{ padding: 8, borderTop: '1px solid #ddd' }}>
        <Checkbox
          indeterminate={false}
          onChange={(e) => console.log(e)}
          checked={false}
        >
          Select all
        </Checkbox>
      </div>
    </div>
  )}
>
  {getVendorCategorydata && getVendorCategorydata?.data.map((item) => (
    <Select.Option key={item?.id} value={item?.name}>
      <Checkbox value={item?.name} />
      {item?.name}
    </Select.Option>
  ))}
</Select> */}
                                      <SearchDropdown
                                        list={getVendorCategorydata?.data}
                                        label={"Store category"}
                                        placeholder={"Store category"}
                                        selectedItems={sellercategory}
                                        setSelectedItems={setsellercategory}
                                        isCustomBorder={true}
                                        isLoading={isCategoryLoading}
                                        className={"custom-ddl"}
                                      />
                                    </Col>
                                  </Row>
                                </Form.Item>
                              </Form.Item>
                              <Typography.Title level={4}>
                                Address
                              </Typography.Title>
                              <Form.Item
                                label={
                                  <span>
                                    House no/ Building name (Address line 1)
                                  </span>
                                }
                              >
                                <Form.Item
                                  label="Address line 1"
                                  name="address1"
                                  noStyle
                                >
                                  <Row>
                                    <Col span={24}>
                                      <Input className={"custom-input"} />
                                    </Col>
                                  </Row>
                                </Form.Item>
                              </Form.Item>
                              <Form.Item
                                label={<span>Road/ Area/ Colony</span>}
                              >
                                <Form.Item
                                  label="Road/ Area/ Colony"
                                  name="area"
                                  noStyle
                                >
                                  <Row>
                                    <Col span={24}>
                                      <Input className={"custom-input"} />
                                    </Col>
                                  </Row>
                                </Form.Item>
                              </Form.Item>
                              <Form.Item label={<span>Pincode</span>}>
                                <Form.Item
                                  label="Pincode"
                                  name="pincode"
                                  noStyle
                                >
                                  <Row>
                                    <Col span={24}>
                                      <Input maxLength={6} className={"custom-input"} />
                                    </Col>
                                  </Row>
                                </Form.Item>
                              </Form.Item>
                              <Form.Item
                                label={
                                  <span>
                                    City <span style={{ color: "red" }}>*</span>
                                  </span>
                                }
                              >
                                <Form.Item
                                  label="City"
                                  name="city"
                                  noStyle
                                  validateTrigger="onChange"
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message: "Please input your city",
                                  //   },

                                  // ]}
                                >
                                  <Row>
                                    <Col span={24}>
                                      {/* <Input className={"custom-input"} /> */}
                                      <Select
                                        onChange={handleCitySelectChange}
                                        className={`custom-input ${
                                          cityError ? "input-error" : ""
                                        }`}
                                        showSearch
                                        value={searchCityValue}
                                        placeholder="Select city"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {cityOptions.map((option) => (
                                          <Select.Option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                      {cityError && (
                                        <span className="ant-form-item-explain-error">
                                          {cityError}
                                        </span>
                                      )}
                                    </Col>
                                  </Row>
                                </Form.Item>
                              </Form.Item>
                              <Form.Item
                                label={
                                  <span>
                                    State{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </span>
                                }
                              >
                                <Form.Item
                                  label="State"
                                  name="state"
                                  noStyle
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message: "Please select state!",
                                  //   },
                                  // ]}
                                >
                                  <Row>
                                    <Col span={24}>
                                      {/* <Input className={"custom-input"} /> */}
                                      <Select
                                        value={searchStateValue}
                                        onChange={handleStateSelectChange}
                                        className={`custom-input ${
                                          stateError ? "input-error" : ""
                                        }`}
                                        showSearch
                                        placeholder="Select state"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {stateOptions.map((option) => (
                                          <Select.Option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                      {stateError && (
                                        <span className="ant-form-item-explain-error">
                                          {stateError}
                                        </span>
                                      )}
                                    </Col>
                                  </Row>
                                </Form.Item>
                              </Form.Item>
                              <Form.Item label={<span>Google link</span>}>
                                <Form.Item
                                  label="Google link"
                                  name="googlelink"
                                  noStyle
                                >
                                  <Row>
                                    <Col span={24}>
                                      <Input className={"custom-input"} />
                                    </Col>
                                  </Row>
                                </Form.Item>
                              </Form.Item>
                              <Form.Item>
                                <Form.Item
                                  label="Terms"
                                  name="Terms"
                                  noStyle
                                  rules={[
                                    {
                                      required: true,
                                      message: "Accept terms & conditions",
                                    },
                                  ]}
                                >
                                  <p>
                                    <Checkbox
                                    // checked={agreeTerms}
                                    // onChange={(e) => setAgreeTerms(e.target.checked)}
                                    >
                                      By clicking agree and continue you agree
                                      to our{" "}
                                      <Text className="hyperlink-text">
                                       <a href="/Termsandcondition" target="_blank" rel=""> 
                                       Terms and Conditions
                                       </a>
                                      </Text>{" "}
                                      and{" "}
                                      <Text className="hyperlink-text">
                                      <a href="/privacypolicy" target="_blank" rel=""> 
                                      Privacy Statement
                                      </a>
                                      </Text>
                                      .
                                    </Checkbox>
                                  </p>
                                </Form.Item>
                              </Form.Item>
                              {/* <Form.Item
                                    label={
                                      <span>
                                        Password{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </span>
                                    }
                                    style={{
                                      marginTop: "20px",
                                      marginBottom: "10px",
                                    }}
                                  > */}
                              {/* <Form.Item
                            label="Password"
                            name="Password"
                            noStyle
                            rules={[
                              {
                                required: true,
                                message: "Please input your Password!",
                              },
                            ]}
                          >
                            <Row>
                              <Col span={24}>
                                <Input
                                  type="password"
                                  className={Password ? "custom-input" : ""}
                                  style={{
                                    height: "40px",

                                    borderColor: Password ? "" : "#bf2600",
                                    backgroundColor: Password ? "" : "#ffebe6",
                                  }}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                              </Col>
                            </Row>
                          </Form.Item> */}
                              {/* </Form.Item> */}
                            </Col>
                            <Col className="footer-class">
                              <Form.Item>
                                <Button
                                  style={{
                                    width: "100%",
                                    height: "40px",
                                    fontWeight: 500,
                                  }}
                                  type={"primary"}
                                  htmlType="submit"
                                  onClick={handleValidation}
                                  loading={isSubmitting}
                                >
                                  {"AGREE AND CONTINUE"}
                                </Button>
                              </Form.Item>
                            </Col>
                          </Form>
                        </>
                      </>
                    )}
                  </>
                </>
                {/* ) : (
                  <>
                    <Row style={{ gap: "1.5rem" }}>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Col style={{ width: "27%" }}>
                          <LeftArrowIcon
                            style={{ cursor: "pointer" }}
                            onClick={handleForgetBack}
                          />{" "}
                        </Col>
                        <Text
                          style={{
                            fontSize: "20px",
                            fontWeight: 500,
                            width: "73%",
                          }}
                        >
                          Forgot password
                        </Text>
                      </Col>
                      <Col>
                        <Text style={{ fontSize: "13.7px" }}>
                          Please specify your email address to receive
                          instructions for resetting it. If an account exists by
                          that email, we will send a password reset
                        </Text>
                      </Col>
                    </Row>
                    <Form
                      name="basic"
                      style={{
                        maxWidth: 500,
                        marginTop: "1rem",
                      }}
                      initialValues={{
                        remember: true,
                      }}
                      layout="vertical"
                      autoComplete="off"
                    >
                      <Form.Item label="Email id">
                        <Form.Item
                          label="Email id"
                          name="username"
                          noStyle
                          rules={[
                            {
                              required: true,
                              message: "Please input your Email id!",
                            },
                          ]}
                        >
                          <Input
                            className="custom-input"
                            style={{ height: "40px" }}
                          />
                        </Form.Item>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          style={{
                            width: "100%",
                            fontWeight: 500,
                            height: "40px",
                            backgroundColor: "#0354a3",
                          }}
                          htmlType="submit"
                        >
                          SEND RESET LINK
                        </Button>
                      </Form.Item>
                    </Form>
                  </>
                )} */}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SellerLogin;
