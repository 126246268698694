import React from "react";
import SellerLayout from "../../../layouts/SellerLayout";
import { Navigate } from "react-router-dom";
import { Content } from "antd/es/layout/layout";

const SellerRoute = (props) => {
  const { children } = props;
  const isAuthenticated = localStorage.getItem("accessToken") !== null;
  const isBMPIdAuthenticated = localStorage.getItem("bmp_id") !== null;
  return (isAuthenticated && isBMPIdAuthenticated) ? (
    <SellerLayout>
      <Content>{children}</Content>
    </SellerLayout>
  ) : (
    <Navigate to="/Sellerlogin" replace />
  );
};

export default SellerRoute;
