import React from "react";
import SellerOrdersComponent from "../../../components/SellerComponents/SellerOrdersComponent/SellerOrdersComponent";
import { StatusCardSkeleton } from "../../../components/skeleton/StatusCardSkeleton";
import { ReactComponent as NoOrders } from "../../../assets/seller/icons/Noorders.svg";
import { Col,Typography } from "antd";
export default function SellerNewOrders(props) {
  const {
    data,
    updateOrderStatusLoading,
    updateOrderStatusApi,
    isFetching,
    setSearchedValue,
  } = props; 

  {
    // eslint-disable-next-line
    const handleSearchedValue = (params) => {
      setSearchedValue(params);
      // setCurrentPage(1);
    };
    return (
      <>
        {isFetching ? (
          <StatusCardSkeleton />
        ) : (
          <>
            
            {data?.length > 0 ? (
            <SellerOrdersComponent
              data={data}
              updateOrderStatusLoading={updateOrderStatusLoading}
              updateOrderStatusApi={updateOrderStatusApi}
              
            />
          ) : (
            <>
              <Col
                className="no-order-data-wrapper"
                xs={24}
                md={24}
                lg={24}
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60vh", // set the height to the full viewport height
                }}
              >
                <NoOrders />
                <Typography.Text className="mb-2 no-order-records">
                  No order found!
                </Typography.Text>
                {/* <Text className="no-order-records-small">
                  Oops! We can’t find what you’re looking for.
                </Text> */}
              </Col>
            </>
          )}
          </>
        )}
      </>
    );
  }
}
