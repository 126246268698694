import {
    // Button,
    Col,
    Table,
    Typography,
  } from "antd";
  import React from "react";
  import "./SellerDeliveryTable.css";
  import { ReactComponent as InfoIcon } from "../../../../../../assets/icons/InfoIcon.svg";
  const { Text } = Typography;
  const SellerDeliveryTable = ({ columns, data, deliveryStatus, editSubOrderData }) => {
    const totalPriceAfterTax = data?.reduce((acc, current) => acc + Number(current.price_after_tax || 0), 0);
    let discountAmount = 0;
  if (editSubOrderData?.discount_in==="percentage") {
    discountAmount = (totalPriceAfterTax * editSubOrderData?.discount) / 100;
  } else {
    discountAmount = editSubOrderData?.discount;
  }
// eslint-disable-next-line 
  const finalTotal = totalPriceAfterTax - discountAmount;
  // console.log("🚀 ~ SellerDeliveryTable ~ finalTotal:", finalTotal)

    return (
      <>
        <Table
          className={`deliveryBy-table custom-tablehead ${deliveryStatus === "Rejected by seller" || deliveryStatus === "Cancelled" ? "rejected-table" : ""}`}
          columns={columns}
          pagination={false}
        
          footer={() => (
            <Col>
              <Col className={`deliveryBy-calc ${deliveryStatus === "Rejected by seller" || deliveryStatus === "Cancelled" ? "rejected-table" : ""}`}>
                <Col className="deliveryBy-align-amount">
                  <Text>Subtotal (with Tax)</Text>
                  <Text>
                  ₹ {Number(editSubOrderData?.sub_total || 0).toFixed(2)}
                    {/* ₹ {(Number(editSubOrderData?.sub_total || 0)+Number(editSubOrderData?.tax||0)).toFixed(2)} */}
                  </Text>
                </Col>
                <Col className="deliveryBy-align-amount">
                  <Text>Discount</Text>
                  <Text>
                    ₹ {Number(discountAmount || 0).toFixed(2)}
                  </Text>
                </Col>
                <Col className="deliveryBy-align-amount">
                  <Text className="tax-info">
                    {" "}
                    Tax
                    <InfoIcon />
                  </Text>
                  <Text>
                    ₹{" "}
                    {/* {Number((editSubOrderData?.cgst || 0) + (editSubOrderData?.sgst || 0)).toFixed(2)} */}
                    {(editSubOrderData?.tax||0).toFixed(2)}
                  </Text>
                </Col>
                {/* <Col className="deliveryBy-align-amount">
                  <Text>MOB Delivery fee</Text>
                  <Text>
                    ₹ {Number(editSubOrderData?.delivery_fee || 0).toFixed(2)}
                  </Text>
                </Col> */}
              </Col>
              <Col className="deliveryBy-align-total">
                <Text className="deliveryBy-text">Total</Text>
                <Text className="deliveryBy-text">
                  ₹ {Number(editSubOrderData?.total || 0).toFixed(2)}
                </Text>
              </Col>
            </Col>
          )}
          dataSource={data?.map((itemData) => ({
            ...itemData,
            //           button:(
            //  deliveryStatus==="Delivered" && <Col className="btn-containerI">
  
            // <Button className="styled-btn">Return</Button>
            // <Button className="styled-btn">Exchange</Button>
            // </Col>
  
            //           )
          }))}
        />
      </>
    );
  };
  
  export default SellerDeliveryTable;
  