import React from "react";
import { Col, Typography, Row, Card } from "antd";
import "./SellerMobCreditOnboarding.css";

const { Text } = Typography;

const rejectclients = (props) => {
  //   const {

  //   } = props;
  // eslint-disable-next-line

  return (
    <>
      <Col className="mt-4">
        <Row className="mt-4">
          <Card className="card-content">
            <Col className="p-0 d-flex">
              <Text className="">
                We are sorry that the client Line of Credit did NOT get
                approved. (Systems are automatically aligned to RBI mandates)
                <br />
                <Text className="">
                  You may ask the client/s take the following steps to improve
                  your credit score (CIBIL) & apply again in the future;
                  <br />
                  1) Monitor business credit reports regularly
                  <br />
                  2) Pay your bills on time
                  <br />
                  3) Don’t max out your credit card often
                  <br />
                  4) File your GSTIN filings on time
                  <br />
                </Text>
              </Text>
            </Col>
            <Col className="mt-4 card-alert-strip">
              NOTE: We have noticed that rejections are largely because of low
              CIBIL score due to past delinquency. It is advisable to work only
              on pre-payment with the client.
            </Col>
          </Card>
        </Row>
      </Col>
    </>
  );
};

export default React.memo(rejectclients);
