import React, { useEffect, useState } from "react";
import { Col, Input } from "antd";

import "./TableFooter.css";
// import { getBmpPrice } from "../../../../redux/slices/createQuote/action";
// import { useDispatch, useSelector } from "react-redux";
// import { bmpPriceSelector } from "../../../../redux/slices/createQuote/selector";

const FloatInput = (props) => {
  const [focus, setFocus] = useState(true);
  // const dispatch = useDispatch();
  let {
    label,
    value,
    placeholder,
    type,
    required,
    setAddDeliveryNotes,
    // tableIndex,
    addDeliveryNotes,
    // editCondition,
  } = props;

  // const bmpData = useSelector(bmpPriceSelector);

  const deliveryNotes = (event) => {
    const value = event.target.value;  

    if (value !== "") {
      setFocus(true);
    }
    setAddDeliveryNotes(value);
    console.log("🚀 ~ FloatInput ~ value:", addDeliveryNotes);
  };
  useEffect(() => {
    if (addDeliveryNotes !== "") {
      setFocus(true);
    } else {
      setFocus(false);
    }
  }, [addDeliveryNotes]);
  // useEffect(() => {
  //   if (editCondition && bmpData[tableIndex]?.quote_delivery_notes) {
  //     setAddDeliveryNotes(bmpData[tableIndex]?.quote_delivery_notes);
  //     setFocus(true);
  //   } else {
  //     setAddDeliveryNotes("");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [editCondition]);
  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const requiredMark = required ? <span className="text-danger">*</span> : null;

  return (
    <Col
      className="float-label"
      // onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Input       
        className="toggle_input"
        type={type}
       // defaultValue={addDeliveryNotes || ""}
        value={addDeliveryNotes || ""}
        // defaultValue={
        //   editCondition ? bmpData[tableIndex]?.quote_delivery_notes : value
        // }
        onPressEnter={deliveryNotes}
        onBlur={deliveryNotes}
        onChange={(event) => deliveryNotes(event)}
        style={{
          borderColor: "#f3f2ef",
          backgroundColor: "#f3f2ef",
          color: "#0a243f",
        }}
      />
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </Col>
  );
};

export default FloatInput;
