import { React } from "react";
import AddVehicleCustomDropdown from "../../../../../../ManageOrders/OrderDetails/OrderModals/AddPaymentModal/AddVehicleCustomDropdown";
import { Col, Typography, Avatar, Input } from "antd";
import { ReactComponent as DeleteIcon } from "../../../../../../../assets/icons/Delete icon.svg";
import { baseUrl } from "../../../../../../../commonUtils/commonUtils";

const Text = Typography;

const SellerVehicleRequest = ({
  vehiclesmaster,
  addVehicleFormData,
  setAddVehicleFormData,
}) => {
  

  const handleVehicleRemove = (id) => {    
    const updatedVehicles = addVehicleFormData.filter(
      (vehicle) => vehicle.id !== id
    );
    setAddVehicleFormData(updatedVehicles);
  };
  const vehicleTypeVisible = (id) => {  
   
  
    const updatedVehicles = addVehicleFormData.map((vehicle) =>
      vehicle.id === id
        ? {
             ...vehicle,
            // vechicle_type: "SELECT VEHICLE",
            // vechicle_type_Id:0,
            // vechicle_image: "",
            // vechicle_weight: "",
            // vechicle_storage: "",
            vehicleVisible: vehicle.vehicleVisible === true ? false : true,
          }
        : vehicle
    );
   
    
    setAddVehicleFormData(updatedVehicles);
  };
  const handleVehcileTypeClick = (selectedValue, id) => {
    // setVehicleTypeSelected(selectedValue);

    const selectedVehicle = vehiclesmaster?.find((option) => {
      return option.vechicle_type === selectedValue;
    });
    const updatedVehicles = addVehicleFormData.map((vehicle) =>
      vehicle.id === id
        ? {
            ...vehicle,
            vechicle_type: selectedValue,
            vechicle_type_Id: selectedVehicle.id,
            vechicle_image: selectedVehicle.vechicle_image,
            vechicle_weight: selectedVehicle.vechicle_weight,
            vechicle_storage: selectedVehicle.vechicle_storage,
            vehicleVisible: !selectedVehicle.vechicle_storage,
          }
        : vehicle
    );
    setAddVehicleFormData(updatedVehicles);
    // setSelectedVehicleData(selectedVehicle);
  };
  const handleQuantityChange = (id, value) => {
    value = isNaN(value) ? 0 : value;
    const updatedQuantity = value !== 0 ? value : "";
    const updatedVehicles = addVehicleFormData.map((vehicle) =>
      vehicle.id === id ? { ...vehicle, quantity: updatedQuantity } : vehicle
    );
    setAddVehicleFormData(updatedVehicles);
  };
  const addVehicle = () => {
    const newId =
      addVehicleFormData.length > 0
        ? addVehicleFormData[addVehicleFormData.length - 1].id + 1
        : 1;
    const newVehicle = {
      id: newId,
      vechicle_type: "SELECT VEHICLE",
      vechicle_type_Id: 0,
      quantity: 1,
      vechicle_image: "",
      vechicle_weight: "",
      vechicle_storage: "",
      vehicleVisible: false,
    };
    setAddVehicleFormData([...addVehicleFormData, newVehicle]);
  };

  return (
    <>
      <Col>
        <Text>{addVehicleFormData?.length-1} Vehicle selected</Text>
      </Col>
      {addVehicleFormData.map((vehicle) => (
        <Col className="add-vehicle-type-request">
          <Col className="add-vehicle-type-wrapper" style={{ gap: "10px" }}>
            <Col className="dropdown-wrapper" style={{ minWidth: "55%" }}>
              <AddVehicleCustomDropdown
                handleOptionClick={handleVehcileTypeClick}
                visible={vehicle?.vehicleVisible}
                handleVisibleChange={vehicleTypeVisible}
                selectedText={vehicle?.vechicle_type}
                options={vehiclesmaster}
                id={vehicle.id}
              />
            </Col>
            <Col
              className="vehicle-type-del"
              style={{ minWidth: "15%" }}
              onClick={() => handleVehicleRemove(vehicle.id)}
            >
              <DeleteIcon />
            </Col>
            <Col className="" style={{ minWidth: "25%" }}>
              <Input
                className="addPayment-input"
                name="quantity"
                value={vehicle.quantity}
                
                onChange={(e) =>
                  handleQuantityChange(vehicle.id, parseInt(e.target.value))
                }
                maxLength={10}
                onKeyDown={(e) => {
                  if (
                    (e.key < "0" || e.key > "9") &&
                    e.key !== "Backspace" &&
                    e.key !== "ArrowLeft" &&
                    e.key !== "ArrowRight" &&
                    e.key !== "Tab"
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </Col>
          </Col>
          {vehicle?.vechicle_type &&
            vehicle?.vechicle_type !== "SELECT VEHICLE" && (
              <Col className="vehicle-disc-wrapper">
                <Avatar
                  shape="square"
                  src={`${baseUrl}${vehicle?.vechicle_image}`}                 
                  className="vehicle-icon"
                />

                <Col className="vehicle-disc">
                  <Col className="vehicle-disc-align">
                    <Col style={{ width: "60%" }}>
                      <Text
                        className="vehicle-disc-key"
                        style={{ width: "unset" }}
                      >
                        Maximum shipment weight:
                      </Text>
                    </Col>
                    <Text className="text-right vehicle-disc-key text-bold" style={{ width: "40%" }}>
                      {vehicle?.vechicle_weight}
                    </Text>
                  </Col>
                  <Col className="vehicle-disc-align">
                    <Col style={{ width: "55%" }}>
                      <Text
                        className="vehicle-disc-key "
                        style={{ width: "unset" }}
                      >
                        Vehicle storage (LxBxH):
                      </Text>
                    </Col>
                    <Text className="text-right vehicle-disc-key text-bold"  style={{ width: "45%" }}>{`${vehicle?.vechicle_storage} cm`}</Text>
                  </Col>
                </Col>
              </Col>
            )}
        </Col>
      ))}
      <Col>
        <button
          className="vehicle-cancel-btn vehicle-add-button"
          onClick={addVehicle}
        >
          + Add new vehicle
        </button>
      </Col>
    </>
  );
};
export default SellerVehicleRequest;
