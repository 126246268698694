import React from "react";
import { Typography, Col, Row, Modal, Button } from "antd";

import toggleIcon from "../../../../assets/seller/icons/toggle.svg";
import boxIcon from "../../../../assets/seller/icons/box.svg";
import bikeIcon from "../../../../assets/seller/icons/bike.svg";
import notesIcon from "../../../../assets/seller/icons/notes.svg";
const { Text } = Typography;

export default function OrderStaticText({heading,description}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleGotIt = () => {
    // Add any additional logic when the "Got It" button is clicked
    setIsModalOpen(false);
  };
  return (
    <>
     {description ? (
      <Row className="mt-2 mb-2">
       
        <Col span={24} sm={8} md={8} lg={10} xl={12} >
          <Text className="how-to-use">
           {description}
          </Text>
        </Col>

        <Col span={24} sm={8} md={8} lg={10} xl={12} className="text-right how-to-use">
          <Text className="cursor-pointer mr-1 text-underline"   aria-label="Credit Icon" onClick={handleModalOpen}>
           {heading}
          </Text>
        </Col>
      </Row>
      ):(
        <Col span={10} sm={8} md={8} lg={10} xl={8} className="text-right how-to-use">
          <Text className="cursor-pointer mr-1 text-underline"   aria-label="Credit Icon" onClick={handleModalOpen}>
           {heading}
          </Text>
        </Col>
      )}
      <Modal
      className="order-modal-style"
        title="How to accept order?"
        visible={isModalOpen}
        onCancel={handleModalClose}
        footer={[
          <div className="modal-footer d-block text-center footer-bottom-border-line">
            <Button
              key="cancel"
              className="btn-cancel order-cancel-btn-style btnI"
              onClick={handleModalClose}
            >
              CANCEL
            </Button>
            
            <Button
              key="gotIt"
              type="primary"
              className="btn-theme order-got-btn-style btnI"
              onClick={handleGotIt}
            >
              GOT IT!
            </Button>
          </div>,
        ]}
      >
        <div style={{ height: "450px", overflow: "auto",padding: "0px" }}>
          <Row className="static-list accept-order-padding pt-0 mt-4">
            <Col span={20}>
              <p className="customer-font-style">
                Click on “view to accept” button
              </p>
              <p className="view-order-style">
                View the order details and go through the items and customer
                notes
              </p>
            </Col>
            <Col span={4} className="text-right"><img
                      src={toggleIcon}
                      className="icon-image-static"
                      alt=""
                    /></Col>
          </Row>
          <Row className="static-list accept-order-padding">
            <Col span={20}>
              <p className="customer-font-style">
              Check material availability
              </p>
              <p className="view-order-style">
              Go through the items details and check if the items are available with you
              </p>
            </Col>
            <Col span={4} className="text-right">
            <img
                      src={notesIcon}
                      className="icon-image-static"
                      alt=""
                    /></Col>
          </Row>
          <Row className="static-list accept-order-padding">
            <Col span={20}>
              <p className="customer-font-style">
              Click on “ Assign vehicle to accept” button 
              </p>
              <p className="view-order-style">
              Click on “ Assign vehicle to accept” button if the items are available with you. If not then click on reject button or call MOB support 
              </p>
            </Col>
            <Col span={4} className="text-right"><img
                      src={toggleIcon}
                      className="icon-image-static"
                      alt=""
                    /></Col>
          </Row>
          <Row className="static-list accept-order-padding">
            <Col span={20}>
              <p className="customer-font-style">
              Assign vehicle and accept
              </p>
              <p className="view-order-style">
              Assign the type of vehicle that would be suitable for the order and click on “ Assign vehicle to accept” button.
              </p>
            </Col>
            <Col span={4} className="text-right"><img
                      src={bikeIcon}
                     className="icon-image-static"
                      alt=""
                    /></Col>
          </Row>
          <Row className="static-list accept-order-padding">
            <Col span={20}>
              <p className="customer-font-style">
              Keep the items ready for pickup
              </p>
              <p className="view-order-style">
              Please keep the items packed and ready for delivery. Our support team will coordinate with you regarding the delivery time.
              </p>
            </Col>
            <Col span={4} className="text-right"><img
                      src={boxIcon}
                      className="icon-image-static"
                      alt=""
                    /></Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
