import { createApi } from "@reduxjs/toolkit/query/react";
import { loginBaseQuery } from "../baseQuery";
export const RequestMobCreditApi = createApi({
  reducerPath: "RequestMobCreditApi",
  baseQuery: loginBaseQuery,
  endpoints: (builder) => ({
    requestLineOfCredit: builder.mutation({
        query: ({body}) => ({
          method: "POST",
          url: `/api/request_line_of_credit/`,
          body,
        }),
      }),  
    
  }),
});

export const {
    useRequestLineOfCreditMutation,  
} = RequestMobCreditApi;

export default RequestMobCreditApi;
