import {
  Avatar,
  Button,
  Col,
  Skeleton,
  Typography,
  Tag,
  Card,
  Row,
  Badge,
  Modal,
  Select,
} from "antd";

import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./SellerViewDetails.css";
// import DropdownComponent from "../../../../modules/Request/RFQ/DropdownComponent";
// import { EyeOutlined } from "@ant-design/icons";
import { ReactComponent as InvoiceIcon } from "../../../../../assets/seller/icons/invoice.svg";
import { ReactComponent as ElectricityIcon } from "../../../../../assets/seller/icons/electricity.svg";
import  partyIcon  from "../../../../../assets/seller/icons/party-popper.webp";
import { ReactComponent as NotesIconIcon } from "../../../../../assets/seller/icons/notesicon.svg";
import { ReactComponent as Mobcreditlogo } from "../../../../../assets/seller/icons/Mobcreditlogo.svg";
import { ReactComponent as Mobcreditnobglogo } from "../../../../../assets/seller/icons/creditlogo_button.svg";
import { ReactComponent as MobLogoFinalIcon } from "../../../../../assets/seller/icons/mob_logo_final.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/edit-2.svg";
import { ReactComponent as RupeeIcon } from "../../../../../assets/seller/icons/addpayment.svg";
import { ReactComponent as Checklogo } from "../../../../../assets/seller/icons/check.svg";
import { ReactComponent as Copylogo } from "../../../../../assets/seller/icons/copy.svg";
import AddPaymentModal from "../../../../../components/SellerComponents/SellerPayable/AddPaymentModal/AddPaymentModal";
//   import StatusDropDown from "../SellerOrderItems/StatusDropDown";
import { useNavigate, useParams, Link } from "react-router-dom";
//   import NoVehicleAssigned from "./NoVehicleAssigned/NoVehicleAssigned";
import DeliveryTable from "./SellerDeliveryTable/SellerDeliveryTable";

import { ReactComponent as MobCreditIcon } from "../../../../../assets/seller/icons/credit.svg";
import { dateFormat, onlyDateFormat } from "../../../../../commonFuntions/CommonUtilFunctions";

//   import AddVehicleModal from "./AddVehicleModal/AddVehicleModal";
//   import CustomProfileSection from "../../../../components/CustomContainer/CustomProFileSection/CustomProfileSection";
//   import SelectedVehicleTable from "./SelectedVehicleTable/SelectedVehicleTable";

// import { UpArrow } from "../../../../components/Icons/UpArrow";
// import { DownArrow } from "../../../../components/Icons/DownArrow";

import {
  useLazySellerGetSubOrderDetailsQuery,
  useUpdateOrderStatusMutation,
  //useUpdateVehcileRequestStatusMutation
} from "../../../../../apis/Seller/SellerStoreSalesAPI";
import { useDispatch, useSelector } from "react-redux";
import {
  editSubOrderDetailSelector,
  // vehicleDetailSelector,
  // vehicleDetailSelector,
} from "../../../../../redux/slices/manageOrders/selector";
import { getEditSubOrderDetail } from "../../../../../redux/slices/manageOrders/action";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../../NotificationToast/NotificationToast";

import {
  baseUrl,
  demandRupifiUrl,
  showDemandRupifiUrl,
} from "../../../../../commonUtils/commonUtils";
import {
  handlePdfDownload,
  testFileUrl,
} from "../../../../../commonFuntions/CommonUtilFunctions";
import { useAddNewPaymentMutation } from "../../../../../apis/Seller/SellerPayablesAPI";
// import CustomSelect from "../../../../../components/SelectableDropdown/CustomSelect/CustomSelect";

//import SellerChooseVehicleModal from "../SellerModals/SellerChooseVehicleModal/SellerChooseVehicleModal";
//import SellerConfirmationModal from "../../SellerOrderModals/SellerConfirmationModal/SellerConfirmationModal";
//import { SellerCancelOrderModal } from "../SellerOrderModals/SellerCancelOrderModal/SellerCancelOrderModal";
// import SelectedVehicleTable from "../../../../ManageOrders/OrderDetails/ViewDetails/SelectedVehicleTable/SelectedVehicleTable";

const { Text } = Typography;

const SellerStoreViewDetails = () => {
  const { id } = useParams();

  // const bmp_id = localStorage.getItem("bmp_id");
  const bmp_name = localStorage.getItem("bmp_name");
  const copyLinkToClipboard = async (linkText, text) => {
    // const linkText = paymentLinkRef.current.textContent;

    try {
      await navigator.clipboard.writeText(`${linkText}`);
      showSuccessToast(`${text} copied to clipboard successfully`);
    } catch (err) {
      showErrorToast(`Failed to copy ${text} to clipboard`, err);
    }
  };
  const [formData, setFormData] = useState({});
  const [datePickerEmpty, setDatePickerEmpty] = useState(false);
  const [paymentMode, setPaymentMode] = useState();
  const [inputDate, setInputDate] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [editPayment, setEditPayment] = useState({
    state: false,
    check: true,
  });
  // eslint-disable-next-line
  const [titleText, settitleText] = useState("Add Payment");
  const [openUploadModal, setOpenUploadModal] = useState(false);
  // eslint-disable-next-line
  const [dateSearch, setDateSearch] = useState({
    start_date: "",
    end_date: "",
  });
  const [editOrderVal, seteditOrderVal] = useState({});
  // eslint-disable-next-line
  const [paymentStatus, setPaymentStatus] = useState("");
  // eslint-disable-next-line
  const [updateVehicleModalOpen, setUpdateVehicleModalOpen] = useState(false);
  const editSubOrderData = useSelector(editSubOrderDetailSelector);
 
  const [
    orderNewPaymentApi,
    // eslint-disable-next-line
    { isSuccess: successnewOrderPayment, isLoading: isPaymentFetching },
  ] = useAddNewPaymentMutation();
  // eslint-disable-next-line
  const [productTableData, setProductTableData] = useState({});

  const [
    getSubOrderDetailApi,
    { data: editSubData, isFetching: isFetchingEditSubData },
  ] = useLazySellerGetSubOrderDetailsQuery();
  const [
    updateOrderStatusApi,
    { isLoading: updateOrderStatusLoading },
    //  { isSuccess: successUpdateOrderStatusApi }
  ] = useUpdateOrderStatusMutation();
  // eslint-disable-next-line
  const [checkboxState, setCheckboxState] = useState({
    email: false,
    phone_number: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleGenerateTaxInvoice = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setOpenUploadModal(false);
    setPaymentMode("");
    setInputDate("");
    // setDatePickerEmpty(false);
    // setFormData({});
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleChangeStatus = async (status) => {
    const response = await updateOrderStatusApi({
      order_id: id,
      order_status: status,
      // params: {
      //   status,
      // },
    });
    // console.log("🚀 ~ handleChangeStatus ~ response:", response)
    if (response?.data?.status === true) {
      setIsModalOpen(false);
      // showSuccessToast(response?.data?.message);
      showSuccessToast("Invoice saved!");
      getSubOrderDetailApi(id);
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };
  const handleNavigateDemand = (url) => {
    window.open(`${url}`, "_blank");

    // window.open(`${"uate/"}${demandRupifiUrl}${item}`, "_blank");
  };
  const handleViewDetals = (id, status) => {
    const url = `/Seller/orders/SellerViewDetails/${id}`;
    window.open(url, "_blank");
  };
  const handleViewPdf = (url) => {
    console.log("🚀 ~ handleViewPdf ~ url:", url);
    if (testFileUrl(url || "")) {
      handlePdfDownload(url);
    } else {
      showErrorToast("Something went wrong");
    }
  };
  const totalQuantity = editSubData?.data?.items.reduce(
    (acc, item) => acc + item.quantity,
    0
  );

  const totalAmountPaid = (editSubOrderData?.payments || []).reduce(
    (acc, item) => acc + item.amount,
    0
  );

  const totalAmountDue = (
    Number(editSubOrderData?.total) - Number(totalAmountPaid)
  ).toFixed(2);

  // const [
  //   updateOrderVehicleRequestApi,
  //   { isLoading: updateOrderVehicleRequestLoading },
  //   //  { isSuccess: successUpdateOrderStatusApi }
  // ] = useUpdateVehcileRequestStatusMutation();
  // const vehicleDetailData = useSelector(vehicleDetailSelector);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const onEdit = (data) => {
  //   setVehicleUpdateData(data);
  //   setUpdateVehicleModalOpen(true);
  // };

  // eslint-disable-next-line

  const columnsDeliveryBy = [
    {
      title: `${editSubOrderData?.items?.length} Items`,
      width: "200px",
      // dataIndex: "products",
      render: (params, record) => {
        return (
          <>
            <Col className="table-product-wrapper">
              <Col>
                <Avatar
                  size={44}
                  shape="square"
                  src={`${baseUrl}${params?.product_image}`}
                />
              </Col>
              <Col
                className="deliveryBy-items"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  width: "250px",
                }}
              >
                <Text>{params?.product?.product_name}</Text>
                {params?.mob_sku && (
                  <Text style={{ color: "#6c7c8c" }}>
                    HSN: {params?.product?.hsn_code}
                  </Text>
                )}
              </Col>
            </Col>
            {/* {deliveryStatus === "Delivered" && (
                <Col className="return-exchange-notify">
                  <Col
                    className="status-pointer"
                    style={{ backgroundColor: "#4fb589" }}
                  />
                  <Text className="delivery-status-notify">
                    Return/ Exchange window open till 12 Jan 2022
                  </Text>
                </Col>
              )} */}
          </>
        );
      },
    },
    {
      title: "Price (Before tax)",
      align: "right",
      width: "200px",
      render: (params, record) => {
        return (
          <Col className="text-right" style={{ marginRight: "16px" }}>
            {/* {` ₹ ${Number(params).toFixed(2)}`} */}
            {` ₹ ${Number(params?.price_before_tax || 0).toFixed(2)}`}
          </Col>
        );
      },
    },
    {
      title: "Quantity",
      width: "120px",
      align: "center",
      dataIndex: "quantity",
    },
    {
      title: "Tax%",
      width: "150px",
      align: "center",
      render: (params, record) => {
        return <Col>{`${Number(params?.gst || 0).toFixed(2)} %`}</Col>;
      },
    },
    {
      title: "Price (After tax)",
      width: "200px",
      align: "right",
      render: (params, record) => {
        return (
          <Col className="text-right" style={{ marginRight: "16px" }}>
            {/* {` ₹ ${Number(params).toFixed(2)}`} */}
            {` ₹ ${Number(params?.price_after_tax || 0).toFixed(2)}`}
          </Col>
        );
      },
    },
    {
      title: "Discount",
      width: "180px",
      align: "right",
      render: (params, record) => {
        return (
          <Col className="text-right" style={{ marginRight: "16px" }}>
            {` ₹ ${Number(params?.discount || 0).toFixed(2)}`}
          </Col>
        );
      },
    },
    {
      title: "Total price",
      width: "180px",
      // dataIndex: "price_after_tax",
      align: "right",
      render: (params, record) => {
        return (
          <Col className="text-right" style={{ marginRight: "16px" }}>
            {/* {` ₹ ${Number(params).toFixed(2)}`} */}
            {` ₹ ${Number(
              (params?.total || 0)
            ).toFixed(2)}`}
          </Col>
        );
      },
    },
  ];
  const handleBack = () => {
    // navigate(-1);
    navigate(`/seller/storeorders/`);
  };
  const handleStatus = () => {
    const record = {
      order: {
        order_id: editSubData?.data?.order_id,
      },
      total: editSubData?.data?.total,
      pending_amount: totalAmountDue,
    };
    seteditOrderVal(record);
    setOpenUploadModal(true);
  };
  useEffect(() => {
    getSubOrderDetailApi(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (editSubData?.status) {
      dispatch(getEditSubOrderDetail(editSubData?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSubData]);

  return (
    <>
      <Col className="view-detail-wrapper">
        <Col className="view-detail-status no-border">
          <Col className="detail-activity">
            <Button
              className="view-detail-btn"
              icon={<ArrowLeftOutlined />}
              onClick={handleBack}
            >
              BACK TO ORDERS
            </Button>
            {/* <ActivityContainer onClick={showDrawer}>
              <ClockIcon style={{ height: "20px", width: "20px" }} />
              <ActivityText level={5}>Activity log</ActivityText>
            </ActivityContainer> */}
          </Col>
          {isFetchingEditSubData ? (
            <>
              <Skeleton active paragraph={{ rows: 2 }} />
            </>
          ) : (
            <>
              <Col className="detail-invoice-wrapper">
                <Col className="comp-wrapper">
                  {/* <Button
                  className="view-detail-btn"
                  icon={<ShareIcon />}
                  onClick={handleBack}
                >
                  SHARE
                </Button> */}
                  {/* <Button
                  className="view-detail-btn"
                  icon={<PrintIcon />}
                  onClick={handleBack}
                >
                  PRINT
                </Button> */}
                  
                   {editSubData?.data?.proforma_invoice && editSubData?.data?.proforma_invoice.length>0  && (
                    <Select
                      className="ddl-width ddl-custom-invoice"
                      placeholder={
                        <>
                          <NotesIconIcon /> {" "} Proforma invoice
                        </>
                      }
                      options={editSubData?.data?.proforma_invoice?.map(
                        (item) => {
                          const url = new URL(item);
                          const fileName = url.pathname.split("/").pop();
                          return {
                            value: item,
                            label: fileName,
                          };
                        }
                      )}
                      onChange={(selectedOption) =>
                        handleViewPdf(selectedOption)
                      }
                    />
                  )}
                  { editSubData?.data?.order_status !== "Order Created" && editSubData?.data?.invoice && editSubData?.data?.invoice.length>0  && (
                    <Select
                      className="ddl-width ddl-custom-invoice"
                      placeholder={
                        <>
                          <NotesIconIcon />{" "} Customer Invoice
                        </>
                      }
                      options={editSubData?.data?.invoice?.map((item) => {
                        const url = new URL(item);
                        const fileName = url.pathname.split("/").pop();
                        return {
                          value: item,
                          label: fileName,
                        };
                      })}
                      onChange={(selectedOption) =>
                        handleViewPdf(selectedOption)
                      }
                    />
                  )} 
                </Col>
                <Col className="comp-wrapper">
                  {!editSubData?.data?.mob_credit_order &&
                    editSubData?.data?.order_status === "Order Created" && (
                      <Button
                        className="view-detail-btn"
                        icon={<Checklogo />}
                        onClick={handleGenerateTaxInvoice}
                      >
                        GENERATE TAX INVOICE
                      </Button>
                    )}
                  <Link
                    to={`/Seller/StoreSales/update-order/${editSubData?.data?.order_id}`}
                    target="_blank"
                  >
                    <Button className="view-detail-btn" icon={<EditIcon />}>
                      EDIT SALE
                    </Button>
                  </Link>
                  {totalAmountDue > 0 && (
                    <Button
                      className="view-detail-btn btn-payment"
                      onClick={() => handleStatus()}
                      icon={<RupeeIcon />}
                    >
                      ADD PAYMENT
                    </Button>
                  )}
                </Col>
              </Col>
              {editSubData?.data?.mob_credit_order &&
                editSubData?.data?.mob_order_id !== null && (
                  <>
                    <Col className="bg-green bt-card-common">
                      <Text>
                        <img src={partyIcon} className="mr-2" alt=""></img>
                        MOB online order has been created for this sale. Please
                        click on view details to check the order status
                      </Text>
                    </Col>
                    <Col>
                      <Card
                        className="card-credit-success card-green-border"
                        title={<Mobcreditnobglogo />}
                      >
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Col span={12}>
                            <Text className="text-blue text-caption">
                            mob Sub ID: {editSubData?.data?.mob_order_id}
                            </Text>
                            <br />
                            <Text className="store-bg">
                              {onlyDateFormat(editSubData?.data?.order_date)}
                            </Text>
                            <br /><br />
                            <Text className="store-bg mt-2">
                              Items: {totalQuantity} Total: ₹{" "}
                              {Number(editSubData?.data?.total).toFixed(2)}
                            </Text>
                          </Col>

                          <Col span={12} className="cls-invoice-float">
                          
                            <Select
                      className="ddl-width mr-2 ddl-custom-invoice"
                      placeholder={
                        <>
                          <NotesIconIcon />{" "} Customer Invoice
                        </>
                      }
                      options={editSubData?.data?.invoice?.map((item) => {
                        const url = new URL(item);
                        const fileName = url.pathname.split("/").pop();
                        return {
                          value: item,
                          label: fileName,
                        };
                      })}
                      onChange={(selectedOption) =>
                        handleViewPdf(selectedOption)
                      }
                    />
                    <Button
                              className="view-detail-btn"
                              onClick={() =>
                                handleViewDetals(
                                  editSubData?.data?.mob_order_id
                                )
                              }
                            >
                              VIEW DETAILS
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    {editSubData?.data?.mob_credit_order  && editSubData?.data?.seller_invoice && editSubData?.data?.seller_invoice.length>0  && (
                    <Row className="bg-yellow bt-card-common">
                      <span className="bg-danger badge-info-text">
                        Not for customers
                      </span>
                      <Row className="d-flex w-100" style={{justifyContent:"space-between"}}>
                      <Col
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={19}
                        className="mt-4"
                      >
                        <Typography.Title level={5}>
                          Seller invoice for Mad over Buildings
                        </Typography.Title>
                        <Text>
                        You can decide to use this automated invoice or you could continue using your current invoicing system to bill <br/>
                       <strong> ( MadOverBuilding AI Private Limited, GSTIN: 33AAPCM2989N1ZL )</strong>
                        </Text>
                      </Col>
                      <Col
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={5}
                        className="text-right"
                      >
                      
                    <Select
                      className="ddl-width ddl-custom-invoice"
                      placeholder={
                        <>
                          <NotesIconIcon /> {" "} Seller invoice
                        </>
                      }
                      options={editSubData?.data?.seller_invoice?.map(
                        (item) => {
                          // console.log("🚀 ~ SellerStoreViewDetails ~ item:", item)
                          // const url = new URL(item?.file);
                          const fileName = item?.file_name;
                          return {
                            value: item?.file,
                            label: fileName,
                          };
                        }
                      )}
                      onChange={(selectedOption) =>
                        handleViewPdf(selectedOption)
                      }
                    />
                  
                      </Col>
                      </Row>
                    </Row>
                    )}
                  </>
                )}
              {editSubData?.data?.mob_credit_order &&
                editSubData?.data?.mob_order_id === null && (
                  <Col>
                    <Card
                      className="card-credit-link"
                      style={{
                        backgroundColor: "#c13615",
                        padding: "2px",
                        color: "#007bff",
                      }}
                      bodyStyle={{ backgroundColor: "#ffebe6" }}
                      title="For customer to CONFIRM using the link and mobile OTP"
                      extra={<Mobcreditlogo />}
                    >
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                          <Typography.Title
                            level={4}
                            onClick={() =>
                              handleNavigateDemand(
                                `${demandRupifiUrl}${editSubData?.data?.checkout_link}`
                              )
                            }
                            target="_blank"
                            // rel="noopener noreferrer"
                            className=""
                            style={{ cursor: "pointer" }}
                          >
                            {showDemandRupifiUrl || "N/A"}
                          </Typography.Title>

                          <Text>
                            Hi {editSubData?.data?.delivery_address?.name},
                            please accept the link to confirm the order with
                            your Registered No.{" "}
                            {editSubData?.data?.delivery_address?.phone_number}{" "}
                            and OTP <br /> Customer Checkout link:{" "}
                            <Text
                              onClick={() =>
                                handleNavigateDemand(
                                  `${demandRupifiUrl}${editSubData?.data?.checkout_link}`
                                )
                              }
                              target="_blank"
                              // rel="noopener noreferrer"
                              className="hyperlink"
                              style={{ cursor: "pointer" }}
                            >
                              {showDemandRupifiUrl || "N/A"}
                            </Text>
                          </Text>
                        </Col>
                        <Col
                          xs={24}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="copytext-alignment"
                        >
                          <Button
                            className="view-detail-btn copytext-btn"
                            icon={<Copylogo />}
                            onClick={() =>
                              copyLinkToClipboard(
                                `${demandRupifiUrl}${editSubData?.data?.checkout_link}`,
                                "Text"
                              )
                            }
                          >
                            Copy text
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                )}
            </>
          )}
        </Col>

        {isFetchingEditSubData ? (
          <>
            <Skeleton active paragraph={{ rows: 8 }} />
          </>
        ) : (
          <>
            <Card
              className={
                editSubData?.data?.mob_credit_order
                  ? "top-credit-strip"
                  : !editSubData?.data?.invoice
                  ? "top-store-strip"
                  : "no-strip"
              }
              title={
                editSubData?.data?.mob_credit_order ? (
                  <>
                    <span className="credit-bg">
                      <MobCreditIcon />
                    </span>{" "}
                    This is a mobCREDIT order ( Converted from store to online
                    order )
                  </>
                ) : (
                  <>
                    {!editSubData?.data?.invoice && (
                      <span className="store-bg">
                        This is a Proforma invoice. Please click on “Generate
                        tax invoice” to convert it to GSTIN invoice
                      </span>
                    )}
                  </>
                )
              }
            >
              <Row>
                <Col
                  style={{ display: "flex",alignItems:"center" }}
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={16}
                >
                  <Text className="text-heading">
                    {" "}
                    {editSubData?.data?.order_status === "Order Delivered"
                      ? "Tax invoice"
                      : "Customer proforma invoice"}
                  </Text>
                  <Tag
                    className={`status-tag-style ${
                      paymentStatus === "paid"
                        ? "status-ready"
                        : paymentStatus === "Payment pending"
                        ? "status-rejected"
                        : "status-default"
                    }`}
                  >
                    {paymentStatus}
                  </Tag>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={8}
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  {!editSubData?.data?.mob_credit_order ? (
                    <Badge className="bg-bmp-square">
                      <Text className="text-center">{bmp_name}</Text>
                    </Badge>
                  ) : (
                    <Badge className="bg-bmp-square-dark">
                      <Text className="text-center">
                        <MobLogoFinalIcon />
                      </Text>
                    </Badge>
                  )}
                </Col>
              </Row>
              {editSubData?.data?.mob_credit_order && (
                <>
                  <Col xs={24} sm={12} md={8} lg={6} xl={12}>
                    <Row>
                      <Text className="text-caption mb-2">
                        Mob proforma invoice #
                      </Text>
                      <span className="text-detail ml-1">
                        {editSubData?.data?.order_id}
                      </span>
                    </Row>
                    <Row>
                      <Text className="text-caption mb-2">Order Date: </Text>
                      <span className="text-detail ml-1">
                        {onlyDateFormat(editSubData?.data?.order_date)}
                      </span>
                    </Row>
                    {editSubData?.data?.invoice && (
                      <Row>
                        <Text className="text-caption mb-2">Invoice Date:</Text>
                        <span className="text-detail ml-1">
                          {dateFormat(editSubData?.data?.invoice_date)}{" "}
                        </span>
                      </Row>
                    )}
                    {editSubData?.data?.Purchaseorder && (
                      <Row>
                        <Text className="text-caption">
                          Purchase Order Ref No:
                        </Text>
                        <span className="text-detail"> </span>
                      </Row>
                    )}
                  </Col>
                </>
              )}
              <Row gutter={16}>
                {!editSubData?.data?.mob_credit_order ? (
                  <Col xs={24} sm={12} md={8} lg={6} xl={12}>
                    <Typography.Title
                      className="text-invoice-billing"
                      level={5}
                    >
                      BILLED BY
                    </Typography.Title>
                    <Typography.Title level={5}>
                      {editSubData?.data?.seller?.name}
                    </Typography.Title>
                    <Row>
                      {" "}
                      <Text className="text-detail">
                        {editSubData?.data?.seller?.address}
                      </Text>
                    </Row>
                    <Row>
                      <Text className="text-detail">
                        {editSubData?.data?.seller?.address_line_2}
                      </Text>
                    </Row>
                    <Row>
                      <Text className="text-detail">
                        Ph no: {editSubData?.data?.seller?.phone_number}
                      </Text>
                    </Row>
                    {editSubData?.data?.seller?.email && (
                      <Row>
                        <Text className="text-detail">
                          Email Id: {editSubData?.data?.seller?.email || ""}
                        </Text>
                      </Row>
                    )}
                    {editSubData?.data?.seller?.gst_number && (
                      <Row>
                        <Text>
                          GSTIN: {editSubData?.data?.seller?.gst_number || ""}
                        </Text>
                      </Row>
                    )}
                  </Col>
                ) : (
                  <>
                    <Col
                      className="mob-address-card"
                      xs={24}
                      sm={12}
                      md={8}
                      lg={6}
                      xl={12}
                    >
                      <Typography.Title
                        className="text-invoice-billing"
                        level={5}
                      >
                        BILLED BY
                      </Typography.Title>
                      <Row>
                        <Text className="text-detail">
                          MadOverBuilding AI Private Limited
                        </Text>
                      </Row>
                      <Row>
                        <Text className="text-detail">
                          No. 10, Palat Sankaran Road,
                        </Text>
                      </Row>

                      <Row>
                        <Text className="text-detail">
                          Mahalingapuram, Nungambakkam, Chennai,
                        </Text>
                      </Row>

                      <Row>
                        <Text className="text-detail">India - 600034</Text>
                      </Row>
                      <Row>
                        <Text className="text-detail">Ph no: 8660423608</Text>
                      </Row>

                      {editSubData?.data?.billing_address?.gst_number && (
                        <Row>
                          <Text className="text-caption">
                            GSTIN:{" "}
                            <span className="text-detail">33AAPCM2989N1ZL</span>
                          </Text>
                        </Row>
                      )}
                    </Col>
                  </>
                )}
                {!editSubData?.data?.mob_credit_order ? (
                  <Col xs={24} sm={12} md={8} lg={6} xl={12}>
                    <Row>
                      <Text className="text-caption mb-2">Order Date: </Text>
                      <span className="text-detail ml-1">
                        {dateFormat(editSubData?.data?.order_date)}
                      </span>
                    </Row>
                    <Row>
                      <Text className="text-caption mb-2">
                        Proforma Invoice #
                      </Text>
                      <span className="text-detail ml-1">
                        {editSubData?.data?.order_id}
                      </span>
                    </Row>
                    {editSubData?.data?.invoice && (
                      <Row>
                        <Text className="text-caption mb-2">Invoice Date:</Text>
                        <span className="text-detail ml-1">
                          {dateFormat(editSubData?.data?.invoice_date)}{" "}
                        </span>
                      </Row>
                    )}
                    {editSubData?.data?.Purchaseorder && (
                      <Row>
                        <Text className="text-caption">
                          Purchase Order Ref No:
                        </Text>
                        <span className="text-detail"> </span>
                      </Row>
                    )}
                  </Col>
                ) : (
                  <>
                    <Col
                      xs={24}
                      sm={12}
                      md={8}
                      lg={6}
                      xl={12}
                      className="card-routingstore"
                    >
                      <Typography.Title
                        level={5}
                        className="text-invoice-billing"
                      >
                        VIA
                      </Typography.Title>
                      <Row>
                        <Typography.Title level={5}>
                          ROUTING STORE (FOR MOBCREDIT ORDER)
                        </Typography.Title>
                        <Text className="text-caption mb-2">
                          Store proforma invoice #{" "}
                        </Text>
                        <span className="text-detail ml-1">
                          {editSubData?.data?.order_id}
                        </span>
                      </Row>
                      <Row>
                        <Text className="text-detail">
                          {editSubData?.data?.seller?.name}
                        </Text>
                      </Row>
                      <Row>
                        <Text className="text-detail">
                          {editSubData?.data?.seller?.address}{" "}
                          {editSubData?.data?.seller?.address_line_2}
                        </Text>
                      </Row>
                      <Row>
                        <Text className="text-detail">
                          Ph no: {editSubData?.data?.seller?.phone_number}
                        </Text>
                      </Row>
                      {editSubData?.data?.seller?.email && (
                        <Row>
                          <Text className="text-detail">
                            Email Id: {editSubData?.data?.seller?.email || ""}
                          </Text>
                        </Row>
                      )}
                      {editSubData?.data?.seller?.gst_number && (
                        <Row>
                          <Text>
                            GSTIN: {editSubData?.data?.seller?.gst_number || ""}
                          </Text>
                        </Row>
                      )}
                    </Col>
                  </>
                )}
              </Row>
              <Row gutter={16} className="mt-4 mb-4">
                <Col xs={24} sm={12} md={8} lg={6} xl={12}>
                  <Typography.Title level={5} className="text-invoice-billing">
                    BILLED TO
                  </Typography.Title>
                  <Row>
                    <Text className="text-detail">
                      {editSubData?.data?.billing_address?.name}
                    </Text>
                  </Row>
                  <Row>
                    <Text className="text-detail">
                      {editSubData?.data?.billing_address?.address_line_1}
                      {", "}
                    </Text>
                  </Row>
                  {editSubData?.data?.billing_address?.address_line_2 && (
                    <Row>
                      <Text className="text-detail">
                        {editSubData?.data?.billing_address?.address_line_2}
                        {", "}
                      </Text>
                    </Row>
                  )}
                  <Row>
                    <Text className="text-detail">
                      {editSubData?.data?.billing_address?.city}
                      {", "}
                      {editSubData?.data?.billing_address?.state}
                      {"-"}
                      {editSubData?.data?.billing_address?.pincode}
                    </Text>
                  </Row>
                  <Row>
                    <Text className="text-detail">
                      Ph no: {editSubData?.data?.billing_address?.phone_number}
                    </Text>
                  </Row>
                  {editSubData?.data?.billing_address?.email &&(
                  <Row>
                    <Text className="text-detail">
                      Email Id:{" "}
                      {editSubData?.data?.billing_address?.email || ""}
                    </Text>
                  </Row>)}
                  {editSubData?.data?.billing_address?.gst_number && (
                    <Row>
                      <Text className="text-caption">
                        GSTIN:{" "}
                        <span className="text-detail">
                          {editSubData?.data?.billing_address?.gst_number}
                        </span>
                      </Text>
                    </Row>
                  )}
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={12}>
                  <Typography.Title className="text-invoice-billing" level={5}>
                    DELIVERY ADDRESS
                  </Typography.Title>
                  <Row>
                    <Text className="text-detail">
                      {editSubData?.data?.delivery_address?.name}
                    </Text>
                  </Row>
                  <Row>
                    <Text className="text-detail">
                      {editSubData?.data?.delivery_address?.address_line_1}
                      {", "}
                    </Text>
                  </Row>
                  {editSubData?.data?.delivery_address?.address_line_2 && (
                    <Row>
                      <Text className="text-detail">
                        {editSubData?.data?.delivery_address?.address_line_2}
                        {", "}
                      </Text>
                    </Row>
                  )}
                  <Row>
                    <Text className="text-detail">
                      {editSubData?.data?.delivery_address?.city}
                      {", "}
                      {editSubData?.data?.delivery_address?.state}
                      {"-"}
                      {editSubData?.data?.delivery_address?.pincode}
                    </Text>
                  </Row>
                  <Row>
                    <Text className="text-detail">
                      Ph no: {editSubData?.data?.delivery_address?.phone_number}
                    </Text>
                  </Row>
                  {editSubData?.data?.delivery_address?.email && (
                  <Row>
                    <Text className="text-detail">
                      Email Id:{" "}
                      {editSubData?.data?.delivery_address?.email || ""}
                    </Text>
                  </Row>
                  )}
                  {editSubData?.data?.delivery_address?.gst_number && (
                    <Row>
                      <Text className="text-caption">
                        GSTIN:{" "}
                        <span className="text-detail">
                          {editSubData?.data?.delivery_address?.gst_number}
                        </span>
                      </Text>
                    </Row>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="vehicle-notify mt-4">
                  <DeliveryTable
                    data={editSubOrderData?.items}
                    columns={columnsDeliveryBy}
                    editSubOrderData={editSubOrderData}
                    deliveryStatus={""}
                    paymentStatus={paymentStatus}
                    setPaymentStatus={setPaymentStatus}
                    totalAmountDue={totalAmountDue}
                    totalAmountPaid={totalAmountPaid}
                  />
                </Col>
              </Row>
              {editSubOrderData?.customer_notes && (
                <>
                  <Col className="vehicle-notify mt-4">
                    <Text className="detail-heading">NOTES</Text>
                    {editSubOrderData?.customer_notes}
                  </Col>
                </>
              )}
              {editSubOrderData?.terms_and_conditions && (
                <>
                  <Col className="vehicle-notify mt-4">
                    <Text className="detail-heading">TERMS AND CONDITIONS</Text>
                    {editSubOrderData?.terms_and_conditions}
                  </Col>
                </>
              )}
            </Card>
            {editSubData?.data?.mob_credit_order && (
            <Row >
              <Col span={24} className="post-conversion-status">
              <Col className="d-flex" style={{gap:"6px",alignItems:"center"}}>
              <Text>
              <ElectricityIcon/>
              </Text>
              <Text className="text-bold-heading" level={5}>
               Post conversion order EDIT
              </Text>
              </Col>
              <Row className="mt-2">
                <Text>
                  Edits can be made until the order has been marked DELIVERED
                  <br />
                  1. Increase in mobCREDIT amount is NOT possible
                  <br />
                  2. Decrease in mobCREDIT amount is possible{" "}
                  <span style={{ fontStyle: "italic" }}>
                    (System will auto adjust client’s mobCREDIT amount once
                    marked delivered)
                  </span>
                </Text>
              </Row>

              <Row className="mt-2">
                <Text>
                  NOTE: Any amount increase can be collected by other payment
                  methods OR GENERATE A NEW ORDER
                </Text>
              </Row>
              </Col>
            </Row>
            )}
            <Modal
              title=""
              visible={isModalOpen}
              onCancel={handleModalClose}
              footer={[
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "28px",
                  }}
                >
                  <Button
                    onClick={handleModalClose}
                    style={{
                      height: "48px",
                      fontWeight: 500,
                      fontSize: "14px",
                      maxWidth: "120px",
                      width: "100%",
                    }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    onClick={() => handleChangeStatus("Order Delivered")}
                    style={{
                      height: "48px",
                      fontWeight: 500,
                      fontSize: "14px",
                      border: "none",
                      color: "white",
                      backgroundColor: "#0354a3",
                    }}
                    loading={updateOrderStatusLoading}

                    // disabled={!isFormValid}
                  >
                    GENERATE TAX INVOICE
                  </Button>
                </Col>,
              ]}
            >
              <Col className="pt-6">
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <InvoiceIcon />
                </Col>
                <Col
                  className="mt-4"
                  style={{
                    padding: "16px",
                    display: "flex",
                    // flexDirection: "column",
                    gap: "16px",
                    minHeight: "100px",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", fontWeight: 500 }}>
                    Are you sure you want to generate tax invoice?
                  </Text>
                </Col>
              </Col>
            </Modal>
          </>
        )}
      </Col>
      <AddPaymentModal
        openAddFile={openUploadModal}
        setOpenAddFile={setOpenUploadModal}
        titleText={titleText}
        custData={editSubOrderData?.cutomer}
        handleOk={handleOk}
        formData={formData}
        setFormData={setFormData}
        datePickerEmpty={datePickerEmpty}
        setDatePickerEmpty={setDatePickerEmpty}
        paymentMode={paymentMode}
        setPaymentMode={setPaymentMode}
        editPayment={editPayment}
        setEditPayment={setEditPayment}
        setOpenUploadModal={setOpenUploadModal}
        setInputDate={setInputDate}
        inputDate={inputDate}
        setAmountError={setAmountError}
        amountError={amountError}
        dateError={dateError}
        setDateError={setDateError}
        editOrderVal={editOrderVal}
        orderNewPaymentApi={orderNewPaymentApi}
        unmapped_amount={editSubOrderData?.unmapped_amount}
        getSubOrderDetailApi={getSubOrderDetailApi}
        pendingAmount={editOrderVal?.pending_amount}
      />
    </>
  );
};

export default SellerStoreViewDetails;
