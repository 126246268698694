import {Col, Dropdown, Menu, Space, Typography} from "antd";
import React from "react";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
const {Text} = Typography;
const SellerCustomDropdown = ({
  visible,
  handleVisibleChange,
  selectedText,
  options,
  handleOptionClick,
  className
}) => {
  
  const dropdownContent = (
    
    <Menu className={className} style={{width: "400px"}}>
      {options?.map((option) => (
        <Menu.Item
          key={option.key}
          className="styled-menu"
          onClick={() => handleOptionClick(option.value)}>
          <Col className="val-text">{option.value}</Col>
          <Text className="dis-text">{option.description}</Text>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      <Dropdown
        overlay={dropdownContent}
        style={{fontWeight: 500, color: "#0a243f"}}
        trigger={["click"]}
        onVisibleChange={handleVisibleChange}
        visible={visible}>
        <Space
          style={{
            fontWeight: 500,
            color: "#0a243f",
            width: "100%",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          {selectedText}
          {visible ? <UpOutlined /> : <DownOutlined />}
        </Space>
      </Dropdown>
    </>
  );
};

export default SellerCustomDropdown;
