import React, { useEffect, useState } from "react";
import "./SellerStoreOrders.css";
import {
  //  Avatar, Dropdown, Menu,
  Col,
  Typography,

} from "antd";
import {
   // eslint-disable-next-line
  LoadingOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";

import {
  useLazyGetDownloadStoreSalesReportsQuery,

  useLazyGetStoreStockReportsQuery
} from "../../../apis/reports";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
// import { DashOutlined } from "@ant-design/icons";
// import { ReactComponent as DefaultIcon } from "../../../assets/icons/default.svg";

import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import {  
  dateFormat,
} from "../../../commonFuntions/CommonUtilFunctions";
const { Text } = Typography;
 // eslint-disable-next-line
const options = [
  {
    key: 1,
    value: "Export",
    label: "Export",
  },
];

const dateOptions = [
  {
    key: 1,
    value: "Today",
    label: "Today",
  },
  {
    key: 2,
    value: "Yesterday",
    label: "Yesterday",
  },
  {
    key: 3,
    value: "last_7_day",
    label: "Last 7 day",
  },
  {
    key: 4,
    value: "last_30_day",
    label: "Last 30 day",
  },
  {
    key: 5,
    value: "last_60_day",
    label: "Last 60 day",
  },
  {
    key: 6,
    value: "Custom",
    label: "Custom",
  },
];

const SellerOutOfStock = () => {
  const [dateFilter, setDateFilter] = useState();
  const [searchedValue, setSearchedValue] = useState({});
  const [dateSearch, setDateSearch] = useState({
    start_date: "",
    end_date: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [sorterState, setSorterState] = useState({
    order_id: "",
    sub_order_id: "",
    created_at: "",
  });

    const [getSellerStockReportApi, { data: StockData, isFetching: IsStockFetch }] =
    useLazyGetStoreStockReportsQuery();
    
  const [
    getDownloadSellerSalesReportApi,
     // eslint-disable-next-line
    { isFetching: DownloadReportFetching },
  ] = useLazyGetDownloadStoreSalesReportsQuery();

  useEffect(() => {
    getSellerStockReportApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

 
  const Stockcolumns = [
    {
      // title: "Order Id",
      fixed: typeof window !== 'undefined' && window.innerWidth > 768 ? "left" : "",
      title: (
        <>
          <Col className="title_sorter">
            <Text className="sorter_heading">Reference no</Text>
            <Text className="sorter_container_icon">
              <CaretUpOutlined
                style={{
                  color: sorterState?.out_of_stock_ref_no === "out_of_stock_ref_no" ? "" : "grey",
                }}
                onClick={() => sorterStockChange("out_of_stock_ref_no")}
                className="sorter_icon"
              />
              <CaretDownOutlined
                style={{
                  color: sorterState?.out_of_stock_ref_no === "-out_of_stock_ref_no" ? "" : "grey",
                }}
                onClick={() => sorterStockChange("-out_of_stock_ref_no")}
                className="sorter_icon"
              />
            </Text>
          </Col>
        </>
      ),
      dataIndex: "out_of_stock_ref_no",
      key: "out_of_stock_ref_no",
      width: "250px",
      render: (params, record) => {
        return (
          <Link
            // onClick={() => handleDetailNav(record)}
            to={`/Seller/storeorder/outofstock/${record?.out_of_stock_ref_no}`}
            target="_blank"
            className="dashboard-table-orderid"
          >
            {params}
          </Link>
        );
      },
    },

    //////////////////////////
    {
      // title: "Order Date",
      title: (
        <>
          <Col className="title_sorter">
            <Text className="sorter_heading">Date</Text>
            <Text className="sorter_container_icon">
              <CaretUpOutlined
                style={{
                  color: sorterState?.created_at === "created_at" ? "" : "grey",
                }}
                onClick={() => sorterStockChange("created_at")}
                className="sorter_icon"
              />
              <CaretDownOutlined
                style={{
                  color:
                    sorterState?.created_at === "-created_at" ? "" : "grey",
                }}
                onClick={() => sorterStockChange("-created_at")}
                className="sorter_icon"
              />
            </Text>
          </Col>
        </>
      ),
      dataIndex: "created_at",
      key: "created_at",
      width: "150px",
      render: (params, record) => {
        return (
          <Col          
            style={{ fontWeight: 400 }}
          >
            {params ? dateFormat(params) : "N/A"}
          </Col>
        );
      },
    },

    {
      title: "Notes",
      dataIndex: "notes",
      key: "Notes",
      width: "100px",
      render: (params, record) => {
        return (
          <Col
            // onClick={() => handleUserDetailsNavigation(record)}
            style={{ fontWeight: 400 }}          >
            {params ? params : ""}
          </Col>
        );
      },
    },
    {
      title: "Items",
      dataIndex: "stocks",
      key: "stock",
      width: "100px",
      align: "center",

      render: (params, record) => {
        return (
          <Col
            // onClick={() => handleUserDetailsNavigation(record)}
            style={{ fontWeight: 400, textAlign: "center" }}
          >
           {params ? params?.length : 0}
          </Col>
        );
      },
    },
  ];
  const handleStockChange = (value) => {
    let formattedDate = null;

    switch (value) {
      case "Today":
        formattedDate = dayjs().format("YYYY-MM-DD");
        break;
      case "Yesterday":
        formattedDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        break;
      case "last_7_day":
        formattedDate = dayjs().subtract(6, "day").format("YYYY-MM-DD");
        break;
      case "last_30_day":
        formattedDate = dayjs().subtract(29, "day").format("YYYY-MM-DD");
        break;
      case "last_60_day":
        formattedDate = dayjs().subtract(59, "day").format("YYYY-MM-DD");
        break;
      case "Custom":
        formattedDate = "Custom";
        break;
      default:
        break;
    }

    const currentDate = dayjs().format("YYYY-MM-DD");
    if (value !== "Custom" && value !== null) {
      if (value === "clear_date") {
        getSellerStockReportApi({
          ...(searchedValue?.search && { search: searchedValue?.search }),
          ...(sorterState.order_id && { sort_by: sorterState.order_id }),
          ...(sorterState.sub_order_id && {
            sort_by: sorterState.sub_order_id,
          }),
          ...(sorterState.created_at && { sort_by: sorterState.created_at }),
          page: 1,
        });
        setDateSearch({
          start_date: "",
          end_date: "",
        });
      } else {
        getSellerStockReportApi({
          start_date: formattedDate,
          end_date: currentDate,
          ...(searchedValue?.search && { search: searchedValue?.search }),
          ...(sorterState.order_id && { sort_by: sorterState.order_id }),
          ...(sorterState.sub_order_id && {
            sort_by: sorterState.sub_order_id,
          }),
          ...(sorterState.created_at && { sort_by: sorterState.created_at }),
          page: 1,
        });
        setDateSearch({
          start_date: formattedDate,
          end_date: currentDate,
        });
      }
      setCurrentPage(1);
    }
    setDateFilter(value);
  };
  const handleStockChangeRangePicker = (dates, dateStrings) => {
    if (dates && dates.length === 2) {
      const startDateFormatted = dates[0].format("YYYY-MM-DD");
      const endDateFormatted = dates[1].format("YYYY-MM-DD");
      getSellerStockReportApi({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
        ...(sorterState.order_id && { sort_by: sorterState.order_id }),
        ...(sorterState.sub_order_id && { sort_by: sorterState.sub_order_id }),
        ...(sorterState.created_at && { sort_by: sorterState.created_at }),
        page: 1,
      });
      setDateSearch({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
      });
    }
  };

  const sorterStockChange = (item) => {
    let key = item;
    setSorterState({
      // ...sorterState,
      [key.replace("-", "")]: item,
    });
    getSellerStockReportApi({
      sort_by: item,
      ...(searchedValue?.search && { search: searchedValue?.search }),
      ...(dateSearch?.start_date && { start_date: dateSearch?.start_date }),
      ...(dateSearch?.end_date && { end_date: dateSearch?.end_date }),
      page: 1,
    });
  };
   // eslint-disable-next-line
  const handleStockExportApi = async () => {
    let response = await getDownloadSellerSalesReportApi({
      ...(searchedValue.search && { search: searchedValue.search }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      ...(sorterState.order_id && { sort_by: sorterState.order_id }),
      ...(sorterState.sub_order_id && { sort_by: sorterState.sub_order_id }),
      ...(sorterState.created_at && { sort_by: sorterState.created_at }),
      // download: true,
    });
    if (response?.status === "fulfilled") {
      // handlePdfDownload(response?.data?.data?.url);
      saveAs(response?.data?.data?.url);
    }
  };
  const handleStockPageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      // ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
      // ...(sorterState.created_at && { sort_by: sorterState.created_at }),
    };
    setCurrentPage(currPage);
    await getSellerStockReportApi(params);
  };

  const handleStockSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };

  return (
    <Col className="SalesReport-wrapper">
      <Text className="heading">Out of stock</Text>
     
          <>
            <Col style={{ display: "flex", gap: "1rem" }}>
              <SearchInput
                size={"large"}
                placeholder={"Search by customer name, mobile no, email"}
                width={"400px"}
                getLibraryApi={getSellerStockReportApi}
                handleSearchedValue={handleStockSearchedValue}
                isLoading={IsStockFetch}
              />
              <Col className="action-sub-container">
                <CustomSelect
                  placeholder={"Date"}
                  width={"150px"}
                  options={dateOptions}
                  handleChange={handleStockChange}
                  value={dateFilter}
                />
                {dateFilter === "Custom" && (
                  <DateRangePicker
                    onChange={handleStockChangeRangePicker}
                    format={"DD-MMM-YYYY"}
                  />
                )}
              </Col>
              {/* <CustomSelect
                placeholder={"Export"}
                width={"150px"}
                options={options}
                handleChange={handleStockExportApi}
                value={DownloadReportFetching ? <LoadingOutlined /> : "EXPORT"}
                disabled={DownloadReportFetching ? true : false}
                loading={DownloadReportFetching}
              /> */}
            </Col>
            <CustomTableWithPagination
              className="order-list-table mt-4"
              //   tableExpandable={defaultExpandable}
              // tableDataSource={tableDataSource || []}
              tableDataSource={StockData?.data?.results}
              tableColumns={Stockcolumns}
              locale={{
                emptyText: IsStockFetch ? <TableSkeleton length={10} /> : "",
              }}
              isLoading={
                StockData?.data?.results?.length && IsStockFetch
              }
              scroll={{ x: "100%" }}
              currentPage={currentPage}
              handlePageChange={handleStockPageChange}
              itemsPerPage={20}
              totalEntries={
                !IsStockFetch &&
                StockData?.data?.pagination?.total_entries
              }
            />
          </>
       
    </Col>
  );
};

export default SellerOutOfStock;
