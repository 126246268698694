import React from "react";
import { Button, Col, Modal, Typography } from "antd";
const { Text } = Typography;
const SellerConfirmationModal = ({
  title,
  subId,
  isModalOpen,
  setIsModalOpen,
  handleOk,
  updateOrderStatusLoading
}) => {
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Modal
        className="confirm-modal"
        title={
          <Col
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Text style={{ fontWeight: 500, fontSize: "22px" }}>{title}</Text>
          </Col>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "12px 24px 12px 24px",
            }}
          >
            <Button
              onClick={handleCancel}
              style={{
                height: "48px",
                fontWeight: 500,
                fontSize: "14px",
                maxWidth: "120px",
                width: "100%",
              }}
            >
              CANCEL
            </Button>
            <Button
              style={{
                height: "48px",
                maxWidth: "120px",
                width: "100%",
                fontWeight: 500,
                fontSize: "14px",
                border: "none",
                color: "white",
                backgroundColor: "#015fe5",
              }}
              onClick={handleOk}
              loading={updateOrderStatusLoading}
            >
              CONFIRM
            </Button>
          </Col>,
        ]}
      >
        <Col
          style={{
            padding: "24px 24px 24px 24px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Text
          className="font-big-bold"
            style={{ fontWeight: 500, fontSize: "20px" }}
          >{`Are you sure to change status to '${title}'`}</Text>
          <Text style={{ fontWeight: 500, fontSize: "20px" }}>{subId}</Text>
        </Col>
      </Modal>
    </>
  );
};
export default SellerConfirmationModal;
