import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQueryWithReauth} from "./../baseQuery";

export const SellerReportsAPI = createApi({
  reducerPath: "SellerReportsAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    

    getSellerSalesReports: builder.query({
      query: (params) => {
        return {
          url: `api/seller/reports/get_seller_sales_reports/`,
          params,
        };
      },
    }),
    
    getDownloadSellerSalesReports: builder.query({
      query: (params) => {
        return {
          url: `api/seller/reports/download_seller_sales_reports/`,
          params,
        };
      },
    }),
    getSubscriptionReports: builder.query({
      query: (params) => {
        return {
          url: `/api/seller/subscription/get_purchased_history/`,
          params,
        };
      },
    }),
    getLineofCredit: builder.query({
      query: (params) => {
        return {
          url: `/api/seller/customer/applied_mob_credit_customers/`,
          params,
        };
      },
    }),
    getLineofCreditExcel: builder.query({
      query: (params) => {
        return {
          url: `/api/download_request_line_of_credit/`,
          params,
        };
      },
    }),
    getStoreAccessDetails: builder.query({
      query: (params) => {
        return {
          url: `/api/seller/dashboard/get_stores/`,
          params,
        };
      },
    }),
  }),
});

export const {

  useLazyGetSellerSalesReportsQuery,  
  useLazyGetDownloadSellerSalesReportsQuery,
  useLazyGetSubscriptionReportsQuery,
  useLazyGetLineofCreditQuery,
  useLazyGetLineofCreditExcelQuery,
  useLazyGetStoreAccessDetailsQuery
 
} = SellerReportsAPI;

export default SellerReportsAPI;
