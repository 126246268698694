import React from "react";
import {  Col, Typography, Row, Card } from "antd";
import "./SellerMobCreditOnboarding.css";
import { ReactComponent as ArchitectIcon } from "../../../assets/seller/icons/architect.svg";
import { ReactComponent as DesignerIcon } from "../../../assets/seller/icons/designer.svg";
import { ReactComponent as WorkerIcon } from "../../../assets/seller/icons/construction-worker.svg";
import CompanyBoardIcon from "../../../assets/seller/icons/companyboard.webp";
import GSTINLogo from "../../../assets/seller/icons/GSTIN.webp";
import DocumentLogo from "../../../assets/seller/icons/document.webp";
import PanLogo from "../../../assets/seller/icons/PAN.webp";
import AadharLogo from "../../../assets/seller/icons/aadhaar-card.webp";
const { Text } = Typography;

const GettingStarted = (props) => {
  //   const {

  //   } = props;
  // eslint-disable-next-line
 

  return (
    <>
     
        
        <Row>
          
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row gutter={[16,16]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                <Card className="card-content-expect" bordered={false}>
                 <ArchitectIcon/> <Typography.Title level={4}> Architects</Typography.Title>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                <Card className="card-content-expect" bordered={false}>
                 <WorkerIcon/><Typography.Title level={4}> Contractors</Typography.Title>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                <Card className="card-content-expect" bordered={false}>
                 <DesignerIcon/><Typography.Title level={4}> Interior designers</Typography.Title>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-4" gutter={[16,16]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Card className="card-content">
              <Col className="p-0 d-flex">
              <Text className="txt-numbers">1</Text>
              <Text className="ml-10 text-head-font">Insert ADDRESS where business SIGNBOARD is there
              <br/>
              <Text className="text-subhead-font">Physical verification might be conducted</Text>
              </Text>
             
              </Col>
              <Col className="p-0 text-center mt-4">
             
              <img alt="" src={CompanyBoardIcon} ></img>
              </Col>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Card className="card-content">
            <Col className="p-0 d-flex">
              <Text className="txt-numbers">2</Text>
              <Text className="ml-10 text-head-font">GSTIN login CREDENTIALS of your business
              <br/>
              <Text className="text-subhead-font">Used to measure the automated limit setup based on filings. Protected in accordance with respective laws and regulations</Text>
              </Text>
             
              </Col>
              <Col className="p-0 text-center mt-4">
             
              <img alt="" src={GSTINLogo} ></img>
              </Col>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4" gutter={[16,16]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Card className="card-content">            
              <Col className="p-0 d-flex">
              <Text className="txt-numbers">3</Text>
              <Text className="ml-10 text-head-font">Client and Business KYC
              <br/>
              <Text className="text-subhead-font">Aadhar and PAN of business and ALL directors/ partners/ owners</Text>
              </Text>
             
              </Col>
              <Col className="p-0 mt-4  pan-aadhar">             
              <img alt="" className="img-kyc" src={PanLogo} ></img>
              <img alt=""  className="img-kyc"  src={AadharLogo} ></img>
              </Col>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Card className="card-content">
             
              <Col className="p-0 d-flex">
              <Text className="txt-numbers">4</Text>
              <Text className="ml-10 text-head-font">Document formats
              <br/>
              <Text className="text-subhead-font">Aadhar and PAN of business and ALL directors/ partners/ owners</Text>
              </Text>
             
              </Col>
              <Col className="p-0 text-center mt-4">
             
              <img alt="" src={DocumentLogo} ></img>
              </Col>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4" gutter={[16,16]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Card className="card-content">
              <p></p>
              <Col className="p-0 d-flex">
              <Text className="txt-numbers">5</Text>
              <Text className="ml-10 text-head-font">Uploading 6 months of primary business bank account statement
              <br/>
              <Text className="text-subhead-font">Mandate by the NBFC for higher credit limit</Text>
              </Text>
             
              </Col>
             
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Card className="card-content">
            
              <Col className="p-0 d-flex">
              <Text className="txt-numbers">6</Text>
              <Text className="ml-10 text-head-font">Document formats
              <br/>
              <Text className="text-subhead-font">E-Nach will be triggered after 90 days, if payment is declined</Text>
              </Text>
             
              </Col>
              
            </Card>
          </Col>
        </Row>
        
     
    </>
  );
};

export default React.memo(GettingStarted);
