import { Col, Form, Input } from "antd";
import React from "react";
import "./SellerAccounts.css";
const SellerPasswordChange = (prop) => {
  const { Passwordform, handlePasswordChange } = prop;

  return (
    <>
      <Col className="password-change-container">
        <Form
          form={Passwordform}
          name="passwordChange"
          autoComplete="off"
          style={
            {
              // maxWidth: 500,
            }
          }
          layout="vertical"
          onFinish={handlePasswordChange}
        >
          <Form.Item
            label={
              <span>
                Current Password 
                {/* <span className="text-danger"> *</span> */}
              </span>
            }
            name="currentPassword"
            rules={[
              {
                required: true,
                message: "Please enter your current password",
              },
            ]}
          >
            <Input type="password" className="change-password-input" />
          </Form.Item>

          <Form.Item
            label={
              <Col>
                New Password 
                {/* <span className="text-danger"> *</span> */}
              </Col>
            }
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your new password",
              },
              {
                min: 8,
                message: "Password must be at least 8 characters",
              },
              {
                pattern: /^(?=.*[A-Z])(?=.*\d)/,
                message:
                  "Password must contain at least 1 uppercase letter and 1 number",
              },
            ]}
          >
            <Input type="password" className="change-password-input" />
          </Form.Item>

          <Form.Item
            label={
              <Col>
                Confirm Password 
                  {/* <span className="text-danger"> *</span> */}
              </Col>
            }
            name="password2"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your new password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input type="password" className="change-password-input" />
          </Form.Item>

         
        </Form>
      </Col>
    </>
  );
};

export default SellerPasswordChange;
