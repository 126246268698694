import React, { useMemo } from "react";
import { Button, Row, Col, Typography, Affix } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ReactComponent as CheckedIcon } from "../../../assets/seller/icons/checked.svg";
import {
  onlyDateFormat,
  handlePdfDownload,
  testFileUrl,
} from "../../../commonFuntions/CommonUtilFunctions";

import Howtouse from "./howtouse";
import { showErrorToast } from "../../../NotificationToast/NotificationToast";
const Paymentresponse = () => {
  const Text = Typography;

  //   const bmp_id = localStorage.getItem("bmp_id");
  const location = useLocation();
  const navigate = useNavigate();
  const queryParameters = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const order_id = queryParameters.get("order_id");
  const pdf_url = queryParameters.get("pdf_url");
  const expiry = queryParameters.get("expiry");

  const handleViewPdf = (url) => {
    if (testFileUrl(url || "")) {
      handlePdfDownload(url);
    } else {
      showErrorToast("Something went wrong");
    }
  };
  const handleBack = () => {
    navigate("/seller/sellerdashboard");
  };
  return (
    <>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
        >
          <Col>
            <Text>
              <Button
                icon={<ArrowLeftOutlined />}
                onClick={handleBack}
                className="view-detail-btn"
              >
                BACK TO DASHBOARD
              </Button>
            </Text>
          </Col>

          <Row className="mt-4">
            <Col>
              <Row className="accept-order-padding pt-0 mt-4">
                <Col span={24} className="text-center">
                  <CheckedIcon />
                </Col>
              </Row>
              <Row className="accept-order-padding">
                <Col span={24} className="text-center">
                  <Typography.Title level={5}>
                    {" "}
                    Congrats! Your mobCREDIT account has been activated
                  </Typography.Title>
                  <Text className="text-head-font">Valid until: {onlyDateFormat(expiry)}</Text>
                  <Text className="text-subhead-font-owner">We will be in touch with you to walk you through and answer any questions you have</Text>
                </Col>
              </Row>
              <Row className="m-16 mt-4">
                <Col xs={{ span: 24, offset: 0 }}
          sm={{ span: 16, offset: 4 }}
          md={{ span: 16, offset: 4 }}
          lg={{ span: 16, offset: 4 }}
          xl={{ span: 16, offset: 4 }} className="accept-order-padding invoice-content d-flex">
                <Col span={12} className="text-left">
                  <Text
                    className="hyperlink-text"
                    onClick={() => handleViewPdf(pdf_url)}
                  >
                    Invoice {order_id}
                  </Text>
                </Col>
                <Col span={12} className="text-right">
                  <Text
                    className="hyperlink-text"
                    onClick={() => handleViewPdf(pdf_url)}
                  >
                    Download
                  </Text>
                </Col>
                </Col>
              </Row>
              <Row className="static-list accept-order-padding">
                <Howtouse />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Affix offsetBottom={0}>
        <Col className="mt-4">
          <Row
            style={{
              marginTop: 24,
              backgroundColor: "white",
              boxShadow: "0 -6px 10px 0 rgba(0, 0, 0, 0.1)",
              padding: 16,
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            <Col className="text-center" xs={24} sm={24} md={24} lg={24} xl={24}>
              <Button
                // icon={<ArrowLeftOutlined />}
                onClick={handleBack}
                className="view-detail-btn"
              >
                BACK TO DASHBOARD
              </Button>
            </Col>
          </Row>
        </Col>
      </Affix>
    </>
  );
};

export default Paymentresponse;
