import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQuery";

export const manageLibraryAPI = createApi({
  reducerPath: "manageLibraryAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getLibraryTable: builder.query({
      query: (params) => {
        return {
          url: `api/product/products_catalogue/get_library_products/`,
          params,
        };
      },
    }),
    getLibraryExcel: builder.query({
      query: (params) => {
        return {
          url: `api/product/products_catalogue/download_products_excel/`,
          params,
        };
      },
    }),
    addNewFile: builder.mutation({
      query: ({ file, is_update }) => {
        const formData = new FormData();
        formData.append("file", file);
        if (is_update) {
          formData.append("is_update", is_update);
        }

        return {
          url: "api/product/products_catalogue/upload_product_listing/",
          method: "POST",
          body: formData,
        };
      },
    }),

    uploadImages: builder.mutation({
      query: (imageFile) => {
        const formData = new FormData();
        for (let i = 0; i <= imageFile.length - 1; i++) {
          formData.append("images", imageFile[i]);
        }
        return {
          url: "api/product/products_catalogue/upload_product_images/",
          method: "POST",
          body: formData,
        };
      },
    }),
    editProductImage: builder.mutation({
      query: (params) => {
        const formData = new FormData();
        formData.append("image", params.image);
        formData.append("product", params.product);
        return {
          url: "api/product/products_catalogue/upload_product_image/",
          method: "POST",
          body: formData,
        };
      },
    }),
    deleteFile: builder.mutation({
      query: (file) => {
        const formData = new FormData();

        formData.append("file", file);

        return {
          url: "api/product/products_catalogue/delete_products/",
          method: "DELETE",
          body: formData,
        };
      },
    }),
    activateTable: builder.mutation({
      query: (params) => ({
        url: `api/product/products_catalogue/${params.product_id}/update_product/`,
        method: "PATCH",
        body: params,
      }),
    }),
    deleteEditProductImage: builder.mutation({
      query: (imageId) => ({
        url: `api/product/products_catalogue/delete_product_image/`,
        method: "DELETE",
        body: imageId,
      }),
    }),
    getEditProductDetail: builder.query({
      query: (id) => ({
        url: `api/product/products_catalogue/${id}/get_product_details/`,
      }),
    }),
    updateProductDetails: builder.mutation({
      query: ({ id, data }) => ({
        url: `api/product/products_catalogue/${id}/update_product/`,
        method: "PATCH",
        body: data,
      }),
    }),
   
    updateSellerProductDetails: builder.mutation({
      
      query: ({ id, data }) => {      
        const formData = new FormData();
        const list = [];
        data?.imageFile?.map((element) => list?.push(element?.originFileObj));      
        for (let i = 0; i <= list.length-1; i++) {
          formData.append("images", list[i]);
        }
        for (const key in data) {
          if (key !== "imageFile") {
            formData.append(key, data[key]);
          }
        }
        return{
          url: `/api/seller/product/${id}/update_product/`,
        method: "PATCH",
        body: formData,
        }
      },
    }),
    // editProductImage: builder.mutation({
    //   query: (params) => {
    //     const formData = new FormData();
    //     formData.append("image", params.image);
    //     formData.append("product", params.product);
    //     return {
    //       url: "api/product/products_catalogue/upload_product_image/",
    //       method: "POST",
    //       body: formData,
    //     };
    //   },
    // }),
    createProductDetails: builder.mutation({
      
      query: ({ id, data }) => {      
        const formData = new FormData();
        const list = [];
        data?.imageFile?.map((element) => list?.push(element?.originFileObj));      
        for (let i = 0; i <= list.length-1; i++) {
          formData.append("images", list[i]);
        }
        for (const key in data) {
          if (key !== "imageFile") {
            formData.append(key, data[key]);
          }
        }
        return{
        url: `/api/seller/product/create_product/`,
        method: "POST",
        body: formData,
        }
      },
    }),
    deleteProductRow: builder.mutation({
      query: (params) => ({
        url: `api/product/products_catalogue/delete_product/`,
        method: "DELETE",
        body: params,
      }),
    }),
    getFlushCategories: builder.query({
      query: (params) => {
        return {
          url: `api/home/flush_categories/`,
          params,
        };
      },
    }),
    getSellerCategoryDetails: builder.query({
      query: () => {
        return {
          url: `/api/seller/catalogue/get_categories/`,
        };
      },
    }),
    getSellerSubCategoryDetails: builder.query({
      query: () => {
        return {
          url: `/api/seller/catalogue/get_subcategories/`,
        };
      },
    }),
    getSellerProductDetails: builder.query({
      query: (id) => {
        return {
          url: `/api/seller/product/${id}/get_seller_created_product_detail/`,
        };
      },
    }),
  }),
});

export const {
  useLazyGetLibraryTableQuery,
  useLazyGetLibraryExcelQuery,
  useAddNewFileMutation,
  useUploadImagesMutation,
  useEditProductImageMutation,
  useDeleteFileMutation,
  useActivateTableMutation,
  useDeleteEditProductImageMutation,
  useGetEditProductDetailQuery,
  useLazyGetEditProductDetailQuery,
  useUpdateProductDetailsMutation,
  useUpdateSellerProductDetailsMutation,
  useDeleteProductRowMutation,
  useLazyGetFlushCategoriesQuery,
  useCreateProductDetailsMutation,
  useLazyGetSellerCategoryDetailsQuery,
  useLazyGetSellerProductDetailsQuery,
  useLazyGetSellerSubCategoryDetailsQuery
} = manageLibraryAPI;

export default manageLibraryAPI;
