import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form } from "antd";
import { Typography } from "antd";
import { ReactComponent as PlusIcon } from "../../../assets/icons/AddIcon.svg";
import FloatInput from "./TableFooter/FloatInput";

import {
  useDispatch,
  // useSelector
} from "react-redux";

const { Text } = Typography;

const StockCheckout = ({
  selectedSeller = [],
  dataSource,
  editCondition,
  handleCreateQuote,
  disableCondition,
  cancelQuote,
  grandPrice,
  buttonText,
  type,
  bmpPrice,
  editQuoteData,
  getGrandPrice,
  billingAddressSelector,
  data,
  loading,
  handleAddRow,
  tableIndex,
  paymentmodeItems,
  setpaymentmodeItems,
  paymentReceived,
  setpaymentReceived,
  addDeliveryNotes,
  setAddDeliveryNotes,
  isCreditOrder,
  dueAmount,
  setdueAmount,
  addDeliveryCharge,
  setAddDeliveryCharge,
  settotalAmount,
  setmobCharges,
  setbalanceAmount,
  setmobChargesGST,
  mobCreditAmount,
  setmobCreditRecieveAmt,
}) => {
  const dispatch = useDispatch();
  // const billing_address = useSelector(billingAddressSelector);
  const [additionalDiscountValue, setAdditionalDiscountValue] = useState("");

  const [active, setActive] = useState(0);
  const onSelect = (value) => {
    setActive(value);
  };
  useEffect(() => {
    if (editCondition) {
      setAdditionalDiscountValue(editQuoteData?.additional_discount);
      onSelect(editQuoteData?.discount_in === "amount" ? 0 : 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCondition, editQuoteData]);
 
  const SubtotalPrice = dataSource?.reduce(
    (tableSubTotal, table, index) =>
      tableSubTotal +
      table.reduce(
        (rowSubTotal, row, index) => rowSubTotal + parseFloat(row.total || 0),
        0
      ) +
      //parseFloat(bmpPrice[index]?.delivery_fee || 0),
      parseFloat(addDeliveryCharge || 0),
    0
  );

  const totalDiscount = dataSource?.reduce(
    (tableTotalDiscount, table, index) =>
      tableTotalDiscount +
      table.reduce(
        (rowDiscount, row, index) =>
          rowDiscount +
          parseFloat(row.discount || 0) * parseFloat(row.quantity || 1),
        0
      ),
    0
  );

  const taxPrice = bmpPrice?.reduce((acc, tableFoot, index) => {
    return acc + parseFloat(tableFoot?.cgst || 0);
  }, 0);
  const igst = bmpPrice?.reduce((acc, tableFoot, index) => {
    return acc + parseFloat(tableFoot?.igst || 0);
  }, 0);

  const grandTotalPrice =
    active === 0
      ? SubtotalPrice - parseFloat(additionalDiscountValue || 0)
      : SubtotalPrice -
        (SubtotalPrice * parseFloat(additionalDiscountValue || 0)) / 100;

  const grandTotal = grandTotalPrice < 0 ? 0 : grandTotalPrice.toFixed(2);
  const dueAmountvalue =
    grandTotalPrice < 0
      ? 0
      : Number((grandTotalPrice - paymentReceived).toFixed(2));
  const mobChargesTotal = Number(mobCreditAmount * (1.75 / 100));
  const mobChargesGSTTotal =
    Number(mobChargesTotal) - Number(mobChargesTotal / (1 + 18 / 100));
  // console.log("🚀 ~ mobChargesGSTTotal:", mobChargesGSTTotal)
  const balanceAmountTotal = Number(grandTotal - mobChargesTotal);
  const creditAmountReceive = Number(
    mobCreditAmount - (mobChargesTotal + mobChargesGSTTotal)
  );
  setdueAmount(dueAmountvalue);
  settotalAmount(grandTotal);
  setmobCharges(mobChargesTotal);
  setmobChargesGST(mobChargesGSTTotal);
  setbalanceAmount(balanceAmountTotal);
  setmobCreditRecieveAmt(creditAmountReceive);
  useEffect(() => {
    dispatch(
      getGrandPrice({
        ...grandPrice,
        additional_discount: additionalDiscountValue || 0,
        sub_total: SubtotalPrice,
        discount: totalDiscount,
        sgst: taxPrice,
        cgst: taxPrice,
        add_additional_discount: additionalDiscountValue || 0,
        discount_in: Number(active) || 0,
        total: grandTotal,
        igst: igst,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalDiscountValue, taxPrice, grandTotal, active]);

  return (
    <>
      {(selectedSeller[0] || selectedSeller?.length === 0) && (
        <>
          <Row>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                // marginTop: "24px",
              }}
            >
              <Button
                style={{
                  // display: "flex",
                  alignItems: "center",
                  // gap: "12px",
                  cursor: "pointer",
                  backgroundColor: "#015fe5",
                  borderRadius: "12px",
                }}
                onClick={() => handleAddRow(tableIndex)}
              >
                <PlusIcon />
                <Text
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 500,
                    color: "#fff",
                  }}
                >
                  Add item
                </Text>
              </Button>
            </Col>
          </Row>
        </>
      )}

      <Form
        size="large"
        name="delivery_note"
        style={{ height: "70px", paddingTop: "20px" }}
        layout="vertical"
      >
        <Form.Item name="delivery_notes_item">
          <FloatInput
            label="Add note"
            placeholder="Add note"
            name="delivery_notes_input"
            setAddDeliveryNotes={setAddDeliveryNotes}
            value={addDeliveryNotes}
            tableIndex={0}
            editCondition={editCondition}
            addDeliveryNotes={addDeliveryNotes}
          />
        </Form.Item>
      </Form>
      <Col
        style={{
          display: "flex",
          justifyContent: "right",
        }}
      >
        <Row
          style={{
            // width: "450px",
            marginTop: "48px",
            display: "flex",
            justifyContent: "end",
            gap: "16px",
          }}
        >
          <Button
            style={{
              padding: "13px 40px",
              height: "48px",
              fontFamily: "Inter",
              fontWeight: "500",
              border: "solid 1px #9da7b2",
              borderRadius: "8px",
            }}
            onClick={() => cancelQuote(editCondition ? "updateOrder" : "")}
          >
            CANCEL
          </Button>
          <Button
            className="create-order-confirm-button"
            style={{
              padding: "13px 60px 13px 60px",
              fontFamily: "Inter",
              fontWeight: "500",
              backgroundColor: "#015fe5",
              color: "#fff",
              height: "48px",
              borderRadius: "8px",
              // opacity: disableCondition || loading ? "0.5" : 1,
            }}
            loading={loading}
            // disabled={disableCondition || loading}
            onClick={handleCreateQuote}
          >
            {/* {editCondition ? "UPDATE QUOTE" : "CREATE QUOTE"} */}
            {buttonText}
          </Button>
        </Row>
      </Col>
    </>
  );
};

export default React.memo(StockCheckout);
