import React, { useState, useEffect } from "react";
import { Button, Col, Input, Modal, Typography } from "antd";
import "./AddPaymentModal.css";
import CustomDropdown from "./CustomDropdown";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../NotificationToast/NotificationToast";
import CustomDatePicker from "./DatePicker/CustomDatePicker";
// import { ReactComponent as Alerticon } from "../../../../assets/icons/alert.svg";
// import { ReactComponent as NoOrders } from "../../../../assets/seller/icons/Noorders.svg";
import { useLazyGetAllOrdersDataQuery } from "../../../../apis/Seller/SellerPayablesAPI";
const { Text } = Typography;

const paymentMode = [
  {
    key: "upi",
    value: "UPI",
  },
  {
    key: "cash",
    value: "Cash",
  },
  {
    key: "bankTransfer",
    value: "Bank transfer",
  },
  {
    key: "others",
    value: "Others",
  },
];

const AddDirectPaymentModal = ({
  openAddFile,
  setOpenAddFile,
  titleText,
  custData,
  orderPaymentApi,
  orderId,
  totalAmount,
  pendingAmount,
  amountError,
  setAmountError,
  dateError,
  setDateError,
  isFetching,
  data,
  OrderData,
  getAllTrasactionData,
  editOrderVal,
  getAllOrderData,
  orderNewPaymentApi,
  unmapped_amount,
  getSubOrderDetailApi,
}) => {
  const [
    getAllOrderPendingData,
    // eslint-disable-next-line
    { data: OrderPendingList, isLoading: isAllOrderpendingDataLoading },
  ] = useLazyGetAllOrdersDataQuery();
  const [datePikerEmpty, setDatePickerEmpty] = useState(false);
  const [paymentModeSelected, setPaymentModeSelected] = useState("UPI");
  const [payModeVisible, setPayModeVisible] = useState(false);
  const [amountErrorValue, setAmountErrorValue] = useState(false);
  const initialFormData = {
    order: "",
    amount: "0",
    payment_date: "",
    remarks: "",
    payment_type: "Online payment",
    payment_mode: "UPI",
    payment_gateway: "Razorpay",
    reference_number: "",
  };
  // eslint-disable-next-line 
  const [useUnmappedAmount, setUseUnmappedAmount] = useState(false);
  const [paymentFormData, setPaymentFormData] = useState(initialFormData);
  
  const [checkedOrders, setCheckedOrders] = useState({});

  useEffect(() => {
    if (custData?.phone_number) {
      const params = {
        is_done: false,
      };
      getAllOrderPendingData({ id: custData?.phone_number, param: params });
    }
  }, [getAllOrderPendingData, custData]);

  const paymentModeVisible = (flag) => {
    setPayModeVisible(flag);
  };
  const handleNewPaymentApi = async () => {
    if (!useUnmappedAmount) {   
      if (!paymentFormData.amount ) {
        setAmountError(true);
        return;
      } else {
        if(paymentFormData.amount==="0")
        {
          setAmountError(true);
          return;
        }
        else{
          setAmountError(false);
        }
        
      }
      if (!paymentFormData.payment_date) {
        setDateError(true);
        return;
      } else {
        setDateError(false);
      }
    }
    
    const params = {
      phone_number: custData?.phone_number,
      amount: useUnmappedAmount
        ? editOrderVal?.pending_amount
        : paymentFormData.amount,
      payment_date: paymentFormData.payment_date || "",
      remarks: paymentFormData.remarks,
      payment_mode: paymentFormData.payment_mode,
      use_unmapped_amount: useUnmappedAmount,
      order_id: editOrderVal?.order?.order_id,
    };

    //console.log(params);
    const response = await orderNewPaymentApi(params);
    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      setOpenAddFile(false);
      setPaymentModeSelected("UPI");
      setPaymentFormData(initialFormData);
      setDatePickerEmpty(true);
      // getAllTrasactionData({ id: custData?.phone_number });
      getSubOrderDetailApi(editOrderVal?.order?.suborder_id);
    } else if (response?.error) {
      showErrorToast(response?.error?.data?.message);
    }
  };
  const handlePaymentApi = async () => {
    if (!paymentFormData.amount) {
      setAmountError(true);
      return;
    } else {
      setAmountError(false);
    }

    // if (!paymentFormData.remarks) {
    //   setRemarksError(true);
    //   return;
    // } else {
    //   setRemarksError(false);
    // }

    if (!paymentFormData.payment_date) {
      setDateError(true);
      return;
    } else {
      setDateError(false);
    }

    const params = {
      phone_number: custData?.phone_number,
      amount:
        titleText === "Give money"
          ? -paymentFormData.amount
          : paymentFormData.amount,
      payment_date: paymentFormData.payment_date,
      remarks: paymentFormData.remarks,
      payment_mode: paymentFormData.payment_mode,
      payment_gateway: paymentFormData.payment_gateway,
      //orders: Object.keys(checkedOrders).map((orderId) => orderId),
      orders: editOrderVal
        ? [editOrderVal?.order?.order_id]
        : Object.keys(checkedOrders).map((orderId) => orderId),
    };

    //console.log(params);
    const response = await orderPaymentApi(params);
    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      setOpenAddFile(false);
      setPaymentModeSelected("UPI");
      setPaymentFormData(initialFormData);
      setDatePickerEmpty(true);
      getAllTrasactionData({ id: custData?.phone_number });
      getAllOrderData({ id: custData?.phone_number });
      setCheckedOrders({});
      const params = {
        is_done: false,
      };
      getAllOrderPendingData({ id: custData?.phone_number, param: params });
    } else if (response?.error) {
      showErrorToast(response?.error?.data?.message);
    }
  };
  const handleCancel = () => {
    setOpenAddFile(false);
    setPaymentFormData(initialFormData);
    setDatePickerEmpty(true);
    setPaymentModeSelected("UPI");
    setAmountErrorValue(false);
    setCheckedOrders({});
  };

  // const handleUncheck = () => {
  //   setCheckedOrders({}); // Reset checkedOrders state
  // };
  const handlePayModeClick = (selectedValue) => {
    setPaymentFormData({
      ...paymentFormData,
      payment_mode: selectedValue,
    });
    setPaymentModeSelected(selectedValue);
    setPayModeVisible(false);
  };
  const handleChildFieldChange = (e) => {
    setPaymentFormData({
      ...paymentFormData,
      ...e,
    });
  };
  const handlePaidFull = () => {
    setPaymentFormData({
      ...paymentFormData,
      amount: parseFloat(pendingAmount || 0),
      // amount: (totalAmount || 0),
    });
    setAmountErrorValue(false);
  };
  const handleFormData = (e) => {
    const key = e.target.name;
    const targetValue = e.target.value;
    if (key === "amount") {
      // const decimalPattern = /^\d+$/;
      // const decimalPattern = /^(?:\d+|\d+\.\d{0,2})$/;
      const decimalPattern = /^-?(?:\d+|\d+\.\d{0,2})$/;
      if (
        decimalPattern.test(targetValue)
        //  &&  targetValue <= parseInt((pendingAmount || 0) + 2)
        // targetValue <= Number((pendingAmount || 0).toFixed(2))
      ) {
        if (
          Number(targetValue) <= (parseFloat(pendingAmount) || 0).toFixed(2)
        ) {
          setPaymentFormData({
            ...paymentFormData,
            [key]: targetValue,
          });
          // setAmountErrorValue(true);
        } else {
          setPaymentFormData({
            ...paymentFormData,
            [key]: targetValue,
          });
          setAmountErrorValue(true);
        }
      } else {
      }
      if (targetValue === "" || targetValue === "-") {
        setPaymentFormData({
          ...paymentFormData,
          [key]: targetValue,
        });
      } else {
        // setPaymentFormData({
        //   ...paymentFormData,
        //   [key]: targetValue,
        // });
      }
    } else {
      setPaymentFormData({
        ...paymentFormData,
        [key]: targetValue,
      });
    }
  };
  return (
    <>
      <Modal
        title={titleText}
        open={openAddFile}
        className="add-payment-modal"
        // onOk={ handlePaymentApi}
        onCancel={handleCancel}
        footer={
          <Col className="addFile-wrapper">
            <Button key="back" className="cancel-btn" onClick={handleCancel}>
              CANCEL
            </Button>
            <Button
              key="submit"
              type="primary"
              className="submit-btn"
              onClick={editOrderVal ? handleNewPaymentApi : handlePaymentApi}
              loading={isFetching}
            >
              SAVE
            </Button>
          </Col>
        }
      >
        <Col
          className="pay-modal"
          style={editOrderVal ? { height: "400px" } : {}}
        >
          <Col className="pay-amount">
            <Col className="pay-align-text">
              <Text className="amount-key">{custData?.name}</Text>
              <Text style={{ fontSize: "16px" }}>{custData?.phone_number}</Text>
            </Col>
          </Col>
          {/* {editOrderVal && (
            <Col className="pay-amount">
              <Col className="pay-align-text">
                <Text className="amount-key">Settle payment</Text>
                <Text className="text-subtitle">
                  Click on the checkbox to settle the due amount from the
                  unmapped amount
                </Text>
                <Text className="bg-unmapped" style={{ fontSize: "16px" }}>
                  <Checkbox
                    disabled={pendingAmount > unmapped_amount}
                    checked={useUnmappedAmount}
                    onChange={handleUmmappedCheckboxChange}
                  >
                    Add ₹ {pendingAmount} from your ₹{" "}
                    {Number(unmapped_amount).toFixed(2)} unmapped amount
                  </Checkbox>
                </Text>
              </Col>
            </Col>
          )} */}
          {!useUnmappedAmount && (
            <Col className="pay-wrapper">
              <Col className="pay-input">
                <Col className="pay-lebel">
                  <Text>Amount</Text>
                  <Text
                  onClick={handlePaidFull}
                  style={{
                    fontWeight: 500,
                    color: "#2973f0",
                    cursor: "pointer",
                  }}
                >
                  Paid in full
                </Text>
                </Col>

                <Input
                  placeholder="Enter amount to pay"
                  className={`addPayment-input ${
                    amountError || amountErrorValue ? "error" : ""
                  }`}
                  name="amount"
                  value={paymentFormData?.amount}
                  onChange={handleFormData}
                />
                {amountError && (
                  <p className="error-message">Amount is required</p>
                )}
                {/* {amountErrorValue && (
                <p className="error-message">                  
                  Amount should be less than {(pendingAmount || 0).toFixed(2)}
                </p>
              )} */}
              </Col>
              <Col className="pay-date">
                <Col className="mode-type">
                  <Text>Payment mode</Text>
                  <Col className="dropdown-wrapper">
                    <CustomDropdown
                      handleOptionClick={handlePayModeClick}
                      visible={payModeVisible}
                      handleVisibleChange={paymentModeVisible}
                      selectedText={paymentModeSelected}
                      options={paymentMode}
                    />
                  </Col>
                </Col>
                {/* <Col className="mode-type">
                  <Text>Reference no</Text>
                  <Input
                    value={paymentFormData?.reference_number}
                    name="reference_number"
                    onChange={handleFormData}
                    className="addPayment-input"
                  />
                </Col> */}
                <Col className="pay-date-picker">
                  <Text>Payment Date</Text>
                  <Col className="pay-cal">
                    <CustomDatePicker
                      name="payment_date"
                      value={paymentFormData?.payment_date}
                      handleChildFieldChange={handleChildFieldChange}
                      datePikerEmpty={datePikerEmpty}
                    />
                    {dateError && (
                      <p className="error-message">Payment Date is required</p>
                    )}
                  </Col>
                </Col>
              </Col>

              <Col>
                <Col>
                  <Text>Note</Text>
                  <Input
                    value={paymentFormData?.remarks}
                    name="remarks"
                    onChange={handleFormData}
                    className={`addPayment-input`}
                    // className={`addPayment-input ${remarksError ? "error" : ""}`}
                  />
                  {/* {remarksError && (
                  <p className="error-message">Remarks are required</p>
                )} */}
                </Col>
              </Col>
            </Col>
          )}
          
            <>
              <Col
                className="order-list-payable bg-grey"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  justifyContent: "space-between",
                }}
              >
                <Col>
                  <Text style={{ fontWeight: "600" }}>
                    {editOrderVal?.order?.suborder_id}
                  </Text>
                </Col>
                <Col>
                  <Text>Invoice total</Text>
                  <br />
                  <Text style={{ fontWeight: "500" }}>
                    ₹ {Number(editOrderVal?.total).toFixed(2)}
                  </Text>
                </Col>
                <Col className="text-right">
                  <Text>Amount due</Text>
                  <br />
                  <Text style={{ fontWeight: "500" }}>
                    ₹ {Number(pendingAmount).toFixed(2)}
                  </Text>
                </Col>
              </Col>
            </>
          
        </Col>
      </Modal>
    </>
  );
};

export default AddDirectPaymentModal;
