import React, { useEffect, useState } from "react";
import "./SellersView.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Skeleton, Switch, Tag, Typography } from "antd";
import { ReactComponent as DefaultIcon } from "../../../assets/icons/default.svg";
import { ReactComponent as TickIcon } from "../../../assets/icons/whiteTik.svg";
import { ReactComponent as NewOrderIcon } from "../../../assets/icons/newOrder.svg";
import { ReactComponent as ReturnsExchangeIcon } from "../../../assets/icons/ReturnsExchange.svg";
import { ReactComponent as TotalAmountIcon } from "../../../assets/icons/TotalAmount.svg";
import { ReactComponent as SettlementIcon } from "../../../assets/icons/Settlement.svg";
import { ReactComponent as DisputeIcon } from "../../../assets/icons/Dispute.svg";
import { ReactComponent as AssetsIcon } from "../../../assets/icons/Assets.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetSellerVendorDetailsQuery } from "../../../apis/sellerCatalogue";
import { useDispatch, useSelector } from "react-redux";
import { getSellerVenderDetail } from "../../../redux/slices/sellerCatalogue/action";
import { sellerVenderDetailsSelector } from "../../../redux/slices/sellerCatalogue/selector";
import { baseUrl } from "../../../commonUtils/commonUtils";
const SellersView = () => {
  const { Text } = Typography;
  const { seller_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggleCheck, setToggleCheck] = useState(false);
  const venderDetailSelector = useSelector(sellerVenderDetailsSelector);
  const [
    vendorDetailAPI,
    { data: vendorData, isFetching: isFetchingVendorData },
  ] = useLazyGetSellerVendorDetailsQuery();
  useEffect(() => {
    vendorDetailAPI({ bmp_id: seller_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seller_id]);

  useEffect(() => {
    if (vendorData?.status) {
      dispatch(getSellerVenderDetail(vendorData?.data));
      setToggleCheck(vendorData?.data?.is_active);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorData]);
  const onChangeToggle = (checked) => {
    setToggleCheck(checked);
  };
  const handleBackNavigate = () => {
    navigate(`/admin/sellers`);
  };
  const handleSettlementNavigate = (sellerId) => {
    navigate(`/admin/settlement/${sellerId}`);
  };
  const handleNavigateToSellerView = (id) => {
    // navigate(`/admin/sellers/catalogue/${id}`);
    window.open(`/admin/sellers/catalogue/${id}`, "_blank");
  };
  return (
    <>
      <Col className="main-seller-view-container">
        <Col className="gray-background"></Col>
        <Col className="wrapper">
          <Button
            className="btn"
            icon={<ArrowLeftOutlined />}
            onClick={handleBackNavigate}
          >
            BACK
          </Button>

          {isFetchingVendorData ? (
            <>
              {" "}
              <Col
                style={{
                  width: "100%",
                  display: "flex",
                  backgroundColor: "#fff",
                  gap: "30px",
                  padding: "20px",
                }}
              >
                <Skeleton.Image active style={{ marginTop: "25px" }} />
                <Skeleton active shape={"square"} block={true} />
              </Col>
            </>
          ) : (
            <Col className="header-container-seller-view">
              <Tag className="verified-tag" size={"large"}>
                <TickIcon style={{ paddingTop: "5px" }} /> MOB Verified - Active
              </Tag>
              <Col className="toggle-container-seller-view">
                <Text className="flag-text">Flag</Text>
                <Text className="store-text">Store timings:</Text>
                <Text className="store-text">Open</Text>
                <Switch
                  defaultChecked
                  onChange={onChangeToggle}
                  checked={toggleCheck}
                />
              </Col>
              <Col className="body-container-seller-view">
                <Col className="image-container">
                  <Avatar
                    style={{
                      width: "80px",
                      height: "80px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f1f1f2",
                    }}
                    shape="square"
                    src={`${baseUrl}${
                      venderDetailSelector?.seller_display_image ||
                      venderDetailSelector?.bmp_image
                    }`}
                    alt="Avatar"
                    icon={<DefaultIcon width={20} height={20} />}
                  />
                </Col>
                <Col className="detail-container">
                  <Text className="detail-name-text">
                    {venderDetailSelector?.vendor_name}
                  </Text>
                  <Text className="detail-id-text">
                    BMP ID: {venderDetailSelector?.bmp_id}
                  </Text>
                  <Col className="detail-body-container">
                    <Col className="detail-body-container-one">
                      <Text className="detail-body-container-one-heading">
                        Address
                      </Text>
                      <Text className="detail-body-container-one-content">
                        {/* 66138 Legros Mission Suite 804 Plains Apt. 613, Chennai,
                      600005 */}
                        {venderDetailSelector?.address || "----"}
                      </Text>
                    </Col>
                    <Col className="detail-body-container-one">
                      <Text className="detail-body-container-one-heading">
                        Category
                      </Text>
                      <Text className="detail-body-container-one-content">
                        {/* Building materials, Home and kitchen, Tiles, Electricals */}
                        {venderDetailSelector?.types_of_material?.map(
                          (obj) => obj.name + ", "
                        ) || "----"}
                      </Text>
                    </Col>
                    <Col className="detail-body-container-one">
                      <Text className="detail-body-container-one-heading">
                        Ratings
                      </Text>
                      <Text className="detail-body-container-one-content">
                        {venderDetailSelector?.rating}
                        <Text className="review">
                          ({venderDetailSelector?.reviews} reviews)
                        </Text>
                      </Text>
                    </Col>
                  </Col>
                </Col>
              </Col>
            </Col>
          )}
          <Col className="actions-container-seller-view">
            <Button
              style={{
                height: "38px",
              }}
              onClick={() => handleSettlementNavigate(seller_id)}
            >
              MOB SETTLEMENTS
            </Button>
            <Button
              style={{
                backgroundColor: "#0354a3",
                color: "#fff",
                height: "38px",
              }}
              onClick={() => handleNavigateToSellerView(seller_id)}
            >
              GO TO SELLER VIEW
            </Button>
          </Col>
          <Col className="end-container-seller-view">
            <Col className="tag-main-container">
              <Col className="icon-main-container">
                <NewOrderIcon />
              </Col>
              <Col className="tag-text-container">
                <Text className="tag-text-heading">New orders</Text>
                <Text className="tag-text-number">
                  {venderDetailSelector?.new_orders}
                </Text>
              </Col>
            </Col>
            <Col className="tag-main-container">
              <Col className="icon-main-container icon-background-2">
                <ReturnsExchangeIcon />
              </Col>
              <Col className="tag-text-container">
                <Text className="tag-text-heading">Returns/ Exchange</Text>
                <Text className="tag-text-number">
                  {venderDetailSelector?.return_and_exchange}
                </Text>
              </Col>
            </Col>
            <Col className="tag-main-container">
              <Col className="icon-main-container icon-background-3">
                <TotalAmountIcon />
              </Col>
              <Col className="tag-text-container">
                <Text className="tag-text-heading">Total sales amount</Text>
                <Text className="tag-text-number">
                  ₹ {venderDetailSelector?.total_sales_amount}
                </Text>
              </Col>
            </Col>
          </Col>
          {/* SECOND TAGS */}
          <Col className="end-container-seller-view">
            <Col className="tag-main-container">
              <Col className="icon-main-container">
                <SettlementIcon />
              </Col>
              <Col className="tag-text-container">
                <Text className="tag-text-heading">
                  Settlement amount pending
                </Text>
                <Text className="tag-text-number">
                  ₹ {venderDetailSelector?.settlement_amount_pending}
                </Text>
                <Text className="footer-container-text">
                  Revenue: ₹ {venderDetailSelector?.revenue || "----"}
                </Text>
                <Text className="footer-container-text">
                  Tax: ₹ {venderDetailSelector?.tax || "----"}
                </Text>
              </Col>
            </Col>
            <Col className="dispute-container">
              <Col className="icon-background-4">
                <DisputeIcon />
              </Col>
              <Col className="tag-text-container">
                <Text className="tag-text-heading">Dispute</Text>
                <Text className="tag-text-number">
                  ₹ {venderDetailSelector?.dispute}
                </Text>
                <Text className="footer-container-text">
                  This is the amount in dispute because of item quality. Our
                  team is looking into it
                </Text>
              </Col>
            </Col>
            <Col className="assets-container">
              <Col className="icon-background-5">
                <AssetsIcon />
              </Col>
              <Col className="tag-text-container">
                <Text className="tag-text-heading">Payment settled</Text>
                <Text className="tag-text-number">
                  ₹ {venderDetailSelector?.payment_settled}
                </Text>
                <Text className="footer-container-text">
                  This is the amount that has been paid to seller by MOB for
                  orders
                </Text>
              </Col>
            </Col>
          </Col>
        </Col>
      </Col>
    </>
  );
};

export default SellersView;
