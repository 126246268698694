import React, { useEffect, useState } from "react";
import "./SellerStoreOrders.css";
import {
  //  Avatar, Dropdown, Menu,
  Col,
  Typography,
  Row
} from "antd";
import {
  LoadingOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import CartIcon from "../../../assets/seller/icons/cart.svg";
import {
  useLazyDwonloadStoreOrdersExcelQuery,
  useLazyGetStoreSalesReportsQuery,

} from "../../../apis/reports";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
// import { DashOutlined } from "@ant-design/icons";
// import { ReactComponent as DefaultIcon } from "../../../assets/icons/default.svg";

import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
// import {  
//   dateFormat,
// } from "../../../commonFuntions/CommonUtilFunctions";
const { Text } = Typography;

const options = [
  {
    key: 1,
    value: "Export",
    label: "Export",
  },
];

const dateOptions = [
  {
    key: 1,
    value: "Today",
    label: "Today",
  },
  {
    key: 2,
    value: "Yesterday",
    label: "Yesterday",
  },
  {
    key: 3,
    value: "last_7_day",
    label: "Last 7 day",
  },
  {
    key: 4,
    value: "last_30_day",
    label: "Last 30 day",
  },
  {
    key: 5,
    value: "last_60_day",
    label: "Last 60 day",
  },
  {
    key: 6,
    value: "Custom",
    label: "Custom",
  },
];

const SellerStoreOrders = () => {
  const [dateFilter, setDateFilter] = useState();
  const [searchedValue, setSearchedValue] = useState({});
  const [dateSearch, setDateSearch] = useState({
    start_date: "",
    end_date: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [sorterState, setSorterState] = useState({
    order_id: "",
    sub_order_id: "",
    created_at: "",
  });
  const [getSellerSalesReportApi, { data: sellerSalesReportData, isFetching }] =
    useLazyGetStoreSalesReportsQuery();
  
    
  const [
    getDownloadSellerSalesReportApi,
    { isFetching: DownloadReportFetching },
  ] = useLazyDwonloadStoreOrdersExcelQuery();
  useEffect(() => {
    getSellerSalesReportApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value) => {
    let formattedDate = null;

    switch (value) {
      case "Today":
        formattedDate = dayjs().format("YYYY-MM-DD");
        break;
      case "Yesterday":
        formattedDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        break;
      case "last_7_day":
        formattedDate = dayjs().subtract(6, "day").format("YYYY-MM-DD");
        break;
      case "last_30_day":
        formattedDate = dayjs().subtract(29, "day").format("YYYY-MM-DD");
        break;
      case "last_60_day":
        formattedDate = dayjs().subtract(59, "day").format("YYYY-MM-DD");
        break;
      case "Custom":
        formattedDate = "Custom";
        break;
      default:
        break;
    }

    const currentDate = dayjs().format("YYYY-MM-DD");
    if (value !== "Custom" && value !== null) {
      if (value === "clear_date") {
        getSellerSalesReportApi({
          ...(searchedValue?.search && { search: searchedValue?.search }),
          ...(sorterState.order_id && { sort_by: sorterState.order_id }),
          ...(sorterState.sub_order_id && {
            sort_by: sorterState.sub_order_id,
          }),
          ...(sorterState.created_at && { sort_by: sorterState.created_at }),
          page: 1,
        });
        setDateSearch({
          start_date: "",
          end_date: "",
        });
      } else {
        getSellerSalesReportApi({
          start_date: formattedDate,
          end_date: currentDate,
          ...(searchedValue?.search && { search: searchedValue?.search }),
          ...(sorterState.order_id && { sort_by: sorterState.order_id }),
          ...(sorterState.sub_order_id && {
            sort_by: sorterState.sub_order_id,
          }),
          ...(sorterState.created_at && { sort_by: sorterState.created_at }),
          page: 1,
        });
        setDateSearch({
          start_date: formattedDate,
          end_date: currentDate,
        });
      }
      setCurrentPage(1);
    }
    setDateFilter(value);
  };
  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates && dates.length === 2) {
      const startDateFormatted = dates[0].format("YYYY-MM-DD");
      const endDateFormatted = dates[1].format("YYYY-MM-DD");
      getSellerSalesReportApi({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
        ...(sorterState.order_id && { sort_by: sorterState.order_id }),
        ...(sorterState.sub_order_id && { sort_by: sorterState.sub_order_id }),
        ...(sorterState.created_at && { sort_by: sorterState.created_at }),
        page: 1,
      });
      setDateSearch({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
      });
    }
  };
  const sorterChange = (item) => {
    let key = item;
    setSorterState({
      // ...sorterState,
      [key.replace("-", "")]: item,
    });
    getSellerSalesReportApi({
      sort_by: item,
      ...(searchedValue?.search && { search: searchedValue?.search }),
      ...(dateSearch?.start_date && { start_date: dateSearch?.start_date }),
      ...(dateSearch?.end_date && { end_date: dateSearch?.end_date }),
      page: 1,
    });
  };
  const handleExportApi = async () => {
    let response = await getDownloadSellerSalesReportApi({
      ...(searchedValue.search && { search: searchedValue.search }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      ...(sorterState.order_id && { sort_by: sorterState.order_id }),
      ...(sorterState.sub_order_id && { sort_by: sorterState.sub_order_id }),
      ...(sorterState.created_at && { sort_by: sorterState.created_at }),
      // download: true,
    });
    if (response?.status === "fulfilled") {
      // handlePdfDownload(response?.data?.data?.url);
      saveAs(response?.data?.data?.url);
    }
  };
  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      // ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
      // ...(sorterState.created_at && { sort_by: sorterState.created_at }),
    };
    setCurrentPage(currPage);
    await getSellerSalesReportApi(params);
  };

  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };

  const columns = [
    {
      // title: "Order Id",
      // fixed: "left",            
      fixed: typeof window !== 'undefined' && window.innerWidth > 768 ? "left" : "",
      title: (
        <>
          <Col className="title_sorter">
            <Text className="sorter_heading">Billing/ Reference no</Text>
            <Text className="sorter_container_icon">
              <CaretUpOutlined
                style={{
                  color: sorterState?.order_id === "order_id" ? "" : "grey",
                }}
                onClick={() => sorterChange("order_id")}
                className="sorter_icon"
              />
              <CaretDownOutlined
                style={{
                  color: sorterState?.order_id === "-order_id" ? "" : "grey",
                }}
                onClick={() => sorterChange("-order_id")}
                className="sorter_icon"
              />
            </Text>
          </Col>
        </>
      ),
      dataIndex: "order_id",
      key: "order_id",
      width: "250px",
      render: (params, record) => {
        return (
          <Link
            // onClick={() => handleDetailNav(record)}
            to={`/Seller/storeorder/OrderDetails/${record?.order_id}`}
            target="_blank"
            className="dashboard-table-orderid"
          >
            {params}
          </Link>
        );
      },
    },

    //////////////////////////
    {
      // title: "Order Date",
      title: (
        <>
          <Col className="title_sorter">
            <Text className="sorter_heading">Date</Text>
            <Text className="sorter_container_icon">
              <CaretUpOutlined
                style={{
                  color: sorterState?.created_at === "order_date" ? "" : "grey",
                }}
                onClick={() => sorterChange("order_date")}
                className="sorter_icon"
              />
              <CaretDownOutlined
                style={{
                  color:
                    sorterState?.created_at === "-order_date" ? "" : "grey",
                }}
                onClick={() => sorterChange("-order_date")}
                className="sorter_icon"
              />
            </Text>
          </Col>
        </>
      ),
      dataIndex: "order_date",
      key: "order_date",
      width: "150px",
    },
    {
      title: "mobCREDIT Order",
      dataIndex: "mob_credit_order",
      key: "mob_credit_order",
      width: "180px",
      align:"center",
      render: (params, record) => {
        return <Col>{params? "Yes":"No"}</Col>;
      },
    },
    {
      // title: "Invoice type",
      title: "Invoice type",
      dataIndex: "order_status",
      key: "Invoice type",
      width: "150px",
      render: (params, record) => {
        return (
          <>
            {/* <Link
               to={`/admin/sellers/seller-view/${params}`}
               // onClick={() => handleNavigateSellerView(params)}
               target="_blank"
               style={{ color: "#2973f0", cursor: "pointer" }}
               className="dashboard-table-orderid"
             > */}
            {/* {params} */}
            {/* </Link> */}
            {params && (
              <Col style={{ fontWeight: 400 }}>
                {params === "Order Created"
                  ? "Proforma Invoice"
                  : "Tax Invoice"}
              </Col>
            )}
          </>
        );
      },
    },
    // {
    //  // title: "Invoice type",
    //  title: "Status",
    //   dataIndex: "order_status",
    //   key: "order_status",
    //   width: "150px",
    //   render: (params, record) => {
    //     return (
    //       <>
    //         {/* <Link
    //           to={`/admin/sellers/seller-view/${params}`}
    //           // onClick={() => handleNavigateSellerView(params)}
    //           target="_blank"
    //           style={{ color: "#2973f0", cursor: "pointer" }}
    //           className="dashboard-table-orderid"
    //         > */}
    //           {/* {params} */}
    //         {/* </Link> */}
    //         {params && (
    //           <Tag
    //             style={{ padding: "8px" }}
    //             className={`tag-font-style ${statusColorMapping[params]}`}
    //           >
    //             {params}
    //           </Tag>
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      width: "200px",
      render: (params, record) => {
        return (
          <Col
            // onClick={() => handleUserDetailsNavigation(record)}
            style={{ fontWeight: 400 }}
          >
            {params?.name ? params?.name : ""}
          </Col>
        );
      },
    },
    {
      title: "Mobile",
      dataIndex: "customer",
      key: "customer",
      width: "200px",
      render: (params, record) => {
        return (
          <Col
            // onClick={() => handleUserDetailsNavigation(record)}
            style={{ fontWeight: 400 }}
          >
            {params?.phone_number ? params?.phone_number : ""}
          </Col>
        );
      },
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      width: "100px",
      align: "center",

      render: (params, record) => {
        return (
          <Col
            // onClick={() => handleUserDetailsNavigation(record)}
            style={{ fontWeight: 400, textAlign: "center" }}
          >
            {params ? params : "N/A"}
          </Col>
        );
      },
    },

    // {
    //   title: "Sub total",
    //   dataIndex: "sub_total",
    //   key: "sub_total",
    //   width: "120px",
    //   align: "right",
    //   render: (params, record) => {
    //     return (
    //       <Col
    //         // onClick={() => handleUserDetailsNavigation(record)}
    //         style={{ fontWeight: 400 , textAlign: "right"}}
    //       >
    //       {`₹ ${parseFloat(params || 0).toFixed(2)}`}
    //       </Col>
    //     );
    //   },
    // },
    {
      title: "Discount",
      dataIndex: "additional_discount",
      key: "additional_discount",
      width: "100px",
      align: "right",
      render: (params, record) => {
        return (
          <Col
            // onClick={() => handleUserDetailsNavigation(record)}
            style={{ fontWeight: 400 }}
          >
            {`₹ ${parseFloat(params || 0).toFixed(2)}`}
          </Col>
        );
      },
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      width: "100px",
      align: "right",
      render: (params, record) => {
        return (
          <Col
            // onClick={() => handleUserDetailsNavigation(record)}
            style={{ fontWeight: 400 }}
          >
            {`₹ ${parseFloat(params || 0).toFixed(2)}`}
          </Col>
        );
      },
    },
    // {
    //   title: "Delivery fee",
    //   dataIndex: "delivery_fee",
    //   key: "delivery_fee",
    //   width: "120px",
    //   align: "right",
    //   render: (params, record) => {
    //     return (
    //       <Col
    //         // onClick={() => handleUserDetailsNavigation(record)}
    //         style={{ fontWeight: 400, textAlign: "right" }}
    //       >
    //         {`₹ ${parseFloat(params || 0).toFixed(2)}`}
    //       </Col>
    //     );
    //   },
    // },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: "120px",
      align: "right",
      render: (params, record) => {
        return (
          <Col
            // onClick={() => handleUserDetailsNavigation(record)}
            style={{ fontWeight: 400, textAlign: "right" }}
          >
            {`₹ ${parseFloat(params || 0).toFixed(2)}`}
          </Col>
        );
      },
    },
    {
      title: "Amount recieved",
      dataIndex: "amount_received",
      key: "amountrecieved",
      width: "200px",
      align: "right",
      render: (params, record) => {
        return (
          <Col
            // onClick={() => handleUserDetailsNavigation(record)}
            style={{ fontWeight: 400, textAlign: "right" }}
          >
            {`₹ ${parseFloat(params || 0).toFixed(2)}`}
          </Col>
        );
      },
    },
  ];
  
  
  return (
    <Col className="SalesReport-wrapper">
      <Text className="heading">Store Sales</Text>
     
          <>
          <Row style={{justifyContent:"space-between"}}>
            <Col style={{ display: "flex", gap: "1rem" }}>
              <SearchInput
                size={"large"}
                placeholder={"Search by customer name, mobile no, email"}
                width={"400px"}
                getLibraryApi={getSellerSalesReportApi}
                handleSearchedValue={handleSearchedValue}
                isLoading={isFetching}
              />
              <Col className="action-sub-container">
                <CustomSelect
                  placeholder={"Date"}
                  width={"150px"}
                  options={dateOptions}
                  handleChange={handleChange}
                  value={dateFilter}
                />
                {dateFilter === "Custom" && (
                  <DateRangePicker
                    onChange={handleChangeRangePicker}
                    format={"DD-MMM-YYYY"}
                  />
                )}
              </Col>
              <CustomSelect
                placeholder={"Export"}
                width={"150px"}
                options={options}
                handleChange={handleExportApi}
                value={DownloadReportFetching ? <LoadingOutlined /> : "EXPORT"}
                disabled={DownloadReportFetching ? true : false}
                loading={DownloadReportFetching}
              />
               
            </Col>
            <Col className="action-sub-container text-right">
              <button
                  className="btn-theme  text-left btn-height-home add-new-style-font button-inner"
                  onClick={() => (window.location.href = "/seller/storesales")}
                >
                  <img
                    src={CartIcon}
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "12px",
                    }}
                    alt=""
                  />
                  Add new store sale
                </button>
                </Col>
            </Row>
            <CustomTableWithPagination
              className="order-list-table mt-4"
              //   tableExpandable={defaultExpandable}
              // tableDataSource={tableDataSource || []}
              tableDataSource={sellerSalesReportData?.data?.results}
              tableColumns={columns}
              locale={{
                emptyText: isFetching ? <TableSkeleton length={10} /> : "",
              }}
              isLoading={
                sellerSalesReportData?.data?.results?.length && isFetching
              }
              scroll={{ x: "100%" }}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              itemsPerPage={15}
              totalEntries={
                !isFetching &&
                sellerSalesReportData?.data?.pagination?.total_entries
              }
            />
          </>
       
    </Col>
  );
};

export default SellerStoreOrders;
