import React, { useEffect, useState } from "react";
import {
   Avatar,
  Col,  
  Typography,Row,Tag,Tooltip,Button,Modal
} from "antd";
// import { Link } from "react-router-dom";

// eslint-disable-next-line 
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";

import { baseUrl } from "../../../commonUtils/commonUtils";
import { getNameInitials } from "../../../commonFuntions/CommonUtilFunctions";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import {  
    useLazyGetStoreAccessDetailsQuery,
} from "../../../apis/Seller/SellerReportsAPI.js";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
// import { DashOutlined } from "@ant-design/icons";
// import { ReactComponent as DefaultIcon } from "../../../assets/icons/default.svg";
// eslint-disable-next-line 
import dayjs from "dayjs";
import SellerRegistration from "../../../components/SellerComponents/Sellerregisration/SellerRegistration.js";


const { Text } = Typography;
// eslint-disable-next-line 


const SellerStoreAccess = () => {

  // eslint-disable-next-line 
  const [searchedValue, setSearchedValue] = useState({});

  // eslint-disable-next-line 
  const [currentPage, setCurrentPage] = useState(1);

  const [getStoreListAPI, { data: StoreList, isFetching }] =

  useLazyGetStoreAccessDetailsQuery();
  // console.log("🚀 ~ SubscriptionReport ~ getSubscriptionReportApi:", sellerSubscriptionReportData)
  // const [
  //   getDownloadSellerSalesReportApi,
  //   { isFetching: DownloadReportFetching },
  // ] = useLazyGetSubscriptionReportsQuery();
  useEffect(() => {
    getStoreListAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const [isAddNewSellerModalOpen, setIsAddNewSellerModalOpen] = useState(false);
  // const handleExportApi = async () => {    
  //   let response = await getDownloadSellerSalesReportApi({
  //     ...(searchedValue.search && { search: searchedValue.search }),
  //     ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
  //     ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
  //     ...(sorterState.order_id && { sort_by: sorterState.order_id }),
  //     ...(sorterState.sub_order_id && { sort_by: sorterState.sub_order_id }),
  //     ...(sorterState.created_at && { sort_by: sorterState.created_at }),
  //     // download: true,
  //   });
  //   if (response?.status === "fulfilled") {
  //     // handlePdfDownload(response?.data?.data?.url);
  //     saveAs(response?.data?.data?.url);
  //   }
  // };

  const columns = [
    
    {
      title: "Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      fixed: typeof window !== 'undefined' && window.innerWidth > 768 ? "left" : "",
      width: "393px",
      render: (params, record) => {
        return (
          <>
            <Col
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                paddingLeft: record.parentKey ? "60px" : "0px",
              }}
            >
              <Col>
                <Avatar
                  style={{
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f1f1f2",
                  }}
                  shape="square"
                  src={`${baseUrl}${
                    record?.seller_display_image || record?.bmp_image
                  }`}
                  alt="Avatar"
                  // icon={<DefaultIcon width={20} height={20} />}
                >
                  {" "}
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80px",
                    }}
                  >
                    <Text
                      style={{
                        color: "black",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {record?.vendor_name
                        ? getNameInitials(record?.vendor_name)
                        : `N/A`}
                    </Text>
                  </Col>
                </Avatar>
              </Col>
              <Col style={{ fontWeight: 500 }}>{record.vendor_name}</Col>
            </Col>
          </>
        );
      },
    },
    {
      title: "BMP ID",
      dataIndex: "bmp_id",
      key: "bmp_id",
      width: "150px",
      render: (params, record) => {
        return (
          <>
            {/* <Link
              to={`/admin/sellers/seller-view/${params}`}
              // onClick={() => handleNavigateSellerView(params)}
              target="_blank"
              style={{ color: "#2973f0", cursor: "pointer" }}
            > */}
              {params}
            {/* </Link> */}
          </>
        );
      },
    },
    // {
    //   title: "Date of Registration",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   width: "150px",
    //   render: (params, record) => {
    //     return (
    //       <>
           
    //        <Col>
    //         <Text>{params ? dateFormat(params) : ""}</Text>
    //       </Col>
           
    //       </>
    //     );
    //   },
    // },
    {
      title: "mob Web Status",
      dataIndex: "is_active",
      key: "is_active",
      width: "120px",
      render: (params, record) => {
        return <>{record?.is_active ? 
          <Tag color="green" className="tag-green" key={record.id}> Active </Tag> :
          <Tag color="red" className="tag-red" key={record.id}> Inactive </Tag>
        }</>;
      },
    },
    {
      title: "mobCredit Status",
      dataIndex: "mob_credit_enable",
      key: "mob_credit_enable",
      width: "120px",
      render: (params, record) => {      
        return <>{record?.mob_credit_enable ? 
          <Tag color="green" className="tag-green" key={record.id}> Active </Tag> :
          <Tag color="red" className="tag-red" key={record.id}> Inactive </Tag>
        }</>;
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "200px",
      render: (params) => {
        return (
          <>
            <Tooltip title={params} className="rfq-table-remarks" color="#fff">
              <Text style={{ cursor: "pointer" }}>
                {params?.length > 100
                  ? `${params?.slice(0, 40)}...`
                  : params || ""}
              </Text>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: "120px",
    },
    // {
    //   title: <Col style={{ textAlign: "left" }}>Business mobile</Col>,
    //   dataIndex: "phone_number",
    //   key: "phone_number",
    //   width: "180px",
    // },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "250px",
    },
    // {
    //   title: "Business GSTIN certificate",
    //   dataIndex: "gstin_certificate",
    //   key: "gstin_certificate",
    //   width: "200px",
    //   align: "center",
    //   render: (params, record) => {
    //     return (
    //       <>
    //       {params && (
    //       <a
    //         style={{ padding: "8px" }}
    //         className=""
    //         href={params}
    //         target="_blank"
    //         rel="noreferrer"
    //       >
    //         View
    //       </a>
    //       )}
    //       </>
    //     );
    //   },
    // },

    // {
    //   title: "Business PAN card",
    //   dataIndex: "business_pan_card",
    //   key: "business_pan_card",
    //   width: "200px",
    //   align: "center",
    //   render: (params, record) => {
    //     return (
    //       <>
    //       {params && (
    //       <a
    //         style={{ padding: "8px" }}
    //         className=""
    //         href={params}
    //         target="_blank"
    //         rel="noreferrer"
    //       >
    //         View
    //       </a>
    //       )}
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Owner/ Partner/ Director’s Aadhar",
    //   dataIndex: "",
    //   key: "Aadhar",
    //   width: "200px",
    //   align: "center",
    //   render: (parmas, record) => {
    //     return (
    //       <>
    //         {record?.adhar_card && (
    //           <a
    //             style={{ padding: "8px" }}
    //             className=""
    //             href={record?.adhar_card}
    //             target="_blank"
    //             rel="noreferrer"
    //           >
    //             View Upload 1
    //           </a>
    //         )}
    //         {record?.adhar_card_2 && (
    //           <>
    //             <br />
    //             <a
    //               style={{ padding: "8px" }}
    //               className=""
    //               href={record?.adhar_card_2}
    //               target="_blank"
    //               rel="noreferrer"
    //             >
    //              View Upload 2
    //             </a>
    //           </>
    //         )}
    //         {record?.adhar_card_3 && (
    //           <>
    //             <br />
    //             <a
    //               style={{ padding: "8px" }}
    //               className=""
    //               href={record?.adhar_card_3}
    //               target="_blank"
    //               rel="noreferrer"
    //             >
    //               View Upload 3
    //             </a>
    //           </>
    //         )}
    //         {record?.adhar_card_4 && (
    //           <>
    //             <br />
    //             <a
    //               style={{ padding: "8px" }}
    //               className=""
    //               href={record?.adhar_card_4}
    //               target="_blank"
    //               rel="noreferrer"
    //             >
    //               View Upload 4
    //             </a>
    //           </>
    //         )}
    //         {record?.adhar_card_5 && (
    //           <>
    //             <br />
    //             <a
    //               style={{ padding: "8px" }}
    //               className=""
    //               href={record?.adhar_card_5}
    //               target="_blank"
    //               rel="noreferrer"
    //             >
    //               View Upload 5
    //             </a>
    //           </>
    //         )}
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Owner/ Partner/ Director’s PAN card",
    //   dataIndex: "",
    //   key: "Partner",
    //   width: "200px",
    //   align: "center",
    //   render: (parmas, record) => {
    //     return (
    //       <>
    //         {record?.owner_pan_card && (
    //           <a
    //             style={{ padding: "8px" }}
    //             className=""
    //             href={record?.owner_pan_card}
    //             target="_blank"
    //             rel="noreferrer"
    //           >
    //             View Upload 1
    //           </a>
    //         )}
    //         {record?.owner_pan_card_2 && (
    //           <>
    //             <br />
    //             <a
    //               style={{ padding: "8px" }}
    //               className=""
    //               href={record?.owner_pan_card_2}
    //               target="_blank"
    //               rel="noreferrer"
    //             >
    //               View Upload 2
    //             </a>
    //           </>
    //         )}
    //         {record?.owner_pan_card_3 && (
    //           <>
    //             <br />
    //             <a
    //               style={{ padding: "8px" }}
    //               className=""
    //               href={record?.owner_pan_card_3}
    //               target="_blank"
    //               rel="noreferrer"
    //             >
    //               View Upload 3
    //             </a>
    //           </>
    //         )}
    //         {record?.owner_pan_card_4 && (
    //           <>
    //             <br />
    //             <a
    //               style={{ padding: "8px" }}
    //               className=""
    //               href={record?.owner_pan_card_4}
    //               target="_blank"
    //               rel="noreferrer"
    //             >
    //               View Upload 4
    //             </a>
    //           </>
    //         )}
    //         {record?.owner_pan_card_5 && (
    //           <>
    //             <br />
    //             <a
    //               style={{ padding: "8px" }}
    //               className=""
    //               href={record?.owner_pan_card_5}
    //               target="_blank"
    //               rel="noreferrer"
    //             >
    //               View Upload 5
    //             </a>
    //           </>
    //         )}
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Shop Time",
    //   dataIndex: "shop_opening_time",
    //   key: "shop_opening_time",
    //   width: "200px",
    //   render: (parms, record) => {
    //     return (
    //       <>
    //         <Text>
    //           {record?.shop_opening_time} - {record?.shop_closing_time}
    //         </Text>
    //       </>
    //     );
    //   },
    // },
  ];

  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
    //   ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
    //   ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      // ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
      // ...(sorterState.created_at && { sort_by: sorterState.created_at }),
    };
    setCurrentPage(currPage);
    await getStoreListAPI(params);
  };
  const handleAddNewSeller=()=>{
    setIsAddNewSellerModalOpen(true);
  }
  const handleAddNewSellerModalClose = () => {
    setIsAddNewSellerModalOpen(false);
  };
  // const handleSearchedValue = (params) => {
  //   setSearchedValue(params);
  //   setCurrentPage(1);
  // };

  return (
    <>
    <Col className="SalesReport-wrapper">
      <Row>
        <Col span={12}><Text className="screen-heading">Store Access</Text></Col>       
      </Row>
           {/* <Col className="d-flex-colum" style={{ display: "flex", gap: "1rem"}}>
         <SearchInput
          size={"large"}
          placeholder={"Search by customer name, mobile no, email"}
           width={"50%"}
          getLibraryApi={getSubscriptionReportApi}
          handleSearchedValue={handleSearchedValue}
          isLoading={isFetching}
        /> 
       
       
      </Col> */}
      <Col className="d-flex-colum text-right" style={{ display: "flex",justifyContent:"right", gap: "1rem"}}>
             <Button
              size="large"
              className="create-order-button"
              onClick={() => handleAddNewSeller()}
            >
              Add Store{" "}
            </Button>
       
       
      </Col>
      <CustomTableWithPagination
        className="order-list-table table-fixed"
        //   tableExpandable={defaultExpandable}
        // tableDataSource={tableDataSource || []}
        tableDataSource={StoreList?.data}
        tableColumns={columns}
        locale={{
          emptyText: isFetching ? <TableSkeleton length={10} /> : "",
        }}
        isLoading={StoreList?.data?.length && isFetching}
        scroll={{ x: "100%" }}
        // currentPage={currentPage}
        handlePageChange={handlePageChange}
        itemsPerPage={20}
        totalEntries={
          !isFetching && StoreList?.data?.length
        }
      />
    </Col>
    <Modal
      title="Add New Store"
      visible={isAddNewSellerModalOpen}
      onCancel={handleAddNewSellerModalClose}
      footer={[]}
    >
     
      <SellerRegistration handleAddNewSellerModalClose={handleAddNewSellerModalClose} reloadAPI={getStoreListAPI}/>
    </Modal>
    </>
  );
};

export default SellerStoreAccess;
