import React, { useEffect, useState } from "react";
// eslint-disable-next-line 
import { Card, Col, Divider, Form, Input, Typography } from "antd";
// eslint-disable-next-line 
import { ReactComponent as PlusIcon } from "../../../../assets/icons/PlusIconTable.svg";
// eslint-disable-next-line 
import Toggle from "../../../../components/Discount/Toggle";
import { useDispatch } from "react-redux";
import "./TableFooter.css";
// import FloatInput from "./FloatInput";
// eslint-disable-next-line 
const { Text } = Typography;

const TableFooter = ({
  handleAddRow,
  tableIndex,
  selectedSeller,
  dataSource,
  editCondition,
  bmpPrice,
  getBmpPrice,
  billingAddressSelector,
}) => {
  const dispatch = useDispatch();
  const [addBmpDiscount, setAddBmpDiscount] = useState("");
  const [addDeliveryCharge, setAddDeliveryCharge] = useState("");
  // eslint-disable-next-line 
  const [addDeliveryNotes, setAddDeliveryNotes] = useState("");
  const [active, setActive] = useState(0);

  const onSelect = (value) => {
    setActive(value);
  };

  const tax =
    dataSource[tableIndex].reduce((total, row, index) => {
      if (row.quantity === 0 || row.quantity === "") {
        return total + 0;
      }
      return Math.abs(
        total +
          (parseFloat(row.after_tax || 0) - parseFloat(row.before_tax || 0)) *
            parseFloat(row.quantity || 0)
      );
    }, 0) / 2;

  const igstTax = dataSource[tableIndex].reduce((total, row, index) => {
    if (row.quantity === 0 || row.quantity === "") {
      return total + 0;
    }
    return Math.abs(
      total +
        (parseFloat(row.after_tax || 0) - parseFloat(row.before_tax || 0)) *
          parseFloat(row.quantity || 0)
    );
  }, 0);
  const igst = igstTax < 0 ? 0 : igstTax;
  const sgstTax = tax < 0 ? 0 : tax;

  const totalBMPprice = dataSource[tableIndex].reduce(
    (total, row, index) => total + parseFloat(row.bmp_price || 0),
    0
  );
  const FinalBMPprice =
    active === 0
      ? totalBMPprice - parseFloat(addBmpDiscount || 0)
      : totalBMPprice - (totalBMPprice * parseFloat(addBmpDiscount || 0)) / 100;

  const finalBMPprice = FinalBMPprice < 0 ? 0 : FinalBMPprice;

  const discountPrice = dataSource[tableIndex].reduce(
    (total, row, index) =>
      total + (parseFloat(row.discount) || 0) * (parseFloat(row.quantity) || 0),
    0
  );
  const subTotal =
    dataSource[tableIndex].reduce(
      (total, row, index) => total + parseFloat(row.total),
      0
    ) + parseFloat(addDeliveryCharge || 0);
  useEffect(() => {
    if (
      editCondition &&
      (bmpPrice[tableIndex]?.additional_bmp_discount ||
        bmpPrice[tableIndex]?.delivery_fee)
    ) {
      setAddBmpDiscount(bmpPrice[tableIndex]?.additional_bmp_discount);
      setAddDeliveryCharge(bmpPrice[tableIndex]?.delivery_fee);
      onSelect(
        bmpPrice[tableIndex]?.additional_bmp_discount_in === "amount" ? 0 : 1
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCondition, bmpPrice]);

  useEffect(() => {
    dispatch(
      getBmpPrice({
        ...bmpPrice[tableIndex],
        total_bmp_price: totalBMPprice,
        additional_bmp_price: addBmpDiscount,
        delivery_fee: addDeliveryCharge,
        discountPrice: discountPrice,
        subTotal: subTotal,
        index: tableIndex,
        sgst: sgstTax,
        cgst: sgstTax,
        additional_bmp_discount: addBmpDiscount,
        additional_bmp_discount_in: active === 0 ? "amount" : "percentage",
        final_bmp_price: finalBMPprice,
        igst: igst,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addDeliveryCharge,
    addBmpDiscount,
    sgstTax,
    subTotal,
    finalBMPprice,
    totalBMPprice,
  ]);
  // eslint-disable-next-line 
  const handleAddDiscount = (e) => {
    const value = e.target.value;
    const decimalPattern = /^(?:\d+|\d+\.\d{0,2})$/;
    if (decimalPattern.test(value)) {
      setAddBmpDiscount(value || "");
    }
    if (value === "") {
      setAddBmpDiscount("");
    }
  };
  // eslint-disable-next-line 
  const handleAddDeliveryCharge = (e) => {
    const value = e.target.value;
    const decimalPattern = /^(?:\d+|\d+\.\d{0,2})$/;
    if (decimalPattern.test(value)) {
      setAddDeliveryCharge(value || "");
    }
    if (value === "") {
      setAddDeliveryCharge("");
    }
  };

  return (
    <React.Fragment key={tableIndex}>
      {(selectedSeller[tableIndex] || tableIndex !== 0) && (
        <>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              gap: "30px",
              marginBottom: "40px",
            }}
          >
            {/* <Col
              style={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                cursor: "pointer",
              }}
              onClick={() => handleAddRow(tableIndex)}
            >
              <PlusIcon />
              <Text
                style={{
                  fontFamily: "Inter",
                  fontWeight: 500,
                  color: "#015fe5",
                }}
              >
                Add line item
              </Text>
            </Col> */}
            {/* <Col
              style={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <FooterLogo />
              <Text
                style={{
                  fontFamily: "Inter",
                  fontWeight: 500,
                  color: "#015fe5",
                }}
              >
                View mob library
              </Text>
            </Col> */}
          </Col>
          
          {/* <Form
            size="large"
            name="delivery_note"
            style={{ height: "70px", paddingTop: "20px" }}           
            layout="vertical"
          >
            <Form.Item name="delivery_notes_item">
              <FloatInput
                label="Customer note for delivery"
                placeholder="Customer note for delivery"
                name="delivery_notes_input"
                setAddDeliveryNotes={setAddDeliveryNotes}
                value={addDeliveryNotes}
                tableIndex={tableIndex}
                editCondition={editCondition}
              />
            </Form.Item>
          </Form> */}
        </>
      )}
    </React.Fragment>
  );
};

export default TableFooter;
