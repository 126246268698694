import React from "react";
import { Button, Col, Modal, Typography,Card,Row } from "antd";
import "./UpdateOrderModal.css";

const { Text } = Typography;

const UpdateOrderModal = (props) => {
  const { isModalOpen, setIsModalOpen, handleSaveApi, loading, orderButtonText,
    totalAmount,
    // eslint-disable-next-line
    mobCharges,
    // eslint-disable-next-line
    balanceAmount,
    // eslint-disable-next-line 
    paymentReceived,
    oldTotalAmount,
      // eslint-disable-next-line 
    dueAmount,
    // eslint-disable-next-line 
    isCreditOrder} = props;
    // console.log("🚀 ~ UpdateOrderModal ~ oldTotalAmount:", oldTotalAmount)

  const handleSave = () => {
    handleSaveApi();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        onCancel={handleCancel}
        title={"Are you sure?"}
        // closeIcon={null}
        // open={true}
        onOk={handleCancel}
        open={isModalOpen}
        style={{ color: "#0a243f", padding: "0px" }}
        footer={[
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              borderTop: "1px solid #dedede",
              borderRadius: "0px 0px 8px 8px",
              padding: "20px 0px",
              boxShadow: "0 -6px 10px 0 rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
              width: "100%",
            }}
          >
            <Button
              onClick={handleCancel}
              style={{
                height: "48px",
                fontWeight: 500,
                fontSize: "14px",
                // maxWidth: "120px",
                // width: "100%",
              }}
            >
              NO, GO BACK
            </Button>
            <Button
              loading={loading}
              disabled={loading}
              onClick={handleSave}
              style={{
                height: "48px",
                // maxWidth: "220px",
                // width: "100%",
                fontWeight: 500,
                fontSize: "14px",
                color: "#fff",
                backgroundColor: "#0354a3",
                border: "none",
              }}
            >
              {orderButtonText}
            </Button>
          </Col>,
        ]}
      >
        <Col
          style={{
            display: "flex",           
            alignItems: "center",
            padding: "20px",
          }}
        >
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Text
              style={{
                color: "#0a243f",
                marginBottom: "20px",
                fontWeight: 500,
                fontSize: "20px",
              }}
            >
             Are you sure you want to edit this order? Order will be updated immediately
            </Text>
            <Text
              style={{
                color: "#0a243f",
                fontWeight: 500,
                fontSize: "12px",
              }}
            >
             
            </Text>
          </Col>
          
        </Col>
        <Row style={{ padding: "20px" }}>
          {isCreditOrder ? (
            <Col span={24}>
            <Card
              className="edit-Order-model"
              title=""
            >
              <p className="card-content-para">
                <Text>Old Total:</Text> <Text>₹ {Number(oldTotalAmount).toFixed(2)}</Text>
              </p>
              <p className="card-content-para">
                <Text>New Total:</Text> <Text>₹ {Number(totalAmount).toFixed(2)}</Text>
              </p>
              {/* <p className="card-content-para">
                <Text>Total Paid :</Text> <Text>₹ {Number(paymentReceived).toFixed(2)}</Text>
              </p> */}
              {Number(oldTotalAmount) === Number(totalAmount) ? (
                <p className="card-content-para">
                  <Typography.Title level={4} className="mt-0">
                    No change in amount
                  </Typography.Title>

                </p>
              ) : Number(oldTotalAmount) > Number(totalAmount) ? (
                <p className="card-content-para">
                  <Typography.Title level={4} className="mt-0">
                  Amount decreased
                  </Typography.Title>
                  <Typography.Title className="mt-0" level={4}>
                  ₹ {Math.abs(Number(totalAmount) - Number(oldTotalAmount)).toFixed(2)}
                </Typography.Title>
                </p>
              ) : (
                <p className="card-content-para">
                  <Typography.Title level={4} className="mt-0">
                  Amount increased
                  </Typography.Title>
                  <Typography.Title className="mt-0" level={4}>
                  ₹ {Number(Number(totalAmount) - Number(oldTotalAmount)).toFixed(2)}
                </Typography.Title>
                </p>
              )}
            
             
            </Card>
          </Col>
          ) : (
            <Col span={24}>
              <Card
                className="edit-Order-model"
                title=""
              >
                <p className="card-content-para">
                  <Text>Old Total:</Text> <Text>₹ {Number(oldTotalAmount).toFixed(2)}</Text>
                </p>
                <p className="card-content-para">
                  <Text>New Total:</Text> <Text>₹ {Number(totalAmount).toFixed(2)}</Text>
                </p>
                {/* <p className="card-content-para">
                  <Text>Total Paid :</Text> <Text>₹ {Number(paymentReceived).toFixed(2)}</Text>
                </p> */}
                {Number(oldTotalAmount) === Number(totalAmount) ? (
                  <p className="card-content-para">
                    <Typography.Title level={4} className="mt-0">
                      No change in amount
                    </Typography.Title>

                  </p>
                ) : Number(oldTotalAmount) > Number(totalAmount) ? (
                  <p className="card-content-para">
                    <Typography.Title level={4} className="mt-0">
                    Amount decreased
                    </Typography.Title>
                    <Typography.Title className="mt-0" level={4}>
                    ₹ {Math.abs(Number(totalAmount) - Number(oldTotalAmount)).toFixed(2)}
                  </Typography.Title>
                  </p>
                ) : (
                  <p className="card-content-para">
                    <Typography.Title level={4} className="mt-0">
                    Amount increased
                    </Typography.Title>
                    <Typography.Title className="mt-0" level={4}>
                    ₹ {Number(Number(totalAmount) - Number(oldTotalAmount)).toFixed(2)}
                  </Typography.Title>
                  </p>
                )}
              
               
              </Card>
            </Col>
          )}
          <Col span={24} className="bg-green p-3 mt-4 borderradius">
            Manage payments in Payables/ receivables section
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default UpdateOrderModal;
