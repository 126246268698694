import React, { useEffect, useState } from "react";
import "./TermsAndCondition.css";
import { Col, Collapse, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { useDispatch } from "react-redux";
import { useLazyGetVendorDetailsQuery } from "../../../../apis/Seller/SellerAccountsAPI";

const { Text } = Typography;

const TermsAndCondition = (props) => {
  const {
    // termsText,
    editCondition,
    editQuoteData,
    grandPrice,
    getGrandPrice,
  } = props;
  const [textValue, setTextValue] = useState("");
  const dispatch = useDispatch();

  const [SellerDetails] = useLazyGetVendorDetailsQuery();

  useEffect(() => {
    if (!editCondition) {
      SellerDetails().then((response) => {
        if (response?.data?.data) {
          const termsAndCondition = response?.data?.data?.notes_and_terms;
          setTextValue(termsAndCondition);
          dispatch(
            getGrandPrice({
              ...grandPrice,
              terms_and_condition: termsAndCondition,
            })
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SellerDetails]);

  const handleOnChange = (e) => {
    const targetValue = e.target.value;
    setTextValue(targetValue);
  };

  const handleSave = () => {
    dispatch(
      getGrandPrice({
        ...grandPrice,
        terms_and_condition: textValue,
      })
    );
  };

  useEffect(() => {
    if (editCondition && editQuoteData?.terms_and_conditions) {
      setTextValue(editQuoteData?.terms_and_conditions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editQuoteData]);

  return (
    <>
      <Collapse className="main-collapse" size="large">
        <Collapse.Panel
          key="1"
          showArrow={false}
          header={
            <Col
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 0 0 16px",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "500",
                  color: "#0a243f",
                }}
              >
                Terms and conditions
              </Text>
              <Col style={{ margin: "15px", cursor: "pointer" }}>
                <DownOutlined />
              </Col>
            </Col>
          }
        >
          <Col
            style={{
              backgroundColor: "#f2f2f2",
              borderRadius: "16px",
              padding: "16px",
              marginBottom: "16px",
            }}
          >
            <Text
              style={{ color: "#0a243f", fontSize: "14px", fontWeight: 500 }}
            >
              Please insert the terms and conditions relating to deliveries,
              return policy et cetera. It will appear in the invoice.
            </Text>
          </Col>
          <TextArea
            style={{
              borderRadius: "12px",
              border: "1.5px solid #dedede",
              padding: "16px",
            }}
            className={"text-area-collapse-style"}
            placeholder={"Type Here...."}
            value={textValue}
            onChange={handleOnChange}
            onBlur={handleSave}
            onPressEnter={handleSave}
            rows={8}
          />
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default TermsAndCondition;
