import React, { useEffect, useState } from "react";
import "../payables.css";
import { Button, Col, Row, Typography, Checkbox, Affix, Modal } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import TableSkeleton from "../../../../components/skeleton/TableSkeleton";
import Payable1Icon from "../../../../assets/seller/icons/payable1.svg";
import Payable2Icon from "../../../../assets/seller/icons/payable2.svg";
import Payable3Icon from "../../../../assets/seller/icons/payable3.svg";
// import { useDispatch, useSelector } from "react-redux";
// import { getmanageOrdersTableData } from "../../redux/slices/manageOrders/action";
// import { manageOrdersDataSelector } from "../../redux/slices/manageOrders/selector";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../NotificationToast/NotificationToast";
import CustomSelect from "../../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import CustomTableWithPagination from "../../../../components/CustomTableWithPagination/CustomTableWithPagination";
// import UploadPaymentModel from "../../components/Modal/SettlementModal/UploadPaymentModel";
import DateRangePicker from "../../../../components/DateRangePicker/DateRangePicker";

// import ConfirmPaymentModal from "../../../components/Modal/SettlementModal/AddPaymentModal/ConfirmPaymentModal";
import { useLazyGetDownloadPdfPaymentQuery } from "../../../../apis/Seller/SellerSettlementAPI";
import dayjs from "dayjs";

import { baseUrl } from "../../../../commonUtils/commonUtils";
const { Text } = Typography;
const handleTableData = (data) => {
  let tableData = [];
  data?.map((item) => {
    tableData.push({
      ...item,
      id: item?.id,
      key: item?.id,
      date: item?.order_date,
      order_id: item?.order_id,
      order_status: item?.order_status,
      order_stotaltatus: item?.total,
      paid_amount: item?.paid_amount,
      pending_amount: item?.pending_amount,
      total: item?.total,
      items: item?.items,
      is_done: item?.is_done,
      invoice_type: item?.invoice_type,
      checked: false,
    });
    return null;
  });
  return tableData;
};

const CustomerOrders = (props) => {
  const {
    searchValue,
    OrderList,
    isAllOrderDataLoading,
    getAllOrderData,    
    orderNewPaymentApi
  } = props;

  // const dispatch = useDispatch();
  // const selectorData = useSelector(manageOrdersDataSelector);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [totalPendingAmount, settotalPendingAmount] = useState(0);
  const [isFixedBarVisible, setIsFixedBarVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dateSearch, setDateSearch] = useState({
    start_date: "",
    end_date: "",
  });
  const [titleCheckboxChecked, setTitleCheckboxChecked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateFilter, setDateFilter] = useState();

  const [tableDataSource, setTableDataSource] = useState([]);
  // const [addPaymentApi] = useAddSettlementPaymentMutation();
  // const [updatePaymentApi] = useUpdateSettlementPaymentMutation();
  const [
    getDownloadPdfPaymentAPi,
    //  { data: pdfData }
  ] = useLazyGetDownloadPdfPaymentQuery();

  const _onCancel = () => {
    let allRows = tableDataSource.map((item) => {
      item.checkbox = false;
      return item;
    });
    setTableDataSource(allRows);
    setIsFixedBarVisible(false);
    // setTitleCheckboxChecked(false);
    setSelectedRows([]);
  };
  const handleSettleOrders = async () => {
    const totalPendingAmount = Object.values(selectedRows).reduce(
      (acc, current) => acc + current.pending_amount,
      0
    );
    const unsettledAmount = Number(OrderList?.data?.unsettled_amount).toFixed(
      2
    );
    if (totalPendingAmount > unsettledAmount) {
      showErrorToast("Amount amount should be less than unsettled amount!");
      return;
    }
    const params = {
     
      amount: totalPendingAmount,    
      use_unmapped_amount: true,
      orders:  Object.values(selectedRows).map((item) => item.order_id),
    };

    //console.log(params);
    const response = await orderNewPaymentApi(params);
    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      setIsFixedBarVisible(false);
      getAllOrderData({ id: searchValue });
      setSelectedRows([]);
      let allRows = tableDataSource.map((item) => {
            item.checkbox = false;
            return item;
          });
          setTableDataSource(allRows);
    } else if (response?.error) {
      showErrorToast(response?.error?.data?.message);
    }
    // const params = {
    //   phone_number: searchValue,
    //   amount: totalPendingAmount,
    //   payment_date: dayjs().format("YYYY-MM-DD"),
    //   remarks: "",
    //   payment_mode: "Cash",
    //   orders: Object.values(selectedRows).map((item) => item.order_id),
    // };

    // //console.log(params);
    // const response = await orderPaymentApi(params);
    // if (response?.data?.status) {
    //   showSuccessToast(response?.data?.message);
    //   getAllOrderData({ id: searchValue });
    //   setSelectedRows([]);
    //   setIsFixedBarVisible(false);
    //   let allRows = tableDataSource.map((item) => {
    //     item.checkbox = false;
    //     return item;
    //   });
    //   setTableDataSource(allRows);
    // } else if (response?.error) {
    //   showErrorToast(response?.error?.data?.message);
    // }
  };

  useEffect(() => {
    if (OrderList?.data?.results?.length >= 0) {
      const tableData = OrderList?.data?.results
        ? handleTableData(OrderList?.data?.results)
        : "";
      setTableDataSource(tableData);
    }
  }, [OrderList]);

  const handleChange = (value) => {
    let formattedDate = null;

    switch (value) {
      case "Today":
        formattedDate = dayjs().format("YYYY-MM-DD");
        break;
      case "Yesterday":
        formattedDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        break;
      case "last_7_day":
        formattedDate = dayjs().subtract(6, "day").format("YYYY-MM-DD");
        break;
      case "last_30_day":
        formattedDate = dayjs().subtract(29, "day").format("YYYY-MM-DD");
        break;
      case "last_60_day":
        formattedDate = dayjs().subtract(59, "day").format("YYYY-MM-DD");
        break;
      case "Custom":
        formattedDate = "Custom";
        break;
      default:
        break;
    }

    const currentDate = dayjs().format("YYYY-MM-DD");
    if (value === "clear_date") {
      setDateSearch({
        start_date: "",
        end_date: "",
      });
    } else {
      setDateSearch({
        start_date: formattedDate,
        end_date: currentDate,
      });
    }
    setDateFilter(value);
  };
  const handleSearchOnDate = () => {
    if (dateFilter === "clear_date") {
      getAllOrderData({ id: searchValue });
    } else {
      getAllOrderData({ id: searchValue, param: dateSearch });
    }
  };

  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      // ...(searchedValue && { search: searchedValue?.search }),
      ...(dateSearch?.start_date && { start_date: dateSearch?.start_date }),
      ...(dateSearch?.end_date && { end_date: dateSearch?.end_date }),
    };
    setCurrentPage(currPage);
    await getAllOrderData({ id: searchValue, param: params });
  };

  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates && dates.length === 2) {
      const startDateFormatted = dates[0].format("YYYY-MM-DD");
      const endDateFormatted = dates[1].format("YYYY-MM-DD");
      setDateSearch({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
      });
    }
  };
 
  const options = [
    {
      key: 1,
      value: "Today",
      label: "Today",
    },
    {
      key: 2,
      value: "Yesterday",
      label: "Yesterday",
    },
    {
      key: 3,
      value: "last_7_day",
      label: "Last 7 day",
    },
    {
      key: 4,
      value: "last_30_day",
      label: "Last 30 day",
    },
    {
      key: 5,
      value: "last_60_day",
      label: "Last 60 day",
    },
    {
      key: 6,
      value: "Custom",
      label: "Custom",
    },
    {
      key: 7,
      value: "clear_date",
      label: (
        <Col style={{ color: "#006644", fontWeight: "500" }}>Clear Date</Col>
      ),
    },
  ];
  // eslint-disable-next-line
  const handleDownload = async () => {
    const response = await getDownloadPdfPaymentAPi({
      ...(dateSearch?.start_date && { start_date: dateSearch?.start_date }),
      ...(dateSearch?.end_date && { end_date: dateSearch?.end_date }),
    });

    if (response?.status === "fulfilled") {
      window.open(`${baseUrl}${response?.data?.data?.url}`, "_self");
    }
  };
  const handleTitleCheckboxChange = (e) => {
    setTitleCheckboxChecked(e.target.checked);

    if (e.target.checked) {
      let finalProductsArray = [];
      let selectedArray = [];
      // eslint-disable-next-line
      let newTableData = tableDataSource.map((item) => {
        //  console.log("🚀 ~ newTableData ~ item:", item)
        if (!item.checked ) {
          finalProductsArray.push({
            ...item,
            checked: !item.checked ? e.target.checked : false,
          });
        }
        // finalProductsArray.push(item);
        return item;
      });
         
      finalProductsArray.forEach((item, index) => {
        if (item?.checked && !item.is_done) {
          selectedArray.push(item);
        }
      });
   
      if (selectedArray.length > 0) {
        setTableDataSource([...finalProductsArray]);
        setSelectedRows([...selectedArray]); // update this
        setIsFixedBarVisible(selectedArray.length > 0);

        const totalPendingAmount = finalProductsArray.reduce((acc, current) => {
          return acc + current.pending_amount;
        }, 0);
        settotalPendingAmount(totalPendingAmount);
      }
    } else {
      const finalProductsArray = tableDataSource?.map((item) => {
        return { ...item, checked: false };
      });
      setTableDataSource([...finalProductsArray]);
      setSelectedRows([]);
      setIsFixedBarVisible(false);
    }
  };
  const handleCheckboxChange = (e, record, tableIndex) => {
    let currentTableData = [...tableDataSource];
    let parentIndex = "";
    let subOrderList = [];
    // subOrderList = subProducts?.filter((item) => {
    //   if (item?.parentKey === record?.key) {
    //     item.checkbox = true;
    //     return item;
    //   }
    //   return null;
    // });
    currentTableData = currentTableData?.map((table, index) => {
      if (table?.key === record?.key) {
        parentIndex = index;
        if (!table?.expandRow) {
          table?.subChild?.map((child, index) => {
            subOrderList.push({
              parentKey: table.key,
              key: child.id,
              id: child.id,
              date: child?.order_date,
              order_id: child?.order_id,
              order_status: child?.order_status,
              order_stotaltatus: child?.total,
              paid_amount: child?.paid_amount,
              pending_amount: child?.pending_amount,
              total: child?.total,
              items: child?.items,
              is_done: child?.is_done,
              invoice_type: child?.invoice_type,
              checked: !child?.checked ? true : false,
              checkbox: !child?.is_available ? true : false,
            });
            return null;
          });
        } else {
          // subOrderList = [];
        }
        return {
          ...table,
          expandRow: true,
          checked: !table?.checked ? (e.target.checked ? true : false) : false,
        };
      } else {
        if (table?.parentKey === record?.key) {
          return {
            ...table,
            checked: !table?.checked
              ? e.target.checked
                ? true
                : false
              : false,
          };
        } else {
          return table;
        }
      }
    });

    if (e.target.checked) {
      currentTableData.splice(parentIndex + 1, 0, ...subOrderList);
      setTitleCheckboxChecked(false);
    } else {
      setTitleCheckboxChecked(false);
    }

    // const newSelectedRows = e.target.checked
    //   ? [
    //       ...selectedRows,
    //       { ...record, tableIndex },
    //       ...subOrderList?.filter((item) => {
    //         if (item?.parentKey === record?.key && !item?.is_available) {
    //           item.checkbox = true;
    //           return item;
    //         }
    //         return null;
    //       }),
    //     ]
    //   : selectedRows.filter((item) => record?.key !== item?.key);
    let newSelectedRows = currentTableData.filter((item) => item?.checked);
    setSelectedRows(newSelectedRows);
    setTableDataSource(currentTableData);
    setIsFixedBarVisible(newSelectedRows.length > 0);
    const totalPendingAmount = newSelectedRows.reduce((acc, current) => {
      return acc + current.pending_amount;
    }, 0);

    settotalPendingAmount(totalPendingAmount);
  };
  // eslint-disable-next-line
  const downloadOptions = [
    {
      key: 1,
      value: "Excel",
      label: "Excel",
    },
  ];
  const columns = [
    {
      width: "5%",
      // fixed: "left",
      // flex: 1,
      title: (
        <Checkbox
          style={{ paddingLeft: "12px" }}
          checked={titleCheckboxChecked}
          onChange={handleTitleCheckboxChange}
        ></Checkbox>
      ),
      align: "center",
      key: 1,
      // dataIndex: "name",
      render: (params, record, tableIndex) => {
        if (!params?.is_done) {
          return (
            <>
              <Checkbox
                onChange={(e) => handleCheckboxChange(e, record, tableIndex)}
                checked={record?.checked}
              />
            </>
          );
        }
      },
    },
    {
      width: "250px",
      // fixed: "left",
      // flex: 1,
      title: "Billing no",
      key: 2,
      // dataIndex: "name",
      render: (params, record) => {
        return (
          <>
            <Text className="table_text">{params?.order_id}</Text>
          </>
        );
      },
    },
    {
      // fixed: "left",
      // flex: 1,
      // width: "250px",
      title: "Date",
      key: 3,
      dataIndex: "order_date",
      className: "table_text",
      render: (params, record) => {
        return (
          <>
            <Text className="table_text">{params ? params : ""}</Text>
          </>
        );
      },
    },
    {
      // fixed: "left",
      // flex: 1,
      // width: "250px",
      title: "Invoice type",
      key: 4,
      dataIndex: "invoice_type",
      className: "table_text",
    },
    {
      // fixed: "left",
      // flex: 1,
      title: "Items",
      // width: "250px",
      key: 5,
      align: "center",
      dataIndex: "items",
      render: (params, record) => {
        return (
          <>
            <Text className="table_text text-right">{params}</Text>
          </>
        );
      },
    },
    {
      // fixed: "left",
      // flex: 1,
      title: "Total",
      align: "right",
      // width: "350px",
      key: 6,
      dataIndex: "total",
      render: (params, record) => {
        return (
          <>
            <Text className="table_text">₹ {Number(params).toFixed(2)}</Text>
          </>
        );
      },
    },
    {
      // fixed: "left",
      // flex: 1,
      title: "Balance Amount",
      // width: "350px",
      align: "right",
      key: 7,
      dataIndex: "pending_amount",
      render: (params, record) => {
        if (Number(params) < 0) {
          return (
            <Text className="table_text text-right text-success">
              Credit: ₹ {Number(params).toFixed(2)}
            </Text>
          );
        } else if (Number(params) === 0) {
          return (
            <Text className="table_text text-right">
              ₹ {Number(params).toFixed(2)}
            </Text>
          );
        } else {
          return (
            <Text className="table_text text-right text-danger">
              Pending: ₹ {Number(params).toFixed(2)}
            </Text>
          );
        }
      },
    },
    {
      // fixed: "left",
      // flex: 1,
      title: <span></span>,
      // width: "50px",
      align: "right",
      dataIndex: "order_id",
      // key: 8,

      render: (params, record) => {
        return (
          <Link
            // onClick={() => handleDetailNav(record)}
            to={`/Seller/storeorder/OrderDetails/${params}`}
            target="_blank"
            style={{ color: "#0a243f" }}
          >
            <RightOutlined />
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <Col>
        <Row className="bg-yellow notification-strip">
          <Col>
            <Text className="text-static-caption">
              Amount to be mapped against the orders
            </Text>{" "}
            <br />
            <Text className="text-static-caption-bold">
              ₹ {Number(OrderList?.data?.unsettled_amount).toFixed(2)}
            </Text>
          </Col>
          <Col className="text-right">
            <br />
            <Text
              className="title-settle"
              onClick={() => setIsModalVisible(true)}
            >
              How to settle order?
            </Text>
          </Col>
        </Row>
        <Row className="action-container">
          <Col className="action-sub-container">
            <CustomSelect
              placeholder={"Date"}
              width={"150px"}
              options={options}
              handleChange={handleChange}
              value={dateFilter}
            />
            {dateFilter === "Custom" && (
              <DateRangePicker
                onChange={handleChangeRangePicker}
                format={"DD-MMM-YYYY"}
              />
            )}
            <Button
              size="large"
              style={{
                backgroundColor: "#0354a3",
                color: "#fff",
                fontWeight: 500,
                height: "48px",
              }}
              onClick={() => handleSearchOnDate()}
            >
              Search
            </Button>
          </Col>
          <Col className="action-sub-container">
            {/* <CustomSelect
              placeholder={"Download"}
              width={"150px"}
              height={"46px"}
              options={downloadOptions}
              handleChange={handleDownload}
              value={"Download"}
            /> */}
          </Col>
        </Row>
        <Col>
          <CustomTableWithPagination
            className="order-list-table no-wrap"
            tableDataSource={tableDataSource || []}
            tableColumns={columns}
            locale={{
              emptyText: isAllOrderDataLoading ? (
                <TableSkeleton length={15} />
              ) : (
                ""
              ),
            }}
            scroll={{ x: "100%" }}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            itemsPerPage={15}
            totalEntries={
              !isAllOrderDataLoading &&
              OrderList?.data?.pagination?.total_entries
            }
          />
        </Col>
      </Col>
      {isFixedBarVisible && (
        <Affix
          offsetBottom={0}
          offsetTop={10}
          style={{ position: "sticky", zIndex: 999 }}
        >
          <Col className="sticky-col">
            <Col className="sticky-col-inner">
              <Text
                style={{
                  width: "150px",
                  color: "#0a243f",
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                {selectedRows?.length} order selected <br />
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#0a243f",
                    opacity: "0.6",
                  }}
                >
                  {" "}
                  ₹ {Number(totalPendingAmount).toFixed(2)}
                </span>
              </Text>
              <Col style={{ display: "flex", gap: "1rem" }}>
                <Button
                  style={{ height: "46px", padding: "0.5rem 2rem" }}
                  onClick={_onCancel}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "#0354a3",
                    height: "46px",
                    padding: "0.5rem 2rem",
                  }}
                  onClick={() => handleSettleOrders()}
                >
                  MARK AS SETTLED
                </Button>
              </Col>
            </Col>
          </Col>
        </Affix>
      )}

      <Modal
        title={<div className="modal-title-settle">How to Settle Order?</div>}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <div className="modal-footer d-block text-center footer-bottom-border-line">
            <Button
              key="cancel"
              className="btn-cancel order-cancel-btn-style btnI"
              onClick={() => setIsModalVisible(false)}
            >
              CANCEL
            </Button>

            <Button
              key="gotIt"
              type="primary"
              className="btn-theme order-got-btn-style btnI"
              onClick={() => setIsModalVisible(false)}
            >
              GOT IT!
            </Button>
          </div>,
        ]}
      >
        <div style={{ height: "350px", overflow: "auto", padding: "0px" }}>
          <Row className="static-list accept-order-padding pt-0 mt-4">
            <Col span={20}>
              <p className="customer-font-style">
                View the unsettled amount in the orders placed section
              </p>
              <p className="view-order-style">
                {`You can view the total unsettled amount for the customer in their order placed section. 
  You can find it in Payables/ recievables > Select customer > customer details page`}
              </p>
            </Col>
            <Col span={4} className="text-right">
              <img src={Payable1Icon} className="icon-image-static" alt="" />
            </Col>
          </Row>
          <Row className="static-list accept-order-padding">
            <Col span={20}>
              <p className="customer-font-style">
                Click on the checkbox to select order
              </p>
              <p className="view-order-style">
                You can select the orders that you want to settle by clicking on
                the checkbox next to it
              </p>
            </Col>
            <Col span={4} className="text-right">
              <img src={Payable2Icon} className="icon-image-static" alt="" />
            </Col>
          </Row>
          <Row className="static-list accept-order-padding">
            <Col span={20}>
              <p className="customer-font-style">Mark as settled</p>
              <p className="view-order-style">
                Click on “mark as settled” button to settle the payments
              </p>
            </Col>
            <Col span={4} className="text-right">
              <img src={Payable3Icon} className="icon-image-static" alt="" />
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default CustomerOrders;
