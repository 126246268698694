import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Row,
  Typography,
  Avatar,
  Tag,
  Card,
  Menu
} from "antd";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import {
  // PlusOutlined,
  // DownOutlined,
  CloseOutlined,
  DashOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import "./ExistingSeller.css"

import { useNavigate, useParams ,Link} from "react-router-dom";
import SearchDropdown from "../../../components/DropDown/SearchDropdown";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import { UpArrow } from "../../../components/Icons/UpArrow";
import { DownArrow } from "../../../components/Icons/DownArrow";
import { ReactComponent as DefaultIcon } from "../../../assets/icons/default.svg";
import  catalogue1 from "../../../assets/seller/icons/catalogue1.svg";
import  catalogue2 from "../../../assets/seller/icons/catalogue2.svg";
import  catalogue3 from "../../../assets/seller/icons/catalogue3.svg";
import {
  getSellerTableData,
  getViewStatus,
} from "../../../redux/slices/sellerCatalogue/action";
import { useDispatch, useSelector } from "react-redux";
import {
  useLazyGetExistingSellerInventoryPriceQuery,
  useLazyDownloadSellerCatalogueQuery,
} from "../../../apis/sellerCatalogue";
import { sellerCatalogueTableSelector } from "../../../redux/slices/sellerCatalogue/selector";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
import {
  colorMapping,
  handlePdfDownload,
} from "../../../commonFuntions/CommonUtilFunctions";
import { baseUrl } from "../../../commonUtils/commonUtils";
// import CatalogueEmptyComponent from "./CatalogueEmptyComponent";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import EmptyCatalougeTable from "../../../components/EmptyTable/EmptyCatalougeTable";

const { Text } = Typography;
const { Meta } = Card;
const handleTableData = (params) => {
  let initialArrVal = [];
  let parentSubOrder = [];

  params?.results?.products?.map((item, index) => {
    let obj = {
      key: item.id,
      productName: item.product_name,
      image: item.images[0] ? `${baseUrl}${item.images[0]?.image}` : "",
      mob_sku: item.mob_sku,
      brand_name: item.brand_name,
      mrp: item.mrp,
      uom: item.mob_sku,
      category: item.category,
      subCategory: item.sub_category_id,
      expandRow: false,
      subproducts: item.subproducts,
      selling_price: item.your_selling_price,
      rfq_price: item.rfq_price,
      tax: item?.product_tax_code?.tax,
      hsn_code: item?.hsn_code,
      sub_category_lvl_1: item?.sub_category_id,
      sub_category_lvl_2: item?.sub_category_id_2,
      vendor_data: item?.vendor_data,
      is_vendor_product: item?.is_vendor_product,
      slug: item?.slug,
    };
    initialArrVal.push(obj);
    // parentSubOrder.push(obj);
    if (item?.subproducts?.length) {
      item?.subproducts?.map((element, index) => {
        parentSubOrder.push({
          parentKey: item.id,
          key: element.id,
          // icon: <Col className="table-icon">...</Col>,
          productName: element.product_name,
          image: element.images[0]
            ? `${baseUrl}${element.images[0]?.image}`
            : "",
          status: (
            <Col
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <Col style={{ fontWeight: 500 }}>Active</Col>
            </Col>
          ),
          mob_sku: element.mob_sku,
          brand_name: element.brand_name,
          mrp: item.mrp,
          selling_price: element.your_selling_price,
          rfq_price: element.rfq_price,
          tax: element?.product_tax_code?.tax,
          hsn_code: element?.hsn_code,
          uom: element.mob_sku,
          category: element.category,
          sub_category_lvl_1: element?.sub_category_id,
          sub_category_lvl_2: element?.sub_category_id_2,
          subCategory: element.sub_category_id,
          is_vendor_product: element?.is_vendor_product,
          vendor_data: element?.vendor_data,
          slug: item?.slug,
        });
        return null;
      });
    }
    return null;
  });

  return { initialArrVal, parentSubOrder };
};

const ExistingSeller = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { seller_id: urlSellerId } = useParams();
  const bmpId = localStorage.getItem("bmp_id");
  const seller_id = urlSellerId || bmpId;
  const tableSelector = useSelector(sellerCatalogueTableSelector);
  const [tableDataSource, setTableDataSource] = useState([]);
  const [searchedValue, setSearchedValue] = useState({ search: "" });

  const [subProducts, setSubProducts] = useState([]);
  const [
    ,
    // subCategoryLength
    setSubCategoryLength,
  ] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  // const [addedFrom, setAddedFrom] = useState([]);
  const [sellerTableApi, { data: tableData, isFetching: isLoading }] =
    useLazyGetExistingSellerInventoryPriceQuery();
    // eslint-disable-next-line 
  const [excelApi, { isFetching: exlLoading, data: excelApiData }] =
  useLazyDownloadSellerCatalogueQuery();
  // const _onRowClick = (data) => {
  //   setProductTableData(data);
  //   // dispatch(getTableRowData(data));
  // };
  // const onEdit = () => {
  //   navigate(`/admin/sellers/update-seller/${productTableData?.key}`, {
  //     state: { id: productTableData?.key },
  //   });
  // };
  useEffect(() => {
    sellerTableApi({ id: seller_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (tableData?.status === true) {
      dispatch(getSellerTableData(tableData?.data));
      dispatch(
        getViewStatus({
          bmp_id: tableData?.data?.results?.vendor_info?.bmp_id,
          vendor_name: tableData?.data?.results?.vendor_info?.vendor_name,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);
  useEffect(() => {
    if (tableSelector) {
      // console.log("🚀 ~ useEffect ~ tableSelector:", tableSelector)
      const data = tableSelector ? handleTableData(tableSelector) : "";
      // console.log("🚀 ~ useEffect ~ data:", data)
      setTableDataSource(data?.initialArrVal || []);
      setSubProducts(data?.parentSubOrder || []);
    }
  }, [tableSelector]);

  const handlePageChange = async (currPage) => {
    let brandArray = [];
    let categoriesArray = [];
    brands.map((item) => brandArray.push(item.label));
    categories.map((item) => categoriesArray.push(item.label));
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
      ...(brandArray.length && { brands: brandArray }),
      ...(categoriesArray.length && { category: categoriesArray }),
    };
    setCurrentPage(currPage);
    await sellerTableApi({ id: seller_id, params });
  };

  const handleFilterLibrary = () => {
    let brandArray = [];
    let categoriesArray = [];
    brands.map((item) => brandArray.push(item.label));
    categories.map((item) => categoriesArray.push(item.label));
    sellerTableApi({
      id: seller_id,
      params: {
        ...(brandArray.length && { brands: brandArray }),
        ...(categoriesArray.length && { category: categoriesArray }),
        page: 1,
      },
    });
    setCurrentPage(1);
  };
  const handleFilterSelect = (label) => {
    let brandArray = [];
    let categoriesArray = [];
    brands.map((item) => brandArray.push(item?.label));
    categories.map((item) => categoriesArray.push(item?.label));
    if (label === "Categories") {
      sellerTableApi({
        id: seller_id,
        params: {
          ...(brandArray.length && { brands: brandArray }),
          page: 1,
        },
      });
    } else if (label === "Brands") {
      sellerTableApi({
        id: seller_id,
        params: {
          ...(categoriesArray.length && { category: categoriesArray }),
          page: 1,
        },
      });
    }
    setCurrentPage(1);
  };
  const handleClearFilter = () => {
    setCategories([]);
    setBrands([]);
    sellerTableApi({ id: seller_id });
    setCurrentPage(1);
  };
  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };
  const handleExportApi = () => {
    let brandArray = [];
    let categoriesArray = [];
    brands.map((item) => brandArray.push(item.label));
    categories.map((item) => categoriesArray.push(item.label));
    excelApi({
      id: seller_id,
      params: {
        ...(brandArray.length && { brands: brandArray }),
        ...(categoriesArray.length && { category: categoriesArray }),
        ...(searchedValue.search && { search: searchedValue.search }),
      },
    }).then(response => {
      console.log(response); // API response
      if (response?.data?.data?.url) {
        handlePdfDownload(response?.data?.data?.url);
      }
    }).catch(error => {
      console.error(error); // Handle errors
    });

   

    // excelApi();
  };
  const handleRowClick = (record, open) => {
    // let currentTableData = [...tableDataSource];
    let currentTableData = tableDataSource.filter((item) => !item.parentKey);
    let parentIndex = "";
    let subOrderList = subProducts.filter(
      (item) => item.parentKey === record.key
    );
    currentTableData = currentTableData.map((table, index) => {
      if (table.key === record.key) {
        parentIndex = index;
        return { ...table, expandRow: open === "up" ? true : false };
      } else {
        return { ...table, expandRow: false };
      }
    });

    if (open === "up") {
      currentTableData.splice(parentIndex + 1, 0, ...subOrderList);
      setSubCategoryLength(subOrderList.length || 0);
    } else {
      // currentTableData.splice(parentIndex + 1, subOrderList.length);
      setSubCategoryLength(0);
    }

    setTableDataSource(currentTableData);
  };
  // const items = [
  // {
  //   label: "Edit",
  //   key: "1",
  //   onClick: onEdit,
  // },
  // {
  //   label: "Activate",
  //   key: "2",
  //   // onClick: onActivate,
  // },
  // {
  //   label: "Delete",
  //   key: "3",
  //   danger: true,
  //   // onClick: onDelete,
  // },
  // ];
  // const menuProps = {
  //   items,
  // };
  const columns = [
    // {
    //   title: (
    //     <>
    //       {/* <PlusOutlined
    //         style={{
    //           backgroundColor: "#fff",
    //           borderRadius: "50%",
    //           padding: "3px",
    //         }}
    //       /> */}
    //     </>
    //   ),
    //   key: "icon",
    //   dataIndex: "icon",
    //   fixed: "left",
    //   width: "50px",
    //   render: (params, record) => {
    //     return (
    //       <>
    //         <Dropdown menu={menuProps} trigger={"click"}>
    //           <DashOutlined onClick={() => _onRowClick(record)} />
    //         </Dropdown>
    //       </>
    //     );
    //   },
    //   // height: "60px",
    // },
    {
      
      key: "icon",
      dataIndex: "slug",
      fixed: "left",
      width: "50px",
      render: (params, record) => {
        // console.log("🚀 ~ ExistingSeller ~ params:", params)
        return (
          <>
            <Dropdown
              overlay={
                <Menu>
                  {/* <> */}
                  <Menu.Item
                    // onClick={onEdit}
                    style={{ padding: "7px 12px" }}
                  >
                    <Link
                      to={`/seller/createProduct/${params}`}
                      target="_blank"
                    >
                      <Col className="container-list">
                        <Text className="select-text">Edit</Text>
                      </Col>
                    </Link>
                  </Menu.Item>
                  
                  {/* <Menu.Item
                    onClick={handleDeleteModalOpen}
                    style={{
                      padding: "7px 12px",
                    }}
                  >
                    <Col className="container-list">
                      <Text
                        className="select-text"
                        style={{ color: "#bf2600" }}
                      >
                        Delete
                      </Text>
                    </Col>
                  </Menu.Item> */}
                  {/* </> */}
                </Menu>
              }
              trigger={"click"}
            >
              <DashOutlined  />
            </Dropdown>
          </>
        );
      },
      // height: "60px",
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
     // fixed: "left",
     fixed: typeof window !== 'undefined' && window.innerWidth > 768 ? "left" : "",
      width: "393px",
      render: (params, record) => {
        return (
          <>
            <Col
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                paddingLeft: record?.parentKey ? "60px" : "0px",
                padding: "10px 4px 10px 20px",
              }}
            >
              {!(record?.parentKey || !record?.subproducts?.length) && (
                <Col>
                  {record?.expandRow ? (
                    <UpArrow
                      className={"cursor"}
                      onClick={() => handleRowClick(record, "down")}
                    />
                  ) : (
                    <DownArrow
                      className={"cursor"}
                      onClick={() => handleRowClick(record, "up")}
                    />
                  )}
                </Col>
              )}
              <Col>
                <Avatar
                  style={{
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f1f1f2",
                  }}
                  shape="square"
                  src={record?.image}
                  alt="Avatar"
                  icon={<DefaultIcon width={20} height={20} />}
                />
              </Col>
              <Col style={{ fontWeight: 500, textAlign: "left" }}>
                {record?.productName}
              </Col>
            </Col>
          </>
        );
      },
    },
    {
      title: "Added",
      dataIndex: "is_vendor_product",
      key: "is_vendor_product",
      width: "150px",
      render: (param, record) => {
        return (
          <>          
            <Tag
              // className={`${colorMapping["MOB library"]}`}
              className={`${colorMapping[param ? "locally" : "MOB library"]}`}
            >
              {/* MOB library */}
              
              {param ? "Locally" : "MOB library"} 
           
            </Tag>
          </>
        );
      },
    },
    // {
    //   title: "BMPId",
    //   dataIndex: "bmp_id",
    //   key: "bmp_id",
    //   width: "120px",
    //   render: (param, record) => {
    //     return <>{param}</>;
    //   },
    // },
    {
      title: "Brand name",
      dataIndex: "brand_name",
      key: "brand_name",
      width: "150px",
      render: (param, record) => {
        return <>{param}</>;
      },
    },
    {
      title: <Col style={{ textAlign: "center" }}>Tax %</Col>,
      dataIndex: "tax",
      key: "tax",
      width: "120px",
      render: (param, record) => {
        return <Col style={{ textAlign: "center" }}>{`${param}%`}</Col>;
      },
    },
    {
      title: "Store sale price",
      dataIndex: "selling_price",
      key: "store_selling_price",
      width: "150px",
      render: (params, record) => {
        return (
          <Col style={{ textAlign: "center" }}>{
            record?.vendor_data?.store_selling_price
              ? `₹ ${ parseFloat(
                  record?.vendor_data?.store_selling_price || 0
                )?.toFixed(2)}`
              : "--"
          }</Col>
        );
      },
    },
    // {
    //   title: "RFQ price",
    //   dataIndex: "rfq_price",
    //   key: "rfq_price",
    //   width: "150px",
    //   render: (params, record) => {
    //     return (
    //       <Col style={{ textAlign: "center" }}>{
    //         record?.vendor_data?.rfq_price
    //           ?`₹ ${ parseFloat(record?.vendor_data?.rfq_price || 0)?.toFixed(2)}`
    //           : "--"
    //       }</Col>
    //     );
    //   },
    // },
    // {
    //   title: "MOB Purchase Price",
    //   dataIndex: "dealer_price",
    //   key: "dealer_price",
    //   width: "150px",
    //   render: (params, record) => {
    //     return (
    //       <Col style={{ textAlign: "center" }}>{
    //         params ?`₹ ${ parseFloat(params || 0)?.toFixed(2)}` : "--"
    //       }</Col>
    //     );
    //   },
    // },
    {
      title: <Col style={{ textAlign: "center" }}>MRP</Col>,
      dataIndex: "mrp",
      key: "mrp",
      width: "150px",
      render: (params, record) => {
        return (
          <Col style={{ textAlign: "center" }}>{
            params ?`₹ ${ parseFloat(params || 0)?.toFixed(2)}` : "--"
          }</Col>
        );
      },
    },
    
    {
      title: "HSN Code",
      dataIndex: "hsn_code",
      key: "hsn_code",
      width: "120px",
    },
    {
      title: "Available inventory",
      dataIndex: "stock",
      key: "stock",
      width: "200px",
      render: (params, record) => {
        return (
          <Col style={{ textAlign: "center" }}>
            {record?.vendor_data?.stock || ""}
          </Col>
        );
      },
    },
    {
      title: "SKU",
      dataIndex: "mob_sku",
      key: "mob_sku",
      width: "120px",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: "150px",
    },
    {
      title: "Sub category level 1",
      dataIndex: "sub_category_lvl_1",
      key: "sub_category_lvl_1",
      width: "200px",
    },
    {
      title: "Sub category level 2",
      dataIndex: "sub_category_lvl_2",
      key: "sub_category_lvl_2",
      width: "200px",
    },
  ];
  const handleNavigatePricing = () => {
    if (bmpId) {
      navigate(`/seller/update-pricing/${seller_id}`);
    } else {
      navigate(`/admin/sellers/update-pricing/${seller_id}`);
    }
  };

  const handleSellerLibNavigation = () => {
    if (bmpId) {
      navigate(`/seller/mob-library/${seller_id}`);
    } else {
      navigate(`/admin/sellers/update-pricing/${seller_id}`);
    }
  };
  const options = [
    {
      key: 1,
      value: "Excel",
      label: "Excel",
    },
  ];
  return (
    <>
      <Col>
        <Row>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <Text
              style={{
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: 1.6,
              }}
            >
              Catalogue
            </Text>
            <Text
              style={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: 1.6,
              }}
            >
              {`${tableSelector?.pagination?.total_entries} items`}
            </Text>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Col
            style={{
              display: "flex",
              gap: "16px",
            }}
          >
            <SearchInput
              size={"large"}
              placeholder={"Search by name, description, SKU"}
              getLibraryApi={sellerTableApi}
              width={"400px"}
              id={seller_id}
              handleSearchedValue={handleSearchedValue}
              isLoading={isLoading}
            />
            <CustomSelect
              placeholder={"Export"}
              width={"150px"}
              options={options}
              handleChange={handleExportApi}
              value={exlLoading ? <LoadingOutlined /> : "Export"}
              disabled={exlLoading ? true : false}
              loading={exlLoading}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              gap: "16px",
            }}
          >
            <Button
              size="large"
              style={{ fontWeight: 500, height: "48px" }}
              onClick={handleNavigatePricing}
            >
            Bulk price upload
            </Button>
            <Button
              size="large"
              style={{
                backgroundColor: "#0354a3",
                color: "#fff",
                fontWeight: 500,
                height: "48px",
              }}
              onClick={handleSellerLibNavigation}
            >
              Add item from library
            </Button>
          </Col>
        </Row>
        <Row
          style={{
            marginBottom: "20px",
          }}
        >
          <Col
            style={{
              display: "flex",
              gap: "12px",
              alignItems: "center",
              height: "60px",
            }}
          >
            <SearchDropdown
              list={tableSelector?.results?.categories}
              label={"Categories"}
              placeholder={"Search categories"}
              selectedItems={categories}
              setSelectedItems={setCategories}
              handleFilterLibrary={handleFilterLibrary}
              handleFilterSelect={handleFilterSelect}
            />
            <SearchDropdown
              list={tableSelector?.results?.brands}
              label={"Brands"}
              placeholder={"Search brands"}
              selectedItems={brands}
              setSelectedItems={setBrands}
              handleFilterLibrary={handleFilterLibrary}
              handleFilterSelect={handleFilterSelect}
            />
            {/* <SearchDropdown
              // list={tableSelector?.results?.brands}
              label={"Added from"}
              placeholder={"Added from"}
              selectedItems={addedFrom}
              setSelectedItems={setAddedFrom}
              handleFilterLibrary={handleFilterLibrary}
            /> */}
            {/* <Select
            defaultValue="Sort"
            style={{
              width: 120,
              height: "48px",
            }}
            // onChange={handleChange}
            options={[]}
          /> */}
            {(brands?.length !== 0 || categories?.length !== 0) && (
              <Button
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: "30px",
                  height: "48px",
                  fontWeight: 500,
                  border: "none",
                }}
                onClick={handleClearFilter}
              >
                <CloseOutlined /> Clear filters
              </Button>
            )}
          </Col>
        </Row>
        {/* {searchedValue?.search ? (
          <CustomTableWithPagination
            className="mob-list-table"
            tableDataSource={tableDataSource || []}
            tableColumns={columns}
            locale={{
              emptyText: isLoading ? (
                <TableSkeleton length={30} />
              ) : (
                <EmptyCatalougeTable />
              ),
            }}
            scroll={{ x: "100%" }}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            itemsPerPage={50}
            totalEntries={
              !isLoading && tableData?.data?.pagination?.total_entries
            }
          />
        ) : (
          <CatalogueEmptyComponent />
        )} */}
        <CustomTableWithPagination
          className="mob-list-table table-fixed"
          tableDataSource={tableDataSource || []}
          tableColumns={columns}
          locale={{
            emptyText: isLoading ? (
              <TableSkeleton length={30} />
            ) : (
              <EmptyCatalougeTable />
            ),
          }}
          scroll={{ x: "100%" }}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          itemsPerPage={50}
          totalEntries={
            !isLoading && tableData?.data?.pagination?.total_entries
          }
          isLoading={tableDataSource.length && isLoading}
        />
        {tableDataSource.length<=0 && (
          <>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
               margin:"16px 0px 16px",
                justifyContent:"space-between"
              }}
            >
              <Text
                style={{
                  fontSize: "20px",
                  fontWeight: 500,
                  lineHeight: 1.6,
                }}
              >
                How to build your store catalogue like magic in minutes?
              </Text>
              <Text
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: 1.6,
                }}
              >
                <Button
              size="large"
              style={{                
                color: "#015fe5",
                fontWeight: 500,
                height: "48px",
                border: "solid 1.5px #015fe5"
              }}
              onClick={handleSellerLibNavigation}
            >
              View MOB library
            </Button>
              </Text>
            </Col>
            <Col style={{display:"flex",justifyContent:"space-between"}}>
            <Card
              style={{ width: 350,background:"#f1f1f2", borderRadius: "16px" }}
              cover={
                <img
                  alt="Seller"
                  src={catalogue1}
                  style={{height:"250px",width:"240px",objectFit: "contain"}}
                />
              }
            >
              <Meta className="card-meta-tile-product"              
                title="Find 10000+ items in MOB library"
                description="Browse throgh a library of products to add to your catalogue"
              />
            </Card>
            <Card
              style={{ width: 350,background:"#f1f1f2", borderRadius: "16px" }}
              cover={
                <img
                alt="Seller"
                src={catalogue2}
                style={{height:"250px",width:"240px",objectFit: "contain"}}
                />
              }
            >
               <Meta className="card-meta-tile-product"      
                title="Select and edit details"
                description="Select the items you want to add and update their, selling price and stock."
              />
            </Card>
            <Card
              style={{ width: 350,background:"#f1f1f2", borderRadius: "16px" }}
              cover={
                <img
                alt="Seller"
                src={catalogue3}
                style={{height:"250px",width:"240px",objectFit: "contain"}}
                />
              }
            >
               <Meta className="card-meta-tile-product"       
                title="Add to catalogue"
                description="Click on “add to catalogue” button to add the selected items to your catalogue."
              />
            </Card>
            </Col>
          </>
        )}
      </Col>
    </>
  );
};

export default ExistingSeller;
