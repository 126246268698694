import React, { useEffect, useState } from "react";
import "./Sellers.css";
import {
  // PlusOutlined,
  // DownOutlined,
  DashOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Menu,
  Row,
  Tooltip,
  Typography,
} from "antd";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import {
  useActivateTableMutation,
  useLazyGetSellerListQuery,
} from "../../../apis/sellerCatalogue";
import { useDispatch, useSelector } from "react-redux";
import {
  getEditSellerVenderDetail,
  getSellerData,
} from "../../../redux/slices/sellerCatalogue/action";
import { sellerDataSelector } from "../../../redux/slices/sellerCatalogue/selector";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
// import { ReactComponent as DefaultIcon } from "../../../assets/icons/default.svg";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
import { Link, useNavigate } from "react-router-dom";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import StatusModal from "../../../components/Modal/StatusModal/StatusModal";
import { getNameInitials } from "../../../commonFuntions/CommonUtilFunctions";
import { baseUrl } from "../../../commonUtils/commonUtils";
import NoDataFound from "../../../components/EmptyTable/NoDataFound";

const handleTableData = (data) => {
  let tableData = [];
  data?.results?.map((item) => {
    tableData.push({
      ...item,
      vendor_name: item?.vendor_name,
      is_active: item?.is_active,
      bmp_id: item?.bmp_id,
      address: item?.address,
      city: item?.city,
      phone_number: item?.phone_number,
      email: item?.email,
      shop_closing_time: item?.shop_closing_time,
      shop_opening_time: item?.shop_opening_time,
      id: item?.id,
      bmp_image: item?.bmp_image,
      seller_display_image: item?.seller_display_image,
    });
    return null;
  });
  return tableData;
};
const Sellers = () => {
  const { Text } = Typography;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableData = useSelector(sellerDataSelector);
  const [tableDataSource, setTableDataSource] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedValue, setSearchedValue] = useState({});
  const [tableActiveData, setTableActiveData] = useState("");
  const [sellerListApi, { data: sellerListData, isFetching: isLoading }] =
    useLazyGetSellerListQuery();
  const [activateTableRowApi] = useActivateTableMutation();
  useEffect(() => {
    sellerListApi();
    dispatch(getEditSellerVenderDetail({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (sellerListData?.status) {
      dispatch(getSellerData(sellerListData?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerListData]);
  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
    };
    setCurrentPage(currPage);
    await sellerListApi(params);
  };
  const onActivate = async () => {
    const params = {
      mob_vendor_id: tableActiveData?.id,
      is_active: !tableActiveData?.is_active,
    };
    const response = await activateTableRowApi(params);
    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      setShowStatusModal(false);
      sellerListApi();
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };

  const handleStatus = (record) => {
    setShowStatusModal(true);
    setTableActiveData(record);
  };
  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (tableData) {
      const data = tableData ? handleTableData(tableData) : "";
      setTableDataSource(data);
    }
  }, [tableData]);
  // const handleNavigateSellerView = (id) => {
  //   navigate(`/admin/sellers/seller-view/${id}`);
  // };
  const handleNavigateAddSeller = () => {
    navigate(`/admin/sellers/add-seller`);
  };

  // const onEdit = (record) => {
  //   navigate(`/admin/sellers/add-seller/${record?.bmp_id}`);
  // };

  const columns = [
    {
      // title: (
      //   <PlusOutlined
      //     style={{
      //       backgroundColor: "#fff",
      //       borderRadius: "50%",
      //       padding: "3px",
      //     }}
      //   />
      // ),
      key: "icon",
      dataIndex: "icon",
      fixed: "left",
      width: "50px",
      render: (params, record) => {
        return (
          <>
            <Dropdown
              overlay={
                <Menu>
                  <>
                    <Menu.Item
                      // onClick={() => onEdit(record)}
                      style={{ padding: "7px 12px" }}
                    >
                      <Link
                        to={`/admin/sellers/add-seller/${record?.bmp_id}`}
                        target="_blank"
                      >
                        <Col className="container-list">
                          <Text className="select-text">Edit</Text>
                        </Col>
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => handleStatus(record)}
                      style={{ padding: "7px 12px" }}
                    >
                      <Col className="container-list">
                        <Text className="select-text">
                          {record?.is_active ? "Deactivate" : "Activate"}
                        </Text>
                      </Col>
                    </Menu.Item>
                    {/* <Menu.Item
                      //   onClick={handleDeleteModalOpen}
                      style={{
                        padding: "7px 12px",
                      }}
                    >
                      <Col className="container-list">
                        <Text
                          className="select-text"
                          style={{ color: "#bf2600" }}
                        >
                          Delete
                        </Text>
                      </Col>
                    </Menu.Item> */}
                  </>
                </Menu>
              }
              trigger={"click"}
            >
              <DashOutlined
              //   onClick={() => _onRowClick(record)}
              />
            </Dropdown>
          </>
        );
      },
      // height: "60px",
    },
    {
      title: "Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      fixed: "left",
      width: "393px",
      render: (params, record) => {
        return (
          <>
            <Col
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                paddingLeft: record.parentKey ? "60px" : "0px",
              }}
            >
              <Col>
                <Avatar
                  style={{
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f1f1f2",
                  }}
                  shape="square"
                  src={`${baseUrl}${
                    record?.seller_display_image || record?.bmp_image
                  }`}
                  alt="Avatar"
                  // icon={<DefaultIcon width={20} height={20} />}
                >
                  {" "}
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80px",
                    }}
                  >
                    <Text
                      style={{
                        color: "black",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {record?.vendor_name
                        ? getNameInitials(record?.vendor_name)
                        : `N/A`}
                    </Text>
                  </Col>
                </Avatar>
              </Col>
              <Col style={{ fontWeight: 500 }}>{record.vendor_name}</Col>
            </Col>
          </>
        );
      },
    },
    {
      title: "BMP ID",
      dataIndex: "bmp_id",
      key: "bmp_id",
      width: "150px",
      render: (params, record) => {
        return (
          <>
            <Link
              to={`/admin/sellers/seller-view/${params}`}
              // onClick={() => handleNavigateSellerView(params)}
              target="_blank"
              style={{ color: "#2973f0", cursor: "pointer" }}
            >
              {params}
            </Link>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: "120px",
      render: (params, record) => {
        return <>{record?.is_active ? "Active" : "Inactive"}</>;
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "200px",
      render: (params) => {
        return (
          <>
            <Tooltip title={params} className="rfq-table-remarks" color="#fff">
              <Text style={{ cursor: "pointer" }}>
                {params?.length > 100
                  ? `${params?.slice(0, 40)}...`
                  : params || ""}
              </Text>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: "120px",
    },
    {
      title: <Col style={{ textAlign: "right" }}>Business mobile</Col>,
      dataIndex: "phone_number",
      key: "phone_number",
      width: "180px",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "250px",
    },
    {
      title: "Shop Time",
      dataIndex: "shop_opening_time",
      key: "shop_opening_time",
      width: "200px",
      render: (parms, record) => {
        return (
          <>
            <Text>
              {record?.shop_opening_time} - {record?.shop_closing_time}
            </Text>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Col style={{ padding: "2rem" }}>
        <Row>
          <Col className="header-container">
            <Text className="heading">Sellers</Text>
          </Col>
        </Row>
        <Row className="action-container">
          <Col className="action-sub-container">
            <SearchInput
              size={"large"}
              placeholder={"Search by shop name, mobile no, email"}
              getLibraryApi={sellerListApi}
              width={"400px"}
              isLoading={isLoading}
              handleSearchedValue={handleSearchedValue}
            />
          </Col>
          <Col className="action-sub-container">
            <Button
              size="large"
              className="create-order-button"
              onClick={handleNavigateAddSeller}
            >
              Add seller{" "}
            </Button>
          </Col>
        </Row>
        <CustomTableWithPagination
          className="order-list-table"
          //   tableExpandable={defaultExpandable}
          tableDataSource={tableDataSource || []}
          tableColumns={columns}
          locale={{
            emptyText: isLoading ? (
              <TableSkeleton length={10} />
            ) : (
              <NoDataFound text={"No sellers found!"} />
            ),
          }}
          scroll={{ x: "100%" }}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          itemsPerPage={sellerListData?.data?.pagination?.end_index}
          totalEntries={
            !isLoading && sellerListData?.data?.pagination?.total_entries
          }
          isLoading={tableDataSource?.length && isLoading}
        />
      </Col>
      <StatusModal
        open={showStatusModal}
        setOpen={setShowStatusModal}
        handleOk={onActivate}
        data={tableActiveData}
      />
    </>
  );
};

export default Sellers;
