import { Avatar, Button, Col, Typography, Upload } from "antd";
import React, { useEffect } from "react";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import { ReactComponent as RemoveIcon } from "../../../assets/icons/RemoveImageIcon.svg";
//   import { ReactComponent as DefaultIcon } from "../../../assets/icons/default.svg";
import "./CreateSellerProduct.css";

const ProductImageUpload = (prop) => {
  const { data, fileList, setFileList } = prop;

  const handleFileChange = async (info) => {
    const newFileList = [...info.fileList];
    setFileList(newFileList);
    //setFileList([list]);
    //   const params = {
    //     product: data.id,
    //     image: info.file,
    //   };
    // const response = await editProductImageApi(params);
    // if (response?.error) {
    //   showErrorToast(response?.error?.data?.message);
    // } else {
    //   const data = response?.data?.data;
    //   showSuccessToast(response?.data?.message);
    //   setFileList([
    //     ...fileList,
    //     {
    //       uid: "-1",
    //       name: "",
    //       id: data.id,
    //       status: "done",
    //       url: data.image,
    //     },
    //   ]);
    // }
    //   const file = info.fileList[0];
    //   const apiData = await addImageApi(file.originFileObj);
    //   if (apiData?.data?.status) {
    //     const data = apiData?.data?.data;
    //     // showSuccessToast(apiData?.data?.message);
    //     setFileList([
    //       ...fileList,
    //       {
    //         uid: "-1",
    //         name: "",
    //         id: data.id,
    //         status: "done",
    //         url: data.image,
    //       },
    //     ]);
    //   }
  };

  useEffect(() => {
    if (data?.images?.length > 0) {
      let list = [];
      data?.images?.map((item) => {
        list.push({
          uid: "-1",
          name: "image.png",
          id: item.id,
          status: "done",
          url: item.image,
        });
        return null;
      });
      setFileList(list);
    }
    // eslint-disable-next-line 
  }, [data]);

  const props = {
    name: "image",
    action: "",
    beforeUpload: () => false,
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: "Download",
      showRemoveIcon: true,
      removeIcon: <CloseOutlined />,
    },
    accept: ".png,.jpeg,.jpg,image/png,image/jpeg,image/jpg",
  };

  const listCondition = fileList?.length === 0;
  const handleRemoveFile = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);

    setFileList(newFileList);
  };

  const Text = Typography;

  const customFileListRender = (fileList) => {
    console.log("🚀 ~ customFileListRender ~ fileList:", fileList);

    return (
      <>
        {fileList.map((item, indx) => {
          console.log("🚀 ~ {fileList.map ~ item:", item);

          return (
            <Col key={indx} style={{ marginBottom: "10px" }}>
              <Avatar
                className="styled-avatar"
                shape="square"
                src={URL.createObjectURL(item?.originFileObj)}
                onError={(e) => {
                  e.target.src = "/path/to/placeholder/image.jpg"; // Fallback image
                  console.error("Error loading image:", e);
                }}
              />
              <Col
                className="cross-wrapper"
                onClick={() => handleRemoveFile(item)}
                style={{ cursor: "pointer" }}
              >
                <RemoveIcon />
              </Col>
            </Col>
          );
        })}
      </>
    );
  };

  return (
    <Col style={{ width: "100%" }}>
      <Text className="heading-text">Image</Text>
      <Col className="btn-container">
        <Upload
          {...props}
          className="upload-list"
          showUploadList={false}
          onChange={(info) => handleFileChange(info)}
        >
          {/* <Progress
            type="line"
            percent={
              fileList.status === "done"
              ? 100
              : Math.floor(fileList[fileList.length - 1]?.percent)
            }
            status={fileList.status === "done" ? "success" : "active"}
          /> */}
          <Col className="style-btn">
            {fileList.length > 9 ? (
              ""
            ) : (
              <Button icon={<UploadOutlined />}>Add Image</Button>
            )}
          </Col>
        </Upload>
      </Col>
      <Col className="list-wrapper">
        {!listCondition && <>{customFileListRender(fileList)}</>}
      </Col>
    </Col>
  );
};

export default ProductImageUpload;
